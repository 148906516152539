import React, { useState } from "react";
import "./super.css"
import { Route, Switch, useHistory } from "react-router-dom";
import Sidebar from "../Reusables/Sidebar";
import Dashboard from "./Dashboard/Dashboard";
import News from "./News/News";
import Header from "./Dashboard/Header/Header";
import Hotel from "./Hotel/Hotel";
import Price from "./Price/Price";
// import History from "./Wallet/History"
import E404 from "../../404/404";
import AddXHotel from "./Hotel/External/AddXHotel";
import HotelState from "../Junior/Manage/Context/hotelState";
import Sources from "./Hotel/sources/Sources"
import Settings from "../Reusables/Settings";
import ListServices from "../Reusables/Services/ListServices";
import AllUsers from "./users";
import Contracts from "./Contracts";
import DMCs from "./DMCs";
import AccountOwners from "./AccountOwners";
import Services from "./Services";
import History from "./Wallet/newWallet";
import Bookings from "./Bookings";
import BookingEngine from "../Reusables/Bookings/Bookings";
import Packages from "./Packages/Packages";
const Super = () => {
  const [sidebar, setSidebar] = useState("hide");
  const [mainWrapper, setMainWrapper] = useState("")

  // const [main, setMain] = useState("col-12")
  const handleClick = () => {
    sidebar === "hide" ? setSidebar("showsidebar"): setSidebar("hide")
    sidebar === "hide" ? setMainWrapper("d-flex justify-content-end wrapper-width") : setMainWrapper("mainWrapper")

  };

  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear()
    history.push("/login")
  }
  return (
  <HotelState>
    <div className="dash h-100 d-flex justify-content-end" style={{position:"relative", minHeight:"100vh"}}>
      <Sidebar sidebar={sidebar} close={handleClick} handleLogout={handleLogout} />
      <div className={`${mainWrapper} px-0 mainWrapper` }>
        <div className="col-12">
          <Header handleClick={handleClick} />
            <Switch>
              <Route exact path={["/admin", "/admin/dashboard"]} component={Dashboard}  />
              <Route path="/admin/news" component={News} />
              <Route  path="/admin/hotel" component={Hotel} />
              <Route path="/admin/hotel/add-external" component={AddXHotel} />
              <Route path="/admin/pricing" component={Price} />
              {/* <Route path="/admin/payment-history" component={History} /> */}
              <Route path="/admin/payment-history" component={History} />
              <Route path="/admin/bookings" component={Bookings} />
              <Route path="/admin/search" component={() => <BookingEngine admin={true} />} />
              <Route path="/admin/sources" component={Sources} />
              <Route path="/admin/profile" component={Settings} />
              {/* <Route path="/admin/services" component={ListServices} /> */}
              <Route path="/admin/users" component={AllUsers} />
              <Route path="/admin/dmcs" component={DMCs} />
              <Route path="/admin/account-owners" component={AccountOwners} />
              <Route path="/admin/contracts" component={Contracts} />
              <Route path="/admin/services" component={Services} />
              <Route path="/admin/packages" component={Packages} />
              <Route path="*" component={E404} />

            </Switch>
        </div>
      </div>
    </div>
  </HotelState>
  );
};

export default Super;
