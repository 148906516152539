import React, {useState, useEffect,useRef} from "react"
import {useLocation} from 'react-router-dom';
import {Fetch} from "../../../../controllers/controller"

const PayPal = ({amount, setLoading, setAlertType, setShowAlert, addMoneyToDB})=>{
    const paypal = useRef();
    
    useEffect(() => {
      window.paypal.Buttons({
          createOrder: (data, action, err)=>{
              return action.order.create({
                  intent: "CAPTURE",
                  purchase_units: [
                      {
                          description: "Make payment",
                          amount:{
                              currency_code:"USD",
                              value:amount
                          }
                      }
                  ]
              })
            }, 
            onApprove: async(data, actions) => {
                const order = await actions.order.capture();
                console.log(order)
                addMoneyToDB("paypal")

            },
            onError: (err)=>{
                window.alert(err)
                console.log(err)
            }


      }).render(paypal.current);
    }, []);
    
    return(
        <div className="w-75 mx-auto">
            <div ref={paypal}></div>
        </div>
    );
}

export default PayPal
