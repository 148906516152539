import React from 'react'
import {TimestampConverter } from "../../../../controllers/controller"
const Row = ({count, timestamp, type, amount, log, source}) => {
  return (
        <tr>
            <td>{count}</td>
            <td style={{ minWidth: "120px" }}>{timestamp && TimestampConverter(timestamp)}</td>
            <td>{type}</td>
            <td>{amount}</td>
            <td>{source === "0" ? "N/A" : source}</td>
            <td>{log}</td>
        </tr>
    )
}

export default Row