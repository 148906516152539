import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Fetch } from "../../../../controllers/controller";
import RequestError from "../../../404/RequestError";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import AddDay from "../../Reusables/Packages/AddDay";
import PackageDeets from "../../Reusables/Packages/PackageDeets";

const Create = () => {
    const [pkg, setPkg] = useState({});
    const [reload, setReload] = useState(false);
    const [packageDetail, setPackage] = useState({});
    const [days, setDays] = useState([]);
    const [priceLoading, setPriceLoading] = useState(true);
    const [addNewDay, setAddNewDay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [preview, setPreview] = useState(null);
    const handleAlertClose = () => {
      setShowAlert(false);
    };
    const [image, setImage] = useState(null);
  
    const handleImage = (e) => {
      setImage(e.target.files[0]);
    };
  
    useEffect(() => {
      if (!image) {
        setPreview(null);
        return;
      }
  
      const objUrl = URL.createObjectURL(image);
      setPreview(objUrl);
      return () => {
        URL.revokeObjectURL(objUrl);
        setPreview(null);
      };
    }, [image]);
  
    console.log(preview)
    const formik = useFormik({
      initialValues: {
        description: "",
        youtube: "",
        sharing: "",
      },
      onSubmit: (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("package_id", packageDetail.package_id);
        formData.append("description", values.description);
        formData.append("youtube_link", values.youtube);
        formData.append("sharing_link", values.sharing);
        formData.append("avatar", image);
  
        Fetch(
          `${process.env.REACT_APP_END_POINT}/update-package-content`,
          formData
        )
          .then((res) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
          })
          .catch((err) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "danger", value: err.message });
          });
      },
    });
  
    useEffect(() => {
      const data = localStorage.getItem("pkg");
      if (data) {
        const pkg = JSON.parse(data);
        setPackage(pkg);
      }
    }, []);
  
    useEffect(() => {
      setPriceLoading(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("package_id", packageDetail.package_id);
  
      Fetch(`${process.env.REACT_APP_END_POINT}/list-package-day`, formData)
        .then((res) => {
          setPriceLoading(false);
          if (res.data.success !== false) {
            setDays(res.data);
          }
        })
        .catch((err) => {
          setPriceLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }, [packageDetail, reload]);
    return (
      <div>
        <div>
          {showAlert === true && (
            <AlertComp
              variant={alert.type}
              alertText={alert.value}
              handleAlertClose={handleAlertClose}
            />
          )}
        </div>
        <Tabs defaultActiveKey="content" className="mb-3 title_font">
          <Tab eventKey="content" title="CONTENT">
            <form
              className="row justify-content-around packages"
              onSubmit={formik.handleSubmit}
            >
              <div className="row justify-content-around">
                <div className="col-md-8">
                  <div className="row justify-content-between">
                    <div className="form-group mb-2 col-sm-5">
                      <label htmlFor="id" className="form-label">
                        Package ID
                      </label>
                      <input
                        type="text"
                        value={packageDetail.package_id}
                        name="id"
                        id="id"
                        disabled
                        className="form-control px-2 py-2"
                      />
                    </div>
                    <div className="form-group mb-2 col-sm-5">
                      <label htmlFor="name" className="form-label">
                        Package Name
                      </label>
                      <input
                        type="text"
                        disabled
                        value={packageDetail.package_name}
                        onBlur={formik.handleBlur}
                        name="name"
                        id="name"
                        className="form-control px-2 py-2"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7 row">
                      <div className="form-group mb-2 col-9">
                        <label htmlFor="youtube link" className="form-label">
                          Youtube Link
                        </label>
                        <input
                          type="url"
                          onChange={formik.handleChange}
                          value={formik.values.youtube}
                          onBlur={formik.handleBlur}
                          name="youtube"
                          id="youtube link"
                          className="form-control px-2 py-2"
                        />
                      </div>
                      <div className="form-group mb-2 col-9">
                        <label htmlFor="sharing link" className="form-label">
                          Sharing Link
                        </label>
                        <input
                          type="url"
                          onChange={formik.handleChange}
                          value={formik.values.sharing}
                          onBlur={formik.handleBlur}
                          name="sharing"
                          id="sharing link"
                          className="form-control px-2 py-2"
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 ps-1 col-sm-5 d-flex flex-column justify-content-center">
                      <div>
                        {preview && (
                          <img
                            src={preview}
                            className="preview_image"
                            alt="preview"
                          />
                        )}
                      </div>
                      <label htmlFor="image" className="form-label">
                        Image
                      </label>
                      <input
                        type="file"
                        className="w-75"
                        onChange={handleImage}
                        name="image"
                        id="image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-2 col-12">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                        name="description"
                        className="d-block w-100 px-4 py-4 form-control"
                        id="description"
                        cols="40"
                        rows="8"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* row ends */}
          </Tab>
          <Tab eventKey="price" title="PRICE">
            <form>
              <div className="row justify-content-center">
                <div className="col-sm-12 row ">
                  <div className="row">
                    <div className="form-group mb-2 col-lg-2 col-sm-3 px-1">
                      <label htmlFor="id" className="form-label text-dark">
                        Package ID
                      </label>
                      <input
                        type="text"
                        onChange={formik.handleChange}
                        value={packageDetail.package_id}
                        disabled
                        onBlur={formik.handleBlur}
                        name="id"
                        id="id"
                        className="form-control px-2 py-2"
                      />
                    </div>
                    <div className="form-group mb-2 col-lg-2  col-sm-3 px-1">
                      <label htmlFor="name" className="form-label text-dark">
                        Package Name
                      </label>
                      <input
                        type="text"
                        onChange={formik.handleChange}
                        value={packageDetail.package_name}
                        onBlur={formik.handleBlur}
                        disabled
                        name="name"
                        id="name"
                        className="form-control px-2 py-2"
                      />
                    </div>
                  </div>
                  <div>
                    <RequestError>
                      {days.length > 0 &&
                        days.map((day) => <PackageDeets item={day} />)}
                    </RequestError>
  
                    <div>{addNewDay && <AddDay reload={setReload} />}</div>
                    <div className="mt-5">
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          setAddNewDay(!addNewDay);
                        }}
                      >
                        Add New Day
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4 mx-auto my-2">
                    {loading ? (
                      <button className="btn w-100 btn-primary">
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      </button>
                    ) : (
                      <button className="btn w-100 btn-primary">
                        Submit Package
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </Tab>
        </Tabs>
      </div>
    );
  };
  
  export default Create;
  