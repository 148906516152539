import React from 'react'
import { Redirect, Route } from 'react-router-dom';

const PrivateDMC = ({component: Component, ...rest}) => {
    const auth = true
    const item = localStorage.getItem("DMC");
    const useable = JSON.parse(item)

  return (
    <Route
      {...rest}
      render={
        (props) =>
        useable !== null? <Component {...props} /> : <Redirect to="/login" />
        // auth === true? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default PrivateDMC
