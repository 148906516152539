import React from "react";
import CountriesImage from "../../../../../../Images/dmcCircle.png";

const Countries = () => {
  return (
    <div className="card pt-5 col-12 col-md-3 shadow" >
      <div>
        <div className="top d-flex justify-content-center" style={{ marginBottom: "52px" }}>
          <img src={CountriesImage} alt=""  style={{margin: "auto"}}/>
        </div>
        <div className="bottom w-100 px-3">
          <div>
            <div className="countries-wrapper mb-3 align-items-center d-flex justify-content-between px-lg-2">
              <div className="left d-flex justify-content-center me-5">
                <div className="color me-3 mt-1" style={{ height: "17px", width: "17px", backgroundColor: "rgba(97, 214, 176, 1)", }} ></div>
                <div className="name">USA</div>
              </div>
              <div className="right">18.0%</div>
            </div>
            <div className="countries-wrapper mb-3 align-items-center d-flex justify-content-between px-lg-2">
              <div className="left d-flex justify-content-center  me-5">
                <div className="color me-3 mt-1 bg-warning" style={{ height: "17px", width: "17px" }} ></div>
                <div className="name">FRANCE</div>
              </div>
              <div className="right">18.0%</div>
            </div>
            <div className="countries-wrapper mb-3 align-items-center d-flex justify-content-between px-lg-2">
              <div className="left d-flex justify-content-center  me-5">
                <div className="color me-3 mt-1" style={{ height: "17px", width: "17px", backgroundColor: "rgba(107, 86, 213, 1)"}} ></div>
                <div className="name">UAE</div>
              </div>
              <div className="right">18.0%</div>
            </div>
            <div className="countries-wrapper mb-3 align-items-center d-flex justify-content-between px-lg-2">
              <div className="left d-flex justify-content-center  me-5">
                <div className="color me-3 mt-1" style={{ height: "17px", width: "17px", backgroundColor: "rgba(238, 121, 141, 1)" }} ></div>
                <div className="name">UK</div>
              </div>
              <div className="right">18.0%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countries;
