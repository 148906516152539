import {
  usePDF,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import Receipt from "./Receipt";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// export const MyDoc = (
//     // <Document>
//     // <Page size="A4" style={styles.page}>
//      <Main  />
//   //   </Page>
//   // </Document>
// );

const PDF = ({ name, data }) => {
  const MyDoc = (
    // <Document>
    // <Page size="A4" style={styles.page}>
    <Receipt data={data} />
    //   </Page>
    // </Document>
  );
  const [instance, updateInstance] = usePDF({ document: MyDoc });
  if (Object.keys(data).length > 0) {
    updateInstance();
  }
  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong:</div>;

  return (
    <a
      href={instance.url}
      download={`${data.senderFullname}.pdf`}
      className="btn btn-outline-success mb-3"
      style={{ fontSize: "12px" }}
    >
      Download Receipt
    </a>
  );
};
export default PDF;
