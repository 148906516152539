import React, { useState } from "react";
import { Route, useLocation } from "react-router-dom";
// import "./contracts.css";
import Modals from "./Modal";
import Home from "../../../Reusables/Packages/Home";
import CreatePackages from "../../../Reusables/Packages/CreatePackages";

const Packages = ({ match }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const location = useLocation();

  const handleShow = () => setShow(true);

  // rooms modal
  const [open, setOpen] = useState(false);
  const handleRemove = () => setOpen(false);

  const handleOpen = () => {};

  return (
    <main className="pt-4 px-lg-4 px-3" style={{ minHeight: "83vh" }}>
      <header className=" mb-3 d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">PACKAGES</h4>
        <button
          className={
            location.pathname === "/dmc/packages/create"
              ? "d-none"
              : `btn btn-lg-lg btn-primary contract-btn`
          }
          onClick={handleShow}
        >
          <span className="button-text me-2 ">CREATE A PACKAGE</span>
          <span>
            <i className="bi bi-plus-circle"></i>
          </span>{" "}
        </button>
      </header>

      <div className="content">
        <Route exact path={"/dmc/packages"} component={Home} />
        <Route path={"/dmc/packages/create"} component={CreatePackages} />
      </div>
      <Modals
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        handleOpen={handleOpen}
        setShow={setShow}
      />
    </main>
  );
};

export default Packages;
