import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {TimestampConverter} from "../../../../controllers/controller"
const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    }
    
  });


  const ReceiptNo = ({data}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text>{data.senderFullname} </Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text >{new Date().toLocaleDateString()}</Text>
            </View >

        </Fragment>
  );
  
  export default ReceiptNo