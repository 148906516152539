import React, { useState } from 'react';
import {Alert} from "react-bootstrap"

function AlertComp({variant, alertText,handleAlertClose }) {
    const [show, setShow] = useState(true)
    return (
        
        <Alert variant={variant} onClose={() => setShow(false)} >
            <div className="d-flex justify-content-between">
                {alertText}
                <p><i className="bi bi-x" onClick={handleAlertClose}></i></p>
            </div>
        </Alert>
       
    )
}

export default AlertComp
