import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import ReceiptTitle from './ReceiptTitle'
// import BillTo from './BillTo'
import ReceiptNo from './ReceiptNo'
import ReceiptItemsTable from './ReceiptItemsTable'
// import StatementThankYouMsg from './StatementThankYouMsg'


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const Receipt = ({data}) => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <ReceiptTitle title='HotelsOffline'/>
                    <ReceiptNo data={data}/>
                    <ReceiptItemsTable data={data} />
                </Page>
            </Document>
        );
  
  export default Receipt