import React from 'react'
import LastestBlog from '../../Cards/latestBlogcard/LastestBlogCard'
import SectionHeader from '../SectionHeader/SectionHeader';
import LatestBlogImage from '../../../Images/Classical.png';

const LatestBlog = () => {
    return (
        <section className="container latestBlog">
            <SectionHeader title="Read the latest from the blog" lead="Homes highly rated for thoughtful design" />
            <div className="row justify-content-center justify-content-lg-start">
                <div className="col-12 col-sm-6 col-lg-3">
                    <LastestBlog cardImage={LatestBlogImage} cardTitle="ECOTOURISM" date="20/10/2010" location="Hilton new hotels" detail="Welcoming hilton hotels onboard" />
                </div>
            </div>
        </section>
    )
}

export default LatestBlog
