import React from "react";
import Banner from "./Banner/Banner";
import BottomBanner from "./BottomBanner/BottomBanner";
import Classical from "./Classical/Classical";
import Cookie from "./Cookie/Cookie";
import Destinations from "./Destinations/Destinations";
import Footer from "./Footer/Footer";
import LatestBlog from "./LatestBlog/LatestBlog";
import Listing from "./Listing/Listing";
import Offer from "./Offer/Offer";
import Promotions from "./Promotions/Promotions";
import Rental from "./Rental/Rental";
import Subscription from "./Subscription/Subscription";

const Landing = () => {
  const [ showCookie, setShowCookie ] = React.useState(true);
  const handleClick = () => {
    setShowCookie(false);
  };
  return (
    <div className="landing">
      <Banner />
      <Offer />
      <Listing />
      <Destinations />
      <Promotions />
      <Rental />
      <Classical />
      <LatestBlog />
      <BottomBanner />
      <Subscription />
      <Footer />
      {showCookie && <Cookie handleClick={handleClick} />}
    </div>
  );
};

export default Landing;
