import React from 'react'

const News = () => {
    return (
        <div>
            <div className="display-4 text-center">Coming soon...</div>
        </div>
    )
}

export default News
