import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Countries from "../../../Signup/Countries/Countries";
import OverrideTable from "./OverrideTable";

const Override = () => {

  const [loader, setLoader] = useState(false)
  const [contractLoader, setContractLoader] = useState(false);
  const [hotelLoader, setHotelLoader] = useState(false);
  const [dmcLoader, setDmcLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recipient, setRecipient] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [country, setCountry] = useState("");
  const [hotel, setHotel] = useState("");
  const [selectCountry, setSelectCountry]= useState(false)
  const [selectHotel, setSelectHotel]= useState(false)
  const [blur, setBlur] = useState(false)
  const [blur2, setBlur2] = useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [showForm, setShowForm] = useState(false)
  const handleBlur = (e) => {
    setBlur(!blur)
  }
  const handleFocus = (e) => {
    setBlur(!blur)
  }
  const handleBlur2 = (e) => {
    setBlur2(!blur2)
  }
  const handleFocus2 = (e) => {
    setBlur2(!blur2)
  }
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const changeHandler = value => {
    setHotelLoader(true)
    setCountry(value.label)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("country", value.label === "All Countries" ? value.value : value.label);

    Fetch(`${process.env.REACT_APP_END_POINT}/markup-hotel-list`, formData)
    .then((res)=> {
      setHotelLoader(false)

      if (res.data.message === "No results") {
        window.alert(res.data.message)
        setHotelList([])
        setContractList([])


      } else {
        setHotelList(res.data)
      setSelectCountry(true)

      }
    })
    .catch((err) => {
      setHotelLoader(false)
      console.log(err)
    })
  }

  
 

  const handleHotelOption = (e) => {
    setHotel(e.target.value)
    setContractLoader(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("country", country);
    formData.append("hoteltoken", e.target.value);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-contract-markup`, formData)
    .then((res)=> {
    setContractLoader(false)

      if (res.data.message === "No results") {
        setContractList([])
        window.alert(res.data.message)
      } else {
        setContractList(res.data)
        setSelectHotel(true)
      }
    })
    .catch((err) => {
      setContractLoader(false)  
      console.log(err)
    })
  }


  const formik = useFormik({
    initialValues: {
      target_amount1: "",
      override1: "",
    },
    
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_token", values.contract);
      formData.append("type", values.type);
      formData.append("hoteltoken", hotel);
      formData.append("country", country);
      formData.append("target_amount1", values.amount);
      formData.append("target_amount2", values.amount2);
      formData.append("target_amount3", values.amount3);
      formData.append("recipient", values.recipient);
      formData.append("nature", values.nature);
      formData.append("override1", values.override1);
      formData.append("override2", values.override2);
      formData.append("override3", values.override3);
      formData.append("start_duration", values.start_duration);
      formData.append("end_duration", values.end_duration);
      Fetch(`${process.env.REACT_APP_END_POINT}/override`, formData)
        .then((res) => {
          setLoading(false);
          setShowAlert(true);
          if(res.data.success){
            setAlertType({ ...alert, type: "success", value: res.data.message });
            setShowForm(false)
            setLoader(true)
            setTimeout(() => {
              setLoader(false)
            }, 2000);
          } else {
            setAlertType({ ...alert, type: "danger", value: res.data.message });

            
          }
          // window.location.reload()
        })
        .catch((err) => window.alert(err));
    },
  });

  console.log(formik.values.contract)

  useEffect(()=>{
    let mounted = true
    if(formik.values.type === "dmc"){
      setDmcLoader(true)
      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        Fetch(`${process.env.REACT_APP_END_POINT}/list-dmc`, formData)
          .then((res) => {
            setDmcLoader(false)
            if(mounted){
              setRecipient(res.data)
            }
            
          })
          .catch((err) => window.alert(err));
    } else if(formik.values.type === "hotel") {
      setDmcLoader(true)

      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        Fetch(`${process.env.REACT_APP_END_POINT}/list-hotels`, formData)
          .then((res) => {
            setDmcLoader(false)
            if(mounted){
              setRecipient(res.data)
            }
          })
          .catch((err) => {
            window.alert(err)
            setDmcLoader(false)
          });
        } 

    return ()=>{
      mounted = false
    }
  },[formik.values.type])
    

  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return  (
    <div className="row justify-content-center ">
      {showAlert === true && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      <div className="wrap col-12 p-4 border shadow">

        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex w-100 justify-content-between mb-2 ">
              <div className="search d-flex align-items-center" style={{gap:"1rem"}} >
                <input
                  type="text"
                  className="form-control"
                  placeholder="search..."
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div style={{flex: "50%"}}>
              <button className="btn btn-primary" onClick={()=>setShowForm(!showForm)}>{showForm ? "Close Form" : "Create Override"}</button>
            </div>
          </div>

          {
            showForm && 

          <form onSubmit={formik.handleSubmit} className="p-4 row align-items-center g-3">
            <div className="row">
              <div className="form-group col-md-3 col-sm-4 px-1">
                <label htmlFor="duration" className="form-label">
                  Start Duration
                </label>
                  <input
                    id="start_duration"
                    name="start_duration"
                    className="form-control border border-dark "
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.start_duration}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Date From"
                  />
                {formik.errors.start_duration && formik.touched.start_duration ? (
                  <small className="text-danger">{formik.errors.start_duration}</small>
                ) : null}
              </div>
              <div className="form-group col-md-3 col-sm-4 px-1">
                <label htmlFor="duration" className="form-label text-left ">
                  End Duration
                </label>
                  <input
                    id="end_duration"
                    name="end_duration"
                    className="form-control border border-dark  col"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.end_duration}
                    onFocus={handleFocus2}
                    onBlur={handleBlur2}
                    placeholder="Date To"
                  />
                {formik.errors.end_duration && formik.touched.end_duration ? (
                  <small className="text-danger">{formik.errors.end_duration}</small>
                ) : null}
              </div>
            </div>
            <div className="row mt-5">
              <div className="form-group col-md-3 col-sm-4 px-1">
                <label htmlFor="type" className="form-label visually-hidden">
                  User Type
                </label>
                <select
                  name="type"
                  id="type"
                  className="form-select border border-dark "
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  onBlur={formik.handleBlur}
                >
                  <option value="" default>
                    User Type
                  </option>
                  <option value="dmc">DMC</option>
                  <option value="hotel">Hotel</option>
                </select>
              </div>
              {/* <div className="form-group col-md-3 col-sm-4 px-1">
              {dmcLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                <>
                  <label htmlFor="recipient" className="form-label visually-hidden">
                    User
                  </label>
                  <select
                    name="recipient"
                    id="recipient"
                    className="form-select border border-dark "
                    onChange={formik.handleChange}
                    value={formik.values.recipient}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" default>
                      User
                    </option>
                      {recipient.length > 0 && recipient.map(item=>(
                        <option value={item.hotelid || item.token} dangerouslySetInnerHTML={{__html: item.hotel_name || item.last_name + " " +  item.first_name + " - " + item.mail}} />

                      ))}
                  </select>
                </>
                }
              </div> */}
                <div className="form-group col-md-3 col-sm-4 px-1">
                  <label htmlFor="type" className="form-label visually-hidden">
                    Nature
                  </label>
                  <select
                    name="nature"
                    id="nature"
                    className="form-select border border-dark "
                    onChange={formik.handleChange}
                    value={formik.values.nature}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" default>
                      Nature
                    </option>
                    <option value="Pay">Pay</option>
                    <option value="Earn">Earn</option>
                  </select>
                </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="form-group col-md-3 col-sm-4 px-1">
                <label htmlFor="type" className="form-label visually-hidden">
                  Country
                </label>
                <div className="">
                <Countries changeHandler={changeHandler}  />
                </div>
              </div>
              <div className="form-group col-md-3 col-sm-4 px-1">
              {hotelLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                <>
                  <label htmlFor="recipient" className="form-label visually-hidden">
                    User
                  </label>
                  <select
                    name="hotel"
                    id="hotel"
                    className="form-select border border-dark "
                    onChange={handleHotelOption}
                    value={hotel}
                    onBlur={formik.handleBlur}
                    disabled={selectCountry === true ? false: true}

                  >
                    <option value="" default>
                      User
                    </option>
                    {hotelList.length > 0 && hotelList.map(hotel =>
                          <option  value={hotel.hoteltoken} dangerouslySetInnerHTML={{__html:  hotel.hotel_name}}  />
                    )}
                  </select>
                </>
              }
              </div>
              <div className="form-group col-md-3 col-sm-4 px-1">
              {contractLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                <>
                  <label htmlFor="nature" className="form-label visually-hidden">
                    Contract
                  </label>
                  <select
                    name="contract"
                    id="contract"
                    className="form-select border border-dark "
                    onChange={formik.handleChange}
                    value={formik.values.contract}
                    onBlur={formik.handleBlur}
                    disabled={selectHotel === true ? false: true}

                  >
                    <option value="" defaultValue>
                      Contract
                    </option>
                    {contractList.length > 0 && contractList.map(contract => (
                    <option value={contract.contract_id}>{contract.contract_name} - {contract.contract_id} </option>

                    ))}
                    
                  </select>
                </>
              }
              </div>
            </div>

            <div className="row mt-4">
              <div className="form-group col-sm-4 px-1">
                <label htmlFor="amount" className="form-label visually-hidden">
                  Target Amount 1
                </label>
                <input
                  id="amount"
                  name="amount"
                  className="form-control border border-dark "
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  onBlur={formik.handleBlur}
                  placeholder="Target 1"

                />
                {formik.errors.amount && formik.touched.amount ? (
                  <small className="text-danger">{formik.errors.amount}</small>
                ) : null}
              </div>
              <div className="form-group col-sm-4 px-1">
                  <label htmlFor="duration" className="form-label visually-hidden">
                    Override 1 <small>(%)</small>
                  </label>
                  
                    <input
                      id="override1"
                      name="override1"
                      className="form-control border border-dark  col"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.override1}
                      onBlur={formik.handleBlur}
                      placeholder="Override 1"

                    />
                  
                  {formik.errors.override1 && formik.touched.override1 ? (
                    <small className="text-danger">{formik.errors.override1}</small>
                  ) : null}
                </div>
              
              
              </div>
              <div className="row mt-3">
                
                <div className="form-group col-sm-4 px-1">
                  <label htmlFor="amount" className="form-label visually-hidden">
                    Target Amount 2
                  </label>
                  <input
                    id="amount2"
                    name="amount2"
                    className="form-control border border-dark "
                    type="text"
                    onChange={formik.handleChange}
                    placeholder="Target 2"

                    value={formik.values.amount2}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.amount2 && formik.touched.amount2 ? (
                    <small className="text-danger">{formik.errors.amount2}</small>
                  ) : null}
                </div>
                <div className="form-group  col-sm-4 px-1">
                  <label htmlFor="duration" className="form-label visually-hidden">
                    Override 2 <small>(%)</small>
                  </label>
                    <input
                      id="override2"
                      name="override2"
                      className="form-control border border-dark  col"
                      type="number"
                      onChange={formik.handleChange}
                      placeholder="Override 2"
                      value={formik.values.override2}
                      onBlur={formik.handleBlur}
                    />
                  {formik.errors.override2 && formik.touched.override2 ? (
                    <small className="text-danger">{formik.errors.override2}</small>
                  ) : null}
                </div>
                
            </div>
            <div className="row mt-3">
            <div className="form-group  col-sm-4 px-1">
                <label htmlFor="amount" className="form-label visually-hidden">
                  Target Amount 3
                </label>
                <input
                  id="amount3"
                  name="amount3"
                  className="form-control border border-dark "
                  type="text"
                  onChange={formik.handleChange}
                  placeholder="Target 3"
                  value={formik.values.amount3}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.amount3 && formik.touched.amount3 ? (
                  <small className="text-danger">{formik.errors.amount3}</small>
                ) : null}
              </div>
              <div className="form-group col-sm-4 px-1">
                  <label htmlFor="duration" className="form-label visually-hidden">
                    Override 3 <small>(%)</small>
                  </label>
                    <input
                      id="override3"
                      name="override3"
                      className="form-control border border-dark  col"
                      type="number"
                      onChange={formik.handleChange}
                      placeholder="Overrride 3"
                      value={formik.values.override3}
                      onBlur={formik.handleBlur}
                    />
                  {formik.errors.override3 && formik.touched.override3 ? (
                    <small className="text-danger">{formik.errors.override3}</small>
                    ) : null}
                </div>
            </div>
            
            
            <div className="btn-wrapper row mt-5 ">
            {loading === true ? 
            <div className="btn btn-primary col-sm-2">
              <div className="spinner-border text-light" role="status"></div>
            </div>:
              <input type="submit" value="SUBMIT" className="btn btn-primary col-md-2 col-sm-3" onClick={()=>formik.handleSubmit} />}
            </div>
          </form>
          }
        <OverrideTable load={loader} setter={setLoader} search={search} />
      </div>
    </div>
  );
};

export default Override;
