import React from 'react'
import { TimestampConverter } from '../../../../../../controllers/controller'

const StaticPenalty = ({cancel_from, cancel_type, timestamp, cancel_days, cancel_to, cancel_penalty, handleEdit, penId}) => {
    return (
        <tr>
            <td>
                <p>{TimestampConverter(timestamp)}</p>
            </td>
            <td>
                <p>{TimestampConverter(cancel_from)}</p>
            </td>
            <td>
                <p>{TimestampConverter(cancel_to)}</p>
            </td>
            <td>
                <p>{cancel_type === ""  || cancel_type === "undefined" || cancel_type === "0"  ? "-" : cancel_type}</p>
            </td>
            <td>
                <p>{cancel_days === "" || cancel_days === "undefined"  || cancel_days === "0" ? "-" : cancel_days}</p>
            </td>
            <td>
                <p>{cancel_penalty === ""  || cancel_penalty === "undefined"  ||cancel_penalty === "0" ? "-" : cancel_penalty}</p>
            </td>
            <td >
            <button className="btn h-100" onClick={(e)=>handleEdit(e, penId)} >
               <div>Edit</div>
            </button>
            </td>
        </tr>
    )
}

export default StaticPenalty
