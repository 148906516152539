import React, { useState } from "react";
import { Link, NavLink, Route, Switch } from "react-router-dom";


import Contracts from "../../Others/DMC/Contracts/Contracts";
import All from "./Status/All";
import ContractDetails from "../../Reusables/contractDetails/ContractDetails";
import Completed from "../../Others/DMC/Contracts/Status/Completed";
import ContractModal from "../../Others/DMC/Contracts/Modal/ContractModal";

const Contract = () => {
  return (
    <main className="pt-4 px-lg-4 px-3" style={{ minHeight: "83vh" }}>
      <header className=" mb-3 d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">CONTRACT</h4>
      </header>
      <div className="content">
          <Route exact path={["/account-owner/contracts", "/dmc/contracts/all"]} component={All} />
          <Route path="/account-owner/contracts/completed" component={Completed} />
          <Route path="/account-owner/contracts/cancelled" component={Completed} />
          <Route path="/account-owner/contracts/create" component={ContractModal} />
          <Route path="/account-owner/contracts/detail" component={ContractDetails} />
          {/* <All /> */}
      </div>
    </main>
  );
};

export default Contract;
