import React from 'react'
import { Users } from '../../Reusables/Settings'

const AllUsers = () => {
  return (
    <div className="pt-4 px-lg-4 px-3 ">
      <header className="d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">Users</h4>
      </header>
      <div className="admin-dashboard-top mt-2 text-center">
        <Users />
      </div>
    </div>
  )
}

export default AllUsers