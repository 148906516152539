import * as React from 'react';
import {useContext, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom"
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import AddOwner from '../../../../Junior/Manage/Modals/AddOwner';
import Deletehotel from '../../../../Junior/Manage/Modals/Deletehotel';
import ViewRoomModal from '../../../../Junior/Manage/Modals/ViewRoomModal';
import HotelContext from '../../../../Junior/Manage/Context/hotelcontext';
import Modals, { ChoiceModals, DynamicModal } from '../../Contracts/Modal/Modal';
import ContractView from './ContractView';
import { Fetch } from '../../../../../../controllers/controller';
import { mainGetUsertoken } from '../../../../Reusables/GetUsertoken/GetUsertoken';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Dessert (100g serving)',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    label: 'Calories',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Fat (g)',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    label: 'Carbs (g)',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'action',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, header, data } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {header.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )} */}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({data, header}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [hotelId, setHotelId] = React.useContext(HotelContext)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // MODALS
    
    const [deleteModal, setDeleteModal] = useState(false)
    const [openViewRoom, setOpenViewRoom] = useState(false)
    const [show, setShow] = useState(false)

    const handleOpenViewRooms = (e) => {
        console.log(e)
        setHotelId(e)
        setOpenViewRoom(true)
        localStorage.setItem("hotelDetail", e)
      }
    
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              header={header}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(data, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.hotelname);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                    row={row} index={index} style="" labelId={labelId}
                    
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
            <AddOwner openOwnerModal={show} handleRemoveOwner={()=> setShow(false)} />
            <Deletehotel showDeleteModal={deleteModal} closeHotelDelete={()=> setDeleteModal(false)} />
            <ViewRoomModal handleViewRoom={()=>setOpenViewRoom(false)} openViewRoom={openViewRoom} />
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

function Row({row, index, style, labelId}){
    function handleClick(){}
    const [hotelId, setHotelId] = useContext(HotelContext)

  

    const location = useLocation();
    const [showChoice, setShowChoice] = useState(false);
    const [show, setShow] = useState(false);
    const [showDynamicModal, setShowDynamicModal] = useState(false);
    const [contractOpen, setContractOpen] = useState(false);
    const handleShowSelect = () => setShowChoice(true);
    const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false)
    const [openViewRoom, setOpenViewRoom] = useState(false)

    const history = useHistory()
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")
      
    const handleClose = () => setShow(false);
      
    function handleCloseChoice(){
      setShowChoice(false)
    }
    const handleShow = () => {
      setShow(true)
      setShowChoice(false)
    };
  
  
    function handleCloseDynamicModal (){
      setShowDynamicModal(false)
      localStorage.removeItem("fromHotel",true)
  
    }
  
    function handleContractClose(){
      setContractOpen(false)
    }
  
    function handleOpenDynamic (){
        localStorage.setItem("hotel",row.hoteltoken)
        localStorage.setItem("fromHotel",true)
      setShowDynamicModal(true)
      setShowChoice(false)
    }
  
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      mainGetUsertoken(location, setUsertoken, setUser)
  
    },[])
    const handleRemove = () => setOpen(false);
  
    const openRooms = (e) =>{
      setHotelId(e)
      localStorage.setItem("hotelDetail", e)
    }
    const handleOpen = () => {
          
          if (row.hoteltoken !== "") {
            // setLoading(true);
  
        
            localStorage.removeItem("CT");
            localStorage.removeItem("Count");
            localStorage.removeItem("City");
            localStorage.removeItem("hotelname");
            localStorage.removeItem("contract_info");
      
            const hotel = localStorage.getItem("hotel");
      
            const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            formData.append("dmctoken", usertoken);
            formData.append("hoteltoken", hotel);
      
            Fetch(`${process.env.REACT_APP_END_POINT}/create-contract`, formData)
              .then((res) => {
                // setLoading(false);
                console.log(res)
                if (res.data.success !== false) {
                  setShow(false);
                  localStorage.setItem("CT", res.data.contractToken);
                  localStorage.setItem("Count", res.data.country);
                  localStorage.setItem("City", res.data.city);
                  localStorage.setItem("hotelname", res.data.hotelname);
                  localStorage.setItem("contractDetail", JSON.stringify(res.data));
                  localStorage.removeItem("header");
                  history.push("/dmc/contracts/create");
                }
                // setShowAlert(true);
      
                // setAlertType({
                //   ...alert,
                //   type: "danger",
                //   value: res.data.message,
                // });
              })
              .catch((err) => {
                console.error(err)
                // setLoading(false);
                // setShowAlert(true);
                // setAlertType({ ...alert, type: "danger", value: err.message });
              });
          } else {
            window.alert("something went wrong");
          }
    };
  
    React.useEffect(() => {
      localStorage.setItem("hotel", row.hoteltoken)
      
    }, [row.hoteltoken])

    function handleHotelEdit(e, all){
      e.preventDefault()
      localStorage.setItem("hotels-offline-hotel-info", JSON.stringify(all))
      history.push("hotels/edit")
    }

    const handleAddRoom = (e) =>{
      setHotelId(e)
      setOpenAddRoomsModal(true)
    }
    const handleOpenViewRooms = (e) => {
      console.log(e)
      setHotelId(e)
      setOpenViewRoom(true)
      localStorage.setItem("hotelDetail", e)
    }
    return (
        <>
        <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                    //   aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                    //   selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="right"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{row.hotelname}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.country}</TableCell>
                      <TableCell align="left">
                         {/* <Link to={`${location.pathname}/rooms`} className='d-inline-flex text-decoration-none'> */}
                            <span className="btn fw-bold table_button" onClick={(e)=>handleOpenViewRooms(row.hoteltoken)}>Rooms</span>
                        {/* </Link> */}
                        {row.type === "hotelOwner" && <span className='btn  fw-bold table_button ' onClick={(e)=>handleHotelEdit(e, row)}>Edit</span>}
                        {row.type === "hotelOwner" && <span className='btn  fw-bold  table_button' onClick={(e)=>handleAddRoom(e, row)}>Add Room</span>}
                        {<span className='btn  fw-bold table_button ' onClick={(e)=>setContractOpen(true)}>View Contracts</span>}
                        {row.type !== "traveller" && <span className='btn  fw-bold table_button' onClick={handleShowSelect}>Create Contract</span>}
                      </TableCell>
                    </TableRow>
                    <Modals
                    handleShow={handleShow}
                    handleClose={handleClose}
                    show={show}
                    handleOpen={handleOpen}
                    setShow={setShow}
                    hoteltoken={row.hoteltoken}
      />
      <ContractView
        handleShow={handleShow}
        handleClose={handleContractClose}
        open={contractOpen}
        name={row.hotelname || row.hotel_name}
        hoteltoken={row.hoteltoken}
      />
        <ViewRoomModal handleViewRoom={()=>setOpenViewRoom(false)} openViewRoom={openViewRoom} />

      <ChoiceModals
       handleShowSelect={handleShowSelect}
       handleCloseChoice={handleCloseChoice}
       showChoice={showChoice}
       setShowChoice={setShowChoice}
       handleOpen={handleOpen}
       handleOpenDynamic = {handleOpenDynamic}
       
      />
      <DynamicModal
        showDynamicModal = {showDynamicModal}
        setShowDynamicModal = {setShowDynamicModal}
        handleCloseDynamicModal = {handleCloseDynamicModal}
        // handleOpenContract = {createDynamicContract}
      />
                    </>
    )
}