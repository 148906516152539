import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {TimestampConverter} from  "../../../../../controllers/controller"
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '50%',
        textAlign: 'left',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingLeft: 8,
        minHeight: 20,
        padding:"2px 5px",
        overflow:"scroll",
        whiteSpace:"wrap"
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingRight: 8,
        minHeight: 20,
        padding:"2px 5px",
        overflow:"scroll",
        whiteSpace:"wrap"
    },
    rate: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingRight: 8,
        minHeight: 20,
        padding:"2px 5px",
        overflow:"scroll",
        whiteSpace:"wrap"
    },
    amount: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingRight: 8,
        minHeight: 20,
        padding:"2px 5px",
        overflow:"scroll",
        whiteSpace:"wrap"
    },
    serial: {
        width: '5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        minHeight: 20,
        padding:"2px 5px",
        overflow:"scroll",
        whiteSpace:"wrap"
    },
  });

// const items = [1, 2, 3, 4]
const StatementTableRow = ({data}) => {
    const rows =  data && data.map( item => 
        <View style={styles.row} key={item.index}>
            <Text style={styles.serial}>{item.count}</Text>
            <Text style={styles.rate}>{TimestampConverter(item.timestamp)}</Text>
            <Text style={styles.rate}>{item.type}</Text>
            <Text style={styles.amount}>{item.amount}</Text>
            <Text style={styles.description}>{item.log}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default StatementTableRow