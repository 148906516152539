import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
const Date = () => {
    const [startDate, setStartDate] = useState(new window.Date());
    // const handleSelect = (ranges) => {
    //     console.log(ranges)
    // }
    // const selectionRange = {
    //     startDate: new window.Date(),
    //     endDate: new window.Date(),
    //     key: 'selection',
    //   }
  return (
    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

    // <DateRangePicker
    //     ranges={[selectionRange]}
    //     onChange={handleSelect}
    //   />
  );
};
export default Date