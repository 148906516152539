
import React, { useState } from "react";
import Staticmeals from "./Staticmeals";
import {
  Fetch,
  TimestampConverter,
  TimestampDateConverter,
} from "../../../../../../controllers/controller";

import ContractAlert from "../../../../../Reusables/ContractAlert/ContractAlert";

const Mealsmodal = ({ show, handleClose, mealsList, reloader }) => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertt, setShow] = useState(false);
  const [value, setValue] = useState('');
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [edit, setEdit] = useState(null);
  const [payload, setPayload] = useState({});
  // const [mealsList, setmealsList] = useState({});

  const handleEdit = (e) => {
    setEdit(true);
    setPayload({...payload, ...mealsList})
  };

  const [newDate, setNewDate] = useState({});

  const handleMealsChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleMealsDateChange = (e) => {
    e.preventDefault();
    setNewDate({ ...newDate, [e.target.name]: e.target.value });
  };

  

  const handleMealsUpdate = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("contract_name", payload.contract_name);
    formData.append("token", payload.contractToken);
    formData.append("hoteltoken", payload.hoteltoken);
    formData.append("dmctoken", payload.dmctoken);
    formData.append("id", payload.id);
    formData.append("meals_end", newDate.meals_end ? newDate.meals_end : TimestampDateConverter(payload.meals_end));
    formData.append("meals_start", newDate.meals_start ? newDate.meals_start : TimestampDateConverter(payload.meals_start));
    formData.append("rooms_only_child", payload.rooms_only_child);
    formData.append("rooms_only_adult", payload.rooms_only_adult);
    formData.append("status", payload.status);
    formData.append("child_age_From", payload.child_age_From);
    formData.append("child_age_To", payload.child_age_To);
    formData.append("currency", payload.currency);
    formData.append("breakfast_child", payload.breakfast_child);
    formData.append("breakfast_adult", payload.breakfast_adult);
    formData.append("fb_child", payload.fb_child);
    formData.append("fb_adult", payload.fb_adult);
    formData.append("hb_child", payload.hb_child);
    formData.append("hb_adult", payload.hb_adult);
    formData.append("sai_child", payload.sail_child);
    formData.append("sai_adult", payload.sai_adult);
    formData.append("uai_child", payload.uai_child);
    formData.append("uai_adult", payload.uai_adult);
    formData.append("all_inclusive_child", payload.all_incl_child);
    formData.append("all_inclusive_adult", payload.all_incl_adult);
    setLoad(true);
    Fetch(
       `${process.env.REACT_APP_END_POINT}/meals-plan-update`,
      formData
    )
      .then((res) => {
        setLoad(false);
        if (res.data.success === true) {
          setShowAlert(true);
          setEdit(null);
          setShow(true);
          setValue(res.data.message)
          reloader();
          // window.location.reload()
        } else {
          setShow(true);
          setValue(res.data.message)
        }
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  };
  return (
    <>
      {edit ? (
        <tr>
          <td>
            <input
              type="text"
              className="form-control"
              name="rimestamp"
              onChange={(e) => handleMealsChange(e)}
              value={TimestampConverter(mealsList.timestamp)}
            />
          </td>
          <td>
            <p>{TimestampConverter(mealsList.meals_start)}</p>
            <input
              type="date"
              className="form-control"
              name="meals_start"
              onChange={(e) => handleMealsDateChange(e)}
              value={newDate.meals_start}
            />
          </td>
          <td>
            <p>{TimestampConverter(mealsList.meals_end)}</p>
            <input
              type="date"
              className="form-control"
              name="meals_end"
              onChange={(e) => handleMealsDateChange(e)}
              value={newDate.meals_end}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control"
              name="rooms_only_adult"
              onChange={(e) => handleMealsChange(e)}
              value={payload.rooms_only_adult}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control"
              name="rooms_only_child"
              onChange={(e) => handleMealsChange(e)}
              value={payload.rooms_only_child}
            />
          </td>
          <td>
            <input
              type="number"
              name="breakfast_adult"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.breakfast_adult}
            />
          </td>
          <td>
            <input
              type="number"
              name="breakfast_child"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.breakfast_child}
            />
          </td>
          <td>
            <input
              type="number"
              name="hb_adult"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.hb_adult}
            />
          </td>
          <td>
            <input
              type="number"
              name="hb_child"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.hb_child}
            />
          </td>
          <td>
            <input
              type="number"
              name="fb_adult"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.fb_adult}
            />
          </td>
          <td>
            <input
              type="number"
              name="fb_child"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.fb_child}
            />
          </td>
          <td>
            <input
              type="number"
              name="sai_adult"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.sai_adult}
            />
          </td>
          <td>
            <input
              type="number"
              name="sail_child"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.sail_child} //note******
            />
          </td>
          <td>
            <input
              type="number"
              name="all_incl_adult"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.all_incl_adult}
            />
          </td>
          <td>
            <input
              type="number"
              name="all_incl_child"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.all_incl_child}
            />
          </td>
          <td>
            <input
              type="number"
              name="uai_adult"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.uai_adult}
            />
          </td>
          <td>
            <input
              type="number"
              name="uai_child"
              id=""
              className="form-control"
              onChange={(e) => handleMealsChange(e)}
              value={payload.uai_child}
            />
          </td>
          <td className="oneFullColumn">
            <button className="btn h-100" onClick={(e) => handleMealsUpdate(e)}>
              {load ? (
                <div
                  class={load && `spinner-border text-primary`}
                  role="status"
                >
                  Saving...
                </div>
              ) : (
                <div>Save</div>
              )}
            </button>
          </td>
        </tr>
      ) : (
        <Staticmeals
          handleEdit={handleEdit}
          timeStamp={mealsList.timestamp}
          roomOnlyC={
            mealsList.rooms_only_child === "" ||
            mealsList.rooms_only_child === "undefined"
              ? "-"
              : mealsList.rooms_only_child
          }
          roomOnlyA={
            mealsList.rooms_only_adult === "" ||
            mealsList.rooms_only_adult === "undefined"
              ? "-"
              : mealsList.rooms_only_adult
          }
          allA={
            mealsList.all_incl_adult === ""
              ? "-" || mealsList.all_incl_adult === "undefined"
              : mealsList.all_incl_adult
          }
          allC={
            mealsList.all_incl_child === ""
              ? "-" || mealsList.all_incl_child === "undefined"
              : mealsList.all_incl_child
          }
          uaiA={
            mealsList.uai_adult === "" || mealsList.uai_adult === "undefined"
              ? "-"
              : mealsList.uai_adult
          }
          uaiC={
            mealsList.uai_child === "" || mealsList.uai_child === "undefined"
              ? "-"
              : mealsList.uai_child
          }
          saiA={
            mealsList.sai_adult === "" || mealsList.sai_adult === "undefined"
              ? "-"
              : mealsList.sai_adult
          }
          saiC={
            mealsList.sail_child === "" || mealsList.sail_child === "undefined"
              ? "-"
              : mealsList.sail_child
          }
          fb_child={
            mealsList.fb_child === "" || mealsList.fb_child === "undefined"
              ? "-"
              : mealsList.fb_child
          }
          fb_adult={
            mealsList.fb_adult === "" || mealsList.fb_adult === "undefined"
              ? "-"
              : mealsList.fb_adult
          }
          hb_child={
            mealsList.hb_child === "" || mealsList.hb_child === "undefined"
              ? "-"
              : mealsList.hb_child
          }
          hb_adult={
            mealsList.hb_adult === "" || mealsList.hb_adult === "undefined"
              ? "-"
              : mealsList.hb_adult
          }
          breakC={
            mealsList.breakfast_child === "" ? "-" : mealsList.breakfast_child
          }
          breakA={
            mealsList.breakfast_adult === "" ? "-" : mealsList.breakfast_adult
          }
          mealExp={mealsList.meals_end}
          mealStart={mealsList.meals_start}
        />
      )}
      <ContractAlert show={showAlertt} setShow={setShow} value={value} />
    </>
  );
};

export default Mealsmodal;
