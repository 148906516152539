import react from 'react';
import { useState } from 'react';
import { fetchAdminBookings } from '../apis';
import {useMutation} from "react-query"
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { mainGetUsertoken } from '../../Reusables/GetUsertoken/GetUsertoken';
import AlertComp from '../../../Reusables/AlertComp/AlertComp';

const Bookings = () => {
    const [bookings, setBookings] = useState([]);
    const location = useLocation();

    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");



    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
    },[])



    const fetchBookings = useMutation(fetchAdminBookings, {
        onSuccess: res => {
            console.log({res})
            if(res.data.success){
                setBookings(res.data.bookings)
            }else {
                setShowAlert(true)
                setAlertType({type: 'danger', value: res.data.message})
            }
        },
        onError: err => {
            setShowAlert(true)
            setAlertType({type: 'danger', value: err.message})
        }
    })



    useEffect(() => {
      if(usertoken){
        fetchBookings.mutate({usertoken})
      }
    }, [usertoken])
    

    const handleAlertClose = () => {
        setShowAlert(false)
    };


    function listMeals(meal){
        switch (meal) {
            case "all_incl_adult":
                return "All inclusive adult"
            case "all_incl_child":
                return "All inclusive child"
            case "breakfast_adult":
                return "Breakfast adult"
                
            case "breakfast_child":
                return "Breakfast child"
            case "fb_adult":
                return "FB adult"
            case "fb_child":
                return "FB child"
            case "soft_all_incl_adult":
                return "Soft all inclusive adult"
                
            case "soft_all_incl_child":
                return "Soft all inclusive child"
            case "hb_adult":
                return "HB adult"
            case "hb_child":
                return "HB child"
            default:
                break;
        }
    }


    let allUsers = []

    function getTotal(array){

        array.reduce((acc, current) =>{ 
            console.log(current)
            let figure
            if(current.childNumber ){
                figure = acc + Number(current.childNumber) + Number(current.adultNumber)
                console.log({figure})
                return figure
            }else {
                figure = acc +  Number(current.adultNumber)
                console.log({figure})
                allUsers.push(figure)
                console.log(allUsers)

                return figure
            }

        }, 0)
    }


    return (
      <main className="pt-4 px-lg-4 px-2" style={{ minHeight: "90vh" }}>
        <header className=" mb-3 d-flex justify-content-between align-items-center flex-wrap dashHeader">
          <h4 className="text-primary">BOOKINGS</h4>
        </header>
        <section className="content">
        {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
            )}
            <div className="admin_hotel">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Booking ID</th>
                                <th>Contract ID</th>
                                <th>Arrival</th>
                                <th>Hotel</th>
                                <th>Rooms</th>
                                <th>Meal plan</th>
                                <th>Cancellation Policy</th>
                                <th>Night</th>
                                <th>Pax</th>
                                <th>Status</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bookings?.map((item,i)=>(
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item.id}</td>
                                        {/* booking and contract id */}
                                        <td>{item.contract_token}</td>
                                        <td>{item.real_time}</td>
                                        <td  dangerouslySetInnerHTML={{ __html: item.hotelname}} />
                                        <td>
                                            <ol>
                                                {item?.bookingData?.rooms?.map(item=>(
                                                    <li>{Object.keys(item)}</li>
                                                ))}
                                            </ol>
                                        </td>
                                        <td>
                                            <ol>
                                                {
                                                    Object.keys(item.bookingData?.meals)?.map(item =>(
                                                        <li>{listMeals(item)}</li>
                                                    ))   
                                                }
                                            </ol>
                                            {listMeals(item?.bookingData?.meals)}
                                            
                                        </td>
                                        <td>{item.totalamount}</td>
                                        <td>{item.totalamount}</td>
                                        <td>
                                            {
                                                getTotal(item?.bookingData?.rooms)
                                             
                                            }
                                        </td>
                                        <td>{item.totalamount}</td>
                                        <td>{item.totalamount}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        {/* <Route exact path="/dmc/bookings" component={Booking} />
        <Route path="/dmc/bookings/details" component={BookingDetails} /> */}
        </section>
      </main>
    );
  };
  

export default Bookings