import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Button } from "react-bootstrap";
import { Fetch } from "../../../../../controllers/controller";

import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import RC from "./RC";
import RA from "./RA";
import { useLocation } from "react-router-dom";

const AddRooms = ({ handleRemove, openModal, details }) => {
  const location = useLocation()
  
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [amenList, setAmenList] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  
  useEffect(() => {
  
  // CHECK IF IT'S MEANT TO BE AN EDIT
    if(details?.id){
      setPayload({...details, hotelname: details.room_name, available: details.availnum, description: details.room_description })
    }

}, [ details?.id])

console.log(details)
  useEffect(() => {
    const accowner = localStorage.getItem("hotelChain");
    if (accowner) {
      const useableAccOwner = JSON.parse(accowner);
      setPayload({ ...payload, usertoken: useableAccOwner.usertoken });
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-amenities`, formData)
      .then((res) => {
        if (mounted) setAmenList(res.data);
      })
      .catch((err) => {
        setAlertType({ ...alert, type: "danger", value: err.message });
      });

    return () => {
      mounted = false;
    };
  }, []);
  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-room-category`, formData)
      .then((res) => {
        if (mounted) setCateList(res.data);
      })
      .catch((err) => {
        setAlertType({ ...alert, type: "danger", value: err.message });
      });

    return () => {
      mounted = false;
    };
  }, []);
  const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    hotelname: "",
    available: "",
    price: "",

    description: "",
    SGL: "0",
    DBL: "0",
    TRPL: "0",
    QUAD: "0",
    FAM: "0",
    file: null,
  });

  const handleImages = (e) => {
    setPayload({ ...payload, file: e.target.files[0] });
  };
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };
  const handleCateChange = (e) => {
    e.target.value === "0"
      ? setPayload({ ...payload, [e.target.name]: "1" })
      : setPayload({ ...payload, [e.target.name]: "0" });
  };
  const handleCheck = (e, cate) => {
    let checked = e.target.checked;

    if (checked === true) {
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("roomtoken", localStorage.getItem("room")); //change to roomid
      formData.append("room_amenities", cate.room_amenities);
      formData.append("amenities_id", cate.id);
      Fetch(`${process.env.REACT_APP_END_POINT}/selected-amenities`, formData)
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      const form = new FormData();
      form.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      form.append("roomtoken", localStorage.getItem("room")); //change to roomid
      form.append("room_amenities", "");
      form.append("amenities_id", cate.id);
      Fetch(`${process.env.REACT_APP_END_POINT}/selected-amenities`, form)
        .then((res) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "success", value: res.data.message });
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }
  };
  const radioChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };
  const submitHotelRoom = (e) => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hoteltoken", localStorage.getItem("hotel"));
    formData.append("roomtoken", localStorage.getItem("room"));
    formData.append("name", payload.hotelname);
    formData.append("room_desc", payload.description);
    formData.append("avail_room_num", payload.available);
    formData.append("max_adult", payload.max_adult);
    formData.append("min_adult", payload.min_adult);
    formData.append("max_child", payload.max_child);
    formData.append("bed_number", payload.bed_number);
    formData.append("room_size", payload.room_size);
    formData.append("category", payload.category);
    formData.append("sgl", payload.SGL);
    formData.append("dbl", payload.DBL);
    formData.append("trpl", payload.TRPL);
    formData.append("quad", payload.QUAD);

    formData.append("room_image", payload.file);

    setLoading(true);
    Fetch(`${process.env.REACT_APP_END_POINT}/update-room`, formData)
      .then((res) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "success", value: res.data.message });
        // window.location.reload();
        setPayload({
          ...payload,
          hotelname: "",
          description: "",
          available: "",
          max_adult: "",
          min_adult: "",
          max_child: "",
          bed_number: "",
          room_size: "",
          category: "0",
          sgl: "0",
          dbl: "0",
          trpl: "0",
          quad: "0",
          fam: "0",
          file: null,
        });
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };


  
  // console.table(payload);
  return (
    <>
      <Modal show={openModal} onHide={handleRemove} centered>
        {loading ? (
          <Loading />
        ) : (
          <div className="py-5 px-5 modals addrooms">
            <div className="d-flex flex-column justify-content-start  mb-5">
              <h4 className="text-primary fw-bold text-center">
                {details?.id ? "Edit Hotel Room":"Add Hotel Room"}
              </h4>
            </div>
            <Modal.Body className="mb-5 mb-sm-2">
              <Tabs defaultActiveKey="roomcontent" className="mb-3 title_font">
                <Tab eventKey="roomcontent" title="ROOM CONTENT">
                  <form className="row justify-content-center">
                    <div className="form-left col-md-10">
                      {showAlert === true && (
                        <AlertComp
                          variant={alert.type}
                          alertText={alert.value}
                          handleAlertClose={handleAlertClose}
                        />
                      )}
                      <div className="row">
                        <div className="status mb-3 col-sm-6">
                          <label
                            htmlFor="hotelname"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Room name
                          </label>
                          <input
                            type="text"
                            name="hotelname"
                            id="hotelname"
                            className="form-control"
                            onChange={handleChange}
                            required
                            value={payload.hotelname}
                          />
                        </div>
                        <div className="price mb-3 col-sm-6">
                          <label
                            htmlFor="price"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Room Size{" "}
                            <small>
                              <em>(sqm)</em>
                            </small>
                          </label>
                          <input
                            type="number"
                            name="room_size"
                            className="form-control"
                            required
                            value={payload.room_size}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="available mb-3 col-sm-6">
                          <label
                            htmlFor="available"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Available Rooms
                          </label>
                          <input
                            type="number"
                            name="available"
                            id="available"
                            className="form-control"
                            value={payload.available}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="maxChild mb-3 col-sm-6">
                          <label
                            htmlFor="max_child"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            Max Child{" "}
                          </label>
                          <input
                            type="number"
                            name="max_child"
                            className="form-control"
                            required
                            value={payload.max_child}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="min_adult mb-3 col-sm-6">
                          <label
                            htmlFor="min_adult"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Min Adult
                          </label>
                          <input
                            type="number"
                            name="min_adult"
                            id="available"
                            className="form-control"
                            value={payload.min_adult}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="max_adult mb-3 col-sm-6">
                          <label
                            htmlFor="max_adult"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            Max Adult{" "}
                          </label>
                          <input
                            type="number"
                            name="max_adult"
                            className="form-control"
                            required
                            value={payload.max_adult}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="bed_number mb-3 col-sm-4">
                          <label
                            htmlFor="bed_number"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Bed Number
                          </label>
                          <input
                            type="number"
                            name="bed_number"
                            className="form-control"
                            required
                            value={payload.bed_number}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-sm-8">
                          <label
                            htmlFor="hotelImg"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Select room image
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="hotelImg"
                            id="hotelImg"
                            onChange={handleImages}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="description mb-3 col-md-8">
                          <label
                            htmlFor="description"
                            className="mb-2 text-primary form-label"
                            style={{ fontSize: "12px" }}
                          >
                            Description
                          </label>
                          <textarea
                            name="description"
                            id="description"
                            cols="30"
                            rows="5"
                            className="form-control"
                            value={payload.description}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                </Tab>
                <Tab eventKey="features" title="FEATURES">
                  <form>
                    {showAlert === true && (
                      <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose}  />
                    )}
                    <div className="category mb-4">
                      <fieldset>
                        <legend>ROOM AMENITIES</legend>
                        <div className="radio category_list">
                          {amenList.length > 0 &&
                            amenList.map((cate, index) => (
                              <>
                                <RC
                                  typee="checkbox"
                                  title={cate.room_amenities}
                                  name={cate.room_amenities}
                                  handleRadioChange={(e) =>
                                    handleCheck(e, cate)
                                  }
                                />
                              </>
                            ))}
                        </div>
                      </fieldset>
                    </div>
                    <div className="occupancyWrapper">
                      <fieldset>
                        <legend> ROOM OCCUPANCY</legend>
                        <div className="detailWrapper d-flex justify-content-between align-items-center">
                          <div className="r_o">
                            <input
                              type="checkbox"
                              name="DBL"
                              id="dbl"
                              className="form-check-input me-2"
                              value={payload.DBL}
                              onChange={handleCateChange}
                            />
                            <label htmlFor="dbl">DBL</label>
                          </div>
                          <div className="r_o">
                            <input
                              type="checkbox"
                              name="SGL"
                              id="sgl"
                              className="form-check-input me-2"
                              value={payload.SGL}
                              onChange={handleCateChange}
                            />
                            <label htmlFor="sgl">SGL</label>
                          </div>
                          <div className="r_o">
                            <input
                              type="checkbox"
                              name="TRPL"
                              id="trpl"
                              className="form-check-input me-2"
                              value={payload.TRPL}
                              onChange={handleCateChange}
                            />
                            <label htmlFor="trpl">TRPL</label>
                          </div>
                          <div className="r_o">
                            <input
                              type="checkbox"
                              name="QUAD"
                              id="quad"
                              className="form-check-input me-2"
                              value={payload.QUAD}
                              onChange={handleCateChange}
                            />
                            <label htmlFor="quad">QUAD</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="category mt-4">
                      <fieldset>
                        <legend>ROOM CATEGORY</legend>
                        <div className="radio category_list">
                          {cateList.length > 0 &&
                            cateList.map((cate, index) => (
                              <RA
                                typee="radio"
                                title={cate.category}
                                handleRadioChange={radioChange}
                                name="category"
                                value={cate.category}
                              />
                            ))}
                        </div>
                      </fieldset>
                    </div>
                    <div className="d-flex m-3">
                      <button
                        className="btn btn-lg btn-primary mb-2 w-50 m-auto"
                        style={{ padding: "11px" }}
                        onClick={submitHotelRoom}
                      >
                        Add Rooms
                      </button>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleRemove}>
                Close
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddRooms;
