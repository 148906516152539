import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Fetch,
  getContractbyCountry,
} from "../../../../controllers/controller";
import { useLocation } from "react-router-dom";
import Countries from "../../../Signup/Countries/Countries";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import { Modal } from "react-bootstrap";
import Loading from "../../../Reusables/Loader/Loading";
import { mainGetUsertoken } from "../GetUsertoken/GetUsertoken";


const PackageDeets = ({ item }) => {
  // const [dayCreated, setDayCreated] = useState(false);
  const [day, setDay] = useState("");
  const [packageDetail, setPackage] = useState({});
  const [loading, setLoading] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [listLoad, setListLoad] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [delID, setDelID] = useState("");

  const [sellerLoader, setSellerLoader] = useState(false);
  const [serviceLoader, setServiceLoader] = useState(false);
  const [contractLoader, setContractLoader] = useState(false);
  const [roomLoader, setRoomLoader] = useState(false);

  const [roomList, setRoomList] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [seller, setSeller] = useState([]);
  const [service, setService] = useState([]);

  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
      mainGetUsertoken(location, setUsertoken, setUser);
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const data = localStorage.getItem("pkg");
    if (data) {
      const pkg = JSON.parse(data);
      setPackage(pkg);
    }
  }, []);

  const changeHandler = (value) => {
    formik.setValues({ ...formik.values, country: value.label });
    setContractLoader(true);
    getContractbyCountry(setContractLoader, value.label, setContractList);
  };

  // set contract state and get hotel by contract
  const contractChangeHandler = (e) => {
    // setformik value
    formik.setValues({ ...formik.values, contract: e.target.value });
    // change roomLoad
    setRoomLoader(true);
  };

  // load room list

  useEffect(() => {
    if (roomLoader) {
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("token", formik.values.contract);

      Fetch(`${process.env.REACT_APP_NEND_POINT}/get-contract-rooms`, formData)
        .then((res) => {
          console.log({res})
          setRoomLoader(false);

          if (res.data.success !== false) {
            setRoomList(res.data);
          } else {
            window.alert(res.data.message);
          }
        })
        .catch((err) => {
          setRoomLoader(false);
          window.alert(err.message);
        });
    }
  }, [formik.values.contract]);

  console.table(formik.values);

  const submitPackage = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    // formData.append("dmctoken", uDmc.usertoken);
    formData.append("package_id", item.package_id);
    formData.append("day_id", item.id);
    formData.append("seller_type", formik.values.seller_type);
    formData.append("seller", formik.values.seller);
    formData.append("country", formik.values.country);
    formData.append("contract_token", formik.values.contract);
    formData.append("roomtoken", formik.values.room);
    formData.append("package_type", formik.values.service_type);
    formData.append("package_type_id", formik.values.service);

    Fetch(
      `${process.env.REACT_APP_END_POINT}/create-package-day-item`,
      formData
    )
      .then((res) => {
        console.log(res);

        setLoading(false);
        setShow(true);
        setMessage(res.data.message);
        setListLoad(true);
        setTimeout(() => {
          setListLoad(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setShow(true);
        setMessage(err.message);
      });
  };

  const [userType, setUserType] = useState("");

  useEffect(() => {
    location.pathname === "/dmc/packages/create"
      ? setUserType("DMC")
      : setUserType("accountOwner");
  }, []);

  useEffect(() => {
    let mounted = true;

    if (formik.values.seller_type === "DMC") {
      setSellerLoader(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      Fetch(`${process.env.REACT_APP_END_POINT}/list-dmc`, formData)
        .then((res) => {
          setSellerLoader(false);
          if (mounted) {
            setSeller(res.data);
          }
        })
        .catch((err) => window.alert(err));
    } else if (formik.values.seller_type === "HOTEL") {
      setSellerLoader(true);

      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      Fetch(`${process.env.REACT_APP_END_POINT}/hotel-owner-list`, formData)
        .then((res) => {
          setSellerLoader(false);
          if (mounted) {
            setSeller(res.data);
          }
        })
        .catch((err) => {
          window.alert(err);
          setSellerLoader(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [formik.values.seller_type]);


  useEffect(() => {
    let mounted = true;
    // const data = localStorage.getItem(userType);
    // const user = JSON.parse(data);
    if (formik.values.service_type === "HOTEL") {
      setServiceLoader(true);
      const formData = {
        apptoken: process.env.REACT_APP_APP_TOKEN,
        token: formik.values.seller
      };
      Fetch(`${process.env.REACT_APP_NEND_POINT}/dmc/listHotels`, JSON.stringify(formData))
        .then((res) => {
          setServiceLoader(false);
          if (mounted) {
            setService(res.data.hotels);
          }
        })
        .catch((err) => {
          setServiceLoader(false);
          window.alert(err.message);
        });
    } else if (formik.values.service_type === "SERVICE") {
      setServiceLoader(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("usertoken", usertoken);

      Fetch(`${process.env.REACT_APP_END_POINT}/service-list`, formData)
        .then((res) => {
          setServiceLoader(false);
          if (mounted) {
            setService(res.data);
          }
        })
        .catch((err) => {
          window.alert(err);
          setServiceLoader(false);
        });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.service_type]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("package_id", item.package_id);
    formData.append("day", item.day);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-package-day-item`, formData)
      .then((res) => {
        setPackageList(res.data);
      })
      .catch((err) => {
        setShow(true);
        setMessage(err.message);
      });
  }, [listLoad]);
  return (
    <div className="row mt-3 card shadow px-2 py-1">
      <div className="form-group mb-3 col-sm-2">
        <label htmlFor="name" className="form-label text-dark">
          Day
        </label>
        <div className="d-flex ">
          <input
            type="number"
            onChange={(e) => {
              setDay(e.target.value);
            }}
            value={item.day}
            onBlur={formik.handleBlur}
            name="day"
            id="day"
            disabled
            className="form-control px-2 py-2 me-3"
          />
        </div>
      </div>
      <div className="mt-2">
        {/* {dayCreated && ( */}
        <p className="text-dark">ADD NEW ITEMS</p>
        <div className="row table-responsive">
          <table className="table table-row package-table">
            <thead>
              <tr>
                <th style={{ minWidth: "120px" }}>Seller Type</th>
                <th style={{ minWidth: "120px" }}>Seller</th>
                <th style={{ minWidth: "120px" }}>Item Type</th>
                <th style={{ minWidth: "150px" }}>Country</th>
                <th style={{ minWidth: "120px" }}>Contract</th>
                <th style={{ minWidth: "120px" }}>Room</th>
                <th style={{ minWidth: "120px" }}>Hotel/Service</th>
                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody style={{ height: "100px" }}>
              <tr>
                <td>
                  <div className="form-group mb-2 col-sm-3   w-100 ">
                    <label
                      htmlFor="seller_type"
                      className="form-label text-dark visually-hidden"
                    >
                      Seller Type
                    </label>
                    <select
                      name="seller_type"
                      id="seller_type"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.seller_type}
                    >
                      <option defaultValue="">Choose Seller Type</option>
                      <option value="DMC">DMC</option>
                      <option value="HOTEL">Hotel</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="form-group mb-2 col-sm-2   w-100  ">
                    {sellerLoader === true ? (
                      <div className="d-flex align-items-end pb-2 h-100">
                        <div
                          className={`spinner-border text-primary`}
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <>
                        <label
                          htmlFor="seller"
                          className="form-label text-dark visually-hidden"
                        >
                          Seller
                        </label>
                        <select
                          name="seller"
                          id="seller"
                          className="form-select"
                          onChange={formik.handleChange}
                          value={formik.values.seller}
                          onBlur={formik.handleBlur}
                        >
                          <option value="" default>
                            Seller
                          </option>
                          {seller?.length > 0 &&
                            seller.map((item) => (
                              <option
                                value={item.token}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.last_name +
                                    " " +
                                    item.first_name +
                                    " - " +
                                    item.mail,
                                }}
                              />
                            ))}
                        </select>
                      </>
                    )}
                  </div>
                </td>

                <td>
                  <div className="form-group mb-2 col-sm-3   w-100 ">
                    <label
                      htmlFor="service_type"
                      className="form-label text-dark visually-hidden"
                    >
                      Hotel/Service
                    </label>
                    <select
                      name="service_type"
                      id="service_type"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.service_type}
                    >
                      <option defaultValue>Select a Type</option>
                      <option value="HOTEL">Hotel</option>
                      <option value="SERVICE">Service</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="form-group mb-2 col-sm-3   w-100 ">
                    <label
                      htmlFor="service_type"
                      className="form-label text-dark visually-hidden"
                    >
                      Country
                    </label>
                    <Countries changeHandler={changeHandler} />
                  </div>
                </td>
                <td>
                  <div className="form-group mb-2 col-sm-3   w-100 ">
                    {contractLoader === true ? (
                      <div
                        className={`spinner-border text-primary`}
                        role="status"
                      ></div>
                    ) : (
                      <>
                        <label
                          htmlFor="nature"
                          className="form-label visually-hidden"
                        >
                          Select Contract
                        </label>
                        <select
                          name="contract"
                          id="contract"
                          className="form-select border border-dark "
                          onChange={contractChangeHandler}
                          value={formik.values.contract}
                          onBlur={formik.handleBlur}
                          // disabled={selectHotel === true ? false : true}
                        >
                          <option value="" defaultValue>
                            Contract
                          </option>
                          {contractList?.length > 0 &&
                            contractList.map((contract) => (
                              <option value={contract.contract_id}>
                                {contract.contract_name}-{contract.contract_id}
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div className="form-group mb-2 col-sm-3   w-100 ">
                    {roomLoader === true ? (
                      <div
                        className={`spinner-border text-primary`}
                        role="status"
                      ></div>
                    ) : (
                      <>
                        <label
                          htmlFor="room"
                          className="form-label text-dark visually-hidden"
                        >
                          Room
                        </label>
                        <select
                          name="room"
                          id="room"
                          className="form-select"
                          onChange={formik.handleChange}
                          value={formik.values.room}
                        >
                          <option defaultValue>Select Room</option>
                          {roomList?.length > 0 &&
                            roomList.map((room) => (
                              <option value={room.roomtoken}>
                                {room.room_name}
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div className="form-group mb-2 col-sm-3   w-100 ">
                    {serviceLoader === true ? (
                      <div className="d-flex align-items-end">
                        <div
                          className={`spinner-border text-primary`}
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <>
                        <label
                          htmlFor="service"
                          className="form-label text-dark visually-hidden"
                        >
                          Hotel/Service
                        </label>
                        <select
                          onChange={formik.handleChange}
                          value={formik.values.service}
                          onBlur={formik.handleBlur}
                          name="service"
                          id="service"
                          className="form-control"
                        >
                          <option defaultValue>Select</option>
                          {service?.length > 0 &&
                            service.map((item) => (
                              <option
                                value={item.hotelid ? item.hotelid : item.hoteltoken ? item.hoteltoke : item.id}
                                dangerouslySetInnerHTML={{
                                  __html: item.hotelname || item.service_name,
                                }}
                              />
                            ))}
                        </select>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div
                    className="form-group mb-2 col-sm-4  w-100 align-items-end"
                    style={{ minWidth: "120px" }}
                  >
                    {loading ? (
                      <button className="btn btn-dark" onClick={submitPackage}>
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      </button>
                    ) : (
                      <button className="btn btn-dark" onClick={submitPackage}>
                        Add New
                      </button>
                    )}
                  </div>
                </td>
              </tr>
              {packageList?.length > 0 &&
                packageList.map((item) => (
                  <tr key={item.id}>
                    <td>{item.seller_type}</td>
                    <td>{item.seller_type_name}</td>
                    <td>{item.package_type}</td>
                    <td>{item.country}</td>
                    <td>{item.contract_name}</td>
                    <td>{item.roomname}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: item.package_type_name,
                      }}
                    />
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          setDelID(item.id);
                          setDeleteModal(true);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                    {delID === item.id ? (
                      <DeletePrompt
                        id={item.id}
                        show={deleteModal}
                        handleClose={() => {
                          setDeleteModal(false);
                          // window.location.reload()
                        }}
                      />
                    ) : null}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

const DeletePrompt = ({ id, show, handleClose, }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  function deletePackageItem(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("id", id);

    Fetch(
      `${process.env.REACT_APP_NEND_POINT}/delete-package-day-item`,
      formData
    )
      .then((res) => {
        setLoading(false);
        if (res.data.success === false) {
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: res.data.message });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "success",
            value: res.data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
        window.location.reload();
      });
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="select-hotel"
      centered
    >
      <Modal.Header>
        <div className="w-100">
          <h5 className="text-dark text-uppercase text-center">
            Delete Package Item
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body className="mb-5">
        {loading ? (
          <Loading />
        ) : (
          <div>
            {showAlert === true && (
              <AlertComp
                variant={alert.type}
                alertText={alert.value}
                handleAlertClose={handleAlertClose}
              />
            )}
            <p className="text-center text-danger fw-bolder">
              DELETE PACKAGE ITEM?
            </p>

            <div className="w-100 d-flex justify-content-between align-items-center">
              <button className="btn btn-danger" onClick={deletePackageItem}>
                Delete
              </button>
              <button className="btn btn-dark" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default PackageDeets;
