import React from "react";
import { Redirect, Route } from "react-router";
// import UserContext from "../../Context/User/userContext";


export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = true
  const item = localStorage.getItem("admin");
  const useable = JSON.parse(item)

  return (
    <Route
      {...rest}
      render={
        (props) =>
        useable !== null? <Component {...props} /> : <Redirect to="/login" />// unString !== null? <Component {...props} /> : <Redirect to="/login" />
        // auth === true? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
