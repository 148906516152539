import React, { useContext, useState, useEffect } from 'react'
import { Fetch } from '../../../../../../controllers/controller';
import Loading from '../../../../../Reusables/Loader/Loading';
import HotelContext from '../../../../Junior/Manage/Context/hotelcontext';
import Unavailable from '../../../../Junior/Manage/Hotels/Unavailable';
import Hotel from '../HotelsCard/Hotel';
import AddOwner from '../../../../Junior/Manage/Modals/AddOwner';
import Deletehotel from '../../../../Junior/Manage/Modals/Deletehotel';
import { useMutation } from 'react-query';
import { listHotelbyDmc } from '../../../../Super/apis';
import AlertComp from '../../../../../Reusables/AlertComp/AlertComp';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Modals, { ChoiceModals, DynamicModal } from '../../Contracts/Modal/Modal';
import { mainGetUsertoken } from '../../../../Reusables/GetUsertoken/GetUsertoken';
import ContractView from './ContractView';
import ViewRoomModal from '../../../../Junior/Manage/Modals/ViewRoomModal';
import { FiFilter } from 'react-icons/fi';
import EnhancedTable from './sortTable';

const HotelList = () => {
  const [hotelId, setHotelId] = useContext(HotelContext)

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [hotels, setHotels] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false)
  const [openViewRoom, setOpenViewRoom] = useState(false)
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState("")
  const [showChoice, setShowChoice] = useState(false);
    const [showDynamicModal, setShowDynamicModal] = useState(false);
    const handleShowSelect = () => setShowChoice(true);
    
  const listDMCHotel = useMutation(listHotelbyDmc, { 
    onSuccess:(e)=>{
      console.log(e)
      if(!e.data.success){
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: e.data.message });
      } else {
        setHotels(e.data.hotels);
      }
    },
    onError: (err)=>{
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    }

  })

  useEffect(() => {
    
    const dmc = localStorage.getItem("DMC");

    if (dmc) {
      setLoading(true)
      const useabledmc = JSON.parse(dmc);

      listDMCHotel.mutate({
        apptoken: process.env.REACT_APP_APP_TOKEN,
        token:useabledmc.usertoken
      })
    }
  }, []);
 
  const openRooms = (e) =>{
    setHotelId(e)
    localStorage.setItem("hotelDetail", e)
  }

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const location = useLocation()
  const handleOpenViewRooms = (e) => {
    console.log(e)
    setHotelId(e)
    setOpenViewRoom(true)
    localStorage.setItem("hotelDetail", e)
  }



  const handleSearch = (e) => {
    setSearch(e.target.value);
  };


  const header = [
    {
      id:"index",
      numeric: true,
      diabledPadding: true,
      label: "No"

    },
    {
      id:"hotelname",
      numeric: false,
      diabledPadding: false,
      label: "Name"

    },
    {
      id:"city",
      numeric: false,
      diabledPadding: false,
      label: "City"

    },
    {
      id:"country",
      numeric: false,
      diabledPadding: false,
      label: "Country"

    },
    {
      id:"action",
      numeric: false,
      diabledPadding: false,
      label: "Action"

    },
  ]

  return listDMCHotel.isLoading ? (
    <Loading />
  ) : (
    <div className="col-12">
       {showAlert === true && (
          <AlertComp
            variant={alert.type}
            alertText={alert.value}
            handleAlertClose={handleAlertClose}
          />
        )
        }

        <div className="d-flex w-100 justify-content-between mb-2">
          <div className="search d-flex align-items-center" style={{gap:"1rem"}} >
            <input
              type="text"
              className="form-control"
              placeholder="search..."
              value={search}
              onChange={handleSearch}
            />
          {/* <div className="form-group d-flex align-items-center my-3">
            <label htmlFor="filter" className="form-label d-flex me-3">Filter <i><FiFilter /></i></label>
            <select name="name" id="name" className="form-select form-control"  onChange={handleSearch}>
              <option value="">Select contract type</option>
              <option value="Static">Static</option>
              <option value="Dynamic">Dynamic</option>
            </select>
          </div> */}
          </div>
        </div>
        {
          // <EnhancedTable header={header} data={hotels} />
        }
      {hotels.length === 0 ? (<Unavailable />):(
              <EnhancedTable header={header} data={hotels} />
          // <div className="table-responsive">
          // <table className="table table-bordered">
          //   <thead>
          //     <tr>
          //       <th>No</th>
          //       <th>Name</th>
          //       <th>City</th>
          //       <th>Country</th>
          //       <th>Action</th>
          //     </tr>
          //   </thead>
          // <tbody>
          // { hotels.map((hotel, i)=>(
          //   <ListRow  {...hotel} i={i} 

          //     handleViewRooms={(e)=>handleOpenViewRooms(hotel.hoteltoken)} 

          
          //   />
          // ))}
          // </tbody>
          // </table>
          // </div>
        )}
      <AddOwner openOwnerModal={show} handleRemoveOwner={()=> setShow(false)} />
      <Deletehotel showDeleteModal={deleteModal} closeHotelDelete={()=> setDeleteModal(false)} />
      <ViewRoomModal handleViewRoom={()=>setOpenViewRoom(false)} openViewRoom={openViewRoom} />

    </div>
  );
}


export function ListRow({hoteltoken, hotelname, hotel_name, handleViewRooms, i, type, handleHotelEdit, handleAddRoom, country, city, all}){
  const [hotelId, setHotelId] = useContext(HotelContext)

  

  const location = useLocation();
  const [showChoice, setShowChoice] = useState(false);
  const [show, setShow] = useState(false);
  const [showDynamicModal, setShowDynamicModal] = useState(false);
  const [contractOpen, setContractOpen] = useState(false);
  const handleShowSelect = () => setShowChoice(true);
  
  const history = useHistory()
  const [usertoken, setUsertoken] = useState("")
  const [user, setUser] = useState("")
    
  const handleClose = () => setShow(false);
    
  function handleCloseChoice(){
    setShowChoice(false)
  }
  const handleShow = () => {
    setShow(true)
    setShowChoice(false)
  };


  function handleCloseDynamicModal (){
    setShowDynamicModal(false)
    localStorage.removeItem("fromHotel",true)

  }

  function handleContractClose(){
    setContractOpen(false)
  }

  function handleOpenDynamic (){
      localStorage.setItem("hotel",hoteltoken)
      localStorage.setItem("fromHotel",true)
    setShowDynamicModal(true)
    setShowChoice(false)
  }

  const [open, setOpen] = useState(false);

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser)

  },[])
  const handleRemove = () => setOpen(false);

  const openRooms = (e) =>{
    setHotelId(e)
    localStorage.setItem("hotelDetail", e)
  }
  const handleOpen = () => {
        
        if (hoteltoken !== "") {
          // setLoading(true);

      
          localStorage.removeItem("CT");
          localStorage.removeItem("Count");
          localStorage.removeItem("City");
          localStorage.removeItem("hotelname");
          localStorage.removeItem("contract_info");
    
          const hotel = localStorage.getItem("hotel");
    
          const formData = new FormData();
          formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
          formData.append("dmctoken", usertoken);
          formData.append("hoteltoken", hotel);
    
          Fetch(`${process.env.REACT_APP_END_POINT}/create-contract`, formData)
            .then((res) => {
              // setLoading(false);
              console.log(res)
              if (res.data.success !== false) {
                setShow(false);
                localStorage.setItem("CT", res.data.contractToken);
                localStorage.setItem("Count", res.data.country);
                localStorage.setItem("City", res.data.city);
                localStorage.setItem("hotelname", res.data.hotelname);
                localStorage.setItem("contractDetail", JSON.stringify(res.data));
                localStorage.removeItem("header");
                history.push("/dmc/contracts/create");
              }
              // setShowAlert(true);
    
              // setAlertType({
              //   ...alert,
              //   type: "danger",
              //   value: res.data.message,
              // });
            })
            .catch((err) => {
              console.error(err)
              // setLoading(false);
              // setShowAlert(true);
              // setAlertType({ ...alert, type: "danger", value: err.message });
            });
        } else {
          window.alert("something went wrong");
        }
  };

  useEffect(() => {
    localStorage.setItem("hotel", hoteltoken)
    
  }, [hoteltoken])
  return(
    <>
      <tr key={hoteltoken}>
        <td>{i + 1}</td>
        <td>{hotelname || hotel_name}</td>
        <td>{city}</td>
        <td>{country}</td>
        
        <td className='d-flex flex-wrap' style={{gap:"1rem"}}>
          
          {/* <Link to={`${location.pathname}/rooms`} className='d-inline-flex text-decoration-none'> */}
            <span className="btn fw-bold table_button" onClick={handleViewRooms}>Rooms</span>
          {/* </Link> */}
          {type === "hotelOwner" && <span className='btn  fw-bold table_button ' onClick={(e)=>handleHotelEdit(e, all)}>Edit</span>}
          {type === "hotelOwner" && <span className='btn  fw-bold  table_button' onClick={(e)=>handleAddRoom(e, all)}>Add Room</span>}
          {<span className='btn  fw-bold table_button ' onClick={(e)=>setContractOpen(true)}>View Contracts</span>}
          {type !== "traveller" && <span className='btn  fw-bold table_button' onClick={handleShowSelect}>Create Contract</span>}
        </td>
      </tr>
      <Modals
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        handleOpen={handleOpen}
        setShow={setShow}
        hoteltoken={hoteltoken}
      />
      <ContractView
        handleShow={handleShow}
        handleClose={handleContractClose}
        open={contractOpen}
        name={hotelname || hotel_name}
        hoteltoken={hoteltoken}
      />
      <ChoiceModals
       handleShowSelect={handleShowSelect}
       handleCloseChoice={handleCloseChoice}
       showChoice={showChoice}
       setShowChoice={setShowChoice}
       handleOpen={handleOpen}
       handleOpenDynamic = {handleOpenDynamic}
       
      />
      <DynamicModal
        showDynamicModal = {showDynamicModal}
        setShowDynamicModal = {setShowDynamicModal}
        handleCloseDynamicModal = {handleCloseDynamicModal}
        // handleOpenContract = {createDynamicContract}
      />
    </>
  )
}

export default HotelList
