import React, { useState, useEffect, useContext } from "react";
import {useLocation,Link, useHistory} from "react-router-dom"

import { Fetch } from "../../../../../controllers/controller";
import Loading from "../../../../Reusables/Loader/Loading";
// import AddRooms from "../../../Others/hotelOwner/Manage/Hotels/Modals/AddRooms";
import HotelContext from "../Context/hotelcontext";
import Hotel from "../HotelsCard/Hotel";
import AddDmc from "../Modals/AddDmc";
import AddOwner from "../Modals/AddOwner";
import AddRooms from "../Modals/AddRooms";
import Deletehotel from "../Modals/Deletehotel";
import Unavailable from "./Unavailable";
import { mainGetUsertoken } from "../../../Reusables/GetUsertoken/GetUsertoken";
import ViewRoomModal from "../Modals/ViewRoomModal";
import ContractView from "../../../Others/DMC/Manage/Hotels/ContractView";

const HotelGroup = () => {
  const [hotelId, setHotelId] = useContext(HotelContext)

  
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [hotels, setHotels] = useState([]);
 const [deleteModal, setDeleteModal] = useState(false)
 const [dmcModal, setDmcModal] = useState(false)
 const [openViewRoom, setOpenViewRoom] = useState(false)

  const [show, setShow] = useState(false)
  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    const accowner = localStorage.getItem("accountOwner");
    setLoading(true)
    if (accowner) {
      const useableAccOwner = JSON.parse(accowner);

      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("token", useableAccOwner.usertoken);

      Fetch(
        `${process.env.REACT_APP_END_POINT}/list-hotel-three`,
        formData
      )
        .then((res) => {
          if (mounted) {
            if(res.data.success !== false){
              setHotels(res.data)
            }
            setLoading(false);
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false)



const handleHotelDelete = (e) =>{
    setHotelId(e)
    setDeleteModal(true)
}
const handleAddRoom = (hotel) =>{
  setLoading(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    formData.append("hoteltoken", hotel)

    Fetch(`${process.env.REACT_APP_END_POINT}/add-new-room`, formData)
    .then((res)=>{
        setLoading(false)
        setHotelId(hotel)
        setOpenAddRoomsModal(true)
        localStorage.setItem("room", res.data.roomid)
        localStorage.setItem("hotel", hotel)
      
    })
    .catch((err) => {
      window.alert(err.message)
      setLoading(false)
    
    })

  }
  const addOwner = (e) => {
    setShow(true);
    setHotelId(e)
  }
  const handleDMC = (e) => {
    setHotelId(e)
    localStorage.setItem("hotel", e)
    setDmcModal(true)
  }
  const handleListHotelRooms = (e) => {
    console.log(e)
    setHotelId(e)
    localStorage.setItem("hotelDetail", e)
  }
  const handleOpenViewRooms = (e) => {
    console.log(e)
    setHotelId(e)
    setOpenViewRoom(true)
    localStorage.setItem("hotelDetail", e)
  }
  return loading ? (
    <Loading />
  ) : (
    <div className="">
      
      {hotels.length === 0 ? (<Unavailable />):(
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Token</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {  hotels.map((hotel, i) => (
                <ListRow
                 {...hotel}
                  i={i}
                  handleHotelDelete={(e)=>handleHotelDelete(hotel.hotelid)}
                  handleAddOwner={(e)=>addOwner(hotel.hotelid)}
                  handleDMC={(e)=>handleDMC(hotel.hotelid)}
                  handleAddRoom={(e)=>handleAddRoom(hotel.hotelid)}
                  handleListHotelRooms={(e)=> handleListHotelRooms(hotel.hotelid)}  
                  handleViewRooms={(e)=>handleOpenViewRooms(hotel.hotelid)}
                  
                />
              ))
            }
            </tbody>
          </table>

        </div>
        // <div className="all-contracts justify-content-center">
       
        //    <Hotel
        //      key={hotel.hotelid}
        //      title={hotel.hotel_name}
        //      description={hotel.hotel_desc}
        //      date={hotel.date}
        //      time={hotel.timestamp}
        //      image={hotel.image}
        //      hotelID={hotel.hotelid}
        //      handleHotelDelete={(e)=>handleHotelDelete(hotel.hotelid)}
        //      handleAddOwner={(e)=>addOwner(hotel.hotelid)}
        //      handleDMC={(e)=>handleDMC(hotel.hotelid)}
        //      handleAddRoom={(e)=>handleAddRoom(hotel.hotelid)}
        //      handleListHotelRooms={(e)=> handleListHotelRooms(hotel.hotelid)}
        //      addRoom="d-inline-block"
        //    />
        //  ))}
        // </div>
      )}
      <AddRooms openModal={openAddRoomsModal} handleRemove={()=> setOpenAddRoomsModal(false)} />
      <AddOwner openOwnerModal={show} handleRemoveOwner={()=> setShow(false)} />
      <Deletehotel showDeleteModal={deleteModal} closeHotelDelete={()=> setDeleteModal(false)} />
      <AddDmc openDmcModal={dmcModal} handleAddDmcClose={()=> setDmcModal(false)} />
      <ViewRoomModal handleViewRoom={()=>setOpenViewRoom(false)} openViewRoom={openViewRoom} />
    </div>
  );
};

function ListRow({hoteltoken, hotelname, hotel_name, hotelid, hotel_desc, timestamp, i, type, handleHotelEdit, handleAddRoom, handleViewRooms, handleDMC, handleHotelDelete,handleAddOwner, all}){

  const [hotelId, setHotelId] = useContext(HotelContext)

  const location = useLocation();
  const [showChoice, setShowChoice] = useState(false);
  const [show, setShow] = useState(false);
  const [contractOpen, setContractOpen] = useState(false);

  
  const [usertoken, setUsertoken] = useState("")
  const [user, setUser] = useState("")
    



  function handleContractClose(){
    setContractOpen(false)
  }

 


  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser)

  },[])


  const handleShow = () => {
    setShow(true)
    setShowChoice(false)
  };

  useEffect(() => {
    localStorage.setItem("hotel", hoteltoken)
    
  }, [hoteltoken])
  return(
    <>
      <tr key={hoteltoken}>
        <td> 
           <Link to={`${location.pathname}`} className='d-inline-flex text-decoration-none text-dark'>
            {i + 1}
          </Link>
          </td>
        <td>
          <Link to={`${location.pathname}`} className='d-inline-flex text-decoration-none text-dark'>
            {hotelname || hotel_name}
          </Link>
        </td>  
        <td>
            <Link to={`${location.pathname}`} className='d-inline-flex text-decoration-none text-dark'>
              {hotelid}
            </Link>
          </td>
        <td>
          <Link to={`${location.pathname}`} className='d-inline-flex text-decoration-none text-dark'>
          {new Date(timestamp * 1000).toDateString()}
          </Link>
          </td>
        <td className='d-flex flex-wrap' style={{gap:"1rem"}}>
          {/* <Link to={`${location.pathname}/rooms`} className='d-inline-flex text-decoration-none'> */}
            <span className="btn table_button fw-bold" onClick={handleViewRooms}>Rooms</span>
            {/* <span className="btn table_button fw-bold" onClick={()=>openRooms(hoteltoken||hotelid)}>Available Rooms</span> */}
          {/* </Link> */}
          <span className='btn table_button fw-bold ' onClick={(e)=>setContractOpen(true)}>View Contracts</span>
          <span className='btn table_button fw-bold ' onClick={(e)=>handleAddRoom(e, all)}>Add Room</span>
          <span className='btn table_button fw-bold ' onClick={ handleAddOwner}>Add Hotel Owner</span>
          <span className='btn table_button fw-bold ' onClick={handleDMC}>Add DMC</span>
          <span className='btn table_button fw-bold ' onClick={handleHotelDelete}>Delete</span>
        </td>
      </tr>
      <ContractView
        handleShow={handleShow}
        handleClose={handleContractClose}
        open={contractOpen}
        name={hotelname || hotel_name}
        hoteltoken={hoteltoken}
      />
      {/* <Modals
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        handleOpen={handleOpen}
        setShow={setShow}
        hoteltoken={hoteltoken}
      />
      <ChoiceModals
       handleShowSelect={handleShowSelect}
       handleCloseChoice={handleCloseChoice}
       showChoice={showChoice}
       setShowChoice={setShowChoice}
       handleOpen={handleOpen}
       handleOpenDynamic = {handleOpenDynamic}
       
      />
      <DynamicModal
        showDynamicModal = {showDynamicModal}
        setShowDynamicModal = {setShowDynamicModal}
        handleCloseDynamicModal = {handleCloseDynamicModal}
        // handleOpenContract = {createDynamicContract}
      /> */}
    </>
  )
}
export default HotelGroup;
