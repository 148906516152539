import React from "react";

export function getUsertoken(location, setState, setUser) {
  switch (location.pathname) {
    case "/dmc/wallet-history":
      const data = localStorage.getItem("DMC");
      const token = JSON.parse(data);
      setUser(token);

      setState(token.usertoken);
      break;
    case "/account-owner/wallet-history":
      const accOwner = localStorage.getItem("accountOwner");
      const acToken = JSON.parse(accOwner);
      setUser(acToken);

      setState(acToken.usertoken);
      break;
    case "/hotel-owner/wallet-history":
      const hotelData = localStorage.getItem("hotelChain");
      const hotelOwnertoken = JSON.parse(hotelData);
      setUser(hotelOwnertoken);

      setState(hotelOwnertoken.usertoken);
      break;
    case "/user/wallet-history":
      const traveller = localStorage.getItem("traveller");
      const travellerToken = JSON.parse(traveller);
      setUser(travellerToken);

      setState(travellerToken.usertoken);
      break;
    default:
      break;
  }
}
export function getServiceUsertoken(location, setState) {
  switch (location.pathname) {
    case "/dmc/services":
      const data = localStorage.getItem("DMC");
      const token = JSON.parse(data);
      setState(token.usertoken);
      break;
    case "/account-owner/services":
      const accOwner = localStorage.getItem("accountOwner");
      const acToken = JSON.parse(accOwner);
      setState(acToken.usertoken);
      break;
    case "/hotel-owner/services":
      const hotelData = localStorage.getItem("hotelChain");
      const hotelOwnertoken = JSON.parse(hotelData);
      setState(hotelOwnertoken.usertoken);
      break;
    case "/user/services":
      const traveller = localStorage.getItem("traveller");
      const travellerToken = JSON.parse(traveller);
      setState(travellerToken.usertoken);
      break;
    case "/admin/services":
      const admin = localStorage.getItem("admin");
      const adminToken = JSON.parse(admin);
      setState(adminToken.usertoken);
      break;
    default:
      break;
  }
}

export function getUser(location, setUser, setUsertoken) {
  switch (location.pathname) {
    case "/dmc":
      const data = localStorage.getItem("DMC");
      const token = JSON.parse(data);
      setUser(token);
      setUsertoken(token.usertoken);
      break;
    case "/account-owner":
      const accOwner = localStorage.getItem("accountOwner");
      const accToken = JSON.parse(accOwner);
      setUser(accToken);
      setUsertoken(accToken.usertoken);
      break;
    case "/hotel-owner":
      const hotelOwner = localStorage.getItem("hotelChain");
      const hotelToken = JSON.parse(hotelOwner);
      setUser(hotelToken);
      setUsertoken(hotelToken.usertoken);
      break;

    case "/user":
      const traveller = localStorage.getItem("traveller");
      const travellerToken = JSON.parse(traveller);
      setUser(travellerToken);
      setUsertoken(travellerToken.usertoken);
      break;

    default:
      break;
  }
}


export function mainGetUsertoken(location, setState, setUser){
  if(location.pathname.split("/").includes("dmc")){
    const data = localStorage.getItem("DMC");
      const token = JSON.parse(data);
      setUser(token);

      setState(token.usertoken);

  } else if (location.pathname.split("/").includes("account-owner")){
    const accOwner = localStorage.getItem("accountOwner");
      const acToken = JSON.parse(accOwner);
      setUser(acToken);
      setState(acToken.usertoken);

  } else if(location.pathname.split("/").includes("hotel-owner")){
    const hotelData = localStorage.getItem("hotelChain");
    const hotelOwnertoken = JSON.parse(hotelData);
    setUser(hotelOwnertoken);

    setState(hotelOwnertoken.usertoken);
  }else if(location.pathname.split("/").includes("traveller")){
    const traveller = localStorage.getItem("traveller");
      const travellerToken = JSON.parse(traveller);
      setUser(travellerToken);

      setState(travellerToken.usertoken);
  } else if(location.pathname.split("/").includes("admin")){
    const admin = localStorage.getItem("admin");
      const admintoken = JSON.parse(admin);
      setUser(admintoken);
      setState(admintoken.usertoken);
  }

  return
}