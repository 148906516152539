import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Fetch, TimestampConverter } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";
import { mainGetUsertoken } from "../../Reusables/GetUsertoken/GetUsertoken";
import { payWithPayoneer } from "../apis";
import { useMutation } from "react-query";


const Pending = ({ show, setShow }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [items, setItems] = useState([]);
  const [usertoken, setUsertoken]= useState("")
  const [user, setUser]= useState({})

  const location = useLocation()

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  
  const handleClose = () => {
    setShow(false);
  };
  
  function listWithdrawal(){
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
  
    Fetch(`${process.env.REACT_APP_END_POINT}/list-withdrawals`, formData)
      .then((res) => {
          setItems(res.data);
          setLoading(false);  

      })
      .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "success",
            value: err.message,
          });
        
      });
  }
  React.useEffect(() => {
    let mounted = true;
    if(mounted){
      mainGetUsertoken(location, setUsertoken, setUser)
      listWithdrawal()

    }
    return () => {
      mounted = false;
    };
  }, []);

  const mutation = useMutation(payWithPayoneer, {
    onSuccess: (res)=> {
      if(!res.data.success){
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "danger",
          value: res.data.message,
        });
      } else {
        setShowAlert(true);
        listWithdrawal()
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      }
    },
    onError: (err)=> {
      console.error(err);
    }
  })
  function handleApprove(e, id) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("id", id);

    if(window.confirm("Confirm payment?")){
      Fetch(`${process.env.REACT_APP_END_POINT}/confirm-withdrawal`, formData)
        .then((res) => {
          setLoading(false);
          listWithdrawal()
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "success",
            value: res.data.message,
          });
        })
        .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: err.message,
        });
      });

    }
  }
  function handlePayWithPayoneer(e, id, amount, token, currency) {
    e.preventDefault();
    if(window.confirm("Confirm payment?")){
        mutation.mutate({
          apptoken: process.env.REACT_APP_APP_TOKEN,
          usertoken: token,
          currency: currency,
          wid:id,
          amount: amount
    
        })
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h3 className="text-dark">PENDING</h3>
      </Modal.Header>
      {showAlert === true && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      <Modal.Body>
        <div className="row justify-content-center align-items-center">
          {loading || mutation.isLoading ? (
            <Loading />
          ) : (
            <div className="table table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>TIME</th>
                    <th>ACCOUNT</th>
                    <th>AMOUNT</th>
                    <th>ACTION</th>
                  </tr>
                </thead>

                <tbody>
                  {items.length > 0 &&
                    items.map((data, index) => (
                      <tr>
                        <td>{data.count}</td>
                        <td>
                          {data.timestamp && TimestampConverter(data.timestamp)}
                        </td>
                        <td>
                          <div>
                            <p>{data.fullName}</p>
                            <small>{data.email}</small>
                          </div>
                        </td>
                        <td>{data.amount}</td>
                        <td className="d-flex">
                          <button
                            className="btn btn-dark"
                            onClick={(e) => {
                              handleApprove(e, data.id);
                            }}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-dark ms-2"
                            onClick={(e) => {
                              handlePayWithPayoneer(e, data.id, data.amount, data.usertoken, data.currency);
                            }}
                          >
                            Pay with Payoneer
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Pending;
