import React from "react";
import {useLocation, Link, Route} from "react-router-dom"
// import ListServices from "./ListServices";
import Service from "./Service";

const Services = () => {
  const location = useLocation();

  return (
    <div className="pt-4 px-lg-4 px-3">
      <header className=" mb-3 d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">SERVICES</h4>
        {/* <Link to="/dmc/services/create">
        <button
          className={
            location.pathname === "/dmc/services/create"
              ? "d-none"
            : `btn btn-lg-lg btn-primary contract-btn`
          }
          // onClick={handleShow}
          >
          <span className="button-text me-2 ">CREATE SERVICE</span>
          <span>          
            <i className="bi bi-plus-circle"></i>
          </span>{" "}
        </button>
        </Link> */}
      </header>
      <div className="admin-dashboard-top row mt-5">
          <Service />
          {/* <Route path="/dmc/services" exact component={ListServices} />
          <Route path="/dmc/services/create" component={Service} />    */}
      </div>
    </div>
  );
};

export default Services;
