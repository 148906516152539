import React from 'react'
import { TimestampConverter } from '../../../../../controllers/controller'

const ContractList = ({index,contract_id, type, buttonLoad, hoteltoken, contract_name, start_date, expiry_date, city,company, handleContractEdit, hotelname, child_age_To, child_age_From, duration, hotel, country, status, age, currency, noneAccountOwner, openAvailabilityModal, openMarkup, }) => {
  return (
    <tr>
        <td>{index + 1}</td>
        <td dangerouslySetInnerHTML={{ __html: contract_name === "" ? "N/A" : contract_name }} />
        <td>{contract_id}</td>
        <td>{type}</td>
        <td>{TimestampConverter(start_date)} - {TimestampConverter(expiry_date)}</td>
        <td dangerouslySetInnerHTML={{ __html: hotelname === "" ? "N/A" : hotelname }} />
        <td dangerouslySetInnerHTML={{ __html: country === "" ? "N/A" : country }} />
        <td dangerouslySetInnerHTML={{ __html: city === "" ? "N/A" : city }} />
        <td dangerouslySetInnerHTML={{ __html: company === "" ? "N/A" : company }} />
        <td>{status.toUpperCase()}</td>
        <td>{child_age_From} - {child_age_To}</td>
        <td>{currency}</td>
        <td>
        <div className="links-wrapper mb-3 d-flex justify-content-end">
            {/* <button className={`btn  table_button me-4 contractHotel ${noneAccountOwner}`} onClick={() => handleBookContract(ID)}>Book</button>
            <Link to={`${matched}/detail`} className={`btn table_button  me-4 contractHotel ${noneAccountOwner}`} onClick={() => handleSummary(ID)} >View</Link> */}
            <button
              className={`btn  table_button fw-bolder  me-4 ${noneAccountOwner} contractHotel`}
              onClick={() => handleContractEdit(contract_id, hoteltoken, hotelname, country, city, contract_name, child_age_From, child_age_To, status,currency )}
              style={{fontSize: "13px" }}
            >
              Edit
            </button>
            <div>
              {buttonLoad === contract_id ? (
                <button
                  className={`btn me-4  table_button  contractHotel ${noneAccountOwner}`}
                  onClick={() => openAvailabilityModal(contract_id)}
                >
                  <span
                    className="spinner-border text-primary" style={{height: ".9rem", width: ".9rem"}}
                    role="status"
                  ></span>
                </button>
              ) : (
                <button
                  className={`btn me-4  table_button fw-bolder contractHotel ${noneAccountOwner}`}
                  onClick={(e) => openAvailabilityModal(contract_id, e, index)}
                  style={{fontSize: "13px" }}
                >
                  Availability
                </button>
              )}
            </div>
            <button
              className={`btn table_button fw-bolder contractHotel ${noneAccountOwner}`}
              onClick={() => openMarkup(contract_id)}
              style={{fontSize: "13px" }}
            >
              Markup
            </button>
          </div>
        </td>
    </tr>
  )
}

export default ContractList