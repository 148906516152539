import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import _ from "lodash"
import { Modal } from "react-bootstrap";

const OptionRow = ({ index, formik, item, supplement, bookInfo, setBookInfo, adult_title, child_title }) => {
  const filter = localStorage.getItem("filter");
  const [filterItems,setFilterItems]= useState({})
  const selectFormik = useFormik({
    initialValues: {
      price_child: [],
      price_adult: [],
    },
  });
  const [userDetails, setUserDetails]= useState([[
    {
      name:"",
      email:"",
      phoneNo:""
    }
  ]])
  const [openUserDetailsModal, setOpenUserDetailsModal]= useState(false)
  const [adultNumber, setAdultNumber]= useState(0)
  const [priceSelect, setPriceSelect] = useState({
    adult: 0,
    child: 0,
  });
  const [flexAmount, setFlexAmount] = React.useState(0);

  // select button
  const handleChange = (e) => {
    setFlexAmount(e.target.value);
    for (let i = 0; i <= 4; i++) {
      if(e.target[i].value === e.target.value){
        setAdultNumber(e.target[i].innerHTML)
      } else {
        console.log("not found")
      }
      // return
    }
    // open userdetails modal
    setOpenUserDetailsModal(true)

  };




  
   const handleClick = (e)=>{
    // console.log("clicked",e.target)

   }


  useEffect(() => {
    if (selectFormik.values.price_adult.length > 0) {
      setPriceSelect({
        ...priceSelect,
        adult: selectFormik.values.price_adult?.reduce(
          (a, b) => Number(a) + Number(b),
          0
        ),
      });
    } else {
      setPriceSelect({ ...priceSelect, adult: 0 });
    }
  }, [flexAmount, selectFormik.values.price_adult]);

  useEffect(() => {
   
    if (selectFormik.values.price_child.length > 0) {
      setPriceSelect({
        ...priceSelect,
        child: selectFormik.values.price_child?.reduce(
          (a, b) => Number(a) + Number(b),
          0
        ),
      });
    } else {
      setPriceSelect({ ...priceSelect, child: 0 });
    }

    if(filter){
      setFilterItems(JSON.parse(filter))
    }
  }, [selectFormik.values.price_child]);

  const [checked, setChecked] = React.useState(false);
  
  // console.log(filterItems)
const [roomDetail, setRoomDetail] = useState({})

  function handleInfoSet(e, name, id, roomtoken, type, amount){
    let roomdetail = {
      _id:name+index,
      roomname:name,
      markup_id: id ? id : null,
      roomtoken,
      price_room: flexAmount,
    }

    if(type === "adult") {

      if (e.target.checked){
        // check if it does not exist in the rooms array
        if(bookInfo.bookingData.rooms.findIndex(room=> room._id === name+index ) === -1){
          // add to state
          setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
            rooms:[ ...bookInfo.bookingData?.rooms, {...roomdetail, adultNumber: adultNumber, totalAdultPrice: e.target.value, userDetails }]
            }
          })
          setRoomDetail({...roomDetail, ...roomdetail, adultNumber: adultNumber, totalAdultPrice: e.target.value, userDetails})

        }  else{
          roomdetail = {
            ...roomdetail,
            adultNumber: adultNumber, 
          }
          let findRoom = bookInfo.bookingData.rooms.find(room => room._id === name+index)
          // add exisiting data to room detail
          roomdetail = {
            ...roomdetail, ...findRoom,  adultNumber: adultNumber,  totalAdultPrice: e.target.value, userDetails
          }
          // console.log("found", findRoom)
          // console.log("new room detail", roomdetail)

          let allRooms = bookInfo.bookingData.rooms
          let roomIndex = allRooms.findIndex(room=> room._id === roomdetail._id )
          allRooms[roomIndex] = roomdetail
          setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
            rooms:allRooms
            }
          })
            // console.log("room array",allRooms)

        }
      } else{
        roomdetail = {
          ...roomdetail, ...roomDetail,
          adultNumber: 0, 
          totalAdultPrice:0
          
        }        
        let allRooms = bookInfo.bookingData.rooms
        let roomIndex = bookInfo.bookingData.rooms.findIndex(room=> room._id === roomdetail._id )
        // console.log("che", allRooms[roomIndex])
        setRoomDetail({...allRooms[roomIndex], ...roomdetail})
        allRooms[roomIndex] = {...allRooms[roomIndex], ...roomdetail}
        setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
          rooms:allRooms
          }
        })

        if((roomdetail.childNumber === 0 || roomdetail.childNumber === null ) && (roomdetail.adultNumber === 0 || roomdetail.adultNumber === null)){
          let findRoom = bookInfo.bookingData.rooms.filter(room => room._id !== name+index)
          // console.log(findRoom)
          setBookInfo({...bookInfo, bookingData:{ 
            ...bookInfo.bookingData,
            rooms:findRoom
            }
          })  
        }

      }
    } else  {
      //  for child checkbox
      if (e.target.checked){
        if(bookInfo.bookingData.rooms.findIndex(room=> room._id === name+index ) === -1){
          // add to state
          roomdetail = {
            ...roomdetail,
            childNumber: filterItems.child, 
            totalChildPrice: e.target.value
          }
          setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
            rooms:[ ...bookInfo.bookingData?.rooms, {...roomdetail}]
            }
          })
          setRoomDetail({...roomDetail, ...roomdetail})

        } else{
          // if found
          roomdetail = {
            ...roomdetail,
            childNumber: filterItems.child, 
            totalChildPrice: e.target.value
          }
          let findRoom = bookInfo.bookingData.rooms.find(room => room._id === name+index)
          // add exisiting data to room detail
          roomdetail = {
            ...roomdetail, ...findRoom, childNumber: filterItems.child, totalChildPrice: e.target.value
          }
          // console.log("found", findRoom)
          // console.log("new room detail", roomdetail)

          let allRooms = bookInfo.bookingData.rooms
          let roomIndex = allRooms.findIndex(room=> room._id === roomdetail._id )
          allRooms[roomIndex] = roomdetail
          setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
            rooms:allRooms
            }
          })
            // console.log("room array", allRooms)
        }
      } else{
        roomdetail = {
          ...roomdetail, ...roomDetail,
          childNumber: 0, 
          totalChildPrice: 0
        }
        setRoomDetail({...roomDetail, ...roomdetail})
        let allRooms = bookInfo.bookingData.rooms
        let roomIndex = bookInfo.bookingData.rooms.findIndex(room=> room._id === roomdetail._id )
        allRooms[roomIndex] = {...allRooms[roomIndex], ...roomdetail}
        setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
          rooms:allRooms
          }
        })
        if((roomdetail.childNumber === 0 || !roomdetail.childNumber ) && (roomdetail.adultNumber === 0 || !roomdetail.adultNumber)){
          let findRoom = bookInfo.bookingData.rooms.filter(room => room._id !== name+index)
          // console.log(findRoom)
          setBookInfo({...bookInfo, bookingData:{ 
            ...bookInfo.bookingData,
            rooms:findRoom
            }
          })  
        }

      }

    }
  }
  
  console.log({roomDetail})
  console.log({bookInfo})

  function handleSupplementInfoSet(e, name){

  }

  return (
    <>
    <UserDataModal adultNumber={adultNumber} showUserDetails={openUserDetailsModal} setShowedUserDetails={setOpenUserDetailsModal} userDetails={userDetails} setUserDetails={setUserDetails} />
      <tr className="position-relative vertical-align">
        <td style={tdStyle} className="fw-bold ">
          {item.room_name}
        </td>
        <td style={tdStyle}>
          <select
            disabled={checked ? true : false}
            name="price_type"
            id="price_type"
            className="form-select w-50"
            value={flexAmount}
            onChange={handleChange}
          >
            <option defaultValue value={0}>
              No of Rooms
            </option>
            {item.price_sgl !== "" && item.price_sgl !== "0" && (
              <option value={item.markup ? +item.price_sgl + item.markup.markup :item.price_sgl } >1</option>
            )}
            {item.price_dbl !== "" && item.price_dbl !== "0" && (
              <option value={item.markup ? +item.price_dbl + item.markup.markup :item.price_dbl } >2</option>
            )}
            {item.price_trl !== "" && item.price_trl !== "0" && (
              <option value={item.markup ? +item.price_trl + item.markup.markup :item.price_trl } >3</option>
            )}
            {item.qud !== "" && item.qud !== "0" && (
              <option value={item.markup ? +item.price_qud + item.markup.markup :item.price_qud } >4</option>
            )}
          </select>
        </td>
        <td style={tdStyle}>
          <div>
            <span className="me-2">$ {+flexAmount + priceSelect.adult}</span>
            <span className="">
              <label htmlFor="adult_price" className="visually-hidden">
                Price
              </label>
              <input
                type="checkbox"
                name="price"
                id="adult_price"
                onChange={(e) => {
                  setChecked(!checked);
                  formik?.handleChange(e);
                  handleInfoSet(e,  item.room_name, item.markup?.id, item.roomtoken,"adult")
                }}
                value={+flexAmount + priceSelect.adult}
              />
            </span> 
          </div>
        </td>
        <td style={tdStyle}>
          <span className="me-2">$ {+item.price_child + priceSelect.child}</span>
          <span className="">
            <label htmlFor="adult_price" className="visually-hidden">
              Price
            </label>
            <input
              type="checkbox"
              name="price"
              id="child_price"
              onChange={(e) => {
                formik?.handleChange(e);
                handleInfoSet(e, item.room_name, item.markup?.id, item.roomtoken, "child")

              }}
              value={+item.price_child + priceSelect.child}
            />
          </span>
        </td>
      </tr>

      {supplement.length > 0 &&
        supplement.map((supp) => (
          <>
            <tr>
              <td className="text-capitalize supplement_list">
                {supp.service}
              </td>
              <td className="supplement_list">Price</td>
              <td className="supplement_list">
                <span className="d-flex align-item-center gap-2">
                  <span className="">$ {supp.price_adult} </span>
                  <label htmlFor="price_adult" className="visually-hidden">
                    Supplememt Price Adult
                  </label>
                  <input
                    type="checkbox"
                    name="price_adult"
                    id="price_adult"
                    disabled={checked ? true : false}
                    onChange={(e) =>{ 
                      selectFormik.handleChange(e)
                      handleSupplementInfoSet(e, "adult", item.room_name, item.markup?.id)

                      }
                    }
                    value={supp.price_adult}
                  />
                </span>
              </td>
              <td className="supplement_list">
                <span className="d-flex align-item-center gap-2">
                  <span className="">$ {supp.price_child} </span>
                  <label htmlFor="adult_price" className="visually-hidden">
                    Supplememt Price Child
                  </label>
                  <input
                    type="checkbox"
                    name="price_child"
                    id="price_child"
                    onChange={(e) => {
                      selectFormik.handleChange(e)
                      handleSupplementInfoSet(e, "child", item.room_name, item.markup?.id)

                    }}
                    value={supp.price_child}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td className="supplement_list"></td>
              <td className="supplement_list">Amount</td>
              <td className="supplement_list">
                <span className="d-flex align-item-center gap-2">
                  {
                    supp.adult_amount ?
                    <span>$ {supp.adult_amount}</span>
                    :
                    <span>{supp.adult_perc} % </span>
                  }
                  <label htmlFor="adult_price" className="visually-hidden">
                    Supplememt Adult Amount
                  </label>
                  <input
                    type="checkbox"
                    name="price_adult"
                    id="adult_price"
                    disabled={checked ? true : false}
                    onChange={(e) => selectFormik.handleChange(e)}
                    value={
                      (supp?.adult_perc * flexAmount) / 100 +
                      Number(supp?.adult_amount)
                    }
                  />
                </span>
              </td>
              <td className="supplement_list">
                <span className="d-flex align-item-center gap-2">
                  {
                    supp.child_amount ? 
                    <span>$ {supp.child_amount} </span>
                    :
                    <span>{supp.child_perc} % </span>
                  }
                  <label htmlFor="adult_price" className="visually-hidden">
                    Supplememt Price Child
                  </label>
                  <input
                    type="checkbox"
                    name="price_child"
                    id="price_child"
                    onChange={(e) => selectFormik.handleChange(e)}
                    value={
                      (supp?.child_perc * flexAmount) / 100 +
                      Number(supp?.child_amount)
                    }
                  />
                </span>
              </td>
            </tr>
          </>
        ))}
    </>
  );
};

export default OptionRow;

const childRowStyle = {
  bottom: "-5px",
  left: 0,
  width: "100%",
  // border: "1px solid tomato",
};

const tdStyle = {
  paddingBlock: "1.5rem",
};



function UserDataModal({showUserDetails, setShowedUserDetails, userDetails, setUserDetails, adultNumber=0}){

  const [count, setCount] = useState(1)
  
  function change(e, i, roomIndex){
    console.log(userDetails[roomIndex] === undefined)
    if(userDetails[roomIndex] === undefined){
      setUserDetails([...userDetails, [{[e.target.name]: e.target.value}]])
    }else {
      let data = userDetails
      data[roomIndex][i] = {...data[roomIndex][i], [e.target.name]: e.target.value}
      setUserDetails(data)
    }
    
  }


  return (
    <Modal
      show={showUserDetails}
      onHide={()=>setShowedUserDetails(false)}
      dialogClassName="select-hotel"
      centered
    >
      <div className="py-5 px-5 modals">
        <div className="d-flex flex-column justify-content-start  mb-5">
          <h4 className="text-primary fw-bold">Adult occupant(s) info</h4>
        </div>  
        <Modal.Body className="mb-5">

          {
            [...Array(Number(adultNumber))]?.map((_, i) => (
                <div className="my-2">
                  <p className="fw-bold">Room {i + 1}</p>
                    {
                      [...Array(count)]?.map((item, index) => (
                        <form action="" className="form my-2">
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" name="name" onChange={(e)=>change(e, index, i)}  />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" name="email" id="email" className="form-control" onChange={(e)=>change(e, index, i)}   />
                          </div>
                          <div className="form-group">
                            <label htmlFor="phoneNo" className="form-label">Phone</label>
                            <input type="tel" name="phoneNo" id="phoneNo" className="form-control"  onChange={(e)=>change(e, index, i)}  />
                          </div>
                        </form>
                      ))
                    }
                    <button className="btn btn-dark" style={{fontSize:"14px"}} onClick={()=>setCount(count + 1)}>Add More</button>

                </div>
            ))
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
          <button className="btn btn-dark" onClick={()=>setShowedUserDetails(false)}>Save</button>
          <button className="btn btn-muted" onClick={()=>setShowedUserDetails(false)}>Close</button>

          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}