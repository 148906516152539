import React from "react";

import { useLoading, BallTriangle, Bars , Grid} from "@agney/react-loading";
function Loading({heightvalue}) {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Grid width="80" />,
    loaderProps: {
      valueText: 'Please wait...',
      style:{color: 'rgba(14, 73, 152, 1)'}
    }
  
  });

  return (
    <section {...containerProps} className="w-200px bordered d-flex justify-content-center align-items-center" style={{height: heightvalue === undefined? "100vh" : "heightvalue"}}>{indicatorEl}</section>
  );
}
export default Loading