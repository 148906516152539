import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidenavlinks.css";

const SidebarLinks = ({  title, link, icon }) => {
  // const location = useLocation();
  // const checkActive = (location, title)=>{
  //   if((location.pathname).includes(title)){
  //     return "active"
  //   } else {
  //     return "inactive"
  //   }
  // }

  // console.log(checkActive(location, title))
  return (
    <>
      <NavLink exact to={link} activeClassName="active" className="d-block sidenav-column my-1 px-2 py-1 text-secondary">
        <div className="sidenavlink px-3 d-flex align-items-center ">
         <div className="bordered"></div>
          <i className={`me-3 sidenavlink-img ${icon}`}></i>
          <p className="sidenavlink-title mb-0 pb-0 title_font">{title}</p>
        </div>
      </NavLink>
    </>
  );
};

export default SidebarLinks;
