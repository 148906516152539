import React from "react";

const SimpleCards = ({destination__img, title}) => {
  return (
    <div className="d-flex justify-content-center" style={{height:"250px" , background:`url(${destination__img}), rgba(255, 255, 255, 0.5)`, backgroundRepeat:"no-repeat", backgroundBlendMode:"multiply", backgroundSize:"cover"}}>
      <div className="destination__card__content w-100 mx-auto">
        {/* <img src={destination__img} alt="" className="img-fluid"  /> */}
        <h3 className="destination__title">{title}</h3>
        <p className="destination__footer"> 1 space 2 Hotels 1 package</p>
      </div>
    </div>
  );
};

export default SimpleCards;
