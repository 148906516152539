import React from 'react'

const TopTable = ({contractTop, handleFirstChange}) => {
  const [info, setInfo]= React.useState({})


    const CT = localStorage.getItem("CT");
    const Country = localStorage.getItem("Count");
    const City = localStorage.getItem("City");
    const hotelname = localStorage.getItem("hotelname");

    React.useEffect(()=>{
      

    },[])
  return (
    <div className="table-responsive mb-5 mt-5">
        <table className=" w-50 table-bordered border-dark mytable">
          <tbody>
            <tr>
              <th>COUNTRY</th>
              <td>{Country !== null && Country.toUpperCase()}</td>
              <th>CITY</th>
              <td>{City !== null && City.toUpperCase()}</td>
              <th>HOTEL</th>
              <td
                dangerouslySetInnerHTML={{
                  __html: hotelname !== null && hotelname.toUpperCase(),
                }}
              />
            </tr>
            <tr>
              <th>ID</th>
              <td>{CT}</td>
              <th className="border border-dark">CONTRACT</th>
              <td className="border border-dark">
                <div>
                  <input
                    type="text"
                    name="contract_name"
                    id="contract_name"
                    placeholder="Name"
                    className="form-control"
                    onChange={handleFirstChange}
                    value={contractTop.contract_name}
                  />
                </div>
              </td>
              <th className="border border-dark">STATUS</th>
              <td className="border border-dark select-wrapper">
                <div>
                  <select
                    name="status"
                    id="status"
                    onChange={handleFirstChange}
                    className="form-select"
                    value={contractTop.status}
                    style={{
                      color:
                        (contractTop.status === "live" && "green") ||
                        (contractTop.status === "closed" && "red") ||
                        (contractTop.status === "renew" && "#ffbf00"),
                      fontWeight: "bold",
                      minWidth: "120px",
                    }}
                  >
                    <option defaultValue>status</option>
                    <option value="live" className="text-success">
                      Live
                    </option>
                    <option value="closed" className="text-danger">
                      Closed
                    </option>
                    <option value="renew" style={{ color: "#ffbf00" }}>
                      Renew
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-dark" style={{minWidth: "118px"}}>CHILD AGE <br /> FROM - TO</th>
              <td className="border border-dark select-wrapper">
                <input
                  type="number"
                  name="child_age_From"
                  id="child_age"
                  placeholder="Min age"
                  className="form-control"
                  onChange={handleFirstChange}
                  value={contractTop.child_age_From}
                  style={{height:"35px"}}
                />
              </td>
              <td className="border border-dark">
                <input
                  type="number"
                  name="child_age_To"
                  id="child_age"
                  placeholder="Max age"
                  className="form-control"
                  onChange={handleFirstChange}
                  value={contractTop.child_age_To}
                  style={{height:"35px"}}
                />
              </td>
              <th className="border border-dark">CURRENCY</th>
              <td className="border border-dark select-wrapper">
                <select
                  name="currency"
                  id="currency"
                  onChange={handleFirstChange}
                  className="form-select"
                  value={contractTop.currency}
                  style={{ minWidth: "118px", height:"35px" }}
                >
                  <option defaultValue>Currency</option>
                  <option value="USD">USD</option>
                  <option value="AED">AED</option>
                </select>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
  )
}

export default TopTable