import React from "react";
import { TimestampConverter } from "../../../../../../../controllers/controller";
import "../offer.css"
const StaticOffer = ({geo_offer, order,timestamp, meals,
  room,
  supplement, handleEdit, offer,  link1, discount_amt, discount_rate, countries, early_bird, stay_from, stay_to, booking_from, booking_to, offers_room_type }) => {
  return (
    <tr>
      <td className="oneFullColumn">
                <p>{TimestampConverter(timestamp)}</p>
      </td>
      <td>
      <p>{offer === "undefined" || offer === "" ? "-": offer}</p>
      </td>
      <td>
      <p>{offers_room_type === "undefined" || offers_room_type === "" ? "-": offers_room_type}</p>
      </td>
      
      <td>
        <p>{TimestampConverter(stay_from)}</p>
      </td>
      <td>
        <p>{TimestampConverter(stay_to)}</p>
      </td>

      <td>
        <p>{TimestampConverter(booking_from)}</p>
      </td>
      <td>
        <p>{TimestampConverter(booking_to)}</p>
      </td>
      <td>
        <p>{ discount_amt === "undefined" || discount_amt === ""  ? "-" : discount_amt}</p>
      </td>
      <td>
        <p>{discount_rate === "undefined" || discount_rate === "" ? "-" : discount_rate}</p>
      </td>
      <td>
       <p>{countries === "undefined" || countries === "" ? "-" : countries}</p>
      </td>
      <td>
          <input type="checkbox" name="link1" id="link1" value={link1} checked={link1 === "0" ? false : true} disabled />
      </td>
      <td>
          <input type="checkbox" name="offer_room" id="offer_room" value={room} checked={room === "0" ? false : true} disabled />
      </td>
      <td>
          <input type="checkbox" name="offer_meals" id="offer_meals" value={meals} checked={meals === "0" ? false : true} disabled />
      </td>
      <td>
          <input type="checkbox" name="offer_supplement" id="offer_supplement" value={supplement} checked={supplement === "0" ? false : true} disabled />
      </td>
      <td>
      <p>{order === "undefined" || order === "" ? "-": order}</p>
      </td>
      <td>
        <button className="btn" onClick={handleEdit}>
          Edit
        </button>
      </td>
    </tr>
  );
};

export default StaticOffer;
