import React from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
const Banner = () => {
  const [langSelect, setLangSelect]= React.useState(true)
  return (
    <div className="wrapper" style={{scrollSnapAlign:"center"}}>
      <div className="top bg-primary px-3 d-flex justify-content-end">
        <div style={{ height: "60px" }}>
          <div className="d-flex align-items-center language">
            <label htmlFor="eng" className="me-2" >
                      <input
                        type="radio"
                        name="lang"
                        id="eng"
                        value="eng"
                        checked={langSelect && true }
                        
                        onClick={()=>setLangSelect(!langSelect)}
                      />
                      <p className="p-1">EN</p>
              </label>
            <label htmlFor="esp" >
                      <input
                        type="radio"
                        name="lang"
                        id="esp"
                        value="esp"
                        checked={!langSelect && true }
                        onClick={()=>setLangSelect(!langSelect)}

                      />
                      <p className="p-1">ES</p>
              </label>
          </div>
        </div>
      </div>
      <section className=" hero" style={{ height: "90vh", padding: "0 0 40px 0", }} >
        <motion.nav className="navbar navbar-light bg-light"
        initial={{y: -200}}
        animate={{y: 0}}
        transition={{delay:0.2}}
        >
          <div className="container-fluid">
            <a className="navbar-brand text-primary">HOTELS OFFLINE</a>
            <div className="d-flex">
             
              <Link to="/login" className="btn btn-outline-primary me-4" type="submit">
                Login
              </Link>
              <Link className="btn btn-outline-primary bg-primary text-white" to="/signup" >
                Register
              </Link>
               
            </div>
          </div>
        </motion.nav>
        <motion.div className="content" 
        initial={{x: -200}}
        animate={{x: 0}}
         style={{ marginTop: "110px" }}
        >
          <div className=" container ps-sm-5 ms-sm-5">
            <div className="row justify-content-left pt-2">
              <div className="col-md-6 col-sm-12 ">
                <h1 className="display-1 fw-bold hero-display">
                  Best Deals Are Made Offline
                </h1>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="form-control hero-input"
                        
                      />
                      <div className="input-group-append hero-search-wrapper">
                        <span className="input-group-text hero-search">
                          <i className="bi bi-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default Banner;
