import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  Fetch,
  TimestampConverter,
} from "../../../../../../controllers/controller";
import ContractCal from "../Calendar/ContractCal";
import Loading from "../../../../../Reusables/Loader/Loading";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";

const DetailsModal = ({
  showModal,
  alert,
  closeModal,
  value,
  loader,
  closeAlert,
  selectedDate,
  closeCalendarModal
}) => {
  const [room, setRooms] = useState([1, 2]);
  const [payload, setPayload] = useState({
    allotment: "",
  });
  const [from, setFrom] = useState(false);
  const [to, setTo] = useState(false);
  const [load, setLoad] = useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const [alertt, setAlertType] = useState({
    type: "",
    value: "",
  });

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setPayload({ ...value });
  }, [value]);


  
  const handleSubmit = (e) => {
    setLoad(true)
    e.preventDefault();
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("contractToken", payload.contract_id);
    formData.append("hoteltoken", payload.hoteltoken);
    formData.append("roomtoken", payload.roomtoken);
    formData.append("dmctoken", payload.dmctoken);
    formData.append("room", payload.allotment === true ? payload.allotment : payload.room);
    formData.append("start_date", payload.start_date);
    formData.append("end_date", payload.expiry_date);
    formData.append("inventory_rel", payload.inv_rel);
    formData.append("price_sgl", payload.price_sgl);
    formData.append("twn", payload.twn);
    formData.append("price_dbl", payload.price_dbl);
    formData.append("price_trl", payload.price_trl);
    formData.append("price_qud", payload.price_quad);
    formData.append("unit", payload.unit);
    formData.append("id", payload.id);
    Fetch(
      `${process.env.REACT_APP_END_POINT}/create-price-contract`,
      formData
    )
      .then((res) => {
        setLoad(false)
        setShowAlert(true)
        setAlertType({
          ...alertt,
          type: "success",
          value: res.data.message,
        });

        if(res.data.success === true){
          closeCalendarModal()
          window.location.reload()
        }

      })
      .catch((err) => {
        setLoad(false)
        setShowAlert(true)
          setAlertType({
            ...alertt,
            type: "danger",
            value: err.message,
          });
      });
  };
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
      contentClassName={"details_modal"}
      dialogClassName={"details_modal_wrapper"}
    >
      <Modal.Body>
        <div className="contractWrapper">
          {loader === true ? (
            <Loading />
          ) : (
            <>
              {alert === true ? (
                <AlertComp
                  variant={"secondary"}
                  alertText={"no record found"}
                  handleAlertClose={closeAlert}
                />
              ) : (
                <div className="editor">
                  {Object.keys(value).length > 0 && (
                    <div className="wrapper bg-light px-1 shadow">
                     {showAlert === true && ( <AlertComp variant={alertt.type} alertText={alertt.value} handleAlertClose={handleAlertClose}
                />
              )}
                      <div className="card">
                        <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
                          <div className="d-flex flex-wrap justify-content-between mb-1 mt-3 w-100">
                            <div className="me-3 mb-1 mb-md-0">
                              <strong className="text-secondary">
                                Contract:{" "}
                              </strong>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: value.contract_name.toUpperCase(),
                                }}
                              />
                            </div>
                            <div className="me-3 mb-1 mb-md-0">
                              <strong className="text-secondary">ID: </strong>
                              <p> {value.contract_id}</p>
                            </div>
                            <div className="me-3 mb-1 mb-md-0">
                              <strong className="text-secondary">
                                Hotel:{" "}
                              </strong>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: value.hotelname === "" ? "N/A" : value.hotelname,
                                }}
                              />
                            </div>
                            <div className="me-3 mb-1 mb-md-0">
                              <strong className="text-secondary">Room: </strong>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: value.room_name,
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between mb-1 mt-3 w-100">
                          <div className="me-3">
                            <strong className="text-secondary">Date:</strong>
                            <div>{selectedDate && new Date(selectedDate).toDateString()}</div>
                          </div>
                            <div className="me-3">
                              <strong className="text-secondary">
                                Status:{" "}
                              </strong>
                              <div> {value.status.toUpperCase()}</div>
                            </div>
                            <div className="me-3">
                              <strong className="text-secondary">
                                Release:{" "}
                              </strong>
                              <div> {value.release_date === "" ? "N/A" : value.release_date}</div>
                            </div>
                            <div className="me-3">
                              <strong className="text-secondary">
                                Currency:{" "}
                              </strong>
                              <div> {value.currency}</div>
                            </div>
                            <div className="me-3">
                              <strong className="text-secondary">
                                Child's Age:{" "}
                              </strong>
                              <div>
                                {" "}
                                {value.child_age_From === "" ? "N/A" : `${value.child_age_From} to ${value.child_age_To}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form className="p-2 mt-5">
                        {/* <p className="lead text-danger fw-bold">EDIT</p> */}
                        <div className="row ms-md-3">
                          <div className="col-sm-4">
                            <label className="text-secondary" htmlFor="start_date">Date From</label>
                            <input
                              type="date"
                              name="start_date"
                              id="start_date"
                              className="form-control border border-dark rounded-3 w-75"
                              placeholder="Date From"
                              onChange={handleChange}
                              onFocus={()=>{
                                setFrom(!from)
                              }}
                              onBlur={()=>{
                                setFrom(false)
                              }}
                              value={payload.start_date}
                            />
                          </div>
                          <div className="col-sm-4">
                          <label className="text-secondary" htmlFor="end_date">Date To</label>
                            <input
                              type="date"
                              name="expiry_date"
                              id="end_date"
                              className="form-control  border border-dark rounded-3 w-75"
                              placeholder="Date To"
                              onChange={handleChange}
                              onFocus={()=>{
                                setTo(!to)
                              }}
                              onBlur={()=>{
                                setTo(false)
                              }}
                              value={payload.expiry_date}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 ms-md-3">
                          <div className="col-sm-4">
                            <label htmlFor="name" className="text-secondary ">
                              Allotment
                            </label>
                            <input
                              type="number"
                              name="room"
                              id="name"
                              value={payload.room === "0" ? payload.allotment: payload.room}
                              className="form-control border border-dark rounded-3 w-75"
                              placeholder="Allotment"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="release" className="text-secondary ">
                              Release
                            </label>
                            <input
                              type="number"
                              name="inv_rel"
                              id="release"
                              placeholder="Release"
                              value={payload.inv_rel}
                              className="form-control border border-dark rounded-3 w-75"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="table-responsive mt-4">
                          <p className="lead fw-bold">Prices</p>
                          <table className="table table-bordered border-dark w-75">
                            <thead>
                              <tr>
                                <th className="text-danger">SGL</th>
                                <th className="text-danger">DBL</th>
                                <th className="text-danger">TWN</th>
                                <th className="text-danger">TRPL</th>
                                <th className="text-danger">QUAD</th>
                                <th className="text-danger">UNIT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="number"
                                    name="price_sgl"
                                    className="form-control"
                                    id="sgl"
                                    onChange={handleChange}
                                    value={payload.price_sgl}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="price_dbl"
                                    className="form-control"
                                    id="dbl"
                                    onChange={handleChange}
                                    value={payload.price_dbl}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="twn"
                                    className="form-control"
                                    id="twn"
                                    onChange={handleChange}
                                    value={payload.twn}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="price_trl"
                                    className="form-control"
                                    id="trpl"
                                    onChange={handleChange}
                                    value={payload.price_trl}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="price_quad"
                                    className="form-control"
                                    id="quad"
                                    onChange={handleChange}
                                    value={payload.price_quad}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="unit"
                                    className="form-control"
                                    id="unit"
                                    onChange={handleChange}
                                    value={payload.unit}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="button">
                          {load === true ?  
                        <div className="btn btn-danger" >
                          <div className="spinner-border text-light" role="status"></div>
                        </div>  
                      :
                          <button
                            className="btn btn-lg btn-danger"
                            onClick={handleSubmit}
                          >
                            SUBMIT
                          </button>
                          }
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
            </Modal.Footer>
    </Modal>
  );
};

export default DetailsModal;
