import React from "react";

function Button({ btnName, btnClass, handleClick }) {
	return (
		<>
			<button className={btnClass} type="submit" onClick={handleClick}>
				{btnName}
			</button>
		</>
	);
}

export default Button;