import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Fetch } from "../../../../../../controllers/controller";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import HotelContext from "../../../../Junior/Manage/Context/hotelcontext";
import { mainGetUsertoken } from "../../../../Reusables/GetUsertoken/GetUsertoken";
import { createDynamicContract, fetchAdminHotels, getExternalSources, getSourceHotels, listHotelbyDmc } from "../../../../Super/apis";
import "./modal.css";
const Modals = ({ handleShow, show, handleClose, setShow }) => {
  const [hotelId, setHotelId] = useContext(HotelContext);
  const [loading, setLoading] = useState(false);
  const [hotel, setHotel] = useState("");
  const [hotels, setHotels] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const history = useHistory();
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);

  const handleOpen = () => {
    if (hotel !== "") {
      setLoading(true);
      
      localStorage.removeItem("CT");
      localStorage.removeItem("Count");
      localStorage.removeItem("City");
      localStorage.removeItem("hotelname");
      localStorage.removeItem("contract_info");

      const hotel = localStorage.getItem("hotel");

      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("dmctoken", usertoken);
      formData.append("hoteltoken", hotel);

      Fetch(`${process.env.REACT_APP_END_POINT}/create-contract`, formData)
        .then((res) => {
          setLoading(false);
          
          if (res.data.success !== false) {
            setShow(false);
            localStorage.setItem("CT", res.data.contractToken);
            localStorage.setItem("Count", res.data.country);
            localStorage.setItem("City", res.data.city);
            localStorage.setItem("hotelname", res.data.hotelname);
            localStorage.setItem("contractDetail", JSON.stringify(res.data));
            localStorage.removeItem("header");
            history.push("/dmc/contracts/create");
          }
          setShowAlert(true);

          setAlertType({
            ...alert,
            type: "danger",
            value: res.data.message,
          });
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      window.alert("select a hotel");
    }
  };

  const listDMCHotel = useMutation(listHotelbyDmc, { 
    onSuccess:(e)=>{
      if(e.data.success !== false){
        setHotels(e.data.hotels);
      }else {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: e.data.message });  
      }

    
    },
    onError: (err)=>{
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    }

  } )
  const listAdminHotel = useMutation(fetchAdminHotels, { 
    onSuccess:(e)=>{
      if(e.data.success !== false){
        setHotels(e.data.hotels);
      }else {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: e.data.message });  
      }

    
    },
    onError: (err)=>{
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    }

  } )


  
  
  useEffect(() => {
    if (usertoken) {
      
      if(location.pathname === "/admin/contracts"){
        listAdminHotel.mutate({
          apptoken: process.env.REACT_APP_APP_TOKEN,
          token:usertoken
        })
      }else {
        listDMCHotel.mutate({
          apptoken: process.env.REACT_APP_APP_TOKEN,
          token:usertoken
        })
      }
    }

  }, [usertoken]);

  const insertHotelID = (e) => {
    localStorage.setItem("hotel", e.target.value);
    setHotelId(e.target.value);
    setHotel(e.target.value);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="select-hotel"
        centered
      >
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">Create Contract</h4>
            <p className="text-secondary ">
              For ease of trackings and documentation
            </p>
          </div>
          <Modal.Body className="mb-5">
            <form>
              {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
              <label
                htmlFor="hotel"
                className=" mb-2 text-secondary form-label"
                style={{ fontSize: "12px" }}
              >
                Select Hotel
              </label>
              <select
                name="hotels"
                id="hotels"
                className="form-select"
                onChange={insertHotelID}
              >
                <option defaultValue value="">select hotel</option>
                {hotels.length > 0
                  ? hotels.map((hotel, index) => (
                      <option
                        key={index}
                        value={hotel.hoteltoken}
                        onClick={insertHotelID}
                        dangerouslySetInnerHTML={{ __html: hotel.hotelname }}
                      />
                    ))
                  : ""}
              </select>
            </form>
          </Modal.Body>
          <div className="d-flex justify-content-center mb-3">  
            {loading ? (
              <div
                className="btn btn-lg btn-secondary btn-block"
                style={{ borderRadius: "0", padding: "11px 90px" }}
              >
                <div className="spinner-border text-light" role="status"></div>
              </div>
            ) : (
              <button
                className="btn btn-lg btn-secondary btn-block"
                style={{ borderRadius: "0", padding: "11px 90px" }}
                onClick={handleOpen}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export const ChoiceModals = ({ showChoice,  handleCloseChoice, handleOpen, handleShowSelect, handleOpenDynamic }) => {
  
  return (
    <>
      <Modal
        show={showChoice}
        onHide={handleCloseChoice}
        dialogClassName="select-hotel"
        centered
      >
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">Select contract type</h4>
          </div>
          <Modal.Body className="mb-5">
            <div className="d-flex flex-column flex-sm-row justify-content-around align-items-center" style={{gap:"1rem"}}>
              <button
                className="btn btn-lg btn-primary btn-block p-5 rounded"
                style={{ borderRadius: "0", padding: "11px 90px" }}
                onClick={handleOpen}
              >
                    Static Contract
              </button>
              <button
                className="btn btn-lg btn-primary btn-block p-5 rounded"
                style={{ borderRadius: "0", padding: "11px 90px" }}
                onClick={handleOpenDynamic}
              >
                    Dynamic Contract
              </button>

            </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseChoice}>
            Close
          </Button>
      </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export const DynamicModal = ({ showDynamicModal,  handleCloseDynamicModal, setShowDynamicModal, handleShowDynamicModal }) => {
  const [usertoken, setUsertoken] = useState("")
  const [user, setUser] = useState("")
  const [sourceData, setSourceData]= useState({})
  const [hotels, setHotels] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const location = useLocation()
  const history = useHistory()
    const handleAlertClose = () => {
      setShowAlert(false);
    };

  const getDMCHotels = useMutation(listHotelbyDmc, {
    onSuccess:(e)=>{
      if(e.data.success !== false){
        setHotels(e.data.hotels);
      }else {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: e.data.message });  
      }

    
    },  
    onError: (e)=>{
      setAlertType({ ...alert, type: "danger", value: e.message });
      setShowAlert(true)
    }
  })



  const listAdminHotel = useMutation(fetchAdminHotels, { 
    onSuccess:(e)=>{
      if(e.data.success !== false){
        setHotels(e.data.hotels);
      }else {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: e.data.message });  
      }

    
    },
    onError: (err)=>{
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    }

  } )


  const getExSources = useMutation(getExternalSources, {
      onError: (e)=>{
          setAlertType({ ...alert, type: "danger", value: e.message });
          setShowAlert(true)
      }, 
      onSuccess:(e)=>{
          if(e.data.response === false) {
              setAlertType({ ...alert, type: "danger", value: e.data.message })
              setShowAlert(true)
          }
      }
  })
  const createContract = useMutation(createDynamicContract, {
      onError: (e)=>{
          setAlertType({ ...alert, type: "danger", value: e.message });
          setShowAlert(true)
      }, 
      onSuccess:(e)=>{
          if(e.data.success === false) {
              setAlertType({ ...alert, type: "danger", value: e.data.message })
              setShowAlert(true)
          } else {
              
              setShowDynamicModal(false);
              localStorage.setItem("CT", e.data.contractToken);
              localStorage.setItem("Count", e.data.country);
              localStorage.setItem("City", e.data.city);
              localStorage.setItem("hotelname", e.data.hotelname);
              localStorage.setItem("contractDetail", JSON.stringify(e.data));
              localStorage.removeItem("header");
              history.push("/dmc/contracts/create");
          }
      }
  })

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser)
  }, [])

  useEffect(() => {
    if(usertoken){
      getExSources.mutate({
          apptoken: process.env.REACT_APP_APP_TOKEN,
          usertoken: usertoken
      })
    }
  }, [usertoken])

  useEffect(() => {
    if(usertoken){
      if(location.pathname === "/admin/contracts"){


      }else {
        getDMCHotels.mutate({
          apptoken:process.env.REACT_APP_APP_TOKEN,
          token:usertoken,
        })
      }
    }

  }, [usertoken])

  function handleForm(e){
    setSourceData({...sourceData, [e.target.name]:e.target.value})
  };

  function handleSubmit(e){
    e.preventDefault()
    let sourceInfo = sourceData.source.split("/")
    let hotel =  JSON.parse(sourceData.hotel)
    
    
    

    let data = {
      apptoken: process.env.REACT_APP_APP_TOKEN,
      hotelcode: sourceData.hotelcode,
      hoteltoken: hotel.token,
      dmctoken: usertoken,
      usertoken: usertoken,
      channel: sourceInfo[1]
    }

    
   createContract.mutate(data)
};
const hotelID = localStorage.getItem("hotel");
const fromHotel = localStorage.getItem("hotel");



  return (
    <>
      <Modal
        show={showDynamicModal}
        onHide={handleCloseDynamicModal}
        dialogClassName="select-hotel"
        centered
      >
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">Dynamic Contract</h4>
          </div>
          <Modal.Body className="mb-5">
          <form onSubmit={handleSubmit} className="form col-12 row justify-content-center">
            {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
              <div className="row">
                <div className="form-group col-12 p-1 ">
                    <label htmlFor="dmc" className="form-label text-primary">Channel</label>
                    {  getExSources.isLoading ? 
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                        </div> :
                        <select name="source" id="hotel" className="form-select" value={sourceData.source} onChange={handleForm}>
                            <option value="">Select Source</option>
                            { (getExSources.data && getExSources.data.data.success !== false) && getExSources.data.data.sources.map(source=>(
                                <option key={source.id} value={source.id + "/" + source.name}>{source.name}</option>
                              ))
                            }
                      </select>
                    }
                  </div>
                </div>
                <div className="row">

                {

                    fromHotel ? 
                    <div className="form-group col-12 p-1 ">
                    <label htmlFor="dmc" className="form-label text-primary">Hotels</label>
                    {  getDMCHotels?.isLoading || listAdminHotel?.isLoading ? 
                      <div>
                          <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                      </div> :
                      <select name="hotel" id="hotel" className="form-select" value={sourceData.hotel} onChange={handleForm}>
                          <option value="">Select Hotel</option>
                              { 
                                hotels?.filter(item=> item.hoteltoken === hotelID).map(hotel=>(
                                  <option key={hotel.id} value={JSON.stringify(hotel)}>{hotel.hotelname}</option>
                                ))

                              }
                      </select>
                    }
                  </div> : 
                    <div className="form-group col-12 p-1 ">
                      <label htmlFor="dmc" className="form-label text-primary">Hotels</label>
                      {
                        getDMCHotels.isLoading || listAdminHotel?.isLoading ? 
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                        </div> :
                        <select name="hotel" id="hotel" className="form-select" value={sourceData.hotel} onChange={handleForm}>
                            <option value="">Select Hotel</option>
                                { 
                                  hotels?.map(hotel=>(
                                    <option key={hotel.id} value={JSON.stringify(hotel)}>{hotel.hotelname}</option>
                                  ))

                                }
                        </select>
                      }
                    </div>
                
                }

                <div className="form-group col-12 p-1 ">
                    <label htmlFor="dmc" className="form-label text-primary">Hotel Code</label>
                    {  
                      sourceData.hotel && 
                      <input type="text" name="hotelcode" id="hotelcode" className="form-control" value={sourceData.hotelcode} onChange={handleForm} />
  
                    }
                </div>
                </div>
                <div className="row">
                <div className="form-group col-12 py-4 px-2">
                    <button className="btn btn-primary"> 
                    {createContract.isLoading ? <div className={`spinner-border text-primary` } role="status" ></div> : "Create Contract"}
                     </button>
                </div>
                </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default Modals;
