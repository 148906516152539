import React, { useEffect, useState } from "react";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { Fetch } from "../../../../../controllers/controller";
import RequestError from "../../../../404/RequestError";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import DeletePrompt from "./DeletePrompt";
import {useMutation} from "react-query"
import { adminAddCategory, adminAddFacility, fetchAdminCategory, fetchAdminFacilities } from "../../../Super/apis";
import { useLocation } from "react-router-dom";
import { mainGetUsertoken } from "../../../Reusables/GetUsertoken/GetUsertoken";
import { border } from "@mui/system";

const AddHotelOptions = ({ open, setOpen }) => {


  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [amenAlert, setAmenAlert] = useState(false);
  const [cateAlert, setCateAlert] = useState(false);
  //   const [deleteItem, setDeleteItem] = useState("");
  //   const [showPrompt, setShowPrompt] = useState(false);
  const [payload, setPayload] = useState({});
  
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };
  
  const confirmOptions = () => {};


//   useEffect(() => {
//     let mounted = true;
//     const formData = new FormData();
//     formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

//     Fetch(`${process.env.REACT_APP_END_POINT}/list-amenities`, formData)
//       .then((res) => {
//         if (mounted) setAmenList(res.data);
//       })
//       .catch((err) => {
//         setAlertType({ ...alert, type: "danger", value: err.message });
//       });

//     return () => {
//       mounted = false;
//     };
//   }, []);

  useEffect(() => {
    
  }, []);

  const deleteAmenities = (name) => {
   
  };

  const deleteCategory = (name) => {
  };
  
  
  const addAmenities = () => {
    
  };
  
  
  const handleAlertClose = () => {
  };

  const handleCateClose = () => {
    setCateAlert(false);
  };

  
  const addCategory = () => {
  };


  




  return (
    <RequestError>
      <Modal show={open} onHide={()=>setOpen(false)} centered>
        {/* {optionsLoad ? (
          <Loading />
        ) : ( */}
          <div className="py-5 px-5 modals addrooms">
            <div className="d-flex flex-column justify-content-start  mb-5">
              <h4 className="text-dark fw-bold text-center">Add Options</h4>
            </div>
            <Modal.Body className="mb-5 mb-sm-2">
              <Tabs defaultActiveKey="facilities" className="mb-3 title_font">
                <Tab eventKey="facilities" title="FACILITIES">
                  <Facilities />
                </Tab>
                <Tab eventKey="category" title="CATEGORY">
                  <Category />
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex mb-3">
                <button className="btn btn-lg btn-secondary mb-2 m-auto" style={{  padding: "11px" }} onClick={()=>setOpen(false)} >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </div>
        {/* )} */}
      </Modal>
    </RequestError>
  );
};




function Facilities(){
    const [amenAlert, setAmenAlert] = useState(false);
    const [payload, setPayload] = useState({});
    const location = useLocation();

    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    const [facilities, setFacilities] = useState([]);
    
    
    
    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
    },[])


  
    const handleChange = (e) => {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    };


    const handleAlertClose = () => {
        setShowAlert(false)
    };


 
    const addFacility = useMutation(adminAddFacility, {
        onSuccess: (res)=>{
            console.log(res)
            if(res.data.success){
                setShowAlert(true)
                setAlertType({type: 'success', value: res.data.message})
                fetchAllFacilities()
            }else {
                setShowAlert(true)
                setAlertType({type: 'danger', value: res.data.message})
            }
        },
        onError: (err)=>{ 
            console.error(err)
            setShowAlert(true)
            setAlertType({type: 'danger', value: err.message})
        }
    })

    

    const fetchFacilities = useMutation(fetchAdminFacilities, {
        onSuccess: (res)=>{
            console.log(res)
            if(res?.data?.success){
                setFacilities(res.data.list)
            }else {
                setShowAlert(true)
                setAlertType({type: 'danger', value: res.data.message})
            }
        },
        onError: (err)=>{
            console.error(err)
            setShowAlert(true)
            setAlertType({type: 'danger', value: err.message})
        }
    })


    function fetchAllFacilities(){
        fetchFacilities.mutate({usertoken})

    }


    useEffect(()=>{
        if(usertoken){
            fetchAllFacilities()
        }

    },[usertoken])



    function submitAddFacility(e){
        e.preventDefault()
        addFacility.mutate({usertoken, ...payload})
    }

    function deleteFacility(e){
        if(window.confirm("Are you sure you want to delete")){
            
        }
    }

    console.log({facilities})

    return (
        <>
            {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
            )}
                  <div>
                    <form className="form row justify-content-center">
                      <div className="col-sm-8 d-flex">
                        <input
                          type="text"
                          name="amenity"
                          id="amenity"
                          className="form-control me-2"
                          onChange={handleChange}
                          required
                          value={payload.amenity}
                          placeholder="Name"
                          disabled={addFacility?.isLoading}

                        />
                        <button
                          className="btn btn-outline-dark"
                          onClick={submitAddFacility}
                          disabled={addFacility?.isLoading}
                        >
                            {
                                addFacility?.isLoading ? 
                                <span className="spinner-border text-dark"><span className="visually-hidden">Loading...</span></span>
                                :
                                "ADD"
                                
                            }
                        </button>
                      </div>
                    </form>
                    <div className="list mt-2 d-flex justify-content-center flex-wrap">
                      {
                        facilities?.map((amenity) => (
                          <div className="me-1 my-1 py-1 d-flex bg-light justify-content-center align-items-center px-1 rounded">
                            <p
                              className="me-2 mb-0 text-capitalize"
                              dangerouslySetInnerHTML={{
                                __html: amenity.amenity,
                              }}
                            />
                            <div className="delete-opt-amenities">
                              <i
                                className="bi bi-x text-danger"
                                onClick={() =>
                                    deleteFacility(amenity.amenity)
                                }
                              ></i>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
        
        </>
    )
}


function Category(){
    const [amenAlert, setAmenAlert] = useState(false);
    const [payload, setPayload] = useState({});
    const location = useLocation();

    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    const [categories, setCategories] = useState([]);
    
    
    
    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
    },[])


  
    const handleChange = (e) => {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    };


    const handleAlertClose = () => {
        setShowAlert(false)
    };


 
    const addCategory = useMutation(adminAddCategory, {
        onSuccess: (res)=>{
            console.log(res)
            if(res.data.success){
                setShowAlert(true)
                setAlertType({type: 'success', value: res.data.message})
                fetchAllCategories()
            }else {
                setShowAlert(true)
                setAlertType({type: 'danger', value: res.data.message})
            }
        },
        onError: (err)=>{ 
            console.error(err)
            setShowAlert(true)
            setAlertType({type: 'danger', value: err.message})
        }
    })

    

    const fetchCategories = useMutation(fetchAdminCategory, {
        onSuccess: (res)=>{
            console.log(res)
            if(res?.data?.success){
                setCategories(res.data.list)
            }else {
                setShowAlert(true)
                setAlertType({type: 'danger', value: res.data.message})
            }
        },
        onError: (err)=>{
            console.error(err)
            setShowAlert(true)
            setAlertType({type: 'danger', value: err.message})
        }
    })


    function fetchAllCategories(){
        fetchCategories.mutate({usertoken})

    }


    useEffect(()=>{
        if(usertoken){
            fetchAllCategories()
        }

    },[usertoken])



    
    function submitAddCategory(e){
        e.preventDefault()
        addCategory.mutate({usertoken, ...payload})
    }

    
    
    function deleteCategory(e){
        if(window.confirm("Are you sure you want to delete")){
            
        }
    }


    return (
        <>
            {showAlert === true && (
                    <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleAlertClose}
                    />
                  )}
                  <div>
                    <form className="form row justify-content-center">
                      <div className="col-sm-8 d-flex">
                        <input
                          type="text"
                          name="type"
                          id="type"
                          className="form-control me-2"
                          onChange={handleChange}
                          required
                          value={payload.type}
                          placeholder="Type"
                        />
                        <button
                          className="btn btn-outline-dark"
                          onClick={submitAddCategory}
                          disabled={addCategory?.isLoading}
                          >
                              {
                                  addCategory?.isLoading ? 
                                  <span className="spinner-border text-dark"><span className="visually-hidden">Loading...</span></span>
                                  :
                                  "ADD"
                                  
                              }
                        </button>
                      </div>
                    </form>
                    <div className="list mt-2 d-flex justify-content-center flex-wrap">
                      {/* {cateList.length > 0 &&
                        cateList.map((cate) => (
                          <div className="me-1 my-1 py-1 d-flex bg-light justify-content-center align-items-center px-1 rounded">
                            <p className="me-2 mb-0 text-capitalize">
                              {cate.category}
                            </p>
                            <div className="delete-opt-category">
                              <i
                                className="bi bi-x text-danger"
                                onClick={() => deleteCategory(cate.category)}
                              ></i>
                            </div>
                          </div>
                        ))} */}
                    </div>
                  </div>
        </>
    )
}

export default AddHotelOptions;
