import React, { useEffect, useState } from "react";
import { Fetch } from "../controllers/controller";
import AlertModal from "./Reusables/AlertModal/AlertModal";

const NewTest = ({
  lists,
  roomNum,
  release,
  sgl, dbl, trl, qud, chd1, chd2, unit, twn,
  setRooms,
  update,
  contractTop,
  setAlertType
}) => {
  const [detail, setDetail] = useState([]);
  const hotelId = localStorage.getItem("hotel");
  const CT = localStorage.getItem("CT");
  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);
  const [show, setShow] = useState(false);
 
  const toggle = () => {
     setShow(false)
  }
  const handleChange = (e, index) => {

    update(false)
    const clonedData = [...lists];
    clonedData[index][e.target.name] = e.target.value;

    setRooms(clonedData);
    
    if(contractTop.status !== "" && contractTop.contract_name !== ""  && contractTop.child_age_From !== ""  &&contractTop.child_age_to !== "" && contractTop.currency !== ""  ){
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("roomtoken", lists[index].roomid);
    formData.append("hoteltoken", hotelId);
    
    formData.append("token", CT);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("contract_name", contractTop.contract_name);

    formData.append("inventory_room", lists[index].avail_room_num);
    // formData.append("room", lists[index].avail_room_name);
    formData.append("exp_date", contractTop.exp_date);
    formData.append("start_date", contractTop.start_date);
    formData.append("status", contractTop.status);
    formData.append("child_age_From", contractTop.child_age_From);
    formData.append("child_age_To", contractTop.child_age_To);
    formData.append("currency", contractTop.currency);
    
    // formData.append("category", lists[index].category);

    formData.append("price_sgl", lists[index].SGL);
    formData.append("price_dbl", lists[index].DBL);
    formData.append("price_trl", lists[index].TRL);
    formData.append("price_qud", lists[index].QUD);
    formData.append("price_chd1", lists[index].CHD1);
    formData.append("price_chd2", lists[index].CHD2);
    formData.append("unit", lists[index].unit);
    formData.append("twn", lists[index].twn);
    formData.append("inventory_rel", lists[index].release_date);

    // setLoading(true);
    Fetch(
      `${process.env.REACT_APP_END_POINT}/date-plan-update`,
      formData
    )
      .then((res) => {
        if(res.data.success === false) {
          window.alert(res.data.message);
          window.location.reload()
        }else{
          setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
        update(true)
      }
      })
      .catch((err) => {
       
          update(true)
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
    } else {
      // window.alert("Please fill in contract's header details first")
      setShow(true)

    }
  }

  // clear input values

  return (
    lists.length > 0 &&
    lists.map((detail, index) => (
      <tr key={index} className="innerTr special_table">
        <td style={{ padding: "0" }}>
         <p>{detail.category}</p>
        </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="SGL"
            id="occupy_min"
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={sgl}
          />
        </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="DBL"
            id="occupy_max"
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={dbl}
          />
        </td>
        <td style={{ padding: "0" }}>
                <input
                  type="number"
                  name="twn"
                  className="form-control"
                  onChange={(e) => handleChange(e, index)}
                  id="twn"
                  value={twn}
                />
            </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="TRL"
            id="occupy_min_child"
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={trl}
          />
        </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="QUD"
            id="QUD"
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={qud}
          />
        </td>
        {/* <td style={{ padding: "0" }}>
          <input
            type="number"
            name="CHD1"
            id=""
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={chd1}
          />
        </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="CHD2"
            id=""
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={chd2}

          />
        </td> */}
           
            <td style={{ padding: "0" }}>
              <input
                type="number"
                name="unit"
                className="form-control"
                onChange={(e) => handleChange(e, index)}
                id="unit"
                value={unit}
              />
            </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="avail_room_num"
            id=""
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={roomNum}
          />
        </td>
        <td style={{ padding: "0" }}>
          <input
            type="number"
            name="release_date"
            id=""
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            value={release}
          />
        </td>
        
<AlertModal show={show} setShow={toggle} />

      </tr>
    ))
  );
};

export default NewTest;
