import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Table from "./Table";
import Override from "./Override";
import Countries from "../../../Signup/Countries/Countries";
import Special from "./Special";

const Price = () => {
  const [contractList, setContractList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [contractLoader, setContractLoader] = useState(false);
  const [hotelLoader, setHotelLoader] = useState(false);
  const [cateLoader, setCateLoader] = useState(false);
  const [sellerLoader, setSellerLoader] = useState(false);
  const [buyerLoader, setBuyerLoader] = useState(false);
  const [hotel, setHotel] = useState("");
  const [seller, setSeller] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [country, setCountry] = useState("");
  const [contract, setContract] = useState("");
  const [selectCountry, setSelectCountry]= useState(false)
  const [selectHotel, setSelectHotel]= useState(false)
  const [selectContract, setSelectContract]= useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [showForm, setShowForm] = useState(false);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  console.log(country)
    
  const handleContractItem = (e) => {
    console.log(e.target)
    setSelectContract(true)
    setContract(e.target.value)
    setCateLoader(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("contract_token", e.target.value);
    Fetch(`${process.env.REACT_APP_END_POINT}/markup-list-category`, formData)
    .then((res)=> {
    setCateLoader(false)

      if (res.data.message === "No results") {
        window.alert(res.data.message)
      } else {
        setCategoryList(res.data)
      }
    })
    .catch((err) => {
      setCateLoader(false)
      console.log(err)
    })
  }
  

  const handleCategory = (item) => {}
  const changeHandler = value => {
    console.log(value)
    setHotelLoader(true)
    const formData = new FormData();
    setCountry(value.label)
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("country", value.label === "All Countries" ? value.value : value.label);

    Fetch(`${process.env.REACT_APP_END_POINT}/markup-hotel-list`, formData)
    .then((res)=> {
    setHotelLoader(false)

      if (res.data.message === "No results") {
        window.alert(res.data.message)
      } else {
        setSelectCountry(true)
        setHotelList([...res.data])
      }
    })
    .catch((err) =>{ 
      console.log(err)
      setHotelLoader(false)

    })
  }
  const handleHotelOption = (e) => {
    console.log(e.target.value)
    setHotel(e.target.value)
    setContractLoader(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("country", country);
    formData.append("hoteltoken", e.target.value);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-contract-markup`, formData)
    .then((res)=> {
      setContractLoader(false)
      if (res.data.message === "No results") {
        return
      } else {
        setSelectHotel(true)
        setContractList(res.data)
      }
    })
    .catch((err) => {
      setContractLoader(false)
      console.log(err)
    })
  }

  const formPricing = useFormik({
    initialValues: {
      markup: "",
      price: "",
      contract: "",
      category: "",
    },

    onSubmit: (values) => {
      console.log(values)
      setLoading(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("markup", values.markup);
      formData.append("category", values.category);
      formData.append("buyer_type", values.buyer_type);
      formData.append("seller_type", values.seller_type);
      formData.append("seller", values.seller);
      formData.append("buyer", values.buyer);
      // formData.append("supplier_type", values.supplier_type);
      formData.append("contract_token", contract);
      formData.append("country", country);
      formData.append("hoteltoken", hotel);
      Fetch(`${process.env.REACT_APP_END_POINT}/markup`, formData)
        .then((res) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "success", value: res.data.message });
          setLoad(true)
          setShowForm(false)
          setTimeout(() => {
            setLoad(false)
          }, 1500);
          // window.location.reload()
        })
        .catch((err) => {
          window.alert(err);
          setLoading(false);

        });
    },
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      Fetch(`${process.env.REACT_APP_END_POINT}/list-contract`, formData)
        .then((res) => {
          setContractList([...res.data]);
        })
        .catch((err) => window.alert(err));
    }
    return () => {
      mounted = false;
    };
  }, []);


  useEffect(()=>{
    
    let mounted = true;

    if(formPricing.values.seller_type === "dmc"){
      setSellerLoader(true)
      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        Fetch(`${process.env.REACT_APP_END_POINT}/list-dmc`, formData)
          .then((res) => {
            setSellerLoader(false)
            if(mounted){
              setSeller(res.data)
            }
            
          })
          .catch((err) => {
            setSellerLoader(false)
            window.alert(err)
          });
    } else if(formPricing.values.seller_type === "hotel owner") {
      setSellerLoader(true)

      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        Fetch(`${process.env.REACT_APP_END_POINT}/hotel-owner-list`, formData)
          .then((res) => {
            setSellerLoader(false)
            if(mounted){
              setSeller(res.data)
            }
          })
          .catch((err) => {
            window.alert(err)
            setSellerLoader(false)
          });
        } else if(formPricing.values.seller_type === "account owner") {
          setSellerLoader(true)
    
          const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            Fetch(`${process.env.REACT_APP_NEND_POINT}/list-accountowner`, formData)
              .then((res) => {
                setSellerLoader(false)
                if(mounted){
                  setSeller(res.data)
                }
              })
              .catch((err) => {
                window.alert(err)
                setSellerLoader(false)
              });
            } 

    return ()=>{
      mounted = false
    }
  },[formPricing.values.seller_type])

  useEffect(()=>{
    
    let mounted = true;

    if(formPricing.values.buyer_type === "dmc"){
      setBuyerLoader(true)
      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        Fetch(`${process.env.REACT_APP_END_POINT}/list-dmc`, formData)
          .then((res) => {
            setBuyerLoader(false)
            if(mounted){
              setBuyer(res.data)
            }
            
          })
          .catch((err) => {
            setBuyerLoader(false)
            window.alert(err)
          });
    } else if(formPricing.values.buyer_type === "traveller") {
      setBuyerLoader(true)

      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        Fetch(`${process.env.REACT_APP_END_POINT}/traveller-list`, formData)
          .then((res) => {
            setBuyerLoader(false)
            if(mounted){
              setBuyer(res.data)
            }
          })
          .catch((err) => {
            window.alert(err)
            setBuyerLoader(false)
          });
        }

    return ()=>{
      mounted = false
    }
  },[formPricing.values.buyer_type])

  // SEARCH
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };



  return (
    <div className="pt-4 px-lg-4 px-3 ">
      <header className="d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">PRICING</h4>
      </header>
      <div className="admin-dashboard-top mt-2 text-center">
        <Tabs defaultActiveKey="markups" className="mb-3 title_font">
          <Tab eventKey="markups" title="MARKUPS">
              {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
            <div className="row justify-content-center">
              <div className="wrap col-12 rounded p-4 border shadow">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex w-100 justify-content-between mb-2 ">
                    <div className="search d-flex align-items-center" style={{gap:"1rem"}} >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="search..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div style={{flex: "50%"}}>
                    <button className="btn btn-primary" onClick={()=>setShowForm(!showForm)}>{showForm ? "Close Form" : "Create Markup"}</button>
                  </div>
                </div>
                  {
                    showForm &&

                  <form
                    onSubmit={formPricing.handleSubmit}
                    className="p-4 g-3 "
                  >
                    <div className="row mt-3 mb-5 g-3">
                      <div className="form-group col-6 col-sm-3 px-1">
                          <label htmlFor="seller_type" className="form-label visually-hidden">
                            Seller Type
                          </label>
                          <select
                            name="seller_type"
                            id="seller_type"
                            className="form-select border border-dark"
                            onChange={formPricing.handleChange}
                            value={formPricing.values.seller_type}
                            onBlur={formPricing.handleBlur}
                          >
                            <option value="" defaultValue>
                              Seller Type
                            </option>
                            <option value="all sellers">All Sellers</option>
                            <option value="dmc">DMC</option>
                            <option value="hotel owner">Hotel</option>
                            <option value="account owner">Account Owner</option>
                            {/* <option value="hotel_owner">Hotel Owner</option> */}
                          </select>
                        </div>
                        <div className="form-group col-md-3 col-sm-3 px-1">
                          {sellerLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                            <>
                              <label htmlFor="seller" className="form-label visually-hidden">
                                Seller
                              </label>
                              <select
                                name="seller"
                                id="seller"
                                className="form-select border border-dark "
                                onChange={formPricing.handleChange}
                                value={formPricing.values.seller}
                                onBlur={formPricing.handleBlur}
                              >
                                <option value="" default>
                                  Seller
                                </option>
                                  {seller.length > 0 && seller.map(item=>(
                                    <option value={item.token} dangerouslySetInnerHTML={{__html: item.last_name + " " +  item.first_name + " - " + item.mail}} />

                                  ))}
                              </select>
                            </>
                            }
                          </div>
                          <div className="form-group col-lg-3 col-sm-4 col-6 px-1">
                            <label htmlFor="country" className="form-label visually-hidden">
                              Country
                            </label>
                            <Countries changeHandler={changeHandler}  />
                          </div>
                        </div>
                    <div className="row mt-3 g-3 mb-5">
                      <div className="form-group col-lg-3 col-sm-4 col-6 px-1">
                        {hotelLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                        <>
                          <label htmlFor="hotel" className="form-label visually-hidden">
                            Hotel
                          </label>
                          <select
                            name="hotel"
                            id="hotel"
                            className="form-select border border-dark"
                            onChange={handleHotelOption}
                            value={hotel}
                            onBlur={formPricing.handleBlur}
                            disabled={selectCountry === true ? false: true}
                          >
                            <option value="" default>
                              Hotel
                            </option>
                            {hotelList.length > 0 && hotelList.map(hotel =>
                                  <option  value={hotel.hoteltoken} dangerouslySetInnerHTML={{__html:  hotel.hotel_name}} />
                            )}
                          </select>
                        </>
                        }
                      </div>
                      <div className="form-group col-lg-3 col-sm-4 col-6 px-1">
                        {contractLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                        <>
                          <label htmlFor="contract" className="form-label visually-hidden">
                            Contract
                          </label>
                          <select
                            name="contract"
                            id="contract"
                            className="form-select border border-dark"
                            onChange={handleContractItem}
                            value={contract}
                            disabled={selectHotel === true ? false: true}
                            onBlur={formPricing.handleBlur}
                          >
                            <option value="" defaultValue>
                              Contract
                            </option>
                            {contractList.length > 0 && contractList.map(item => (
                            <option value={item.contract_id}>
                              {`${item.contract_name} - ${item.contract_id}`}
                            </option> 
                            ))}
                          </select>
                        </>

                        }
                      </div>
                      <div className="form-group col-6 col-sm-3 px-1">
                      {cateLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> :
                          <>
                        <label htmlFor="cateory" className="form-label visually-hidden">
                          Category
                        </label>
                        <select
                          name="category"
                          id="category"
                          className="form-select border border-dark"
                          onChange={formPricing.handleChange}
                          value={formPricing.values.category}
                          onBlur={formPricing.handleBlur}
                          disabled={selectContract === true ? false: true}

                        >
                          <option value="" defaultValue>
                            Category
                          </option>
                          {categoryList.length > 0 && categoryList.map(item => (
                            <option value={item.roomtoken} onClick={()=>handleCategory(item.category)}>{item.category}</option>
                            ))}
                        </select>
                        </>
                          }
                      </div>
                    </div>
                    <div className="row my-5 g-3">
                      <div className="form-group col-6 col-sm-3 px-1">
                        <label htmlFor="markup" className="form-label visually-hidden">
                          Markup
                        </label>
                        <input
                          type="number"
                          name="markup"
                          id="markup"
                          className="form-control border border-dark"
                          Placeholder="Markup"
                          onChange={formPricing.handleChange}
                          value={formPricing.values.markup}
                          onBlur={formPricing.handleBlur}
                        />
                      </div>
                      
                    </div>
                    <div className="row mb-5 g-3">
                        <div className="form-group col-6 col-sm-3 px-1">
                          <label htmlFor="Buyer type" className="form-label visually-hidden">
                            Buyer Type
                          </label>
                          <select
                            name="buyer_type"
                            id="buyer_type"
                            className="form-select border border-dark"
                            onChange={formPricing.handleChange}
                            value={formPricing.values.buyer_type}
                            onBlur={formPricing.handleBlur}
                          >
                            <option value="" defaultValue>
                              Buyer Type
                            </option>
                            <option value="dmc">DMC</option>
                            <option value="traveller">Traveller</option>
                            {/* <option value="hotel_owner">Hotel Owner</option> */}
                          </select>
                        </div>
                        <div className="form-group col-md-3 col-sm-3 px-1">
                            {buyerLoader === true ? <div className={`spinner-border text-primary` } role="status" ></div> : 
                              <>
                                <label htmlFor="buyer" className="form-label visually-hidden">
                                  Add Buyer
                                </label>
                                <select
                                  name="buyer"
                                  id="buyer"
                                  className="form-select border border-dark "
                                  onChange={formPricing.handleChange}
                                  value={formPricing.values.buyer}
                                  onBlur={formPricing.handleBlur}
                                >
                                  <option value="all buyers" defaultValue>
                                    All Buyers
                                  </option>
                                    {buyer.length > 0 && buyer.map(item=>(
                                      <option value={item.token} dangerouslySetInnerHTML={{__html: item.last_name + " " +  item.first_name + " - " + item.mail}} />

                                    ))}
                                </select>
                              </>
                              }
                          </div>
                    </div>
                    <div className="d-flex mt-4">
                      {loading === true ? 
                          <div className="btn btn-primary col-sm-2">
                            <div className="spinner-border text-light" role="status"></div>
                          </div> 
                          :
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                      }
                    </div>
                  </form>
                  }
                
                <hr />
                <Table load={loading} reload={load} setter={setLoad} search={search} />
              </div>
            </div>
          </Tab>
          <Tab eventKey="overrides" title="OVERRIDES">
            <Override />
          </Tab>
          <Tab eventKey="special" title="COMMISSIONS">
            <Special />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Price;
