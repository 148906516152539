import React from "react";

const TableData = ({tag, date, time, itemName, code, total, paid, color, status}) => {
  return (
    <tr>
      <td>{tag}</td>

      <td>
        <div>{date}</div>
        <div>
          <small>{time}</small>
        </div>
      </td>

      <td>
        <div>{itemName}</div>{" "}
        <div>
          <small>{code}</small>
        </div>
      </td>
      <td>{total}</td>
      <td>{paid}</td>
      <td>
        <button className={`btn btn-${color}`}>{status}</button>{" "}
      </td>
    </tr>
  );
};

export default TableData;
