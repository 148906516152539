import React from "react";
import Revenue from "../../../../../Images/Icons/revenue.png"
import PaymentModal from "../../../Reusables/PaymentModal/PaymentModal"
const DashboardCard = ({wallet,title, logo, color, amount, topImage, footer, juniorBackground, lowerImage, juniorMargin, text, juniorColor, juniorFlex, juniorAlign, juniorLogo}) => {
  const [show, setShow] = React.useState(false)
  const openModal =( e )=> {
    e.preventDefault()
    setShow(true)
  }
  return (

    <div className="dashboard-card text-white ">
      <header>
        <p className="title_font" style={{color: `${text}`}}>{title}</p>
      </header>
        <div className="card dashboard-card-body shadow" style={{background: `${color}`}}>
          <div className="card-body">
          <div className={`card-body-header ${topImage}`}>
              <img src={logo}  alt="" />
          </div>
          <div className={`text-center d-flex justify-content-center align-items-center flex-column ${juniorFlex} card-details`}>
            <div className={`img-wrapper justify-self-center ${lowerImage}`}>
              <div className={`img-wrapper p-3 px- 0 px-lg-3  ${juniorBackground}`} style={{borderRadius: "50%", background: `${juniorBackground}`}}>
                <img src={juniorLogo} alt="" />
              </div>
            </div>
            <div className="card-p-wrapper d-flex flex-column align-items-center justify-content-center" style={{color: `${juniorColor}`, height:"100px"}}>
              <p className={`text-center  h4 ${juniorAlign} title_font`}>{amount}</p>
              <p className="text-center mb-0 text_font" >{footer}</p>
            </div>
          </div>
            {/* <div className={wallet ? "d-flex justify-content-center" :"d-none"}>
                  <button className="btn text-white" style={{background: `${juniorColor}`}} onClick={openModal}>Fund Wallet</button>
            </div>
            <PaymentModal show={show} setShow={setShow} /> */}
          </div>

        </div>
    </div>
  );
};

export default DashboardCard;
