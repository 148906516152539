import React from "react";
import SimpleCards from "../../Cards/simpleCards/simpleCards";
import SectionHeader from "../SectionHeader/SectionHeader";
import Destination1 from "../../../Images/Destination1.png";
import Destination2 from "../../../Images/Destination2.png";
import Destination3 from "../../../Images/Destination3.png";
import Destination4 from "../../../Images/Destination4.png";
import Destination5 from "../../../Images/Destination5.png";
import Destination6 from "../../../Images/Destination6.png";

const Destinations = () => {
  return (
    <section className="container destinations">
      <SectionHeader
        title="Top Destinations"
        lead="Hotel highly rated for thoughtful design"
      />
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 mb-3 mb-lg-0 p-1">
          <div>
            <SimpleCards destination__img={Destination1} title="Egypt"/>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mb-4 mb-lg-0 p-1">
          <div>
            <SimpleCards destination__img={Destination2} title="Cairo" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mb-4 mb-lg-0 p-1">
          <div>
            <SimpleCards destination__img={Destination3} title="Dubai" />
          </div>
        </div>
      </div>
      <div className="row mt-1 justify-content-center">
      <div className="col-sm-12 col-md-4 mb-4 mb-lg-0 p-1">
          <div>
            <SimpleCards destination__img={Destination4} title="Egypt"/>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mb-4 mb-lg-0 p-1">
          <div>
            <SimpleCards destination__img={Destination5} title="Maldives" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mb-4 mb-lg-0 p-1">
          <div>
            <SimpleCards destination__img={Destination6} title="Dubai" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Destinations;
