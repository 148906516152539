import React from 'react'
import RentalCards from '../../Cards/RentalCards/RentalCards'
import SectionHeader from '../SectionHeader/SectionHeader'
import RentalImage from '../../../Images/Promotion.png'

const Rental = () => {
    return (
        <section className="container rental">
            <SectionHeader title="Rental Listing" lead="Homes highly rated for thoughtful design" />
            <div className="row">
                <div className="col-md-6 col-lg-3 mb-sm-3 mb-lg-0 p-1">
                    <RentalCards cardImage={RentalImage} cardTitle="America Parks Trail" rating="5.0/5 Excellent" reviews="2 Reviews" />
                </div>
                <div className="col-md-6 col-lg-3 mb-sm-3 mb-lg-0 p-1">
                    <RentalCards cardImage={RentalImage} cardTitle="America Parks Trail" rating="5.0/5 Excellent" reviews="2 Reviews" />
                </div>
                <div className="col-md-6 col-lg-3 mb-sm-3 mb-lg-0 p-1">
                    <RentalCards cardImage={RentalImage} cardTitle="America Parks Trail" rating="5.0/5 Excellent" reviews="2 Reviews" />
                </div>
                <div className="col-md-6 col-lg-3 mb-sm-3 mb-lg-0 p-1">
                    <RentalCards cardImage={RentalImage} cardTitle="America Parks Trail" rating="5.0/5 Excellent" reviews="2 Reviews" />
                </div>
               
               
            </div>
        </section>

    )
}

export default Rental
