import React, { useEffect, useState } from "react";
import { Fetch, LOOP, TimestampConverter } from "../controllers/controller";
import ReadOnlyTest from "./ReadOnlyTest";

const Test = ({ lists, edit, setEdit, initIndex, contractTop, setReload, setLoader,  setAlertType, setShowAlert, alert, contract }) => {
  const [detail, setDetail] = useState([]);
  const hotelId = localStorage.getItem("hotel");

console.log({detail})

  const CT = localStorage.getItem("CT");
  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);
  
  useEffect(() => {
    let mounted = true;
    const mealStart = new Date(lists.start_date * 1000).toLocaleDateString();
    const mealEnd = new Date(lists.exp_date * 1000).toLocaleDateString();

    LOOP(mealEnd, mealStart, lists.contractToken).then((res) => {
      
      if(mounted){
        // console.log("detail", res)
        if (res.data.length > 0){
          setDetail([...res.data])
        } else {
          window.alert(res.data.message)
        }
          
        }
    });
    return () => {
      mounted = false;
    };
  }, [lists]);
  
  const handleChange = (e, index) => {
    const clonedData = [...detail];
   

    detail[index][e.target.name] = e.target.value;

    setDetail(clonedData);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("roomtoken", detail[index].roomtoken);
    formData.append("hoteltoken", hotelId);
    
    formData.append("token", CT);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("contract_name", contractTop.contract_name);
    formData.append("exp_date",   TimestampConverter(contract.exp_date))
    formData.append("start_date",   TimestampConverter(contract.start_date));
    formData.append("status", contractTop.status);
    formData.append("child_age_From", contractTop.child_age_From);
    formData.append("child_age_To", contractTop.child_age_To);
    formData.append("currency", contractTop.currency);
    
    
    formData.append("price_sgl", detail[index].price_sgl);
    formData.append("price_dbl", detail[index].price_dbl);
    formData.append("price_trl", detail[index].price_trl);
    formData.append("price_qud", detail[index].price_qud);
    formData.append("price_chd1", detail[index].price_chd1);
    formData.append("price_chd2", detail[index].price_chd2);

    formData.append("unit", detail[index].unit);
    formData.append("twn", detail[index].twn);

    formData.append("inventory_rel", detail[index].inventory_rel);
    // setLoading(true);
    Fetch(
      `${process.env.REACT_APP_END_POINT}/date-plan-update`,
      formData
    )
      .then((res) => {
        
        setShowAlert(true);
        
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      })
      .catch((err) => {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  
    
  }

   return (
    detail.length > 0 && detail.map((detail, index)=> (
     edit === initIndex ? (

      <tr className="innerTr special_table" key={index}>
      <td style={{ padding: "0" }}>
        <input
          type="text"
          name="room"
          id=""
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.room_category}
          disabled
        />
      </td>
      <td style={{ padding: "0" }}>
        <input
          type="number"
          name="price_sgl"
          id="occupy_min"
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.price_sgl === "0" ? null : detail.price_sgl}
        />
      </td>
      <td style={{ padding: "0" }}>
        <input
          type="number"
          name="price_dbl"
          id="occupy_max"
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.price_dbl === "0" ? null : detail.price_dbl}
        />
      </td>
      <td style={{ padding: "0" }}>
          <input
            type="number"
            name="twn"
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            id="twn"
            value={detail.twn === "0" ? null : detail.twn}
          />
      </td>
      <td style={{ padding: "0" }}>
        <input
          type="number"
          name="price_trl"
          id="occupy_min_child"
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.price_trl === "0" ? null : detail.price_trl}
        />
      </td>
      <td style={{ padding: "0" }}>
        <input
          type="number"
          name="price_qud"
          id="occupy_max_child"
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.price_qud === "0" ? null : detail.price_qud}
        />
      </td>
     
 
      <td style={{ padding: "0" }}>
          <input
            type="number"
            name="unit"
            className="form-control"
            onChange={(e) => handleChange(e, index)}
            id="unit"
            value={detail.unit  === "0" ? null : detail.unit}
          />
      </td>
      <td style={{ padding: "0" }}>
        <input
          type="number"
          name="inventory_room"
          id=""
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.inventory_room === "0" ? null : detail.inventory_room}
        />
      </td>
      <td style={{ padding: "0" }}>
        <input
          type="number"
          name="inventory_rel"
          id=""
          className="form-control"
          onChange={(e) => handleChange(e, index)}
          value={detail.inventory_rel}
        />
      </td>
     
    </tr>
      ):(
        <ReadOnlyTest unit={detail.unit} twn={detail.twn} room={detail.room_category} price_chd2={detail.price_chd2} price_chd1={detail.price_chd1} price_dbl={detail.price_dbl} price_sgl={detail.price_sgl} price_qud={detail.price_qud} price_trl={detail.price_trl} inventory_rel={detail.inventory_rel} inventory_room={detail.inventory_room} />
      )
    
     
    ))
    
  );
};

export default Test;
