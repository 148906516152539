import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import ReceiptTableHeader from './ReceiptTableHeader'
import ReceiptTableRow from './ReceiptTableRow'
import ReceiptTableBlankSpace from './ReceiptTableBlankSpace'

const tableRowsCount = 5;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'column',
        flexWrap: 'no-wrap',
        marginTop: 32,
    },
});

  const ReceiptItemsTable = ({data}) => (
    <View style={styles.tableContainer}>
        <ReceiptTableRow data={data} />
    </View>
  );
  
  export default ReceiptItemsTable