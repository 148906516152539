import React from 'react'
import FeaturedCards from '../../Cards/featuredCards/featuredCards'
import FeaturedImage from "../../../Images/bestListing.png"
import SectionHeader from '../SectionHeader/SectionHeader'

const Listing = () => {
    return (
        <section className="container listing">
           <SectionHeader title="Bestseller Listing" lead="Hotel highly rated for thoughtful design"/>
            <div className="row justify-content-between">
                <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3 p-1" >
                    <FeaturedCards cardImage={FeaturedImage} CardTitle="Porlon Holiday Villas" rating="4/5 Excellent" reviews="5 Reviews"/>
                </div>
                <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3 p-1" >
                    <FeaturedCards cardImage={FeaturedImage} CardTitle="Porlon Holiday Villas" rating="4/5 Excellent" reviews="5 Reviews"/>
                </div>
                <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3 p-1" >
                    <FeaturedCards cardImage={FeaturedImage} CardTitle="Porlon Holiday Villas" rating="4/5 Excellent" reviews="5 Reviews"/>
                </div>
                <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3 p-1" >
                    <FeaturedCards cardImage={FeaturedImage} CardTitle="Porlon Holiday Villas" rating="4/5 Excellent" reviews="5 Reviews"/>
                </div>
                
               
            </div>
        </section>
    )
}

export default Listing
