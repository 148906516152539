import React, { useState, useEffect, useContext } from "react";
import { Fetch } from "../../../../controllers/controller";
import Loading from "../../../Reusables/Loader/Loading";
import HotelContext from "../../Junior/Manage/Context/hotelcontext";
import Unavailable from "../../Junior/Manage/Hotels/Unavailable";
import RoomListsCard from "../RoomsLists/RoomListsCard";
import "./rooms.css";

const Rooms = () => {
  const [hotelId, setHotelId] = useContext(HotelContext);
  const [loading, setLoading] = useState(false);
  console.log("rooms avaialb")
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  
  const [hotels, setHotels] = useState([]);
  
  useEffect(() => {
    const hotelid = localStorage.getItem("hotelDetail")
    let mounted = true;
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelid);

    Fetch( `${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        
        if (mounted) {
          if (res.data.success !== false) {
            setHotels(res.data);
          }
          setLoading(false);
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "success",
            value: res.data.message,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });

    return () => {
      mounted = false;
    };
  }, [hotelId]);

  return loading ? (
    <Loading />
  ) : (<div>

    {hotels.length === 0 ? (
      <Unavailable title="No rooms found" />
      ) : (
        <div className=" row justify-content-center align-items-center">
        {hotels.map((room) => (
          <div className="col-md-6 px-1 d-flex justify-content-center">
            <RoomListsCard
              key={room.rownum}
              name={room.room_name}
              price={room.sgl}
              time={room.timestamp}
              available={room.available}
              image={room.imageURL}
              details={room}
              />
          </div>
        ))}
          
    </div>
      )}
      </div>
  );
};

export default Rooms;
