import React, {useState, useEffect} from "react"
import {useLocation} from "react-router-dom"
import {useMutation} from "react-query"
import {getdmc, getExternalSources, getSourceHotels, getRoomCategories, mapHotelsToCategory, getExternalRooms, getRoomList, getAllExternalHotels} from "../../Super/apis"
import {mainGetUsertoken} from "../GetUsertoken/GetUsertoken"
import AlertComp from "../../../Reusables/AlertComp/AlertComp"

const Sources = ()=>{
    const location = useLocation()
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")
    const [sourceData, setSourceData]= useState({})
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });

    const getDMCs = useMutation(getdmc, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }
    })

    const getSrcHotels = useMutation(getSourceHotels, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }
    })
    const fetchRoomCategories = useMutation(getRoomCategories, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }
    })
    
    const getRooms = useMutation(getRoomList, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess:(e)=>{
            if(e.data.response === false) {
                setAlertType({ ...alert, type: "danger", value: e.data.message })
                setShowAlert(true)
            }
        }
        
    })
    const getExSources = useMutation(getExternalSources, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess:(e)=>{
            if(e.data.response === false) {
                setAlertType({ ...alert, type: "danger", value: e.data.message })
                setShowAlert(true)
            }
        }
        
    })
    const mapHotel = useMutation(mapHotelsToCategory, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        },
        onSuccess: (e)=>{
            setAlertType({ ...alert, type: "success", value: e.data.message });
            setShowAlert(true)
        }
    })

    useEffect(()=>{
        mainGetUsertoken(location, setUsertoken, setUser)
        getDMCs.mutate({
            apptoken:process.env.REACT_APP_APP_TOKEN,
    
        }) 
        fetchRoomCategories.mutate()
    },[])

    useEffect(() => {
      if(sourceData.token){
        getExSources.mutate({
            apptoken: process.env.REACT_APP_APP_TOKEN,
            usertoken: sourceData.token
        })
      }
    }, [sourceData.token])

    useEffect(() => {
      if(sourceData.source){
        let sourceInfo = sourceData.source.split("/")
        getSrcHotels.mutate({
            apptoken:process.env.REACT_APP_APP_TOKEN,
            usertoken:sourceData.token,
            sourceid: sourceInfo[0],
            source: sourceInfo[1]
        })
      }
    }, [sourceData.source])

    useEffect(() => {
      if(sourceData.hotel){
        getRooms.mutate(sourceData.hotel)
      }
    }, [sourceData.hotel])
    

    function handleAlertClose(){
        setShowAlert(false);
    };

    function handleForm(e){
        setSourceData({...sourceData, [e.target.name]:e.target.value})
    };
    
    function handleSubmit(e){
        e.preventDefault()
        mapHotel.mutate({
            apptoken:process.env.REACT_APP_APP_TOKEN,
            roomid:sourceData.room,
            category: sourceData.category
        })
    };
console.log({getExSources})
    return (
        <div className="row">
            <form onSubmit={handleSubmit} className="form col-12 row justify-content-center">
            {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
              <div className="row">
                <div className="form-group col-md-3 p-1">
                    <label htmlFor="dmc" className="form-label">DMC</label>
                    {  getDMCs.isLoading ?  
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>  
                        </div> :
                        <select name="token" id="dmc" className="form-select" value={sourceData.token} onChange={handleForm}>
                            <option value="">Select DMC</option>
                                {(getDMCs.data && getDMCs.data?.data.success !== false)&& getDMCs.data.data.map(dmc=>(
                                    <option key={dmc.id} value={dmc.token}>{dmc.first_name} - {dmc.last_name}</option>
                                    ))
                                }
                        </select>
                    }
                </div>
                <div className="form-group col-md-3 p-1 ">
                    <label htmlFor="dmc" className="form-label">Sources</label>
                    {  getExSources.isLoading ? 
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                        </div> :
                        <select name="source" id="hotel" className="form-select" value={sourceData.source} onChange={handleForm}>
                            <option value="">Select Source</option>
                                { (getExSources.data && getExSources.data.data.success !== false) && getExSources.data.data.sources.map(source=>(
                                    <option key={source.id} value={source.id + "/" + source.name}>{source.name}</option>
                                    ))
                                }
                        </select>
                    }
                </div>
                </div>
                <div className="row">

                <div className="form-group col-md-3 p-1 ">
                    <label htmlFor="dmc" className="form-label">Hotels</label>
                    {  getSrcHotels.isLoading ? 
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                        </div> :
                        <select name="hotel" id="hotel" className="form-select" value={sourceData.hotel} onChange={handleForm}>
                            <option value="">Select Hotel</option>
                                { (getSrcHotels.data && getSrcHotels.data.data.success !== false) && getSrcHotels.data.data.Hotels.map(hotel=>(
                                    <option key={hotel.id} value={hotel.token}>{hotel.hotelname}</option>
                                    ))
                                }
                        </select>
                    }
                </div>
                <div className="form-group col-md-3 p-1 ">
                    <label htmlFor="dmc" className="form-label">Rooms</label>
                    {  getRooms.isLoading ? 
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                        </div> :
                        <select name="room" id="room" className="form-select" value={sourceData.room} onChange={handleForm}>
                            <option value="">Select Hotel</option>
                                { (getRooms.data && getRooms.data.data.message !== "No results") && getRooms.data.data.map(room=>(
                                    <option key={room.id} value={room.roomid}>{room.room_name}</option>
                                    ))
                                }
                        </select>
                    }
                </div>
                </div>
                <div className="row">

                <div className="form-group col-md-3 p-1">
                    <label htmlFor="dmc" className="form-label">Category</label>
                    {  fetchRoomCategories.isLoading ?  <div className={`spinner-border text-primary` } role="status" ></div>  :
                        <select name="category" id="category" className="form-select" value={sourceData.category} onChange={handleForm}>
                            <option value="">Select Room Category</option>
                            { (fetchRoomCategories.data && fetchRoomCategories.data.data.success !== false) && fetchRoomCategories.data.data.map(category=>(
                                    <option key={category.id} value={category.id}>{category.category}</option>
                                ))
                            }
                        </select>
                    }
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-3 py-4 px-2">
                    <button className="btn btn-primary">{ mapHotel.isLoading ? <div className={`spinner-border text-primary` } role="status" ></div> : "Map Rooms"}</button>
                </div>
                </div>
            </form>
        </div>
    )
}
export default Sources