import React, { useState, useEffect } from "react";
import DashboardCard from "../../Super/Dashboard/DashboardCard/DashboardCard";
import { ACards } from "../../Super/superData/data";
import Services from "../../../../Images/Icons/services-card.png";
import { Fetch } from "../../../../controllers/controller";
import { useLocation } from "react-router-dom";
import Loading from "../../../Reusables/Loader/Loading";

const Card = () => {
  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  useEffect(() => {
    if (location.pathname.includes("dmc")) {
      const data = localStorage.getItem("DMC");
      const user = JSON.parse(data);
      setUsertoken(user.usertoken);
    } else if (location.pathname.includes("hotel-owner")) {
      const data = localStorage.getItem("hotelChain");
      const user = JSON.parse(data);
      setUsertoken(user.usertoken);
    } else if (location.pathname.includes("account-owner")) {
      const data = localStorage.getItem("accountOwner");
      const user = JSON.parse(data);
      setUsertoken(user.usertoken);
    } else if (location.pathname.includes("user")) {
      const data = localStorage.getItem("traveller");
      const user = JSON.parse(data);
      setUsertoken(user.usertoken);
    }
  }, []);
  useEffect(() => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("usertoken", usertoken);

    Fetch(`${process.env.REACT_APP_END_POINT}/wallet-balance`, formData)
      .then((res) => {
        setBalance(res.data.walletbalance);
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });

      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: err.message,
        });
      });
  }, [usertoken]);
  return (
    <div className="card-row">
      {ACards.map((card) => (
        <DashboardCard
          key={card.id}
          wallet={card.wallet}
          text={card.color}
          title={card.title}
          footer={card.footer}
          juniorLogo={card.icon}
          juniorFlex="justify-content-between flex-sm-row"
          amount={card.amount}
          juniorColor={card.color}
          juniorBackground={card.color}
        />
      ))}
      {loading ? (
          <div className="d-flex justify-content-center align-items-center ">
            <div className="spinner-border" role="status"></div>
          </div>
      ) : (
        <DashboardCard
          wallet={true}
          text={"#EB5B9D"}
          title={"Wallet"}
          footer={"Total Balance"}
          juniorLogo={Services}
          juniorFlex="justify-content-between flex-sm-row"
          amount={balance}
          juniorColor={"#EB5B9D"}
          juniorBackground={"#EB5B9D"}
        />
      )}
    </div>
  );
};

export default Card;
