import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Countries from "../../../Signup/Countries/Countries";
import { mainGetUsertoken } from "../../Reusables/GetUsertoken/GetUsertoken";

export default function CreateService({show, setShow}){
    const [name, setName] = useState("");
    
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });


    const history = useHistory();
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
    }, []);


    const handleAlertClose = () => {
        setShowAlert(false);
      };
      const [image, setImage] = useState(null);
      const handleImage = (e) => {
        setImage(e.target.files[0]);
      };
      const [loading, setLoading] = useState(false);
    
      const formik = useFormik({
        initialValues: {
          service_name: "",
          location: "",
          date_from: "",
          images: "",
        },
        onSubmit: (values) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("usertoken", usertoken);
          formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
          formData.append("service_name", values.service_name);
          formData.append("date_from", values.date_from);
          formData.append("date_to", values.date_to);
          formData.append("location", values.location);
          formData.append("country", values.country);
          formData.append("city", values.city);
          formData.append("longitude", values.longitude);
          formData.append("latitude", values.latitude);
          formData.append("description", values.description);
          formData.append("social_media_link", values.social);
          formData.append("youtube_link", values.youtube);
          formData.append("price_adult", values.price_adult);
          formData.append("price_child", values.price_child);
          formData.append("child_age_from", values.chd_age_from);
          formData.append("child_age_to", values.chd_age_to);
          formData.append("discounts", values.discounts);
          formData.append("discount_from", values.discount_from);
          formData.append("discount_to", values.discount_to);
          formData.append("min_pax_discount", values.min_pax_discount);
          formData.append("avatar", image);
          // formData.append("image_url", values.image_url)
    
          Fetch(`${process.env.REACT_APP_END_POINT}/create-service`, formData)
            .then((res) => {
              setLoading(false);
              if (res.data.success === false) {
                setShowAlert(true);
                setAlertType({ ...alert, type: "danger", value: res.data.message });
                // setReload(true);
                // setTimeout(() => {
                //   setReload(false);
                // }, 1000);
              } else {
                setShowAlert(true);
                setAlertType({
                  ...alert,
                  type: "success",
                  value: res.data.message,
                });
                // setReload(true);
                // setTimeout(() => {
                //   setReload(false);
                //   window.location.reload();
                // }, 1000);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
          },
        });
      const changeHandler = value => {
        formik.setValues({...formik.values, country: value.label})
      }
    
    
      function handleClose(){
        setShow(false)
      }
    return (
        <Modal
        show={show}
        onHide={handleClose}
        // dialogClassName="select-hotel"
        centered
      >
        <Modal.Body>
            <form
            className="row justify-content-around services"
            onSubmit={formik.handleSubmit}
        >
            <div>
            {showAlert === true && (
                <AlertComp
                variant={alert.type}
                alertText={alert.value}
                handleAlertClose={handleAlertClose}
                />
            )}
            </div>

                <Tabs defaultActiveKey="content" className="mb-3 title_font">
                <Tab eventKey="content" title="CONTENT">

                    
                    <div className="row justify-content-around">
                    <div className="left col-12 col-sm-6 col-md-5">
                        <div className="row px-sm-4">
                        <div className="form-group mb-2 col-12">
                            <label htmlFor="service" className="form-label">
                            Service Name
                            </label>
                            <input
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.service_name}
                            onBlur={formik.handleBlur}
                            name="service_name"
                            id="service"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="date_from" className="form-label">
                            Date from
                            </label>
                            <input
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.date_from}
                            onBlur={formik.handleBlur}
                            name="date_from"
                            id="date_from"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="date_to" className="form-label">
                            Date to
                            </label>
                            <input
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.date_to}
                            onBlur={formik.handleBlur}
                            name="date_to"
                            id="date_to"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-12">
                            <label htmlFor="location" className="form-label">
                            Location
                            </label>
                            <input
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.location}
                            onBlur={formik.handleBlur}
                            name="location"
                            id="location"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="country" className="form-label">
                            Country
                            </label>
                            <Countries changeHandler={changeHandler}  />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="city" className="form-label">
                            City
                            </label>
                            <input
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            onBlur={formik.handleBlur}
                            name="city"
                            id="city"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="longitude" className="form-label">
                            Longitude
                            </label>
                            <input
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.longitude}
                            onBlur={formik.handleBlur}
                            name="longitude"
                            id="longitude"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="latitude" className="form-label">
                            Latitude
                            </label>
                            <input
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.latitude}
                            onBlur={formik.handleBlur}
                            name="latitude"
                            id="latitude"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        
                        </div>
                    </div>
                    <div className="right col-12  col-sm-6 col-md-5 row">
                        <div className="form-group mb-2 col-12">
                        <label htmlFor="description" className="form-label">
                            Description
                        </label>
                        <textarea
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            onBlur={formik.handleBlur}
                            name="description"
                            className="d-block w-100 px-2 py-2 form-control"
                            id="desc"
                            cols="40"
                            rows="8"
                        ></textarea>
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                        <label htmlFor="social" className="form-label">
                            Social media link
                        </label>
                        <input
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.social}
                            onBlur={formik.handleBlur}
                            name="social"
                            id="social"
                            className="form-control px-2 py-2"
                        />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                        <label htmlFor="youtube" className="form-label">
                            Youtube link
                        </label>
                        <input
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.youtube}
                            onBlur={formik.handleBlur}
                            name="youtube"
                            id="youtube"
                            className="form-control px-2 py-2"
                        />
                        </div>
                        <div className="form-group mb-2 col-12">
                        <label htmlFor="images" className="form-label">
                            Images
                        </label>
                        <input
                            // onChange={formik.handleChange}
                            name="images"
                            id="images"
                            type="file"
                            onChange={handleImage}
                            className="d-block w-100 form-control  px-2 py-2"
                        />
                        </div>
                    </div>
                        
                    </div>
                    
                    {/* row ends */}
                </Tab>
                <Tab eventKey="price" title="PRICE">
                    <div className="row justify-content-center">
                    <div className="right col-12 ">
                        <div className="row px-sm-4">
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="price_adult" className="form-label">
                            Price Adult
                            </label>
                            <input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.price_adult}
                            onBlur={formik.handleBlur}
                            name="price_adult"
                            id="price_adult"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="price_child" className="form-label">
                            Price Child
                            </label>
                            <input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.price_child}
                            onBlur={formik.handleBlur}
                            name="price_child"
                            id="price_child"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="chd_age_from" className="form-label">
                            Child Age From
                            </label>
                            <input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.chd_age_from}
                            onBlur={formik.handleBlur}
                            name="chd_age_from"
                            id="chd_age_from"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="chd_age_to" className="form-label">
                            Child Age To
                            </label>
                            <input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.chd_age_to}
                            onBlur={formik.handleBlur}
                            name="chd_age_to"
                            id="chd_age_to"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="discounts" className="form-label">
                            Discounts
                            </label>
                            <input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.discounts}
                            onBlur={formik.handleBlur}
                            name="discounts"
                            id="discounts"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="discount_from" className="form-label">
                            Discount From
                            </label>
                            <input
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.discount_from}
                            onBlur={formik.handleBlur}
                            name="discount_from"
                            id="discount_from"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 pe-1">
                            <label htmlFor="discount_to" className="form-label">
                            Discount To
                            </label>
                            <input
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.discount_to}
                            onBlur={formik.handleBlur}
                            name="discount_to"
                            id="discount_to"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        <div className="form-group mb-2 col-sm-6 ps-1">
                            <label htmlFor="min_pax_discount" className="form-label">
                            Min Pax Discount
                            </label>
                            <input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.min_pax_discount}
                            onBlur={formik.handleBlur}
                            name="min_pax_discount"
                            id="min_pax_discount"
                            className="form-control px-2 py-2"
                            />
                        </div>
                        </div>
                        <div className="servicebtn my-3 col-12">
                        <button type="submit" className="btn btn-primary w-75">
                            SUBMIT
                        </button>
                        </div>
                    </div>
                    </div>
                </Tab>
                </Tabs>
            </form>

        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>


    )
}