import React from "react"
import SidebarLinks from "../../../Reusables/sidenavlinks/SidebarLinks"
import { TravellerBar } from "../../../Super/superData/data"


const Sidebar = ({sidebar, close, handleLogout}) => {
    return (
        <div  className={`sidebar-container bg-white ${sidebar}`} style={{transition:"all .1s ease-in-out"}}>
        <header className="my-5 ps-2">
         <div className="right d-flex">
              <div className="right me-3">
                 <div className="bg-warning h2 text-center text-light p-2" style={{height: "50px", width:"50px", borderRadius: "50%"}}>H</div>
              </div>
              <div className="left">
              <h2 className="text-center sidenav-header lead" style={{color:"rgba(38, 58, 83, 1)"}}>Hotelsoffline</h2>
                {/* <div className="bottom text-secondary"><span className="me-2">Edit Profile</span><span><i className="bi bi-pen-fill"></i></span></div> */}
                <i className="bi bi-x-circle text-warning d-lg-none" onClick={close}></i>
              </div>
          </div>
          <div><p className="text-lead text-secondary text-center mt-2">Traveller</p></div>

      </header>
      
      <div className="sidenav-links">
      {TravellerBar.map(link=>(
        <SidebarLinks title={link.title} link={link.link}  key={link.id} icon={link.class}/>
        
      ))}
      </div>
      <footer className="sidenav-footer  my-5 align-items-center">
        {/* <div className="d-flex mb-3">
          <button className="btn btn-lg btn-primary mb-2 w-75 m-auto  px-5 py-2"  >
              Admin
            </button>
        </div> */}
        <div className="d-flex mt-2">
            <button className="btn btn-outline-primary w-75 m-auto  px-5 py-1" onClick={handleLogout} > Logout </button>
        </div>
      </footer>
    </div>
    )
}

export default Sidebar