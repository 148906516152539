import React from 'react'
import "./Dashboard.css"
import MainCard from './DashboardCard/cardMain/MainCard'
import DashboardCard from './DashboardCard/DashboardCard'
import DashboardChart from './DashboardChart/DashboardChart'
import DashboardTable from './DashboardTable/DashboardTable'
import Header from './Header/Header'

const Dashboard = () => {
    
    return (
        <div className="pt-4 px-lg-4 px-3 ">
            <header className="dashHeader">
                <h4 className="text-primary title_font">DASHBOARD</h4>
            </header>
            <div className="admin-dashboard-top super mt-5">
                <MainCard />
                <DashboardChart />
                <DashboardTable />
            </div>
       
        </div>
    )
}

export default Dashboard
