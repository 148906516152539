import React from "react"
import {Route} from "react-router-dom"

import Index from "./Index"
import RequestError from "../../../../404/RequestError";

const Sources = ()=>{
    return (
        <RequestError>
      <div className="pt-4 px-lg-4 px-3 ">
        <header className="d-flex justify-content-between align-items-center dashHeader">
          <h4>SOURCES</h4>
        </header>
        <div className="admin-dashboard-top mt-5 text-center">
            <Route exact path={["/admin/sources", "/admin/sources/lists" ]} component={Index} />
        </div>
      </div>
    </RequestError>
    )
}

export default Sources      