import React from "react";

const LastestBlog = ({ cardImage, cardTitle, date, location, detail }) => {
  return (
    <div className="card latestBlog__card">
      <img src={cardImage} className="card-img-top rental__image img-fluid" style={{ height: "244px", maxWidth: "100%" }} alt="..." />

      <div className="card-body classical__body">
        <div className="d-flex justify-content-between align-items-center feedback">
          <div className="fw-bold title ">{cardTitle}</div>
          <div className=" text-primary ">{date}</div>
        </div>
        <div className="location">
          <p>{location}</p>
        </div>
        <small>{detail}</small>
        <div className="link">
        <a href="#" className="text-muted">Read More</a>
        </div>
      </div>
    </div>
  );
};

export default LastestBlog;
