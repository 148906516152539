import React, { useEffect, useRef, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import { mainGetUsertoken } from "../../../Reusables/GetUsertoken/GetUsertoken";
import {
  addExternalHotel,
  addNewHotel,
  getAccountOwner,
  getdmc,
  getExternalSources,
  editHotel,
} from "../../../Super/apis";
import { Fetch } from "../../../../../controllers/controller";
import { useGeneral } from "../Context/generalContext";
import RC from "../Modals/RC";
import { useFormik } from "formik";

const AddExternalHotel = () => {
  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [hotelstate, setHotelState] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    usertoken: usertoken,
  });

  const handleChange = (e) => {
    e.preventDefault();
    setHotelState({ ...hotelstate, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);

  const getSourceMutation = useMutation(getExternalSources, {
    onSuccess: () => {
      console.log("done");
    },
  });

  const getDMC = useMutation(getdmc, {
    onSuccess: () => {
      console.log("done");
    },
  });
  // fetch all account owners
  const getAccountOwners = useMutation(getAccountOwner, {
    onError: (e) => {
      console.error(e);
      setAlertType({ ...alert, type: "danger", value: e.message });
      setShowAlert(true);
    },
    onSuccess: (e) => {
      if (e.data?.success === false) {
        setAlertType({ ...alert, type: "danger", value: e.data.message });
        setShowAlert(true);
      }
    },
  });

  const submitForm = useMutation(addExternalHotel, {
    onSuccess: (e) => {
      if (e.data.success !== false) {
        setAlertType({ ...alert, type: "success", value: e.data.message });
        setShowAlert(true);
        setHotelState({
          hotelname: "",
          hotelcode: "",
          dmctoken: "",
          source: "",
        });
      } else {
        setAlertType({ ...alert, type: "danger", value: e.data.message });
        setShowAlert(true);
      }
    },
    onError: (e) => {
      setAlertType({ ...alert, type: "danger", value: e.message });
      setShowAlert(true);
    },
  });

  useEffect(() => {
    if (usertoken) {
      setHotelState({ ...hotelstate, usertoken: usertoken });

      getSourceMutation.mutate({
        apptoken: process.env.REACT_APP_APP_TOKEN,
        usertoken: usertoken,
      });

      getDMC.mutate({
        apptoken: process.env.REACT_APP_APP_TOKEN,
      });
      getAccountOwners.mutate({
        apptoken: process.env.REACT_APP_APP_TOKEN,
      });
    }
  }, [usertoken]);

  function handleSubmit(e) {
    e.preventDefault();
    submitForm.mutate({
      ...hotelstate,
      apptoken: process.env.REACT_APP_APP_TOKEN,
      usertoken,
      aotoken: usertoken,
    });
  }
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <div className="pt-4 px-lg-4 px-3 ">
      <header className="d-flex justify-content-between align-items-center dashHeader">
        <h4>ADD EXTERNAL HOTELS</h4>
      </header>
      <div className="admin-dashboard-top mt-5 text-center row justify-content-center">
        <form className="form row" onSubmit={handleSubmit}>
          {showAlert === true && (
            <AlertComp
              variant={alert.type}
              alertText={alert.value}
              handleAlertClose={handleAlertClose}
            />
          )}
          {submitForm.isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="row">
                <div className="form-group col-md-3 p-3">
                  <label htmlFor="hotelname" className="form-label">
                    Hotel Name
                  </label>
                  <input
                    type="text"
                    name="hotelname"
                    id="hotelname"
                    className="form-control"
                    onChange={handleChange}
                    value={hotelstate.hotelname}
                  />
                </div>
                <div className="form-group col-md-3 p-3">
                  <label htmlFor="hotelcode" className="form-label">
                    Hotel Code
                  </label>
                  <input
                    type="text"
                    name="hotelcode"
                    id="hotelcode"
                    className="form-control"
                    onChange={handleChange}
                    value={hotelstate.hotelcode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-3 p-3">
                  <label htmlFor="source" className="form-label d-block">
                    Source
                  </label>
                  {getSourceMutation.isLoading ? (
                    <div
                      className={`spinner-border text-primary`}
                      role="status"
                    ></div>
                  ) : (
                    <select
                      name="sourceid"
                      id="source"
                      className="form-select"
                      onChange={handleChange}
                      value={hotelstate.sourceid}
                    >
                      <option value="">Select a source</option>
                      {getSourceMutation.data &&
                        getSourceMutation.data.data.sources.map((source) => (
                          <option key={source.id} value={source.id}>
                            {source.name}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
                <div className="form-group col-md-3 p-3">
                  <label htmlFor="dmctoken" className="form-label d-block">
                    DMC
                  </label>
                  {getDMC.isLoading ? (
                    <div
                      className={`spinner-border text-primary`}
                      role="status"
                    ></div>
                  ) : (
                    <select
                      name="dmctoken"
                      id="dmctoken"
                      className="form-select"
                      onChange={handleChange}
                      value={hotelstate.dmctoken}
                    >
                      <option value="">Select a DMC</option>
                      {getDMC.data &&
                        getDMC.data?.data.success !== false &&
                        getDMC.data.data.map((dmc) => (
                          <option key={dmc.id} value={dmc.token}>
                            {dmc.first_name} - {dmc.last_name}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
              </div>
              {/* <div className="form-group col-md-5 col-6 p-3">
              <label htmlFor="aotoken" className="form-label">Account Owner</label>
              {  getAccountOwners.isLoading ?  
              <div>
                  <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>  
              </div> :
              <select name="aotoken" id="aotoken" className="form-select" value={hotelstate.aotoken} onChange={handleChange}>
                  <option value="">Select Account Owner</option>
                  {(getAccountOwners.data && getAccountOwners.data?.data.success !== false) && getAccountOwners.data.data.map(accOwn=>(
                      <option key={accOwn.id} value={accOwn.token}>{accOwn.first_name} - {accOwn.last_name}</option>
                      )) 
                  }
              </select>
              }
            </div> */}
              <div className="row">
                <div className="col-md-2 p-3">
                  <button className="btn btn-primary w-100" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export const AddHotel = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [amenList, setAmenList] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const formik = useFormik({
   initialValues: {
     apptoken: process.env.REACT_APP_APP_TOKEN,
     amenities: [],
    hotelname: "",
    description: "",
    file: null,
    location: "",
   },
 
   onSubmit: (values) => {
     console.log(values);
   },
 });


  const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    hotelname: "",
    description: "",
    file: null,
    location: "",
  });
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");

  const [locationDetail, setLocationDetail] = useState({});

  const mapRef = useRef(null);
  const searchInput = useRef(null);

  const { initMap } = useGeneral();

  let map, geocoder, marker, autocomplete;

  function onChangeAddress(autocomplete) {
    const location = autocomplete.getPlace();
    let latLng = location.geometry?.location;

    map.setCenter(latLng);
    marker.setPosition(latLng);
    marker.setMap(map);

    const address = {
      city: "",
      country: "",
      street: "",
    };
    location.address_components?.forEach((element) => {
      const types = element.types;
      const value = element.long_name;

      if (
        types.includes("locality") ||
        types.includes("administrative_area_level_2")
      )
        address.city = value;
      if (types.includes("country")) address.country = value;
      if (types.includes("route")) address.street = value;
    });

    formik.setValues({
      ...formik.values, 
      latitude: latLng.lat(),
      longitude: latLng.lng(),
      city: address.city,
      country: address.country,
      street: address.street,
      hotelname: location.name,
      location: searchInput.current.value
    
    })
  }


  
  function initializeAutoComplete() {
    if (!searchInput.current || !mapRef.current) return;

    // init geocoder
    geocoder = new window.google.maps.Geocoder();
    // init map
    map = new window.google.maps.Map(mapRef.current, {
      zoom: 18,
      center: { lat: -34.397, lng: 150.644 },
    });
    // init marker
    marker = new window.google.maps.Marker({
      map,
    });
    // init autocomplete
    autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    map.addListener("click", (e) => {
      geocoder.geocode({ location: e.latLng });
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  }

  const addHotel = useMutation(addNewHotel, {
    onSuccess: (e) => {
      // setHotels(res.data);
      setShowAlert(true);
      setAlertType({ ...alert, type: "success", value: e.data.message });
    },
    onError: (err) => {
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    },
  });
  const editMyHotel = useMutation(editHotel, {
    onSuccess: (e) => {
      // setHotels(res.data);
      setShowAlert(true);
      setAlertType({ ...alert, type: "success", value: e.data.message });
    },
    onError: (err) => {
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    },
  });

  useEffect(() => {
    initMap().then(() => initializeAutoComplete());
    mainGetUsertoken(location, setUsertoken, setUser);

    if (location.pathname.includes("edit")) {
      const hotelInfo = JSON.parse(
        localStorage.getItem("hotels-offline-hotel-info")
      );

      formik.setValues({...formik.values,
        hotelname: hotelInfo.hotel_name,
        description: hotelInfo.hotel_desc,
        creator_token: hotelInfo.creator_token,
        hoteltoken: hotelInfo.hotelid,

        location: hotelInfo.location,
        country: hotelInfo.country,
        city: hotelInfo.city,
        latitude: hotelInfo.latitude,
        longitude: hotelInfo.longitude,
      
      })
      
    }
  }, []);

  const handleImages = (e) => {
    formik.setValues({...formik.values, file: e.target.files[0]})
  };

  const handleChange = (e) => {
    // setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const submitHotel = (e) => {
    e.preventDefault();
    
    location.pathname.includes("edit")
      ? editMyHotel.mutate({...formik.values})
      : addHotel.mutate({...formik.values, creator_token: usertoken});
  };
  const handleAlertClose = () => {
    setShowAlert(false);
  };


//   AMENITIES
   useEffect(() => {
      let mounted = true;
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

      Fetch(`${process.env.REACT_APP_END_POINT}/list-amenities`, formData)
      .then((res) => {
         if (mounted) setAmenList(res.data);
      })
      .catch((err) => {
         setAlertType({ ...alert, type: "danger", value: err.message });
      });

      return () => {
      mounted = false;
      };
 }, []);
  
 
 const handleCheck = (e, cate) => {
  }

  console.table(formik.values)

  return (
    <>
      {addHotel.isLoading ? (
        <div className="d-flex">
          <div className="spinner-border text-primary mx-auto">
            <div className="visually-hidden">Loading...</div>
          </div>
        </div>
      ) : (
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold text-center">
              {location.pathname.includes("edit") ? "Edit Hotel" : "Add Hotel"}
            </h4>
          </div>
          {page === 1 ? (
            <form className="row justify-content-center">
              <div className="form-left col-lg-9">
                <div className="description w-100 mb-3">
                  <label
                    htmlFor="description"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Desciption
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    cols="40"
                    rows="5"
                    className="form-control"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    required
                  ></textarea>
                </div>
                <div className="star_ratings mb-3">
                  <div>
                    <label
                      htmlFor="star"
                      className="mb-2 text-secondary form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Star Rating
                    </label>
                    <select
                      name="star"
                      id="star"
                      onChange={formik.handleChange}
                      className="form-select"
                      value={formik.values.star}
                    >
                      <option defaultValue>Select Hotel Level</option>
                      <option value="1">1 star</option>
                      <option value="2 ">2 star</option>
                      <option value="3">3 star</option>
                      <option value="4">4 star</option>
                      <option value="5">5 star</option>
                      <option value="6">6 star</option>
                      <option value="7">7 star</option>
                    </select>
                  </div>
                </div>
                <div className="image mb-3">
                  <div>
                    <label
                      htmlFor="hotelImg"
                      className="mb-2 text-secondary form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Select image
                    </label>
                    <input
                      type="file"
                      name="hotelImg"
                      id="hotelImg"
                      onChange={handleImages}
                    />
                  </div>
                </div>
                <div className=" mb-3 text-muted">
                        <label className="form-label" style={{fontSize: "12px"}}>Room Amenities</label>
                        <div className="radio category_list">
                          {amenList.length > 0 &&
                            amenList.map((cate, index) => (
                              <>
                                <RC
                                  typee="checkbox"
                                  title={cate.room_amenities}
                                  name="amenities"
                                  handleRadioChange={formik.handleChange}
                                  value={cate.id}
                                />
                              </>
                            ))}
                        </div>
                </div>
              </div>
              <div className="d-flex my-3">
                  <button
                  className="btn btn-lg btn-primary  w-50 m-auto"
                  style={{ padding: "11px" }}
                  onClick={submitHotel}
                  >
                  {location.pathname.includes("edit") ? "Edit":"Add"}
                  
                  </button>
               </div>
            </form>
          ) : (
            <form className="row justify-content-center">
              <div className="form-left col-lg-9">
                {showAlert === true && (
                  <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                  />
                )}

                <div className="location mb-3">
                  <label
                    htmlFor="location"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Location here
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    ref={searchInput}
                    name="auto"
                    id="auto"
                    placeholder="Type hotel name"
                  />
                  {/* // value={payload.location} onChange={handleChange} */}
                  {/* /> */}
                  <div className="row">
                    <div className="col-6 p-1 d-flex flex-column justify-content-between">
                      <div className="input-group my-3">
                        <span class="input-group-text" id="basic-addon1">
                          Hotel name
                        </span>
                        <input
                          type="text"
                          style={{paddingBlock: ".8rem"}}
                          name=""
                          id=""
                          className="form-control"
                          disabled
                          onChange={handleChange}
                          required
                          value={formik.values.hotelname}
                        />
                      </div>
                      <div className="input-group my-3">
                        <span class="input-group-text" id="basic-addon1">
                          Street
                        </span>
                        <input
                          type="text"
                          style={{paddingBlock: ".8rem"}}
                          name=""
                          id=""
                          className="form-control"
                          disabled
                          value={formik.values.street}
                        />
                      </div>
                      <div className="input-group my-3">
                        <span class="input-group-text" id="basic-addon1">
                          City
                        </span>
                        <input
                          type="text"
                          style={{paddingBlock: ".8rem"}}
                          name=""
                          id=""
                          className="form-control"
                          disabled
                          value={formik.values.city}
                        />
                      </div>
                      <div className="input-group my-3">
                        <span class="input-group-text" id="basic-addon1">
                          Area
                        </span>
                        <input
                          type="text"
                          style={{paddingBlock: ".8rem"}}
                          name="area"
                          id="area"
                          className="form-control"
                          value={formik.values.area}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="input-group my-3">
                        <span class="input-group-text" id="basic-addon1">
                          Country
                        </span>
                        <input
                          type="text"
                          style={{paddingBlock: ".8rem"}}
                          name=""
                          id=""
                          className="form-control"
                          disabled
                          value={formik.values.country}
                        />
                      </div>
                    </div>
                    <div className="col-6 p-1">
                      <div
                        id="map"
                        ref={mapRef}
                        style={{ width: "100%", minHeight: "280px" }}
                        className="mt-4"
                      ></div>
                      <div className="row">
                        <div className="col-sm-6 p-1">
                          <div className="input-group my-3">
                            <span class="input-group-text" id="basic-addon1">
                              Longitude
                            </span>
                            <input
                              type="text"
                              name=""
                              id=""
                              className="form-control"
                              disabled
                              value={formik.values.longitude}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 p-1">
                          <div className="input-group my-3">
                            <span class="input-group-text" id="basic-addon1">
                              Latitude
                            </span>
                            <input
                              type="text"
                              name=""
                              id=""
                              className="form-control"
                              disabled
                              value={formik.values.latitude}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mb-3">
                  <button className="btn btn-lg btn-primary mb-2 w-50 m-auto" style={{ padding: "11px" }} onClick={()=>setPage(1)} >
                  {/* {location.pathname.includes("edit") ? "Edit":"Add"} */}
                     Next
                  </button>
               </div>
            </form>
          )}

         
        </div>
      )}
    </>
  );
};

export const AddHotelDetails = ({
  payload,
  searchInput,
  locationDetail,
  setPayload,
  setLocationDetail,
}) => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");

  const addHotel = useMutation(addNewHotel, {
    onSuccess: (e) => {
      // setHotels(res.data);
      setShowAlert(true);
      setAlertType({ ...alert, type: "success", value: e.data.message });
    },
    onError: (err) => {
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    },
  });
  const editMyHotel = useMutation(editHotel, {
    onSuccess: (e) => {
      // setHotels(res.data);
      setShowAlert(true);
      setAlertType({ ...alert, type: "success", value: e.data.message });
    },
    onError: (err) => {
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    },
  });

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);

    if (location.pathname.includes("edit")) {
      const hotelInfo = JSON.parse(
        localStorage.getItem("hotels-offline-hotel-info")
      );

      setPayload({
        hotelname: hotelInfo.hotel_name,
        description: hotelInfo.hotel_desc,
        creator_token: hotelInfo.creator_token,
        hoteltoken: hotelInfo.hotelid,
      });
      setLocationDetail({
        location: hotelInfo.location,
        country: hotelInfo.country,
        city: hotelInfo.city,
        latitude: hotelInfo.latitude,
        longitude: hotelInfo.longitude,
      });
    }
  }, []);

  const handleImages = (e) => {
    setPayload({ ...payload, file: e.target.files[0] });
  };
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const submitHotel = (e) => {
    e.preventDefault();
    location.pathname.includes("edit")
      ? editMyHotel.mutate({
          apptoken: process.env.REACT_APP_APP_TOKEN,
          hotelname: payload.hotelname,
          hoteltoken: payload.hoteltoken,
          file: payload.file,
          star: payload.star,
          location: searchInput.current.value
            ? searchInput.current.value
            : locationDetail.location,
          country: locationDetail.country,
          city: locationDetail.city,
          longitude: locationDetail.longitude,
          street: locationDetail.street,
          latitude: locationDetail.latitude,
        })
      : addHotel.mutate(
        {
          apptoken: process.env.REACT_APP_APP_TOKEN,
          creator_token: usertoken,
          hotelname: locationDetail.hotelname,
          description: payload.description,
          star: payload.star,
          file: payload.file,
          location: searchInput.current.value,
          country: locationDetail.country,
          city: locationDetail.city,
          longitude: locationDetail.longitude,
          street: locationDetail.street,
          latitude: locationDetail.latitude,
          }
        );
  };
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <>
      {addHotel.isLoading ? (
        <div className="d-flex">
          <div className="spinner-border text-primary mx-auto">
            <div className="visually-hidden">Loading...</div>
          </div>
        </div>
      ) : (
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold text-center">
              {location.pathname.includes("edit") ? "Edit Hotel" : "Add Hotel"}
            </h4>
          </div>
          <form className="row justify-content-center">
            <div className="form-left col-lg-9">
              {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}

              <div className="description w-100 mb-3">
                <label
                  htmlFor="description"
                  className="mb-2 text-secondary form-label"
                  style={{ fontSize: "12px" }}
                >
                  Desciption
                </label>
                <textarea
                  name="description"
                  id="description"
                  cols="40"
                  rows="5"
                  className="form-control"
                  value={payload.description}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="star_ratings mb-3">
                <div>
                  <label
                    htmlFor="star"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Star Rating
                  </label>
                  <select
                    name="star"
                    id="star"
                    onChange={handleChange}
                    className="form-select"
                  >
                    <option defaultValue>Select Hotel Level</option>
                    <option value="1">1 star</option>
                    <option value="2 ">2 star</option>
                    <option value="3">3 star</option>
                    <option value="4">4 star</option>
                    <option value="5">5 star</option>
                    <option value="6">6 star</option>
                    <option value="7">7 star</option>
                  </select>
                </div>
              </div>
              <div className="image mb-3">
                <div>
                  <label
                    htmlFor="hotelImg"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Select image
                  </label>
                  <input
                    type="file"
                    name="hotelImg"
                    id="hotelImg"
                    onChange={handleImages}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="d-flex mb-3">
            <button
              className="btn btn-lg btn-primary mb-2 w-50 m-auto"
              style={{ padding: "11px" }}
              onClick={submitHotel}
            >
              {location.pathname.includes("edit") ? "Edit" : "Add"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

function AddAllHotels() {
  return (
    <Tabs
      defaultActiveKey="create"
      id="uncontrolled-tab-example"
      className="mb-3 title_font"
    >
      <Tab eventKey="create" title="Add Hotel">
        <AddHotel />
      </Tab>
      {/* <Tab eventKey="external" title="Add External Hotel">
        <AddExternalHotel />
      </Tab> */}
    </Tabs>
  );
}

export default AddAllHotels;
