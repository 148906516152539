import React from "react";
import { TimestampConverter } from "../../../../../controllers/controller";
import "./Override.css"


const OverrideCard = ( {item }) => {
  return (
    <div className="card shadow rounded ">
        <div className="dmcList bg-success rounded py-1">
            <div className="justify-content-between align-items-center item1 border-end">
                <div className="box-top">
                    <p className=" fw-bold">Recipient</p>
                    <p className="text-light">{item.recipient}</p>
                </div>
                <div className="box-top">
                    <p className=" fw-bold">Nature</p>
                    <p className="text-light">{item.nature}</p>
                </div>
                <div className="box-top">
                    <p className=" fw-bold">Start</p>
                    <p className="text-light">{TimestampConverter(item.start_duration)}</p>
                </div>
                <div className="box-top">
                    <p className=" fw-bold">End</p>
                    <p className="text-light">{TimestampConverter(item.end_duration)}</p>
                </div>
            </div>
            
            <div className="bottom item2 d-flex justify-content-center">
                <div className="card-grid d-flex justify-content-between w-100 align-items-center">
                    <div className="box-wrapper">
                        <div className="box">
                            <p className=" fw-bold me-2">Target 1: </p>
                            <p className="text-light">{item.target_amount1}</p>
                        </div>
                        <div className="box">
                            <p className=" fw-bold me-2">Target 2: </p>
                            <p className="text-light">{item.target_amount2}</p>
                        </div>
                        <div className="box">
                            <p className=" fw-bold me-2">Target 3: </p>
                            <p className="text-light">{item.target_amount3}</p>
                        </div>
                    </div>
                    <div className="box-wrapper">
                        <div className="box">
                            <p className=" fw-bold me-2">Override 1: </p>
                            <p className="text-light">{item.override1}</p>
                        </div>
                        <div className="box">
                            <p className=" fw-bold me-2">Override 2: </p>
                            <p className="text-light">{item.override2}</p>
                        </div>
                        <div className="box">
                            <p className=" fw-bold me-2">Override 3: </p>
                            <p className="text-light">{item.override3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default OverrideCard;
