import React,{useState} from 'react'
import { Modal } from 'react-bootstrap';
import { Fetch } from "../../../../controllers/controller";
import AlertComp from '../../../Reusables/AlertComp/AlertComp';
import Loading from "../../../Reusables/Loader/Loading"


const DeletePrompt = ({id, show,  handleRemove, load}) => {

    const [loading, setLoading]=useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const confirmDelete  = () => { 
        const formData = new FormData()
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN )
        formData.append("id", id)
        
        setLoading(true);
        Fetch(`${process.env.REACT_APP_END_POINT}/delete-markup`, formData)
        .then( (res) => {
            setLoading(false)
            setShowAlert(true)
            setAlertType({ ...alert, type: "success", value: res.data.message });
            load(true)
            window.location.reload()
        
        })
        .catch((err) => {
            setLoading(false);
            setShowAlert(true)
            setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }
    return (
        <div className="deletePrompt">
            <Modal show={show} onHide={handleRemove} 
            dialogClassName="deletePrompt"
            centered
            > 
                       
            {loading ? <Loading heightvalue="55vh" /> : 
                <Modal.Body>
                    {showAlert ===  true ? (
                <AlertComp variant={alert.type} alertText={alert.value} />
                ):(
                    <>
                    <p className="lead text-center">Delete ?</p>
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
                        <button className="btn btn-secondary" onClick={handleRemove}>Cancel</button>
                        <button className="btn btn-danger" onClick={confirmDelete}>Delete</button>
                    </div>
                    </>
                )}

                </Modal.Body>
                 }
            </Modal>   
        </div>
    )
}

export default DeletePrompt
