import React from 'react'
import "../../Others/DMC/Contracts/Status/completed.css"
const NoBookings = () => {
    return (
            <div className="completed-wrapper d-flex align-items-end justify-content-center">
                <p className="text-center text_font">Search to View Listings</p>
            </div>
        
    )
}

export default NoBookings