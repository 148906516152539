import React, { useEffect, useState } from 'react'
import {useLocation} from "react-router-dom"
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import useFetch from '../../../Reusables/Fetch/Fetch';
import Loading from '../../../Reusables/Loader/Loading';
// import Datatables from '../../../Reusables/Tables/Datatables';
import NoService from "./NoService"
import Table from './Table';
import TableContent from './TableContent';
import { getServiceUsertoken } from "../GetUsertoken/GetUsertoken";

const ListServices = ({reload}) => {
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");

    useEffect(()=>{
      getServiceUsertoken(location, setUsertoken);
    },[location])



    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("usertoken", usertoken);
    const{data: services, loading, alert, showAlert, setShowAlert } = useFetch("/service-list", formData, reload, usertoken)
    
    const handleAlertClose = () => {
        setShowAlert(false);
      };
      
    return loading ? (<Loading />):(
        <div >
          {/* <p className="text-primary text-center mb-2 fw-bold">SERVICES LIST</p> */}
             {showAlert === true && 
            services.message !== undefined &&
            <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
          }

          { services.length > 0 ? (
            <Table>
            {services.map((item, index)=>(
                <TableContent index={index} item={item} key={item.id} />
                ))}
          </Table>
          // <Datatables column={tableHeader}  data={services} />
          ):(<NoService />)}
        </div>
    )
}

export default ListServices
