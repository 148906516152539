import React from 'react'
import "./completed.css"
const Completed = () => {
    return (
            <div className="completed-wrapper d-flex align-items-end justify-content-center">
                <p className="lead text-center">NO CONTRACT YET</p>
            </div>
        
    )
}

export default Completed
