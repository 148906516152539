import React from "react";
import { Table } from "../../superData/data";
import TableData from "./TableData/TableData";

const DashboardTable = () => {
  const changeColor = (status) => {
    switch (status) {
      case "Processing":
        return "info";

      case "Cancelled":
        return "danger";

      default:
        break;
    }
  };
  return (
    <section className="text-center chartcard-wrapper">
      <div className="top py-2 px-4 mb-3 d-flex justify-content-between">
        <header>
          <p className="lead">Recent Bookings</p>
        </header>
        <div className="others">
          <button className="btn btn-primary">More</button>
        </div>
      </div>
      <div className="card">

      <div className="card-body table-responsive" >
        <table className="table table-striped  table-borderless">
          <thead>
            <tr>
            <th>Item tag</th>
            <th>Date Created</th>
            <th>Item Name</th>
            <th>Total</th>
            <th>Paid</th>
            <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Table.map((row) => (
              <TableData
                key={row.id}
                tag={row.tag}
                date={row.date}
                time={row.time}
                color={changeColor(row.status)}
                itemName={row.name}
                code={row.code}
                total={row.total}
                paid={row.paid}
                status={row.status}
              />
              ))}
          </tbody>
        </table>
      </div>
      </div>
    </section>
  );
};

export default DashboardTable;
