import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 justify-content-center">
            <header>
              <p>NEED HELP?</p>
            </header>
            <div className="call left-bordered d-flex flex-column justify-content-between">
              <p>Call us</p>
              <p>+ 2011111111111</p>
            </div>
            <div className="mail left-bordered d-flex flex-column justify-content-between">
              <p>Email</p>
              <p>info@hotelsoffline.com</p>
            </div>
            <div className="follow left-bordered d-flex flex-column justify-content-between">
              <p>Follow us</p>
              <span>
              <a href="#">
                <i>
                  <FaFacebookF />
                </i>
              </a>
              <a href="#">
                <i>
                  <FaTwitter />
                </i>
              </a>
              <a href="#">
                <i>
                  <FaInstagram />
                </i>
              </a>
              <a href="#">
                <i>
                  <FaLinkedin />
                </i>
              </a>
              </span>
              
            </div>
          </div>
          <div className="col-md-6 col-lg-3 justify-content-center">
            <header>
              <p>COMPANY</p>
            </header>
            <ul>
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">Community Blog</a>
              </li>
              <li>
                <a href="#">Rewards</a>
              </li>
              <li>
                <a href="#">Work with Us</a>
              </li>
              <li>
                <a href="#">Meet the Team</a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 justify-content-center">
            <header>
              <p>SUPPORT</p>
            </header>
            <ul>
              <li>
                <a href="#">Account</a>
              </li>
              <li>
                <a href="#">Legal</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
              <li>
                <a href="#">Affiliate Program</a>
              </li>
              <li>
                <a href="#">Privacy Program</a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 justify-content-center">
            <header>
              <p>SETTINGS</p>
            </header>
            <ul>
              <li>
                <a href="#">Settings 1</a>
              </li>
              <li>
                <a href="#">Settings 2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
