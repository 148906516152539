import React from "react";
import { Modal } from "react-bootstrap";

const ContractModal = ({ handleRemove, open }) => {
  return (
    <>
      <Modal show={open} onHide={handleRemove}>
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">Create Contract</h4>

            <p className="text-secondary ">
              For ease of trackings and documentation
            </p>
          </div>
          <Modal.Body className="mb-5">
            <form className="row">
              <div className="form-left col-md-6">
                <div className="status mb-3">
                  <label
                    htmlFor="status"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Status
                  </label>

                  <select
                    name="status"
                    id="status"
                    className="form-select"
                  ></select>
                </div>
                <div className="select-rooms mb-3">
                  <label
                    htmlFor="select-rooms"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Select Rooms
                  </label>

                  <select
                    name="select-rooms"
                    id="select-rooms"
                    className="form-select"
                  ></select>
                </div>
                <div className="price mb-3">
                  <label htmlFor="price" className="mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >
                    Price
                  </label>
                    <div className="price-input row">

                  <div className="adult col-6">
                    <input
                      type="text"
                      name="adult"
                      id="adult"
                      className="form-control"
                      placeholder="adult"
                      aria-placeholder="adult"
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                  <div className="child col-6">
                    <input
                      type="text"
                      name="child"
                      id="child"
                      className="form-control"
                      placeholder="child"
                      aria-placeholder="child"
                      style={{ fontSize: "12px" }}
                      />
                  </div>
                      </div>
                </div>
              </div>
              <div className="form-right col-md-6">
                <div className="action mb-3">
                  <label
                    htmlFor="action"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Action
                  </label>

                  <select
                    name="status"
                    id="status"
                    className="form-select"
                  ></select>
                </div>

                <div className="price row mb-3">
                  <div className="totalRooms col-6">
                    <label
                      htmlFor="totalRooms"
                      className="mb-2 text-secondary form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Total rooms
                    </label>
                    <input
                      type="text"
                      name="totalRooms"
                      className="form-control"
                      id="totalRooms"
                      placeholder="Total Rooms"
                      aria-placeholder="totalRooms"
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                  <div className="availableRooms col-6">
                    <label
                      htmlFor="availableRooms"
                      className="mb-2 text-secondary form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Available rooms
                    </label>
                    <input
                      type="text"
                      name="availableRooms"
                      className="form-control"
                      id="availableRooms"
                      placeholder="Available Rooms"
                      aria-placeholder="availableRooms"
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                </div>
                <div className="date">
                  <label
                    htmlFor="Date"
                    className="mb-2 text-secondary form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Date
                  </label>

                  <div className="date-input row">
                    <div className="startDate col-6  ">
                      <input
                        type="text"
                        name="startDate"
                        className="form-control"
                        id="startDate"
                        placeholder="From"
                        aria-placeholder="startDate"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                    <div className="endDate col-6">
                      <input
                        type="text"
                        name="endDate"
                        className="form-control"
                        id="endDate"
                        placeholder="To"
                        aria-placeholder="end-date"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <div className="d-flex mb-3">
            <button
              className="btn btn-lg btn-primary mb-2 w-50 m-auto"
              style={{  padding: "11px" }}
            >
              Create Contract
            </button>
            </div>
          <div className="d-flex">

            <button
              className="btn btn-outline-primary w-50 m-auto"
              style={{ padding: "11px" }}
            >
              Back
            </button>
            
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContractModal;
