import React from "react";
import { Route } from "react-router-dom";
import Booking from "./Booking";
import BookingDetails from "./Details/BookingDetails";

const Bookings = ({admin}) => {

  return (
    <main className="pt-4 px-lg-4 px-2" style={{ minHeight: "90vh" }}>
      <header className=" mb-3 d-flex justify-content-between align-items-center flex-wrap dashHeader">
        <h4 className="text-primary">BOOKINGS</h4>
      </header>
      <section className="content">
        {
          admin ? 
          <>
            <Route exact path="/admin/search" component={Booking} />
            <Route path="/admin/search/details" component={BookingDetails} />
          </>
          :
          <>
            <Route exact path="/dmc/bookings" component={Booking} />
            <Route path="/dmc/bookings/details" component={BookingDetails} />
          </>
        }
      </section>
    </main>
  );
};

export default Bookings;
