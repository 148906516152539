import React from "react";
import {
  TimestampConverter,
} from "../../../../../../controllers/controller";

const StaticSupp = ({
  timestamp,
  roomType,
  child_from,
  child_to,
  type,
  subscription,
  service,
  from,
  to,
  adult,
  child,
  handleEdit,
  adult_amount,
  adult_perc,
  child_amount,
  child_perc,
  child_age_from,
  child_age_to,
}) => {
  return (
    <tr>
      <td>
        <p>{TimestampConverter(timestamp)}</p>
      </td>
      <td>
        <p>{service}</p>
      </td>
      <td>
        <p>{subscription}</p>
      </td>
      <td>
        <p>{type}</p>
      </td>
      <td>
        <p>{roomType}</p>
      </td>

      <td>
        <p>{TimestampConverter(from)}</p>
      </td>
      <td>
        <p>{TimestampConverter(to)}</p>
      </td>
      <td>
        <p>{adult}</p>
      </td>
      {/* <td>
                <p>{child}</p>
            </td> */}
      <td>
        <p>{child_from}</p>
      </td>
      {/* <td>
        <p>{child_to}</p>
      </td> */}
      <td>
        <p>{adult_amount}</p>
      </td>
      <td>
        <p>{adult_perc}</p>
      </td>
      <td>
        <p>{child_amount}</p>
      </td>
      <td>
        <p>{child_perc}</p>
      </td>
      <td>
        <p>{child_age_from}</p>
      </td>
      <td>
        <p>{child_age_to}</p>
      </td>
      <td>
        <button className="btn" onClick={handleEdit}>
          Edit
        </button>
      </td>
    </tr>
  );
};

export default StaticSupp;
