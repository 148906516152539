import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Fetch } from '../../../../../controllers/controller';
import AlertComp from '../../../../Reusables/AlertComp/AlertComp';
import Loading from '../../../../Reusables/Loader/Loading';
import HotelContext from '../Context/hotelcontext';

const Deletehotel = ({closeHotelDelete, showDeleteModal}) => {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [deleted, setDeleted] = useState(false)
    const [hotelId] = useContext(HotelContext)
    
    const DeleteHotelOwner = (e) => {
      const formData = new FormData()
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN )
      formData.append("hoteltoken", hotelId)
      setLoading(true)
      Fetch(`${process.env.REACT_APP_END_POINT}/delete-hotel`, formData)
      .then( (res) => {
       
        setDeleted(true)
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "success", value: res.data.message });
  
        })
        .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }
    return  (
      <>
        <Modal show={showDeleteModal} onHide={closeHotelDelete}>
          {loading ? (<Loading />):(
            <div className="py-5 px-5 modals">
                <h4 className="text-center">Delete Hotel</h4>
            <Modal.Body className="mb-5">
              <div className="row justify-content-center">
                <div className="form-left col-md-6">
                {showAlert === true && (
                      <AlertComp variant={alert.type} alertText={alert.value} />
                    )}
                    {deleted ? (  <div className="d-flex justify-content-between">
                        <button className="btn btn-success"  onClick={closeHotelDelete} >Close</button>
                        </div>):(
                        <div>
                            <p className="lead text-center">Are you sure you want to delete?</p>
                            <div className="d-flex justify-content-between">
                            <button className="btn btn-secondary"  onClick={closeHotelDelete} >Cancel</button>
                            <button className="btn btn-danger"  onClick={DeleteHotelOwner} >Delete</button>
                            </div>
                        </div>
                    )}

                  </div>
              </div>
            </Modal.Body>
          </div>
        )}
        </Modal>
      </>
    );
}

export default Deletehotel
