import React from 'react'
import AmenitiesHolder from './AmenitiesHolder'
import ClassicalImage from "../../../../Images/Classical.png"
import {useHistory}  from "react-router-dom"

const BookingCards = (props) => {
    const history = useHistory();
    const {hotelname, location, price, roomname, description, id , amenities,contract_id, info} = props
    const openDetails = (token, all)=>{
        localStorage.setItem("booking", JSON.stringify(all))
        history.push("bookings/details")
    }
  return (
    <div className="row hotel_list mb-3  shadow">
        <div className="Bookingcard_left col-sm-4">
            <img src={ClassicalImage} alt="hotel" />
        </div>
        <div className="Bookingcard_right col-sm-8 ">
            <div className="Bookingcard_top">
                <div className=" top_top d-flex justify-content-between align-items-start">
                    <p className="name mb-0 "  dangerouslySetInnerHTML={{ __html: hotelname }} />
                    <div className="reviews fw-bold text-center text-white"  dangerouslySetInnerHTML={{ __html: roomname }} />
                </div>
                <small className="distance text-success fw-light">{location}</small>
                {/* <div className="reviews fw-bold bg-danger p-2"  style={{width:"15%"}} dangerouslySetInnerHTML={{ __html: roomname }} /> */}
                
            </div>
            <div className="Bookingcard_mid">
               <p className="description" dangerouslySetInnerHTML={{ __html: description }}  />
               {/* <p className="description">Search the world's information, including webpages, images, videos and more. Google has many </p> */}
            </div>
            <div className="Bookingcard_bottom">
                <div className="bottom_left ">
                <AmenitiesHolder id={id} amenities={amenities} />
                </div>
                <div className="bottom_right d-flex ">
                    <p className="fw-bold lead me-5">$ {price === "" ? "0" : price}</p>
                    {/* <p className="fw-bold lead me-5">$ 400</p> */}
                    <button className="br_button ms-3" onClick={()=>{openDetails(contract_id, info)}}>Book Now</button>
                    {/* <Link className="br_button ms-3 text-decoration-none" to={`bookings/details/${contract_id}`}>Book Now</Link> */}
                </div>
            </div>
        </div>
    </div>
  )
}




export default BookingCards