import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import StripeCheckout from "react-stripe-checkout";
import { useLocation } from "react-router-dom";
import { Fetch } from "../../../../controllers/controller";
import { mainGetUsertoken } from "../GetUsertoken/GetUsertoken";


const stripePromise = loadStripe(
  "pk_test_519Ss7DEOJOIsAVXOWTpWooFyL5dzhpurpKTLYJ4Vx0QcvWHMvSNUBEcIJOAyDQ3sEErCsgf7j7Emi06Tpi8EX1j500IfiCsQDu"
);

const CheckoutForm = () => {
  return (
    <form>
      <PaymentElement />
      <button className="btn btn-warning">Submit</button>
    </form>
  );
};

export default function Stripe({
  setLoading,
  setAlertType,
  setShowAlert,
  email,
}) {
  const location = useLocation();
  const [usertoken, setUsertoken] = React.useState("");
  const [user, setUser] = React.useState("");
  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.REACT_APP_STRIPE_SECRET,
  };

  const [amount, setAmount] = React.useState("");

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  React.useEffect(() => {
    mainGetUsertoken(location, setUsertoken,setUser)
  }, [])
  function addMoneyToDB(source) {
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("usertoken", user.role === "6" ? 1: usertoken);
    formData.append("amount", amount);
    formData.append("source", source);

    Fetch(`${process.env.REACT_APP_END_POINT}/credit-wallet`, formData)
      .then((res) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: err.message,
        });
      });
  }

  const onSubmit = (token) => {
    console.log(token);
    addMoneyToDB("stripe");
  };
  // const onClosed = (token) => {
  //   window.location.reload();
  // };
  return (
    // <Elements stripe={stripePromise} options={options}>
    //   <CheckoutForm />
    // </Elements>

    <div className="d-flex flex-column">
      <input
        type="number"
        name="amount"
        id="amount"
        className="form-control mb-2"
        value={amount}
        onChange={handleChange}
        placeholder="Enter amount"
      />
      <StripeCheckout
        description="Fund Wallet"
        token={onSubmit}
        stripeKey="pk_test_519Ss7DEOJOIsAVXOWTpWooFyL5dzhpurpKTLYJ4Vx0QcvWHMvSNUBEcIJOAyDQ3sEErCsgf7j7Emi06Tpi8EX1j500IfiCsQDu"
        amount={Number(amount) * 100}
        currency="USD"
        // closed={onClosed}
        email={email}
      />
    </div>
  );
}
