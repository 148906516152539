import Revenue from "../../../../Images/Icons/revenue.png";
import Earnings from "../../../../Images/Icons/earnings-card.png";
import Bookings from "../../../../Images/Icons/bookings-card.png";
import Services from "../../../../Images/Icons/services-card.png";
import Dashboard from "../../../../Images/Icons/dashboard.png"
import Event from "../../../../Images/Icons/event.png"
import Hotel from "../../../../Images/Icons/hotel.png"
import Location from "../../../../Images/Icons/location.png"
import Menu from "../../../../Images/Icons/menu.png"
import News from "../../../../Images/Icons/news.png"
import Packages from "../../../../Images/Icons/package.png"
import Pages from "../../../../Images/Icons/pages.png"
import Payouts from "../../../../Images/Icons/payout.png"
import Reports from "../../../../Images/Icons/reports.png"
import  Users from "../../../../Images/Icons/users.png"
import Review from "../../../../Images/Icons/review.png"
import Service from "../../../../Images/Icons/service.png"
import Settings from "../../../../Images/Icons/settings.png"
import Tools from "../../../../Images/Icons/tools.png"
import Barsha from "../../../../Images/hotel-room.png"
import Tip from "../../../../Images/Icons/tip.svg"
import Loyalty from "../../../../Images/Icons/loyalty.svg"
import Following from "../../../../Images/Icons/following.svg"
import Followers from "../../../../Images/Icons/followers.svg"
export const Table = [
  {
    id: 1,
    tag: "01",
    date: "22/07/2021",
    time: "19:22",
    name: "TEST FIXES",
    code: "28DEC2000",
    total: "$115",
    paid: "$0",
    status: "Processing",
  },
  {
    id: 2,
    tag: "02",
    date: "22/07/2021",
    time: "19:22",
    name: "TEST FIXES",
    code: "28DEC2000",
    total: "$115",
    paid: "$0",
    status: "Processing",
  },
  {
    id: 3,
    tag: "03",
    date: "22/07/2021",
    time: "19:22",
    name: "HILTON JBR",
    code: "",
    total: "$115",
    paid: "$0",
    status: "Processing",
  },
  {
    id: 4,
    tag: "04",
    date: "22/07/2021",
    time: "19:22",
    name: "TEST FIXES",
    code: "28DEC2000",
    total: "$115",
    paid: "$0",
    status: "Cancelled",
  },
  {
    id: 5,
    tag: "05",
    date: "22/07/2021",
    time: "19:22",
    name: "TEST FIXES",
    code: "28DEC2000",
    total: "$115",
    paid: "$0",
    status: "Processing",
  },

];

export const ACards = [
    {
        id: 1,
        title: "Revenue",
        icon: Revenue,
        amount: "$12.207",
        background: "linear-gradient(270.92deg, #37DD9D 0.93%, #3ADE9B 44.39%, #2AC6AA 107.53%, rgba(196, 196, 196, 0) 111.16%)",
        footer: "Total Revenue",
        color: "rgba(51, 212, 161, 1)"
    },
    {
        id: 2,
        title: "Earnings",
        icon: Earnings,
        amount: "$12.207",
        background: "linear-gradient(90deg, #AA57F7 0%, #C561E5 65.1%, #CF68E2 100%)",
        footer: "Total Earnings",
        color: "#AC58F6"
    },
    {
        id: 3,
        title: "Search",
        icon: Bookings,
        amount: "$12.207",
        background: "linear-gradient(90deg, #8F68FA 0%, #63ACF5 65.8%, #67AEEE 100%)",
        footer: "Total Bookings",
        color: "#7F80F9"

    },
    {
        id: 4,
        title: "Services",
        icon: Services,
        amount: "$12.207",
        footer: "Total Services",
        background: "linear-gradient(90deg, #FEAC39 0%, #FA8F76 62.18%, #F58495 100%)",
        color: "#FEA744"

    },
    // {
    //     id: 5,
    //     title: "Wallet",
    //     icon: Services,
    //     amount: "$376.207",
    //     footer: "Total Balance",
    //     color: "#EB5B9D",
    //     wallet: true,

    // },
]
export const Cards = [
    {
        id: 1,
        title: "Revenue",
        icon: Revenue,
        amount: "$12.207",
        background: "linear-gradient(270.92deg, #37DD9D 0.93%, #3ADE9B 44.39%, #2AC6AA 107.53%, rgba(196, 196, 196, 0) 111.16%)",
        footer: "Total Revenue",
        color: "rgba(51, 212, 161, 1)"
    },
    {
        id: 2,
        title: "Earnings",
        icon: Earnings,
        amount: "$12.207",
        background: "linear-gradient(90deg, #AA57F7 0%, #C561E5 65.1%, #CF68E2 100%)",
        footer: "Total Earnings",
        color: "#AC58F6"
    },
    {
        id: 3,
        title: "Search",
        icon: Bookings,
        amount: "$12.207",
        background: "linear-gradient(90deg, #8F68FA 0%, #63ACF5 65.8%, #67AEEE 100%)",
        footer: "Total Bookings",
        color: "#7F80F9"

    },
    {
        id: 4,
        title: "Services",
        icon: Services,
        amount: "$12.207",
        footer: "Total Services",
        background: "linear-gradient(90deg, #FEAC39 0%, #FA8F76 62.18%, #F58495 100%)",
        color: "#FEA744"

    },
    
]
export const DMCards = [
    {
        id: 1,
        title: "Revenue",
        icon: Revenue,
        amount: "$540.207",
        background: "linear-gradient(270.92deg, #37DD9D 0.93%, #3ADE9B 44.39%, #2AC6AA 107.53%, rgba(196, 196, 196, 0) 111.16%)",
        footer: "Total Revenue",
        color: "rgba(51, 212, 161, 1)"
    },
    {
        id: 2,
        title: "Override",
        icon: Earnings,
        amount: "$176.207",
        background: "linear-gradient(90deg, #AA57F7 0%, #C561E5 65.1%, #CF68E2 100%)",
        footer: "Total Override",
        color: "#AC58F6"
    },
    {
        id: 3,
        title: "Search",
        icon: Bookings,
        amount: "21",
        background: "linear-gradient(90deg, #8F68FA 0%, #63ACF5 65.8%, #67AEEE 100%)",
        footer: "Total Bookings",
        color: "#7F80F9"

    },
    {
        id: 4,
        title: "Room Night",
        icon: Services,
        amount: "147",
        footer: "Total Room Night",
        color: "#FEA744"

    }
    // {
    //     id: 5,
    //     title: "Wallet",
    //     icon: Services,
    //     amount: "$376.207",
    //     footer: "Total Balance",
    //     color: "#EB5B9D",
    //     wallet: true,

    // },
]

export const SideNavLinks = [
    {
        id: 1,
        logo: Dashboard ,
        class:"bi bi-laptop",
        title: "Dashboard",
        link: "/admin"
    },
    // {
    //     id: 2,
    //     logo: News,
    //     class: "bi bi-newspaper",
    //     title: "News",
    //     link: "/admin/news"
    // },
    {
        id: 3,
        class:"bi bi-wallet",
        logo: Pages,
        title: "Wallet",
        link: "/admin/payment-history"
    },
    // {
    //     id: 4,
    //     logo:Location ,
    //     class: "bi bi-geo-alt",
    //     title: "Location",
    //     link: "/admin/location"
    // },
    {
        id: 5,
        logo: Hotel ,
        class:"bi bi-file-earmark-pdf",
        title: "Contracts",
        link: "/admin/contracts"
    },
    {
        id: 6,
        logo: Hotel ,
        class:"bi bi-building",
        title: "Hotels",
        link: "/admin/hotel"
    },
    {
        id: 7,
        logo: Hotel ,
        class:"bi bi-currency-dollar",
        title: "Pricing",
        link: "/admin/pricing"
    },
    
    {
        id: 8,
        logo: Service ,
        class:"bi bi-newspaper",
        title: "Services",
        link: "/admin/services"
    },
    {
        id: 9,
        logo: Review ,
        class:"bi bi-chat-right-dots",
        title: "Bookings",
        link: "/admin/bookings"
    },
    {
        id: 10,
        logo: Tools ,
        class:"bi bi-globe",
        title: "Sources",
        link: "/admin/sources"
    },
    {
        id: 11,
        logo: Tools ,
        class:"bi bi-gear",
        title: "Profile",
        link: "/admin/profile"
    },
    {
        id: 12,
        logo: Tools ,
        class:"bi bi-people",
        title: "Users",
        link: "/admin/users"
    },
    {
        id: 13,
        logo: Tools ,
        class:"bi bi-kanban",
        title: "DMCs",
        link: "/admin/dmcs"
    },
    {
        id: 14,
        logo: Tools ,
        class:"bi bi-person-square",
        title: "Account Owners",
        link: "/admin/account-owners"
    },
    {
        id: 15,
        logo: Tools ,
        class:"bi bi-box",
        title: "Packages",
        link: "/admin/packages"
    },
    {
        id: 15,
        logo: Tools ,
        class:"bi bi-search",
        title: "Search",
        link: "/admin/search"
    },
    
]

export const JuniorAdmin = [
    {
        id: 1,
        logo: Dashboard ,
        class:"bi bi-laptop",
        title: "Dashboard",
        link: "/account-owner"
    },
    {
        id: 2,
        logo: News,
        class: "bi bi-newspaper",
        title: "Search",
        link: "/account-owner/bookings"
    },
    {
        id: 3,
        logo: Payouts,
        class:"bi bi-wallet",
        title: "Hotels",
        link: "/account-owner/hotels"
    },
    {
        id: 4,
        logo: Event ,
        class:"bi bi-calendar-event-fill",
        title: "Contracts",
        link: "/account-owner/contracts"
    },
    {
        id: 5,
        logo: Event ,
        class:"bi bi-briefcase-fill",
        title: "Services",
        link: "/account-owner/services"
    },
    {
        id: 6,
        logo: Settings,
        class:"bi bi-gear",
        title: "Profile",
        link: "/account-owner/profile"
    },
    {
        id: 12,
        logo: Hotel ,
        class:"bi bi-currency-dollar",
        title: "Pricing",
        link: "/account-owner/pricing"
    },
    {
        id: 7,
        logo: Packages,
        class:"bi bi-box-seam",
        title: "Packages",
        link: "/account-owner/packages"
    },
    {
        id: 8,
        logo: Payouts,
        class:"bi bi-clipboard-data",
        title: "Payouts",
        link: "/account-owner/payouts"
    },
    {
        id: 9,
        logo: Users,
        class:"bi bi-wallet",
        title: "Wallet",
        link: "/account-owner/wallet-history"
    },
    // {
    //     id: 10,
    //     logo: Tools ,
    //     class:"bi bi-globe",
    //     title: "Sources",
    //     link: "/account-owner/sources"
    // },
    {
        id: 10,
        logo: Reports ,
        class:"bi bi-clipboard-data",
        title: "Reports",
        link: "/account-owner/reports"
    },
    {
        id: 11,
        logo: Reports ,
        class:"bi bi-gear",
        title: "Profile",
        link: "/account-owner/profile"
    },
]

export const Hotels = [
    {
        id: 1,
        image: Barsha,
        title: "NOVOTEL AL BARSHA",
        date: "18/10/2021",
        time: "16:48"
        // link: "/jAdmin/users"
    },
    {
        id: 2,
        image: Barsha,
        title: "NOVOTEL AL BARSHA",
        date: "18/10/2021",
        time: "16:48"
        // link: "/jAdmin/users"
    }
]

export const DMC = [
    {
        id: 1,
        logo: Dashboard ,
        class:"bi bi-laptop",
        title: "Dashboard",
        link: "/dmc"
    },
    {
        id: 2,
        logo: News,
        class: "bi bi-newspaper",
        title: "Search",
        link: "/dmc/bookings"
    },
    {
        id: 3,
        logo: Payouts,
        class:"bi bi-wallet",
        title: "Hotels",
        link: "/dmc/hotels"
    },
    {
        id: 4,
        logo: Event ,
        class:"bi bi-calendar-event-fill",
        title: "Contracts",
        link: "/dmc/contracts"
    },
    
    {
        id: 5,
        logo: Packages,
        class:"bi bi-box-seam",
        title: "Overrides",
        link: "/dmc/overrides"
    },
    {
        id: 6,
        logo: Event ,
        class:"bi bi-briefcase-fill",
        title: "Services",
        link: "/dmc/services"
    },
    {
        id: 7,
        logo: Payouts,
        class:"bi bi-clipboard-data",
        title: "Packages",
        link: "/dmc/packages"
    },
    {
        id: 8,
        logo: Users,
        class:"bi bi-wallet",
        title: "Wallet",
        link: "/dmc/wallet-history"
    },
    {
        id: 9,
        logo: Reports ,
        class:"bi bi-clipboard-data",
        title: "Reports",
        link: "/dmc/reports"
    },
    {
        id: 10,
        logo: Reports ,
        class:"bi bi-gear",
        title: "Profile",
        link: "/dmc/profile"
    },
    // {
    //     id: 10,
    //     logo: Tools ,
    //     class:"bi bi-globe",
    //     title: "Sources",
    //     link: "/dmc/sources"
    // }
]
export const hotelOwner = [
    {
        id: 1,
        logo: Dashboard ,
        class:"bi bi-laptop",
        title: "Dashboard",
        link: "/hotel-owner"
    },
    {
        id: 2,
        logo: News,
        class: "bi bi-newspaper",
        title: "Search",
        link: "/hotel-owner/bookings"
    },
    {
        id: 3,
        logo: Payouts,
        class:"bi bi-wallet",
        title: "Hotels",
        link: "/hotel-owner/hotels"
    },
    {
        id: 4,
        logo: Event ,
        class:"bi bi-calendar-event-fill",
        title: "Contracts",
        link: "/hotel-owner/contracts"
    },
    
    {
        id: 5,
        logo: Packages,
        class:"bi bi-box-seam",
        title: "Overrides",
        link: "/hotel-owner/overrides"
    },
    {
        id: 6,
        logo: Event ,
        class:"bi bi-briefcase-fill",
        title: "Services",
        link: "/hotel-owner/services"
    },
    {
        id: 7,
        logo: Payouts,
        class:"bi bi-clipboard-data",
        title: "Packages",
        link: "/hotel-owner/packages"
    },
    {
        id: 8,
        logo: Users,
        class:"bi bi-wallet",
        title: "Wallet ",
        link: "/hotel-owner/wallet-history"
    },
    
    {
        id: 9,
        logo: Reports ,
        class:"bi bi-clipboard-data",
        title: "Reports",
        link: "/hotel-owner/reports"
    },
    {
        id: 10,
        logo: Tools ,
        class:"bi bi-globe",
        title: "Sources",
        link: "/hotel-owner/sources"
    },
    {
        id: 11,
        logo: Tools ,
        class:"bi bi-gear",
        title: "Profile",
        link: "/hotel-owner/profile"
    }
]


export const TravellerBar =  [
    {
        id: 1,
        logo: Dashboard ,
        class:"bi bi-laptop",
        title: "Dashboard",
        link: "/user/dashboard"
    },
    {
        id: 2,
        logo: News,
        class: "bi bi-newspaper",
        title: "Booking",
        link: "/user/bookings"
    },
    {
        id: 3,
        logo: Event ,
        class:"bi bi-calendar-event-fill",
        title: "Contracts",
        link: "/user/contracts"
    },
    {
        id: 4,
        logo: Packages,
        class:"bi bi-box-seam",
        title: "Package",
        link: "/user/packages"
    },
    {
        id: 5,
        logo: Payouts,
        class:"bi bi-wallet",
        title: "Payment",
        link: "/user/payment"
    },
    {
        id: 6,
        logo: Payouts,
        class:"bi bi-gear",
        title: "Profile",
        link: "/user/profile"
    },
    
]

export const TravellerCard = [
    {
        id: 1,
        title: "Tip",
        icon: Tip,
        amount: "6",
        background: "#F0FCF8",
        footer: "Total Tips",
        color: "#33D4A2"
    },
    {
        id: 2,
        title: "Following",
        icon: Following,
        amount: "190",
        background: "#F4F3F8",
        footer: "Total Followings",
        color: "#AF59F4"
    },
    {
        id: 3,
        title: "Followers",
        icon: Followers,
        amount: "120",
        background: "#E9F4FE",
        footer: "Total Followers",
        color: "#837BF9"

    },
    {
        id: 4,
        title: "Loyalty Points",
        icon: Loyalty,
        amount: "770",
        footer: "Total Loyalty Points",
        color: "#FDA34C",
        background: "#FFEEDE"

    },
    // {
    //     id: 5,
    //     title: "Wallet",
    //     icon: Services,
    //     amount: "$376.207",
    //     footer: "Total Balance",
    //     color: "#EB5B9D",
    //     wallet: true,

    // },
]