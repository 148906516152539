import React from 'react'
import DetailedCards from '../../Cards/detailedCards/DetailedCards'
import SectionHeader from '../SectionHeader/SectionHeader'
import PromotionImg from "../../../Images/bestListing.png"

const Promotions = () => {
    return (
        <section className="container promotions">
            <SectionHeader title="Our best promotion tours"  lead="Most popular destinations"/>

            <div className="row">
                <div className="col-md-4 mb-3 mb-md-0 p-1">
                    <DetailedCards cardImage={PromotionImg} cardTitle="America Parks Trail" time="8 hours" price="$3000" discount="$324" />
                </div>
                <div className="col-md-4 mb-3 mb-md-0 p-1">
                    <DetailedCards cardImage={PromotionImg} cardTitle="America Parks Trail" time="8 hours" price="$3000" discount="$324" />
                </div>
                <div className="col-md-4 mb-3 mb-md-0 p-1">
                    <DetailedCards cardImage={PromotionImg} cardTitle="America Parks Trail" time="8 hours" price="$3000" discount="$324" />
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-md-4 mb-3 mb-md-0 p-1">
                    <DetailedCards cardImage={PromotionImg} cardTitle="America Parks Trail" time="8 hours" price="$3000" discount="$324" />
                </div>
                <div className="col-md-4 mb-3 mb-md-0 p-1">
                    <DetailedCards cardImage={PromotionImg} cardTitle="America Parks Trail" time="8 hours" price="$3000" discount="$324" />
                </div>
                <div className="col-md-4 mb-3 mb-md-0 p-1">
                    <DetailedCards cardImage={PromotionImg} cardTitle="America Parks Trail" time="8 hours" price="$3000" discount="$324" />
                </div>
            </div>
        </section>
    )
}

export default Promotions
