import axios from "axios"

export const addExternalHotel = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/add-new-external-hotel`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}


export const getAllExternalHotels = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getExternalHotels`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}
export const mapHotelsToCategory = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/mapExtRoomsToCat`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}
export const createSource = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/createSource`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}
export const getExternalSources = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getExtSources`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}
export const getSourceHotels = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getSourceHotels`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}
export const createDynamicContract = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/dmc/createDynamicContract`, JSON.stringify(data), {
        headers: {
            'content-type':'application/json'
        }
    })
   return response
}

export const getdmc = async (data)=>{
    const formdata = new FormData();
    formdata.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/list-dmc`, formdata, {
        headers: {
            'content-type':'multipart/form-data'
        }
    })
   return response
}
export const getAccountOwner = async (data)=>{
    const formdata = new FormData();
    formdata.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/list-accountowner`, formdata, {
        headers: {
            'content-type':'multipart/form-data'
        }
    })
   return response
}
export const getHotelOwner = async (data)=>{
    const formdata = new FormData();
    formdata.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/list-hotelowner`, formdata, {
        headers: {
            'content-type':'multipart/form-data'
        }
    })
   return response
}

export const getRoomCategories = async (data)=>{
    const formdata = new FormData();
    formdata.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/list-room-category`, formdata, {
        headers: {
            'content-type':'multipart/form-data'
        }
    })
   return response
}
export const getRoomList = async (data)=>{
    const formdata = new FormData();
    formdata.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    formdata.append("hotelid", data)
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/room-list`, formdata, {
        headers: {
            'content-type':'multipart/form-data'
        }
    })
   return response
}

export const getExternalRooms = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/get-external-roomByExHotel`, JSON.stringify(data), {
        headers: {
            'content-type':'Application/json'
        }
    })
   return response
}
export const addHoOwner = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/addHotelOwner-exHotel`, JSON.stringify(data), {
        headers: {
            'content-type':'Application/json'
        }
    })
   return response
}
export const mapDMCtoHotel = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/account-owner/mapDMCtoHotel`, JSON.stringify(data), {
        headers: {
            'content-type':'Application/json'
        }
    })
   return response
}
export const listHotelbyDmc = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/dmc/listHotels`, JSON.stringify({...data, apptoken: process.env.REACT_APP_APP_TOKEN}), {
        headers: {
            'content-type':'Application/json'
        }
    })
   return response
}
export const addNewHotel = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/account-owner/addHotel
    `, JSON.stringify(data), {
        headers: {
            'content-type':'Application/json'
        }
    })
   return response
}

export const editHotel = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/hotel-chain/editHotel
    `, JSON.stringify(data), {
        headers: {
            'content-type':'Application/json'
        }
    })
   return response
}


export const updateBio = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/update-user-profile`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getBio = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/user-profile `, JSON.stringify(data),
     { headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const updatePassword = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/update-password`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}

export const updateHotelCredentials = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/hotel-chain/editHotel`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}

export const addStripeID = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/add-stripe-id`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const signupToPayoneer = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/payoneer/linkUserToPayoneer`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const payWithPayoneer = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/payoneer/pay`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getContractData = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getContractData`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}


export const getExtContractRooms = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getExternalContractRooms`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getHotelCategories = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getHotelRoomCategory`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const checkMapped = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/checkIfContractMapped`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getHotelData = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getHotelData`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const connectUserToStripe = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/stripe/linkUserToStripe`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getExternalHotelData = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getExternalHotelData`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getAllUsers = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getAllUsers`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}

export const userApprove = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/approve-user`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const addCancelPolicy = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/create-cancellation-policy`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const listContractHotel = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/list-hotel-contracts`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const deleteHotelRoom = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/delete-hotel-room`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const addContractDistribution = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/create-contract-distribution`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const editContractHeader = async (data)=>{
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/edit-init-contract`, JSON.stringify(data), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const listDistribution = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/list-contract-distribution`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const deleteDist = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/removeDistribution`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getAllCurrencies = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/getAllCurrencies`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const chooseCurrency = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_END_POINT}/update-my-currency`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminHotels = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/listHotels`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminBookingHistory = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/listHotels`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchDMCs = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getDMCs`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAccountOwners = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getAccountOwners`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const addDMCToHotel = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/addDMCtoHotel`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const removeDMCFromHotel = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/removeDMCfromHotel`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAccountOwnerHotel = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getHotelsbyAccountOwner`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const removeAccountOwnerFromHotel = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/removeAccountOwnerFromHotel`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}

export const addAccountOwnerToHotel = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/assignHotelToAccountOwner`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const addDMCToAccountOwner = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/assignDMCtoAccountOwner`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}

export const fetchAdminServices = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getServices`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminPackages = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getPackages`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminWalletBalance = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getBalance`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const adminWithdraw = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/adminWithdraw`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const adminTransfer = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/transferFunds`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminPaymentHistory = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getPackages`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const adminAddFacility = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/createHotelAmenities`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminFacilities = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getAllHotelAmenities`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const adminAddCategory = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/createHotelType`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminCategory = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/getAllHotelTypes`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const fetchAdminBookings = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getBookingHistory`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const verifyUserStatus = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/payoneer/verifyUserStatus`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const addAgentToAccountOwner = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/addAgentToAO`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}
export const getAdminContracts = async (data)=>{
    let option = {...data, apptoken: process.env.REACT_APP_APP_TOKEN}
    const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/admin/getContracts`, JSON.stringify(option), {
        headers: {
            'content-type': 'Application/json'
        }
    })

    return response
}