import React, { useState } from "react";
import {
  Fetch,
  TimestampConverter,
} from "../../../../../../controllers/controller";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import useFetch from "../../../../../Reusables/Fetch/Fetch";
import Loading from "../../../../../Reusables/Loader/Loading";
import HotelContract from "../../../../Reusables/contractCard/hotelContract";
import ContractCalModal from "../Modal/ContractCalModal";
import Markup from "../Modal/Markup";
import Completed from "./Completed";
import ContractList from "../ContractList";
import "./completed.css";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";

import {FiFilter} from "react-icons/fi"
import Modals, { ChoiceModals, DynamicModal } from "../Modal/Modal";
import { useEffect } from "react";
import { mainGetUsertoken } from "../../../../Reusables/GetUsertoken/GetUsertoken";





const Allcontracts = ({ match }) => {
  
  const handleSummary = (e) => {
    localStorage.setItem("con", e);
  };

  const [show, setShow] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(null);
  const [indexx, setIndex] = useState(null);
  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);
  const [calValues, setCalValues] = useState([]);
  const [showMarkup, setShowMarkup] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [cccFilter, setCCCFilter] = useState({
    company: "",
    city: "",
    country: "",
  });
  const history = useHistory();

  const handleFilter = (e) => {
    setCCCFilter({ ...cccFilter, [e.target.name]: e.target.value });
  };
  
  const formData = new FormData();
  formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
  formData.append("dmctoken", useableAccOwner.usertoken);
  const {
    data: contracts,
    loading,
    alert,
    showAlert,
    setShowAlert,
  } = useFetch("/list-contract-one", formData);

  
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const handleMarkupClose = () => {
    setShowMarkup(false);
  };
  const openMarkup = (e) => {
    setId(e);
    setShowMarkup(true);
  };
  
  const openAvailModal = (e, event, index) => {
    // if(event)
    setButtonLoad(e);
    localStorage.setItem("cont", e);
    setIndex(index);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("token", e);
    Fetch(`${process.env.REACT_APP_NEND_POINT}/get-contract-rooms`, formData)
      .then((res) => {
        setButtonLoad(null);
        console.log({res})
        if(res.data?.success) {
          setCalValues([...res.data]);
          setShow(true);
        }else {
          window.alert(res.data.message);
        }
      })
      .catch((err) => {
        setButtonLoad(null);
        window.alert(err);
      });
  };
  const handleEdit = (...args) => {
    const [
      id,
      token,
      hotelname,
      country,
      city,
      contract_name,
      child_age_From,
      child_age_To,
      status,
      currency,
    ] = args;
    
    localStorage.setItem("CT", id);
    localStorage.setItem("hotel", token);
    localStorage.setItem("Count", country);
    localStorage.setItem("City", city);
    localStorage.setItem("hotelname", hotelname);
    localStorage.setItem(
      "contract_info",
      JSON.stringify({
        contract_name: contract_name,
        status: status,
        child_age_From: child_age_From,
        child_age_To: child_age_To,
        currency: currency,
      })
    );
    history.push("/dmc/contracts/create");
  };
  

// CREATE CONTRACT

  const [showChoice, setShowChoice] = useState(false);
  const handleShowSelect = () => setShowChoice(true);
  const location = useLocation();



  const [showDynamicModal, setShowDynamicModal] = useState(false);
  
  
  const handleClose = () => setShowCreate(false);
  
  function handleCloseChoice(){
    setShowChoice(false)
  }
  const handleShow = () => {
    setShowCreate(true)
    setShowChoice(false)
  };


  function handleCloseDynamicModal (){
    setShowDynamicModal(false)
  }
  
  function handleOpenDynamic (){
    setShowDynamicModal(true)
    setShowChoice(false)
  }

  function createDynamicContract(){
    // create dynamic contract
  }

  // rooms modal
  const [open, setOpen] = useState(false);
  const handleRemove = () => setOpen(false);
  const [showCreate, setShowCreate] = useState(false);

  const handleOpen = () => {
    const formData = new FormData();
    const accowner = localStorage.getItem("DMC");
    const hotelId = localStorage.getItem("hotel");

    const useableAccOwner = JSON.parse(accowner);
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("hoteltoken", hotelId);
    Fetch( `${process.env.REACT_APP_END_POINT}/create-contract`, formData)
      .then((res) => {
        if (res.data.success !== false) {
          localStorage.setItem("CT", res.data.contractToken);
          setShowCreate(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return loading ? (
    <Loading />
  ) : (
    <>
      {showAlert === true && contracts.message !== undefined && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      <div className="d-flex justify-content-end">
        <CreateContractButton />
      </div>
    <Tabs
      defaultActiveKey="all"
      id="uncontrolled-tab-example"
      className="mb-3 title_font"
    >
      <Tab eventKey="all" title="ALL">
        

      {contracts.length > 0 ? (
      <>
        <div className="d-flex w-100 justify-content-between align-items-center mb-2">
          <div className="search d-flex align-items-center" style={{gap:"1rem"}} >
            <input
              type="text"
              className="form-control"
              placeholder="search..."
              value={search}
              onChange={handleSearch}
            />
          <div className="form-group d-flex align-items-center my-3">
            <label htmlFor="filter" className="form-label d-flex me-3">Filter <i><FiFilter /></i></label>
            <select name="name" id="name" className="form-select form-control"  onChange={handleSearch}>
              <option value="">Select contract type</option>
              <option value="Static">Static</option>
              <option value="Dynamic">Dynamic</option>
            </select>
          </div>
          </div>
          
        </div>
        <div className="table-responsive" style={{ fontSize: "13px" }}>
          <table className="table table-bordered table-striped table-hover text_font">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Contract</th>
                <th>ID</th>
                <th>Type</th>
                <th style={{ minWidth: "180px" }}>Duration</th>
                <th style={{ minWidth: "110px" }}>Hotel</th>
                <th>Country</th>
                <th>City</th>
                <th>Company</th>
                <th>Status</th>
                <th>Age(yrs)</th>
                <th>Currency</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contracts.length > 0 &&
                contracts
                  .filter(
                    (item) =>
                      (item.company &&
                        item.company
                          .toLowerCase()
                          .includes(search.toLowerCase())) ||
                      item.city.toLowerCase().includes(search.toLowerCase()) ||
                      item.country.toLowerCase().includes(search.toLowerCase()) ||
                      item.status.toLowerCase().includes(search.toLowerCase()) ||
                      item.type.toLowerCase().includes(search.toLowerCase()) ||
                      item.contract_name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((contract, index) => (
                    <ContractList
                      {...contract}
                      index={index}
                      openAvailabilityModal={(event, id) =>
                        openAvailModal(event, id, index)
                      }
                      openMarkup={openMarkup}
                      buttonLoad={buttonLoad}
                      handleContractEdit={handleEdit}
                    />
                  ))}
            </tbody>
          </table>
        </div>
          <ContractCalModal
            show={show}
            value={calValues}
            setShow={setShow}
            handleClose={() => {
              setShow(false);
            }}
          />
          <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
        </>
        ):(<Completed />)}
      </Tab>
      <Tab eventKey="live" title="LIVE">
              {contracts.length > 0 && contracts?.filter(item=>item.status === "live" || item.status === "LIVE") ? (
                <>
                <div className="d-flex gap-3 mb-4">
                  <div className="search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search..."
                      value={search} 
                      onChange={handleSearch}
                    />
                  </div>
                     <div className="d-flex gap-1 form-group ">
                      <label htmlFor="filter" className="form-label d-flex align-items-center">Filter <i><FiFilter /></i></label>
                      <select name="name" id="name" className="form-select"  onChange={handleSearch}>
                        <option value="">Select contract type</option>
                        <option value="Static">Static</option>
                        <option value="Dynamic">Dynamic</option>
                      </select>
                     </div>
                    
                </div>
                <div className="table-responsive" style={{ fontSize: "13px" }}>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Contract</th>
                        <th>ID</th>
                        <th>Type</th>
                        <th style={{ minWidth: "180px" }}>Duration</th>
                        <th style={{ minWidth: "110px" }}>Hotel</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Company</th>
                        <th>Status</th>
                        <th>Age(yrs)</th>
                        <th>Currency</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contracts.length > 0 &&
                        contracts?.filter(item=>item.status === "live" || item.status === "LIVE")
                          .filter(
                            (item) =>
                              (item.company &&
                                item.company
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              item.city.toLowerCase().includes(search.toLowerCase()) ||
                              item.country.toLowerCase().includes(search.toLowerCase()) ||
                              item.status.toLowerCase().includes(search.toLowerCase()) ||
                              item.type.toLowerCase().includes(search.toLowerCase()) ||
                              item.contract_name.toLowerCase().includes(search.toLowerCase())
                          )
                          .map((contract, index) => (
                            <ContractList
                              {...contract}
                              index={index}
                              openAvailabilityModal={(event, id) =>
                                openAvailModal(event, id, index)
                              }
                              openMarkup={openMarkup}
                              buttonLoad={buttonLoad}
                              handleContractEdit={handleEdit}
                            />
                          ))}
                    </tbody>
                  </table>
                </div>
                  <ContractCalModal
                    show={show}
                    value={calValues}
                    setShow={setShow}
                    handleClose={() => {
                      setShow(false);
                    }}
                  />
                  <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
              </>
          ):(<Completed />)}
      </Tab>
      <Tab eventKey="renewal" title="RENEWAL">
          {contracts.length > 0 && contracts?.filter(item=>item.status === "renew" ||item.status === "RENEW") ? (
                  <>
                    <div className="d-flex gap-3 mb-4">
                      <div className="search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="search..."
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="d-flex gap-1 form-group">
                      <label htmlFor="filter" className="form-label d-flex align-items-center">Filter <i><FiFilter /></i></label>
                      <select name="name" id="name" className="form-select"  onChange={handleSearch}>
                        <option value="">Select contract type</option>
                        <option value="Static">Static</option>
                        <option value="Dynamic">Dynamic</option>
                      </select>
                     </div>
                     
                    </div>
                    <div className="table-responsive" style={{ fontSize: "13px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Contract</th>
                            <th>ID</th>
                            <th>Type</th>
                            <th style={{ minWidth: "180px" }}>Duration</th>
                            <th style={{ minWidth: "110px" }}>Hotel</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Company</th>
                            <th>Status</th>
                            <th>Age(yrs)</th>
                            <th>Currency</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contracts.length > 0 &&
                            contracts?.filter(item=>item.status === "renew" || item.status === "RENEW")
                              .filter(
                                (item) =>
                                  (item.company &&
                                    item.company
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  item.city.toLowerCase().includes(search.toLowerCase()) ||
                                  item.country.toLowerCase().includes(search.toLowerCase()) ||
                                  item.status.toLowerCase().includes(search.toLowerCase()) ||
                                  item.type.toLowerCase().includes(search.toLowerCase()) ||
                                  item.contract_name.toLowerCase().includes(search.toLowerCase())
                              )
                              .map((contract, index) => (
                                <ContractList
                                  {...contract}
                                  index={index}
                                  openAvailabilityModal={(event, id) =>
                                    openAvailModal(event, id, index)
                                  }
                                  openMarkup={openMarkup}
                                  buttonLoad={buttonLoad}
                                  handleContractEdit={handleEdit}
                                />
                              ))}
                        </tbody>
                      </table>
                    </div>
                      <ContractCalModal
                        show={show}
                        value={calValues}
                        setShow={setShow}
                        handleClose={() => {
                          setShow(false);
                        }}
                      />
                      <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
                  </>
              ):(<Completed />)}
      </Tab>
      <Tab eventKey="closed" title="CLOSED">
      {contracts.length > 0 && contracts?.filter(item=>item.status === "closed" || item.status === "CLOSED") ? (
              <>
                <div className="d-flex gap-3 align-items-center mb-4">
                  <div className="search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="d-flex gap-1 form-group">
                      <label htmlFor="filter" className="form-label d-flex align-items-center">Filter <i><FiFilter /></i></label>
                      <select name="name" id="name" className="form-select"  onChange={handleSearch}>
                        <option value="">Select contract type</option>
                        <option value="Static">Static</option>
                        <option value="Dynamic">Dynamic</option>
                      </select>
                  </div>
                  
                </div>
                <div className="table-responsive" style={{ fontSize: "13px" }}>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Contract</th>
                        <th>ID</th>
                        <th>Type</th>
                        <th style={{ minWidth: "180px" }}>Duration</th>
                        <th style={{ minWidth: "110px" }}>Hotel</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Company</th>
                        <th>Status</th>
                        <th>Age(yrs)</th>
                        <th>Currency</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contracts.length > 0 &&
                        contracts?.filter(item=>item.status === "closed" || item.status === "CLOSED")
                          .filter(
                            (item) =>
                              (item.company &&
                                item.company
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              item.city.toLowerCase().includes(search.toLowerCase()) ||
                              item.country.toLowerCase().includes(search.toLowerCase()) ||
                              item.status.toLowerCase().includes(search.toLowerCase()) ||
                              item.type.toLowerCase().includes(search.toLowerCase()) ||
                              item.contract_name.toLowerCase().includes(search.toLowerCase())
                          )
                          .map((contract, index) => (
                            <ContractList
                              {...contract}
                              index={index}
                              openAvailabilityModal={(event, id) =>
                                openAvailModal(event, id, index)
                              }
                              openMarkup={openMarkup}
                              buttonLoad={buttonLoad}
                              handleContractEdit={handleEdit}
                            />
                          ))}
                    </tbody>
                  </table>
                </div>
                  <ContractCalModal
                    show={show}
                    value={calValues}
                    setShow={setShow}
                    handleClose={() => {
                      setShow(false);
                    }}
                  />
                  <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
              </>
          ):(<Completed />)}
      </Tab>
      </Tabs>
      
    </>
  )
}

export function CreateContractButton(){


  const [showChoice, setShowChoice] = useState(false);
  const handleShowSelect = () => setShowChoice(true);
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);


  const [showDynamicModal, setShowDynamicModal] = useState(false);
  
  
  const handleClose = () => setShowCreate(false);
  
  function handleCloseChoice(){
    setShowChoice(false)
  }
  const handleShow = () => {
    setShowCreate(true)
    setShowChoice(false)
  };


  function handleCloseDynamicModal (){
    setShowDynamicModal(false)
  }
  
  function handleOpenDynamic (){
    setShowDynamicModal(true)
    setShowChoice(false)
  }

  function createDynamicContract(){
    // create dynamic contract
  }

  // rooms modal
  const [open, setOpen] = useState(false);
  const handleRemove = () => setOpen(false);
  const [showCreate, setShowCreate] = useState(false);

  const handleOpen = () => {
    const formData = new FormData();
    const hotelId = localStorage.getItem("hotel");
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", usertoken);
    formData.append("hoteltoken", hotelId);
    Fetch( `${process.env.REACT_APP_END_POINT}/create-contract`, formData)
      .then((res) => {
        if (res.data.success !== false) {
          localStorage.setItem("CT", res.data.contractToken);
          setShowCreate(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log(location.pathname)

  return (
    <>
     {

      location.pathname === "/admin/contracts" ?

      <button
          className={
            location.pathname === "/admin/contracts/create"
              ? "d-none"
              : `btn btn-lg-lg btn-primary contract-btn ms-auto`
          }
          // onClick={handleShow}
          onClick={handleShowSelect}
        >
          <span className="button-text me-2 ">CREATE CONTRACT</span>
          <span>
            <i className="bi bi-plus-circle"></i>
          </span>{" "}
      </button>
      :
      <button
          className={
            location.pathname === "/dmc/contracts/create"
              ? "d-none"
              : `btn btn-lg-lg btn-primary contract-btn ms-auto`
          }
          // onClick={handleShow}
          onClick={handleShowSelect}
        >
          <span className="button-text me-2 ">CREATE CONTRACT</span>
          <span>
            <i className="bi bi-plus-circle"></i>
          </span>{" "}
      </button>
    }
        <Modals
        handleShow={handleShow}
        handleClose={handleClose}
        show={showCreate}
        handleOpen={handleOpen}
        setShow={setShowCreate}
      />
      <ChoiceModals
      handleShowSelect={handleShowSelect}
      handleCloseChoice={handleCloseChoice}
      showChoice={showChoice}
      setShowChoice={setShowChoice}
      handleOpen={handleShow}
      handleOpenDynamic = {handleOpenDynamic}
      />
      <DynamicModal
        showDynamicModal = {showDynamicModal}
        setShowDynamicModal = {setShowDynamicModal}
        handleCloseDynamicModal = {handleCloseDynamicModal}
        handleOpenContract = {createDynamicContract}
      />

    </>
  )

}

export default Allcontracts;
