import {createContext, useContext, useEffect, useState} from "react"

const GMAPAPI = "https://maps.googleapis.com/maps/api/js";
const KEY = process.env.REACT_APP_GOOGLE_MAPS

function loadMapScript(src){
    return new Promise((resolve) => {
      const script = document.createElement("script");
  
      Object.assign(script, {
        type:"text/javascript",
        async: true,
        src
      })
      script.addEventListener("load", ()=> resolve(script));
      document.head.appendChild(script)
    })
  }



const GeneralContext = createContext();

export const useGeneral = ()=> useContext(GeneralContext);

const GeneralContextProvider = ({children})=>{

    const [state, setState] = useState({
        map:"",
        geocoder:"",
        marker:"",
        autocomplete:""
    })
    let googleMap = {
        map:"",
        geocoder:"",
        marker:"",
        autocomplete:""
    }

    function initMap(){
        if(window.google){
          return Promise.resolve()
        }
        const src = `${GMAPAPI}?key=${KEY}&libraries=places`
        window.initMapAutocomplete = initMap;
        return loadMapScript(src)
    }
    

    return (
        <GeneralContext.Provider value={{state, setState, initMap, googleMap}}>
            {children}
        </GeneralContext.Provider>
    )
    
}

export default GeneralContextProvider;