import React from "react";
import Card from "../Card/Card";
import Countries from "./Countries/Countries";
import DChart from "./dmcChart/chart";

const Dashboard = () => {
  
  return (
    <div className="pt-4 px-lg-4 px-3">
      <header className="dashHeader text-primary fw-normal">
        <h4 className="title_font">DASHBOARD</h4>
      </header>
      <section className="admin-dashboard-top mt-4 text_font">
        <Card />
        <section className="mt-5">
          <div className="d-flex row justify-content-between">
          <DChart />
          <Countries />
          </div>
        </section>
      </section>
    </div>
  );
};

export default Dashboard;
