import React, {useEffect, useState} from 'react'
import { Route, useLocation } from 'react-router-dom'
import Rooms from '../../../Reusables/Rooms/Rooms'
import HotelList from './Hotels/hotels'



const Manage = () => {
    const [user, setUser] = useState({})
    const [payload, setPayload] = useState({

    })
    const location = useLocation()

    
    const [open, setOpen] = useState(false);

    return (
        <div className="pt-4 px-lg-4 px-3">
        <header className="d-flex justify-content-between align-items-center dashHeader">
            <h4 className="text-primary">MANAGE HOTELS</h4>
            {/* <div className="add-hotel">
                <button className="btn btn-outline-warning" onClick={handleClick}>ADD HOTEL</button>
            </div> */}
        </header>
        <div className="admin-dashboard-top row mt-4">
            <Route exact path={["/dmc/hotels", "/dmc/hotels/lists" ]} component={HotelList} />
            <Route path="/dmc/hotels/rooms" component={Rooms} />
           
        </div>
        {/* <AddHotel open={open} handleRemove={handleRemove} /> */}
    </div>
    )
}

export default Manage


