import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TimestampConverter } from "../../../../controllers/controller";
import "./hotelContract.css";

const HotelContract = ({
  title,
  subtext,
  time,
  hotelID,
  matched,
  currency,
  name,
  handleBookContract,
  From,
  To,
  id,
  childFrom,
  childTo,
  contract,
  status,
  price,
  duration,
  handleSummary,
  handleContractEdit,
  noneAccountOwner,
  country,
  openAvailabilityModal,
  openMarkup,
  buttonLoad,
  ID,
  index,
  index2,
  dmc_name,
}) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className="w-100 mb-4">
      {/* <div className="actions mb-3 fw-bolder">
        <span>{new Date(time * 1000).toDateString()}</span>
      </div> */}
      <div className="card contract-card-wrapper shadow ">
        <div className="card-body">
          <div className="links-wrapper d-none d-sm-flex mb-3 d-flex justify-content-end">
            {/* <button className={`btn  btn-outline-primary me-4 contractHotel ${noneAccountOwner}`} onClick={() => handleBookContract(ID)}>Book</button>
            <Link to={`${matched}/detail`} className={`btn btn-outline-primary  me-4 contractHotel ${noneAccountOwner}`} onClick={() => handleSummary(ID)} >View</Link> */}
            <button className={`btn  btn-outline-primary  me-4 ${noneAccountOwner} contractHotel`} onClick={() => handleContractEdit(ID)} > Edit </button>
            <div> {buttonLoad === ID ? ( <button className={`btn me-4  btn-outline-primary contractHotel ${noneAccountOwner}`} onClick={() => openAvailabilityModal(ID)} > <span className="spinner-border text-primary" role="status" ></span> </button>
              ) : (
                <button
                  className={`btn me-4  btn-outline-primary contractHotel ${noneAccountOwner}`}
                  onClick={(e) => openAvailabilityModal(ID, e, index)}
                >
                  Availability
                </button>
              )}
            </div>
            <button className={`btn  btn-outline-primary contractHotel ${noneAccountOwner}`} onClick={() => openMarkup(ID)}>Markup</button>
          </div>
          <div className="justify-content-between topCard">
            <div
              className="card-title fw-bolder lead text-primary text-center card-details"
              dangerouslySetInnerHTML={{ __html: title === "" ? "N/A" : title }}
            />
            <div
              className={`text-center  d-flex  align-items-center card-details`}
            >
              <div className="card-p-wrap d-flex flex-column align-items-center justify-content-center">
                <p
                  className={`contract-small fw-bold text-center text-primary`}
                >
                  {country === "" ? "N/A" : country}
                </p>
                <p
                  className="contract-small text-center text-muted mb-0"
                  style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                >
                  Country
                </p>
              </div>
            </div>
            <div
              className={`text-center  d-flex  align-items-center card-details`}
            >
              <div className="card-p-wrap d-flex flex-column align-items-center justify-content-center">
                <p
                  className={`contract-small fw-bold text-center text-primary`}
                >
                  {dmc_name}
                </p>
                <p
                  className="contract-small text-center text-muted mb-0"
                  style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                >
                  Company
                </p>
              </div>
            </div>
            <div className="card-title fw-bolder text-primary text-center">
              {currency}
            </div>

            <div className="dropdown d-sm-none options">
              <div
                className="dropdown-toggle"
                type="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setDropdown(!dropdown)}
              >
                <i className="bi bi-three-dots-vertical"></i>
              </div>
              <ul
                className={`dropdown-menu ${dropdown ? "showdrop" : ""}`}
                aria-labelledby="dropdownMenuLink"
              >
                {/* <li>
                  <Link
                    className={`btn w-100 contractHotel mb-2 ${noneAccountOwner}`}
                    onClick={() => handleBookContract(ID)}
                  >
                    Book
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to={`${matched}/detail`}
                    className={`btn w-100 mb-2 d-block contractHotel ${noneAccountOwner}`}
                    onClick={() => handleSummary(ID)}
                  >
                    Summary
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={`btn w-100 contractHotel ${noneAccountOwner} border-bottom `}
                    onClick={() => handleContractEdit(ID)}
                  >
                    Edit
                  </Link>
                </li>
                <li>
                  {buttonLoad === ID ? (
                    <button
                      className={`btn  mx-auto  contractHotel ${noneAccountOwner}`}
                      onClick={() => openAvailabilityModal(ID)}
                    >
                      <span
                        className="spinner-border text-light"
                        role="status"
                      ></span>
                    </button>
                  ) : (
                    <Link
                      className={`btn w-100 contractHotel ${noneAccountOwner} border-bottom`}
                      onClick={() => openAvailabilityModal(ID)}
                    >
                      Availability
                    </Link>
                  )}
                </li>
                <li>
                  <Link
                    className={`btn w-100 contractHotel ${noneAccountOwner}`}
                    onClick={() => openMarkup(ID)}
                  >
                    Markup
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom">
            <div className="card-grid">
              <div className="hotelContractSumCard">
                <div
                  className={`text-center  d-flex  align-items-center card-details`}
                >
                  <div
                    className="card-p-wrap d-flex flex-column align-items-center justify-content-center"
                    style={{ height: "100px" }}
                  >
                    <p
                      className={`contract-small fw-bold text-center text-primary`}
                    >
                      {id}
                    </p>
                    <p
                      className="contract-small text-center text-muted mb-0"
                      style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                    >
                      Contract Id
                    </p>
                  </div>
                </div>
                <div
                  className={`text-center  d-flex  align-items-center card-details`}
                >
                  <div
                    className="card-p-wrap d-flex flex-column align-items-center justify-content-center"
                    style={{ height: "100px" }}
                  >
                    <p
                      className={`contract-small fw-bold text-center text-primary`}
                    >
                      {contract}
                    </p>
                    <p
                      className="contract-small text-center text-muted mb-0"
                      style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                    >
                      Contract name
                    </p>
                  </div>
                </div>
                <div
                  className={`text-center  d-flex align-items-center card-details`}
                >
                  <div
                    className="card-p-wrap d-flex flex-column align-items-center justify-content-center"
                    style={{ height: "100px" }}
                  >
                    <div>
                      <p
                        className={`contract-small fw-bold text-primary d-block mb-0`}
                      >
                        {childFrom} - {childTo} years
                      </p>
                      <p className={`contract-small fw-bold text-primary `}></p>
                    </div>
                    <p
                      className="contract-small text-center text-muted mb-0"
                      style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                    >
                      Child age
                    </p>
                  </div>
                </div>
                <div
                  className={`text-center  d-flex  align-items-center card-details`}
                >
                  <div
                    className="card-p-wrap d-flex flex-column align-items-center justify-content-center"
                    style={{ height: "100px" }}
                  >
                    <p className={`contract-small fw-bold text-primary`}>
                      {status}
                    </p>
                    <p
                      className=" contract-small text-muted mb-0"
                      style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                    >
                      status
                    </p>
                  </div>
                </div>
                <div
                  className={`text-center  d-flex align-items-center card-details`}
                >
                  <div
                    className="card-p-wrap d-flex flex-column align-items-center justify-content-center"
                    style={{ height: "100px" }}
                  >
                    <div>
                      <p
                        className={`contract-small fw-bold text-primary d-block mb-0`}
                      >
                        {TimestampConverter(From)} to {TimestampConverter(To)}
                      </p>
                      <p
                        className="contract-small text-center text-muted mb-0"
                        style={{ fontSize: "clamp(10px, 1.5vw, 14px)" }}
                      >
                        Duration
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelContract;
