import React from 'react'
import useFetch from '../../../../Reusables/Fetch/Fetch';
import Loading from "../../../../Reusables/Loader/Loading"
import AlertComp from "../../../../Reusables/AlertComp/AlertComp"
import OverrideCard from "../../DMC/Overrides/OverrideCard"
const Overrides = () => {
    const hc = localStorage.getItem("hotelChain");
    const uHc = JSON.parse(hc)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("token", uHc.usertoken);
    
    const {data,  error, loading, alert, setLoading, showAlert, setShowAlert} = useFetch("/hotel-owner-override-list", formData)
    const handleAlertClose = () => {
        setShowAlert(false);
      }
      
    console.log(data)
    return loading ? (<Loading />): (
        <div className="pt-4 px-lg-4 px-3">
            <header className="d-flex justify-content-between align-items-center dashHeader">
                <h4 className="lead mb-0 text-primary">Overrides</h4>
            </header>
            <div className="admin-dashboard-top row mt-4">
            {showAlert === true && data.message !== undefined && (
                <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
            )}
                    <div className="override">
                        {  data.length > 0 && data.map(item=>(
                            <OverrideCard item={item} />
                            ))}
                    </div>
            
            </div>
        </div>
    )
}

export default Overrides

