import Main from "./Admins/Reusables/Bookings/Statement/Main"
import { usePDF, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });
  

// export const MyDoc = (
//     // <Document>
//     // <Page size="A4" style={styles.page}>
//      <Main  />
//   //   </Page>
//   // </Document>
// );


const Pdf = ({name, data}) => {
  const MyDoc = (
    // <Document>
    // <Page size="A4" style={styles.page}>
     <Main data={data}  />
  //   </Page>
  // </Document>
);
  const [instance, updateInstance] = usePDF({ document: MyDoc });
  const  value = usePDF({ MyDoc })

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong:</div>;

  return (
    <a href={instance.url} download={`${name}.pdf`} className="btn btn-outline-danger mb-3" style={{fontSize:"12px"}}>
     Save as Pdf
    </a>
  );
}
export default Pdf