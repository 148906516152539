import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Meals from "../../../../Meals";
import Penalty from "../../../../Penalty";
import Offer from "./Offer/Offer";
import Supplement from "./Supplement/Supplement";
import PriceAvailability from "./PriceAvailability";
import TopTable from "./TopTable";
import MapRooms from "../Manage/Hotels/MapRooms";
import { editContractHeader, getContractData } from "../../../Super/apis";
import { useMutation } from "react-query"
import Distribution from "./Distribution";
import {debounce} from "lodash"

const NewContract = () => {
  const [contractTop, setContractTop] = useState({
    status: "",
    contract_name: "",
    child_age_From: "",
    child_age_To: "",
  });
  const [type, setType] = useState("")

  const headerMutation = useMutation(editContractHeader, {
    onSuccess: (res)=> console.log(res),
    onError: (err)=> console.log(err)
  })

  const debouncedUpload = debounce(async(e)=>{
    console.log("debounced")
    headerMutation.mutate({
       ...contractTop,
        [e.target.name]: e.target.value,
        apptoken: process.env.REACT_APP_APP_TOKEN,
        contractToken: token,
        contract_name: contractTop.contract_name,
      })

  }, 300)

  const handleFirstChange = async (e) => {
    setContractTop({ ...contractTop, [e.target.name]: e.target.value });
    const header = JSON.stringify(contractTop);
    localStorage.setItem("header", header);
    console.log({contractTop})

    console.log("getting debounced")
    debouncedUpload(e)
    
    // const CT = localStorage.getItem("CT");
    // const Country = localStorage.getItem("Count");
    // const City = localStorage.getItem("City");
    // const hotelname = localStorage.getItem("hotelname");
  };

  useEffect(() => {
    const header = localStorage.getItem("header");

    // FOR EDIT: bring in existing contract from localstorage
    const details = localStorage.getItem("contract_info");
    if (details) {
      const contract_info = JSON.parse(details);
      setContractTop({
        ...contractTop,
        contract_name: contract_info.contract_name,
        status: contract_info.status,
        child_age_From: contract_info.child_age_From,
        child_age_To: contract_info.child_age_To,
        currency: contract_info.currency,
      });

    } else {

      let conDetails = localStorage.getItem("contractDetail")
      let info = JSON.parse(conDetails)
      info.city && setType(info.type)
      if (header) {
        const head = JSON.parse(header);
        setContractTop({
          ...contractTop,
          contract_name: head.contract_name,
          child_age_From: head.child_age_From,
          child_age_To: head.child_age_To,
          status: head.status,
          currency: head.currency,
        });
      }
    }
  }, []);

  const mutation = useMutation(getContractData, {
    onSuccess: (res)=>{
      setType(res.data.data.type)
    },
    onError: (err)=>{
    }
  })

  const token = localStorage.getItem("CT");
  useEffect(()=>{
    
    if(token){
      mutation.mutate({
        apptoken: process.env.REACT_APP_APP_TOKEN,
        contract_token: token 
      })    
    }

  },[token])

  
  return (
    <div className="wrapper">
      <TopTable
        handleFirstChange={handleFirstChange}
        contractTop={contractTop}
      />
      <Tabs defaultActiveKey="dateplan" className="mb-3 title_font">
        <Tab eventKey="dateplan" title="PRICES">
          <PriceAvailability
            contractTop={contractTop}
            setContractTop={setContractTop}
          />
        </Tab>
        <Tab eventKey="meals" title="MEALS">
          <Meals contract={contractTop} />
        </Tab>
        <Tab eventKey="offer" title="OFFERS">
          <Offer contractTop={contractTop} />
        </Tab>
        <Tab eventKey="supplement" title="SUPPLEMENTS">
          <Supplement contract={contractTop} />
        </Tab>
        <Tab eventKey="cancellation" title="CANCELLATIONS">
          <Penalty contract={contractTop} />
        </Tab>
        <Tab eventKey="distribution" title="DISTRIBUTION">
          <Distribution />
        </Tab>
        { 
          type === "dynamic" &&
          <Tab eventKey="room" title="MAPPING">
            <MapRooms  />
          </Tab>
        }
      </Tabs>
    </div>
  );
};

export default NewContract;
