import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import { Fetch } from "../../../../../controllers/controller";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import HotelContext from "../Context/hotelcontext";
import { useMutation } from "react-query";
import { getExternalSources, mapDMCtoHotel } from "../../../Super/apis";

const AddDmc = ({ handleAddDmcClose, openDmcModal }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [hotelId] = useContext(HotelContext);

  useEffect(() => {
    const accowner = localStorage.getItem("accountOwner");
    if (accowner) {
      const useableAccOwner = JSON.parse(accowner);
      setPayload({ ...payload, usertoken: useableAccOwner.usertoken });

      getSourceMutation.mutate({
        apptoken:process.env.REACT_APP_APP_TOKEN,
        usertoken:useableAccOwner.usertoken
      })
    }
  }, []);

  const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    dmc: "",
    hoteltoken: hotelId,
    usertoken: "",
  });

  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const getSourceMutation = useMutation(getExternalSources, { onSuccess:(e)=>{
    console.log(e)
  }})
  const mapDMC = useMutation(mapDMCtoHotel,  {onSuccess:(e)=> {
    setAlertType({ ...alert, type: "success", value: e.data.message });
    setShowAlert(true)
    console.log(e)
  }, 
  onError:(e)=>{
    setAlertType({ ...alert, type: "danger", value: e.message });
    setShowAlert(true)
  }}
)

  const submitHotelOwner = (e) => {
    e.preventDefault()
    const data = {
      apptoken: process.env.REACT_APP_APP_TOKEN,
      usertoken: payload.usertoken,
      dmc_email: payload.email,
      hoteltoken: hotelId
    }
    
    if(payload.sourceid){
      const source = payload.sourceid.split("/")
      data.hotelcode  = payload.hotelcode
      data.source = source[1]
      mapDMC.mutate(data)
    } else {
      mapDMC.mutate(data)
    }
    
  };


  const handleAlertClose =() =>{
    setShowAlert(false);
  }
  return (
    <>
      <Modal show={openDmcModal} onHide={handleAddDmcClose}>
        {mapDMC.isLoading ? (
          <Loading />
        ) : (
          <div className="py-5 px-5 modals">
            <h4 className="text-center">Add DMC</h4>
            <Modal.Body className="mb-5">
              <form className="row justify-content-center">
                <div className="form-left col-md-6">
                  {showAlert === true && (
                    <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                  )}
                  <div className="status mb-3">
                    <label
                      htmlFor="email"
                      className="mb-2 text-secondary form-label"
                      style={{ fontSize: "12px" }}
                    >
                      DMC's email address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      onChange={handleChange}
                      required
                      value={payload.email}
                    />
                  </div>
                  {/* <div className="form-group mb-3">
                    <label htmlFor="source" className="form-label d-block" style={{ fontSize: "12px" }} >Source</label>
                    {getSourceMutation.isLoading ?  <div className={`spinner-border text-primary` } role="status" ></div> :   
                    <select name="sourceid" id="source" className="form-select" onChange={handleChange} value={payload.sourceid}>
                        <option value="">Select a source</option>
                        {getSourceMutation.data && getSourceMutation.data.data.sources.map(source=>(
                            <option key={source.id} value={source.id + "/" + source.name}>{source.name}</option>
                        ))}
                    </select>
                    }
                  </div> */}
                  {payload.sourceid && 
                    <div className="form-group mb-3">
                      <label htmlFor="hotelcode" className="form-label d-block" style={{ fontSize: "12px" }} >Hotel Code</label>
                      <input
                        type="text"
                        name="hotelcode"
                        id="hotelcode"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={payload.hotelcode}
                      />
                    </div>
                  }
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary"
                      onClick={submitHotelOwner}
                    >
                      Add DMC
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleAddDmcClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddDmc;
