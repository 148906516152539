import React from "react";

const Table = ({ children }) => {
  return (
    <div className="card">
    <div className="table-responsive">
      <table className="table table-striped table-bordered mytable">
        <thead className="table-primary">
          <tr>
            <th>S/N</th>
            <th style={{minWidth:"110px"}}>Timestamp</th>
            <th style={{minWidth:"110px"}}>Date From</th>
            <th style={{minWidth:"110px"}}>Date To</th>
            <th style={{minWidth:"110px"}}>Name</th>
            <th style={{minWidth:"110px"}}>Location</th>
            <th style={{minWidth:"110px"}}>Country</th>
            <th style={{minWidth:"110px"}}>City</th>
            <th style={{minWidth:"110px"}}>Price Adult</th>
            <th style={{minWidth:"110px"}}>Price Child</th>
            <th style={{minWidth:"110px"}}>Discount</th>
            <th style={{minWidth:"110px"}}>Discount From</th>
            <th style={{minWidth:"110px"}}>Discount To</th>
            <th style={{minWidth:"110px"}}>Min Pax Discount</th>
            <th style={{minWidth:"110px"}}>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
    </div>

  );
};

export default Table;
