import React from 'react'
import hamburger from "../../../../Images/Icons/ham.png";
const Header = ({handleClick, navButton}) => {
    return (
        <div className="top-nav navbar px-lg-4 px-3 d-flex justify-content-between">
            <div className="left d-flex align-items-center">
              <div className="hamburger" onClick={handleClick}>
                <img src={hamburger} alt="sidebar toggle" />
              </div>
              <p className="ms-2 text-primary mb-0 header_font">
                Home
              </p>
            </div>
            

          </div>
    )
}

export default Header
