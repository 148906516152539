import React, { useEffect, useState} from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import { mainGetUsertoken } from "../GetUsertoken/GetUsertoken";

// import "./modal.css";
const Modals = ({ handleShow, show, handleClose, setShow }) => {
    const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const history = useHistory();
  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");



  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);



  const handleOpen = (e) => {
    e.preventDefault();
    if (name !== "") {
      setLoading(true);
      const accowner = localStorage.getItem("accountOwner");
      const useableAccOwner = JSON.parse(accowner);


      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("dmctoken", useableAccOwner.usertoken);
      formData.append("package_name", name);
      formData.append("usertoken", usertoken);

      Fetch(`${process.env.REACT_APP_END_POINT}/create-package`, formData)
        .then((res) => {
          setLoading(false);
          if (res.data.success !== false) {
            setShow(false);
            localStorage.setItem("pkg", JSON.stringify(res.data));
            history.push("/account-owner/packages/create");
          }
          setAlertType({
            ...alert,
            type: "success",
            value: res.data.message,
          });
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      window.alert("Package name cannot be empty");
    }
  };

   

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="select-hotel"
        centered
      >
        <form className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">Create a Package</h4>
          </div>
          <Modal.Body className="mb-5">
            <div>
              {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
              <label
                htmlFor="name"
                className=" mb-2 text-secondary form-label"
                style={{ fontSize: "12px" }}
              >
                Package Name
              </label>
              <input type="text" name="name" id="name" className="form-control"  onChange={(e)=>setName(e.target.value)} value={name} />
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center mb-3">
            {loading ? (
              <div
                className="btn btn-lg btn-secondary btn-block"
                style={{ borderRadius: "0", padding: "11px 90px" }}
              >
                <div className="spinner-border text-light" role="status"></div>
              </div>
            ) : (
              <button
                className="btn btn-lg btn-secondary btn-block"
                style={{ borderRadius: "0", padding: "11px 90px" }}
                onClick={handleOpen}
              >
                Next
              </button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Modals;
