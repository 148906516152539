import React, { useEffect, useState, useContext } from "react";
import { Fetch } from "../../../../../controllers/controller";
import { useFormik } from "formik";
import Row from "./Row";
import RequestError from "../../../../404/RequestError";

const Meals = ({
  setAmount,
  setMealsTotal,
  amount,
  formik,
  bookInfo,
  setBookInfo,
}) => {
  const [loading, setLoading] = useState(true);
  const [filterItem, setFilterItem] = useState({});
  const [list, setList] = useState([]);
  const [discount, setDiscount] = useState({});
  // useEffect(()=>{
  //   setAmount(...amount, formik?.values.price.reduce((a,b)=>Number(a) + Number(b), 0))
  // },[formik?.values.price])

  const mealsFormik = useFormik({
    initialValues: {
      price: {},
    },
  });
  // console.log(discount);

  useEffect(() => {
    // get meals total and subtract discount if it exist
    if (Object.keys(mealsFormik.values.price).length > 0) {
      const obj = Object.values(mealsFormik.values.price);
      let total = obj.reduce((a, b) => Number(a) + Number(b), 0);
      // console.log(discount);
      if (discount.length > 0) {
        if (
          discount[0].discount_rate !== "undefined" &&
          discount[0].discount_rate !== "0"
        ) {
          let perc = (total * discount[0].discount_rate) / 100;
          setMealsTotal(total - perc);
        } else {
          setMealsTotal(total - discount[0].discount_amount);
          // console.log(discount[0].discount_amount);
        }
      } else {
        setMealsTotal(total);
      }
    }
  }, [mealsFormik.values.price]);

  useEffect(() => {
    const filter = localStorage.getItem("filter");
    const all = localStorage.getItem("booking");

    if (all && filter) {
      const item = JSON.parse(all);
      const dates = JSON.parse(filter);
      setFilterItem(dates);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_token", item.contract_id);
      formData.append("from", dates.start_date);
      formData.append("to", dates.end_date);

      Fetch(`${process.env.REACT_APP_NEND_POINT}/search-get-meals`, formData)
        .then((res) => {
          setLoading(false);
          if (res.data.success === false) {
            setList([]);
          } else if (res.data.length > 0) {
            setList(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);
  useEffect(() => {
    const filter = localStorage.getItem("filter");
    const all = localStorage.getItem("booking");

    if (all && filter) {
      const item = JSON.parse(all);
      const dates = JSON.parse(filter);
      setFilterItem(dates);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_token", item.contract_id);
      formData.append("from", dates.start_date);
      formData.append("to", dates.end_date);

      Fetch(
        `${process.env.REACT_APP_NEND_POINT}/search-get-offers-meals`,
        formData
      )
        .then((res) => {
          // console.log(res);
          if (res.data.success !== false) {
            setDiscount(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <div className="mt-3">
      <div className="d-flex  align-items-center justify-content-between">
        <div className="booking_option_title ">
          <p className="fw-bold">Meals</p>
        </div>
        {loading ? (
          <div className="w-100  d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form
            className="form booking_table"
            style={{ borderTop: "1px solid" }}
          >
            <div className="table-responsive ">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Adult</th>
                    <th>Child</th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 &&
                    list.map((item) => (
                      <>
                        {item.all_incl_adult === "" ||
                        item.all_incl_adult === "0" ? null : (
                          <Row
                            base={item.all_incl_adult}
                            child={item.all_incl_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="All Inclusive"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"all_incl_adult"}
                            child_title={"all_incl_child"}
                          />
                        )}
                        {item.breakfast_adult === "" ||
                        item.breakfast_adult === "0" ? null : (
                          <Row
                            base={item.breakfast_adult}
                            child={item.breakfast_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="Breakfast"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"breakfast_adult"}
                            child_title={"breakfast_child"}
                          />
                        )}
                        {item.fb_adult === "" ||
                        item.fb_adult === "0" ? null : (
                          <Row
                            base={item.fb_adult}
                            child={item.fb_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="FB"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"fb_adult"}
                            child_title={"fb_child"}
                          />
                        )}
                        {item.hb_adult === "" ||
                        item.hb_adult === "0" ? null : (
                          <Row
                            base={item.hb_adult}
                            child={item.hb_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="HB"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"hb_adult"}
                            child_title={"hb_child"}
                          />
                        )}
                        {item.rooms_only_adult === "" ||
                        item.rooms_only_adult === "0" ? null : (
                          <Row
                            base={item.rooms_only_adult}
                            child={item.rooms_only_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="Rooms Only"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"rooms_only_adult"}
                            child_title={"rooms_only_child"}
                          />
                        )}
                        {item.soft_all_incl_adult === "" ||
                        item.soft_all_incl_adult === "0" ? null : (
                          <Row
                            base={item.soft_all_incl_adult}
                            child={item.soft_all_incl_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="Soft All Inclusive"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"soft_all_incl_adult"}
                            child_title={"soft_all_incl_child"}
                          />
                        )}
                        {item.ultra_all_incl_adult === "" ||
                        item.ultra_all_incl_adult === "0" ? null : (
                          <Row
                            base={item.ultra_all_incl_adult}
                            child={item.ultra_all_incl_child}
                            formik={mealsFormik}
                            filterItem={filterItem}
                            name="Ultra All Inclusive"
                            bookInfo={bookInfo}
                            setBookInfo={setBookInfo}
                            adult_title={"ultra_all_incl_adult"}
                            child_title={"ultra_all_incl_child"}
                          />
                        )}
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </form>
        )}
      </div>
      {discount.length > 0 && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="booking_option_title " style={{ flex: "20%" }}>
            <p className="fw-bold">Offers</p>
          </div>
          <div
            className="d-flex justify-content-evenly py-3"
            style={{ flex: "80%", borderTop: "1px solid" }}
          >
            <p>Advance Booking</p>
            <p>{`- ${
              discount[0].discount_rate !== "undefined" &&  discount[0].discount_rate !== "0"
                ? discount[0].discount_rate + "%"
                : "$" + discount[0].discount_amount
            }`}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Meals;
