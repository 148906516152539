import React from "react";

const Row = ({ base, name, formik, filterItem, child, bookInfo, setBookInfo, adult_title, child_title  }) => {

  function handleInfoSet(e, name){
    // console.log(e.target.name, e.target.value)
    // formik.setValues({ ...formik.values, price: {...formik.values.price, [e.target.name ]: e.target.value} })


    let checked = e.target.checked;
    if( checked ){
      setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData, meals:{ ...bookInfo.bookingData.meals, [name]: e.target.value}}}) 
      formik.setValues({ ...formik.values, price: {...formik.values.price, [e.target.name ]: e.target.value} })

    } else {   
      setBookInfo({...bookInfo, bookingData:{ ...bookInfo.bookingData,
        meals:{ ...bookInfo.bookingData.meals, [name]: 0}
      }
      }) 
      formik.setValues({ ...formik.values, price: {...formik.values.price, [e.target.name ]: 0} })

    }
  }
  return (
    <tr>
      <td>{name}</td>
      <td></td>
      <td>
        <div>
          <span>$ {base * filterItem?.adult}</span>
          <span className="ms-2">
            <label htmlFor="adult_price" className="visually-hidden">
              Price
            </label>
            <input
              type="checkbox"
              name={adult_title}
              id="adult_price"
              onChange={(e)=>{
                // console.log(e.target.value)
              // formik?.handleChange(e)
              handleInfoSet(e, adult_title)
            }}
              value={base * filterItem?.adult}
            />
          </span>
        </div>
      </td>
      <td>
        <span>$ {child * filterItem?.child}</span>
        <span className="ms-2">
          <label htmlFor="adult_price" className="visually-hidden">
            Price
          </label>
          <input
            type="checkbox"
            name={child_title}
            id="adult_price"
            onChange={(e)=>{
              // formik?.handleChange(e)
              handleInfoSet(e, child_title)
            }}
            value={child * filterItem?.child}
          />
        </span>
      </td>
    </tr>
  );
};
export default Row;
