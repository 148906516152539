import axios from "axios";
import React, {useState, useEffect, useContext} from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";


import { Fetch } from "../../../../../controllers/controller";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import { BookingContext } from "../../Context/BookingContext";
import { getUsertoken, mainGetUsertoken } from "../../GetUsertoken/GetUsertoken";
import PayPal from "../../PaymentModal/PayPal";
import Stripe from "../../PaymentModal/Stripe";
import StripeChoice from "./StripeChoice";



const PayChoiceModal = ({ open, handleClose,  amount}) => {
  const [loading, setLoading] = useState(false)
  const [others, setOthers]=  useState(false)
  const [paypal, setPaypal] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPaypal, setShowPaypal] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [total, setTotal] = useContext(BookingContext)
  const [showAlert, setShowAlert] = useState(false);
  const [usertoken, setUsertoken] = useState("")
  const [user, setUser] = useState({})
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const location = useLocation();
  const [book, setBook] = useContext(BookingContext)

  const handleAlertClose = () => {
    setShowAlert(false);
  };
 
  
  function handlePaypal() {
    setShowPaypal(true);
  }

  React.useEffect(()=>{
    getUsertoken(location,  setUsertoken, setUser)
  }, []

  )
  async function addMoneyToDB(){
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/book-card`,  JSON.stringify(book.options), {
        "content-type":"application/json"
      })
      setShowAlert(true);
      setAlertType({ ...alert, type: "success", value: response.data.message });

    
    } catch (error) {
      console.error(error)
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: error.message });
    } finally {
      setLoading(false)
    }
  }
  
  function closeModal(){
    setOpenModal(!openModal)
  }
  return (

    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header>
        <h3 className="text-dark">SELECT PAYMENT OPTION</h3>
      </Modal.Header>
      <Modal.Body>
        {showAlert === true && (
          <AlertComp
            variant={alert.type}
            alertText={alert.value}
            handleAlertClose={handleAlertClose}
          />
        )}
        <div className="d-flex justify-content-center" style={{gap:"1rem"}}>
          {loading ? <Loading /> : (
            <>
              <button className="btn btn-outline-primary px-3 py-2 fw-bold" style={{lineHeight:"34px"}} onClick={()=>setOpenModal(!openModal)}>
                Pay with Wallet
              </button>
              <button onClick={()=> setOthers(!others)} type="button" className="btn btn-outline-secondary px-3 py-2 fw-bold" style={{lineHeight:"34px"}}>
                Other Payment Options
              </button>
            </>
            )}
        </div>

        {others &&
        <div className="row">
            <div className="col-12 text-center my-4 justify-content-center">
              {paypal && 
              <div className="d-flex-justify-content-center">
                <PayPal amount={amount} addMoneyToDB={addMoneyToDB} setLoading={setLoading} setAlertType={setAlertType} setShowAlert={setShowAlert} />                      
              </div>
              }
              {!paypal && 
              <button
              className="btn btn-outline-primary mx-auto w-50 mb-3"
              onClick={(e) => {
                e.preventDefault();
                setStripe(false);
                setPaypal(true);
                setShowStripe(false);
              }}
              >
                Pay with PayPal
              </button>
              }
          </div>
          <div className="col-12 d-flex justify-content-center ">
            {stripe && 
            <StripeChoice setLoading={setLoading} setShowAlert={setShowAlert} setAlertType={setAlertType} email={user.mail} amount={amount} /> 
            }
             {
            !stripe &&     
              <button
              className="btn btn-outline-warning w-50 mx-auto"
              onClick={(e) => {
                  e.preventDefault();
                  setShowPaypal(false);
                  setPaypal(false);
                  setStripe(true);
                }}
              >
                Pay with Stripe
              </button>
            }
          </div>
     </div>
     
      }
       {openModal &&  <ConfirmModal openModal={openModal} closeModal={closeModal} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PayChoiceModal;

function ConfirmModal({openModal, closeModal}){
  const [book, setBook] = useContext(BookingContext)
  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("")
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  useEffect(()=>{
    mainGetUsertoken(location, setUsertoken, setUser)
  },[])

  useEffect(() => {
    if(usertoken){
      setLoading(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("usertoken", usertoken);
    
    Fetch(`${process.env.REACT_APP_END_POINT}/wallet-balance`, formData)
      .then((res) => {
        setBalance(res.data.walletbalance);
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: err.message,
        });
      });
    }
  }, [usertoken]);


  const handleAlertClose = () => {
    setShowAlert(false);
  };
  async function bookRoom(e){
      e.preventDefault()
      try {
        setLoading(true)
        const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/book-wallet`,  JSON.stringify(book.options), {
          "content-type":"application/json"
        })
        setShowAlert(true);
        setAlertType({ ...alert, type: "success", value: response.data.message });

      
      } catch (error) {
        console.error(error)
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: error.message });
      } finally {
        setLoading(false)
      }
      
  }

  return(
    <Modal show={openModal} onHide={closeModal} centered>
      <Modal.Header className="text-center">
        <h5 className="text-dark mx-auto">Pay With Your Hotelsoffline Wallet</h5>
      </Modal.Header>
      <Modal.Body>
        {showAlert === true && (
          <AlertComp
            variant={alert.type}
            alertText={alert.value}
            handleAlertClose={handleAlertClose}
          />
        )}
        {loading ? <Loading /> :(
          <>
        <div className="text-center" style={{lineHeight:"30px"}}>
          <p>Your wallet balance is <span className="fw-bold">{balance}</span></p>
          <p>Deduct <span className="fw-bold">{book.total}</span> from wallet balance ?</p>
        </div>
                
        <div className="d-flex justify-content-around mt-4">
          <button className="btn btn-dark btn-lg" onClick={closeModal}>No</button>
          <button className="btn btn-primary btn-lg" disabled={balance < book.total} onClick={bookRoom}>Yes</button>
        </div>
          </>
        )}
      </Modal.Body>
      </Modal>
  ) 
}