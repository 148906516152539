import React, { useState } from "react";
import { Link, NavLink, Route, Switch, useLocation } from "react-router-dom";
import ContractModal from "./Modal/ContractModal";
import Modals, { ChoiceModals, DynamicModal } from "./Modal/Modal";
import Completed from "./Status/Completed";
import "./contracts.css";
import Cancelled from "./Status/Cancelled";
import Allcontracts from "./Status/AllContracts";
import ContractDetails from "../../../Reusables/contractDetails/ContractDetails";
import NewContract from "./NewContract";
import { Fetch } from "../../../../../controllers/controller";
import Mealsmodal from "./Modal/Mealsmodal";
import Penaltymodal from "./Modal/Penaltymodal";
import ContractFirstModal from "./ContracFirstModal";
import ContractItem from "./Modal/StaticContract";
import RequestError from "../../../../404/RequestError";

const Contracts = ({ match }) => {
  const [show, setShow] = useState(false);
  
  const location = useLocation();
  const [showChoice, setShowChoice] = useState(false);
  const handleShowSelect = () => setShowChoice(true);
  const [showDynamicModal, setShowDynamicModal] = useState(false);
  
  
  const handleClose = () => setShow(false);
  
  function handleCloseChoice(){
    setShowChoice(false)
  }
  const handleShow = () => {
    setShow(true)
    setShowChoice(false)
  };


  function handleCloseDynamicModal (){
    setShowDynamicModal(false)
  }
  
  function handleOpenDynamic (){
    setShowDynamicModal(true)
    setShowChoice(false)
  }

  function createDynamicContract(){
    // create dynamic contract
  }

  // rooms modal
  const [open, setOpen] = useState(false);
  const handleRemove = () => setOpen(false);

  const handleOpen = () => {
    const formData = new FormData();
    const accowner = localStorage.getItem("DMC");
    const hotelId = localStorage.getItem("hotel");

    const useableAccOwner = JSON.parse(accowner);
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("hoteltoken", hotelId);
    Fetch( `${process.env.REACT_APP_END_POINT}/create-contract`, formData)
      .then((res) => {
        if (res.data.success !== false) {
          localStorage.setItem("CT", res.data.contractToken);
          setShow(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <main className="pt-4 px-lg-4 px-3"  style={{ minHeight: "83vh" }}>
      <header className=" mb-3 d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">CONTRACTS</h4>
        {/* <button
          className={
            location.pathname === "/dmc/contracts/create"
              ? "d-none"
              : `btn btn-lg-lg btn-primary contract-btn`
          }
          // onClick={handleShow}
          onClick={handleShowSelect}
        >
          <span className="button-text me-2 ">CREATE CONTRACT</span>
          <span>
            <i className="bi bi-plus-circle"></i>
          </span>{" "}
        </button> */}
      </header>

          <RequestError>
          <div className="content">
            <Route
              exact
              path={["/dmc/contracts", "/dmc/contracts/all"]}
              component={Allcontracts}
            />
            <Route path="/dmc/contracts/completed" component={Completed} />
            <Route path="/dmc/contracts/cancelled" component={Completed} />
            <Route path="/dmc/contracts/create/meals" component={Mealsmodal} />
            <Route
              path="/dmc/contracts/create/cancellation"
              component={Penaltymodal}
            />
            <Route path="/dmc/contracts/create/contract" component={ContractItem} />
            <Route exact path="/dmc/contracts/create" component={NewContract} />
            <Route path="/dmc/contracts/detail" component={ContractDetails} />
          </div>

              </RequestError>
      <Modals
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        handleOpen={handleOpen}
        setShow={setShow}
      />
      <ChoiceModals
       handleShowSelect={handleShowSelect}
       handleCloseChoice={handleCloseChoice}
       showChoice={showChoice}
       setShowChoice={setShowChoice}
       handleOpen={handleShow}
       handleOpenDynamic = {handleOpenDynamic}
      />
      <DynamicModal
        showDynamicModal = {showDynamicModal}
        setShowDynamicModal = {setShowDynamicModal}
        handleCloseDynamicModal = {handleCloseDynamicModal}
        handleOpenContract = {createDynamicContract}
      />
    </main>
  );
};

export default Contracts;
