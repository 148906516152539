import React, {useState, useEffect, useRef} from "react";
import { Modal } from "react-bootstrap";
import { Fetch } from "../../../../../controllers/controller";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import { useGeneral } from "../Context/generalContext";
import "./AddHotel.css"

const AddHotel = ({ handleRemove, open }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  

  const mapRef = useRef(null)
  const searchInput = useRef(null)

  const{initMap} = useGeneral()

  let map, geocoder, marker, autocomplete 

  function onChangeAddress(autocomplete){
    const location = autocomplete.getPlace()
    let latLng = location.geometry.location

    map.setCenter(latLng);
    marker.setPosition(latLng);
    marker.setMap(map);
  }

  function initializeAutoComplete() {
    if(!searchInput.current || !mapRef.current) return
    
    // init geocoder
    geocoder = new window.google.maps.Geocoder();
    
    // init map
    map = new window.google.maps.Map(
        mapRef.current,
        {
          zoom: 18,
          center: { lat: -34.397, lng: 150.644 },
        }
    );
    // init marker
    marker = new window.google.maps.Marker({
      map,
    });
    // init autocomplete
  
    autocomplete =  new window.google.maps.places.Autocomplete(searchInput.current)
    map.addListener("click", (e) => {
      geocoder.geocode({ location: e.latLng });
      console.log(e)
    });

    autocomplete.setFields(["address_component", "geometry"])
    autocomplete.addListener("place_changed", ()=> onChangeAddress(autocomplete))
  }

  const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    hotelname: "",
    description: "",
    file: null,
    location:"",
    usertoken: ""

  })
  useEffect(() => {
    // initMap().then(()=> initializeAutoComplete())
    const accowner = localStorage.getItem("accountOwner");
    if (accowner) {
      const useableAccOwner = JSON.parse(accowner);
      setPayload({...payload, usertoken: useableAccOwner.usertoken})
    }
   
  }, [])

  const handleImages = (e) =>{
      setPayload({...payload, file: e.target.files[0]})
  }
  const handleChange = (e) => { 
    setPayload({...payload, [e.target.name]: e.target.value})
  }
  
  const submitHotel = (e) => {
    const formData = new FormData()
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN )
    formData.append("creator_token", payload.usertoken )
    formData.append("hotelname", payload.hotelname)
    formData.append("description", payload.description)
    formData.append("file", payload.file)
    formData.append("location", payload.location)
    setLoading(true)

    Fetch(`${process.env.REACT_APP_NEND_POINT}/add-new-hotel`, formData)
    .then( (res) => {
    setLoading(false);
    setShowAlert(true);
    setAlertType({ ...alert, type: "success", value: res.data.message });

  })
  .catch((err) => {
    setLoading(false);
    setShowAlert(true);
    setAlertType({ ...alert, type: "danger", value: err.message });
  });
  }
    const handleAlertClose =()=>{
      setShowAlert(false);
    }
  return  (
    <>
      <Modal show={open} onHide={handleRemove}>

        {loading ? (<Loading />):(

          <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold text-center">Add Hotel</h4>
          </div>
          <Modal.Body className="mb-5">
                <form className="row justify-content-center">
                  <div className="form-left col-md-8">
                  {showAlert === true && (
                        <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                        )}
                    <div className="status mb-3">
                      <label htmlFor="hotelname" className="mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >
                        Hotel name
                      </label>
                      <input type="text" name="hotelname" id="hotelname" className="form-control" onChange={handleChange}  required value={payload.hotelname} />
                    </div>
                    <div className="description mb-3">
                      <label htmlFor="description" className="mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >
                        Desciption
                      </label>
                    <textarea name="description" id="description" cols="30" rows="5" className="form-control" value={payload.description} onChange={handleChange} required ></textarea>
                    </div>
                    <div className="location mb-3">
                      <label htmlFor="location" className="mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >
                        Location here
                      </label>

                      <input type="text" className="form-control" ref={searchInput} name="auto" id="auto" />
                        {/* // value={payload.location} onChange={handleChange} */}
                       {/* /> */}
                      <div id="map" ref={mapRef} style={{width: "100%", height: "480px"}} className="mt-4"></div>
                    </div>
                    <div className="price mb-3">
                      <div >
                        <label htmlFor="hotelImg"  className="mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >Select image</label>
                        <input type="file" name="hotelImg" id="hotelImg" onChange={handleImages} />
                      </div>
                    </div>
                  </div>
                </form>
            
            </Modal.Body>
          <div className="d-flex mb-3">
            <button className="btn btn-lg btn-primary mb-2 w-50 m-auto" style={{  padding: "11px" }} onClick={submitHotel} >
              Add
            </button>
            <button className="btn btn-lg btn-secondary mb-2 w-50 m-auto" style={{  padding: "11px" }} onClick={handleRemove} >
              Add
            </button>
            </div>
        </div>
      )}
      </Modal>
    </>
  );
};

export default AddHotel;
