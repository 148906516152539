
import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../../../controllers/controller";
import ContractDate from "../../../../../allContract";
import ReadOnlyContract from "../../../../../ReadOnlyContract";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../../Reusables/Loader/Loading";

const ContractItem = () => {
    const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [edit, setEdit] = useState(null);
  const [update, setUpdate] = useState([]);
  const [currentContract, setCurrentContract] = useState([]);
  const handleEdit = (e, index) => {
    setEdit(index);
   
  };

//   const handleFormUpdate = (e, index) => {
//     // setUpdate({ ...update, [e.target.name]: e.target.value });
//   };
  const handleFormUpdate = (e, index) => {
    
    const clonedContract = [...update];
    clonedContract[index][e.target.name] = e.target.value;
    setUpdate(clonedContract);
  };


  const [allMeals, setAllMeals] = useState([]);
  
  useEffect(() => {
    let mounted = true;
    setLoading(true)
    const stdate = localStorage.getItem("dates");
    const use = JSON.parse(stdate);
   
    const start = new Date(use.start_date * 1000).toLocaleDateString();
    const end = new Date(use.exp_date * 1000).toLocaleDateString();
   
    const CT = localStorage.getItem("CT");

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("start_date", start);
    formData.append("exp_date", end);
    formData.append("token", CT);

    Fetch(
      `${process.env.REACT_APP_END_POINT}/list-date-plan-details`,
      formData
    )
      .then((res) => {
       
        if (mounted) {
            setLoading(false)
            setUpdate(res.data)
        //   setAllMeals(res.data);
        //   setMeals([...allMeals]);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      mounted = false;
    };
  }, []);

 
  const handleDatePlanUpdate = (e, index) => {
     
      e.preventDefault();
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("roomtoken", update[index].roomtoken);
    formData.append("hoteltoken", update[index].hoteltoken);
    formData.append("token", update[index].contractToken);
    formData.append("dmctoken", update[index].dmctoken);
    formData.append("price", update[index].price);
    formData.append("contract_name", update[index].contract_name);
    formData.append("avail_room_num", update[index].availnum);
    formData.append("exp_date", update[index].expiry_date);
    formData.append("start_date", update[index].start_date);
    formData.append("status", update[index].status);
    formData.append("child_age_From", update[index].child_age_From);
    formData.append("child_age_To", update[index].child_age_To);
    formData.append("currency", update[index].currency);
    formData.append("occupy_min", update[index].occupy_min);
    formData.append("occupy_max", update[index].occupy_max);
    formData.append("occupy_min_child", update[index].occupy_min_child);
    formData.append("occupy_max_child", update[index].occupy_max_child);
    formData.append("release_date", update[index].release_date);
    setLoading(true);
    Fetch(
      `${process.env.REACT_APP_END_POINT}/date-plan-update`,
      formData
    )
      .then((res) => {
       
        setLoading(false);
        setShowAlert(true);
        // setReload(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
        
      })
    }
    const handleAlertClose = () =>{
      setShowAlert(false);
    }
    return(
        <div>
               {loading ? (
          <Loading />
        ) : (
            <div className="table-responsive mytable">
                {showAlert === true && (
                <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                )}
                <table className="table table-bordered border-dark ">
                    <thead>
                    <tr>
                    <th scope="col" rowSpan="2" className=" primary">
                        TIME STAMP
                    </th>
                    <th colSpan="2" scope="colgroup" className="text-center  success">
                        DATE
                    </th>
                    <th scope="col" rowSpan="2" className=" primary">
                        ROOM
                    </th>
                    <th colSpan="6" scope="colgroup" className="text-center  success">
                        PRICE
                    </th>
                    
                    <th colSpan="2" scope="colgroup" className="text-center">
                        INVENTORY
                    </th>

                    <th scope="col" rowSpan="2">
                        ACTION
                    </th>
                    </tr>

                    <tr>
                    <th scope="col" className=" success">
                        FROM
                    </th>
                    <th scope="col" className=" success">
                        TO
                    </th>

                    <th scope="col">SGL</th>
                    <th scope="col">DBL</th>
                    <th scope="col">TRL</th>
                    <th scope="col">QUD</th>
                    <th scope="col">CHD1</th>
                    <th scope="col">CHD2</th>

                   

                    <th scope="col">ROM</th>
                    <th scope="col">REL</th>

                    
                    </tr>
                </thead>
                <tbody>
                    {update.length > 0
                    ? update.map((update, index) =>
                        edit === update.contract_id ? (
                        <ContractDate
                            timeStamp={update.timestamp}
                            roomName={update.room_name}
                            price={update.price}
                            index={index}
                            contract={update}
                            CT={update.contract_id}
                            roomId={update.roomid}
                            token={update.hotelid}
                            handleChange={(e)=>handleFormUpdate(e, index)}
                            roomNum={update.availnum}
                            handleUpdate={handleDatePlanUpdate}
                            from={update.start_date}
                            to={update.expiry_date}
                            occupyMax={update.occupy_max}
                            occupyMin={update.occupy_min}
                            occupyMaxChild={update.occupy_max_child}
                            occupyMinChild={update.occupy_min_child}
                            release={update.release_date}
                            editable={true}
                        />
                        ) : (
                        <ReadOnlyContract
                            timeStamp={update.timestamp}
                            roomName={update.room_name}
                            price={update.price}
                            contract={update}
                            CT={update.contract_id}
                            roomId={update.roomid}
                            token={update.hotelid}
                            roomNum={update.availnum}
                            
                            handleEdit={handleEdit}
                            from={update.start_date}
                            to={update.expiry_date}
                            occupyMax={update.occupy_max}
                            occupyMin={update.occupy_min}
                            occupyMaxChild={update.occupy_max_child}
                            occupyMinChild={update.occupy_min_child}
                            release={update.release_date}
                            
                        />
                        )
                    )
                    : ""} 
                        
                    </tbody>
                </table>
            </div>
        )}
        </div>

    )
}

export default ContractItem;

