import React, {useState, useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Fetch, TimestampConverter } from '../../../../../../controllers/controller';
import ContractCal from '../Calendar/ContractCal';
import DetailsModal from './DetailsModal';

const ContractCalModal = ({show, handleClose, value, setShow}) => {
    // const [room, setRooms]= useState([1,2]);
    const [selectedDate, setSelectedDate]= useState("")
    const [details, setDetails]= useState({})
    const [showModal, setShowModal] = useState(false);
    const [loadCModal, setLoadCModal] = useState(false);
    const closeModal = () => {
        setShowModal(false)
    }
    const token = localStorage.getItem("cont");
    const [showAlert, setShowAlert] = useState(false);


const handleDate = (date, index) => {
    setSelectedDate(date)
    console.log('handleDate', date.getDate())
    const day = date.getDate();
    const month = date.getMonth() +1;
    const year = date.getFullYear();
    setLoadCModal(true)
    setShowModal(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN)
    formData.append("token", token);
    formData.append("id", value[index].id);
    formData.append("roomtoken", value[index].roomtoken);
    formData.append("timestamp", `${month}/${day}/${year}`);
    formData.append("start_date", `${month}/${day}/${year}`);
    formData.append("end_date",  `${month}/${day}/${year}`);
    // formData.append("timestamp", new Date(date).getTime() / 1000);
    // formData.append("start_date", TimestampConverter(value[index].start_date));
    // formData.append("start_date", TimestampConverter(value[index].start_date));
    // formData.append("start_date", Math.floor(new Date(date).getTime() / 1000) );
    // formData.append("end_date",  Math.floor(new Date(date).getTime() / 1000));
    Fetch(`${process.env.REACT_APP_NEND_POINT}/get-contract-date-data`, formData)
    .then((res)=>{
        setLoadCModal(false)
        if(res.data.success === false){
            // Alert value is already hardcoded
            setShowAlert(true)

            setDetails({})

        } else {
            setDetails(...res.data)
            setShowAlert(false)
        }
        })
        .catch((err) => {
            window.alert(err.message)
            setLoadCModal(false)
        })
}

    const closeAlert = () => {
        setShowAlert(false)
    }


    console.log("vl", value)
    const hideModal = {
        position: "absolute",
        right: "1rem",
        top:".5rem",
        zIndex:"20002"
    }
    return (
        <Modal  show={show} onHide={handleClose} centered>
            <div className="d-flex d-sm-none justify-content-end mt-2" style={hideModal} onClick={()=>setShow(false)}><i className="bi bi-x-circle text-muted"  onClick={()=>setShow(false)}></i></div>
            <Modal.Body>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                {value.length > 0 && value.map((room, index)=>(
                    <ContractCal roomObject={room} room={room.room_category} price={room.room_price} all={room} index={index} handleDateClick={handleDate} detail={details} />
                ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
            <DetailsModal selectedDate={selectedDate}  closeCalendarModal={()=>setShow(false)} closeAlert={closeAlert} alert={showAlert} showModal={showModal} loader={loadCModal} reload={showModal}  closeModal={closeModal} value={details} />
        </Modal>
    )
}

export default ContractCalModal
