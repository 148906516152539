import React from 'react'
import DashboardCard from '../../../Super/Dashboard/DashboardCard/DashboardCard'
import { TravellerCard } from '../../../Super/superData/data'

const Card = () => {
    return (
        <div className="card-row">
        {TravellerCard.map(card=>(
            <DashboardCard key={card.id} wallet={card.wallet} text={card.color} title={card.title} footer={card.footer} juniorLogo={card.icon} juniorFlex="justify-content-between flex-sm-row" amount={card.amount} topImage="d-none"  juniorColor={card.color} juniorBackground={card.background}/>
        ))}
    </div>
    )
}

export default Card
