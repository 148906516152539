import React, { useEffect } from 'react'
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { TimestampConverter } from '../../../../../controllers/controller';
import AlertComp from '../../../../Reusables/AlertComp/AlertComp';
import Countries from '../../../../Signup/Countries/Countries'
import { mainGetUsertoken } from '../../../Reusables/GetUsertoken/GetUsertoken';
import { addContractDistribution, deleteDist, listDistribution } from '../../../Super/apis';

const Distribution = () => {
    const [payload, setPayload] = useState("")
    const CT = localStorage.getItem("CT");
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    
    const [distribution, setDistribution] = useState([])
    const changeHandler = (value) => {
        setPayload(value);
    };

    const location = useLocation();
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser)
    
    },[])

    const handleAlertClose = () => {
        setShowAlert(false);
      };
    const mutation = useMutation(addContractDistribution, {
        onSuccess: (res)=>{
            console.log({res})

            if(!res.data.success) {
                setShowAlert(true);
                setAlertType({
                ...alert,
                type: "danger",
                value: res.data.message,
                });

                return
            } 
            fetchDist.mutate({token:CT})
            setShowAlert(true);
            setAlertType({
                ...alert,
                type: "success",
                value: res.data.message,
            });


        },
        onError: (err)=>{
            console.error(err)
            setShowAlert(true);
            setAlertType({
            ...alert,
            type: "danger",
            value: err.message,
            });

        }
    })
    const fetchDist = useMutation(listDistribution, {
        onSuccess: (res)=>{
            if(res.data?.length > 0) {
                setDistribution(res.data)
                return
            } 
            setShowAlert(true);
            setAlertType({
                ...alert,
                type: "danger",
                value: res.data.message,
            });
        },
        onError: (err)=>console.error(err)
    })

    function handleSubmit(e){
        e.preventDefault();
        mutation.mutate({
            apptoken: process.env.REACT_APP_APP_TOKEN,
            country: payload.label,
            contractToken:CT
        })

    } 

    useEffect(()=>{
        if(CT){
            fetchDist.mutate({token:CT})
        }

    },[CT])




  return (
    <div className="mt-4">
        <div className="row">
        {showAlert === true && (
            <AlertComp
                variant={alert.type}
                alertText={alert.value}
                handleAlertClose={handleAlertClose}
            />
        )}
            <div className="col-md-4">
                <form action="" className="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="countries" className="form-label my-3">Choose a country</label>
                        <Countries
                            changeHandler={changeHandler}
                            value={payload.countries}
                        />    
                    </div>    
                    <button className="btn btn-primary mt-3">
                        {
                            mutation.isLoading ? <div className="spinner-border text-white">
                                <div className="visually-hidden">Loading...</div>
                            </div>
                            :
                            <span>Submit</span>
                        }
                    </button>
                </form>
            </div>

            <div className="table-responsive mt-5">
                {
                    fetchDist.isLoading ?

                    <div className="spinner-border text-primary">
                        <div className="visually-hidden">Loading</div>
                    </div>
                    :
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Date</th>
                                <th>Country</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                distribution?.filter(item=>item.contract_token === CT).map((item, i)=>(
                                    <DistList item={item} i={i} fetchDist={fetchDist} />
                                ))
                            }
                        </tbody>
                    </table>

                }
            </div>
            
        </div>

    </div>
  )
}

function DistList({item, i, fetchDist}){

    const location = useLocation();
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")
    const CT = localStorage.getItem("CT");



    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser)
    
    },[])



    const deleteMutation = useMutation(deleteDist, {
        onSuccess: (res)=>{
            console.log(res)
            fetchDist.mutate({token:CT})
        },
        onError: (err)=>console.error(err)
    })


    function deleteDistribution(e, token){
        if(window.confirm("Are you sure you want to delete this distribution")){
            deleteMutation.mutate({did: token, usertoken})
        } 
    }
    return (
        <tr >
            <td>{i + 1}</td>
            <td>{TimestampConverter(item.timestamp)}</td>
            <td>{item.country}</td>
            <td>
                {
                    deleteMutation.isLoading ? 
                    <div className="spinner-border text-danger p-1">
                        <div className="visually-hidden">Loading</div>
                    </div>
                    :
                    <button className='bg-danger p-1 text-white rounded border' onClick={(e)=>deleteDistribution(e, item.id)}>Delete</button>
                }
            </td>
        </tr>
    )
}

export default Distribution