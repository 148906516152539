import React, { useState } from 'react'
import { Fetch } from '../../controllers/controller'
import AlertComp from '../Reusables/AlertComp/AlertComp'
import Loading from '../Reusables/Loader/Loading'
import {Link, useParams} from "react-router-dom"
import { useEffect } from 'react'
const Activate = () => {
    
    const { token } = useParams()
    const [payload, setPayload] = useState({
        apptoken: process.env.REACT_APP_APP_TOKEN,
        code: token
    })

    useEffect(() => {
        let mounted = true;

        const formData = new FormData ();
        formData.append("apptoken", payload.apptoken)
        formData.append("code", payload.code)
        if(payload.code !== "") {
            setLoading(true)
            Fetch(`${process.env.REACT_APP_NEND_POINT}/activate-user`, formData)
            .then(res=>{
                if(mounted) {
                    setLoading(false)
                    setShowAlert(true)
                    setAlertType({...alert, type: "success", value: res.data.message})
                }
            })
            .catch(err=>{
                console.log(err)
                if(mounted) {
                    setLoading(false)
                    setShowAlert(true)
                    setAlertType({...alert, type: "danger", value: err.message})
                }
          
            })
        } else {
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: "All fields are required"})

        }
        return () => {
            mounted = false
        }
    }, [])
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: ""
    })

    const handleChange = (e) => {
        setPayload({...payload, code: e.target.value})
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData ();
        formData.append("apptoken", payload.apptoken)
        formData.append("code", payload.code)
        if(payload.code !== "") {
            setLoading(true)
            Fetch(`${process.env.REACT_APP_NEND_POINT}/activate-user`, formData)
            .then(res=>{
                setLoading(false)
                setShowAlert(true)
                setAlertType({...alert, type: "success", value: res.data.message})
            })
            .catch(err=>{
            setLoading(false)
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: err.message})
          
            })
        } else {
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: "Cannot find token"})

        }
    }
    return loading ? (<Loading />): (
        <div className="container" style={{height: "100vh"}}>
            <div className="row h-100 justify-content-center align-items-center">
                <div className="content">
                {showAlert === true && (
                    <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    />
                )}
                <div className="text">
                    <p className="text-center lead">Proceed to {" "}
                    <Link to="/login">
                    login

                    </Link>
                    
                    </p>
                </div>
                </div>
            
            </div>
        </div>
    )
}

export default Activate
