import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import NoBookings from "./NoBookings";
import Loading from "../../../Reusables/Loader/Loading";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import BookingCards from "./BookingCards";

// Example items, to simulate fetching from another resources.

function Items({ currentItems, showAlert, loading, handleAlertClose }) {
  return (
    <>

    {loading ? (
        <Loading />
        ) : currentItems.length > 0 && (
            currentItems.filter(item => item.roomtoken !== "0").map((filterTerm) => (
        <BookingCards
        hotelname={filterTerm.hotelname}
        price={filterTerm.price_sgl}
        location={filterTerm.country}
        description={filterTerm.room_desc}
        roomname={filterTerm.room_name}
        id={filterTerm.roomtoken}
        amenities={filterTerm.room_category}
        contract_id={filterTerm.contract_id}
        info={filterTerm}
        />
        ))
        ) 
    }

    </>
  );
}

function PaginatedItems({list, loading, showAlert, alert, handleAlertClose, itemsPerPage }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(list.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(list.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, list]);
  console.log("list", list)
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} loading={loading} showAlert={showAlert} alert={alert} handleAlertClose={handleAlertClose} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="previous"
        renderOnZeroPageCount={null}
        activeClassName="activeClassName"
        pageClassName="pageClassName"
        containerClassName="paginateWrapper"
        previousLinkClassName="previousLinkClassName"
        nextLinkClassName="nextLinkClassName"
        previousClassName="previousClassName"
        nextClassName="nextClassName"
        disabledClassName="disabledClassName"

      />
    </>
  );
}
export default PaginatedItems