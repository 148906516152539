import React, {useEffect, useState} from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Rooms from '../../../Reusables/Rooms/Rooms'
import HotelList from './Hotels/hotels'
import {AddHotel} from "../../../Junior/Manage/Hotels/AddExternalHotel"



const Manage = () => {
    const [user, setUser] = useState({})
    const [payload, setPayload] = useState({

    })
    
   
    
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true)
    const handleRemove = () => setOpen(false);
    return (
        <div className="pt-4 px-lg-4 px-3  bg-light">
        <header className="d-flex justify-content-between align-items-center dashHeader">
            <h4 className="text-primary">MANAGE HOTEL</h4>
        </header>
        <div className="admin-dashboard-top row mt-5">
            <Route exact path={["/hotel-owner/hotels", "/hotel-owner/hotels/lists" ]} component={HotelList} />
            <Route path="/hotel-owner/hotels/rooms" component={Rooms} />
            <Route path="/hotel-owner/hotels/edit" component={AddHotel} />
        </div>
        {/* <AddHotel open={open} handleRemove={handleRemove} /> */}
    </div>
    )
}

export default Manage


