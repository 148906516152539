import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";
import { getUser, getUsertoken } from "../GetUsertoken/GetUsertoken";
import {useMutation } from "react-query"
import { adminWithdraw } from "../../Super/apis";


const WithdrawalModal = ({ show, setShow, loadHistory, loadWalBal, type }) => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [usertoken, setUsertoken] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [options, setOptions] = React.useState("");
  const [user, setUser] = React.useState({});

  const [alert, setAlertType] = React.useState({
    type: "",
    value: "",
  });

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  React.useEffect(() => {
    getUsertoken(location, setUsertoken, setUser);
  }, []);


  const mutation = useMutation(adminWithdraw, {
    onSuccess: res => {
      console.log(res)
      if(res?.data?.success){
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      }else {
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "danger",
          value: res.data.message,
        });
      }
      loadHistory();


    },
    onError: err => {
      console.error(err)
    }
  })



  const handleSubmit = () => {
    if(type !== "admin"){

        setLoading(true);
        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("usertoken", usertoken);
        formData.append("amount", amount);
        formData.append("options", options);
        formData.append("currency", currency);
    
        Fetch(`${process.env.REACT_APP_END_POINT}/withdraw`, formData)
          .then((res) => {
            setLoading(false);
            loadHistory();
            loadWalBal();
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
          })
          .catch((err) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: err.message,
            });
          });
    }else {
      mutation.mutate({})
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose} centered className="modal-size">
      <Modal.Header className="d-flex justify-content-center">
        <h3 className="text-danger text-center">Withdraw</h3>
      </Modal.Header>
      {showAlert === true && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      <Modal.Body>
        <div className="row justify-content-center align-items-center">
          {loading || mutation?.isLoading ? (
            <Loading />
          ) : (
            <form onSubmit={handleSubmit} className="form col-sm-5">
              <div className="input-wrapper my-2">
                <label className="form-label text-danger fw-bold" htmlFor="amount">
                  Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="form-control"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="input-wrapper my-2">
                <label htmlFor="withdraw_option" className="form-label text-danger fw-bold">Select Option</label>
                <select name="option" id="withdraw_option" className="form-select" onChange={(e)=>setOptions(e.target.value)}>
                  <option value="" default>Withdrawal Options</option>
                  <option value="paypal">Paypal</option>
                  <option value="stripe">Stripe</option>
                  <option value="payoneer">Payoneer</option>
                </select>
              </div>
              {/* <div className="input-wrapper my-2">
                <label htmlFor="withdraw_option" className="form-label text-danger fw-bold">Select Currency</label>
                <select name="option" id="withdraw_option" className="form-select" onChange={(e)=>setCurrency(e.target.value)}>
                  <option value="" default>Currency Options</option>
                  <option value="AED">AED</option>
                  <option value="USD">USD</option>
                </select>
              </div> */}

              {/* <div className="input-wrapper my-2">
                <label htmlFor="address" className="form-label text-danger fw-bold">Target Address</label>
                <input type="text" name="address" id="address" className="form-control" />
              </div> */}
              <div className="d-flex justify-content-end align-items-center">
                <button className="mt-2 btn btn-danger" onClick>
                  Withdraw
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawalModal;
