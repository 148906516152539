import React, { useState } from "react";
import {
  Fetch,
  TimestampConverter,
  TimestampDateConverter,
} from "../../../../../../controllers/controller";
import ContractAlert from "../../../../../Reusables/ContractAlert/ContractAlert";

import StaticPenalty from "./StaticPenalty";

const Penaltymodal = ({
  penaltyList,
  contract,
  reloader,
  cancel_from,
  cancel_days,
  cancel_to,
  cancel_penalty,
}) => {
  const [payload, setPayload] = useState({});
  const [load, setLoad] = useState(false);
  const [showAlertt, setShow] = useState(false);
  const [value, setValue] = useState('');

  const [edit, setEdit] = useState(null);
 

  const handleEdit = (e) => {
    setEdit(true);
    setPayload({ ...payload, ...penaltyList });
  };

  const [newDate, setNewDate] = useState({});

  const handleCancellation = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handlePenDateChange = (e) => {
    e.preventDefault();
    setNewDate({ ...newDate, [e.target.name]: e.target.value });
  };

  const updatePen = (e) => {
    e.preventDefault();

    if (
      contract.status !== "" &&
      contract.contract_name !== "" &&
      contract.child_age_From !== "" &&
      contract.child_age_to !== "" &&
      contract.currency !== ""
    ) {
      setLoad(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_name", payload.contract_name);
      formData.append("token", payload.contractToken);
      formData.append("hoteltoken", payload.hoteltoken);
      formData.append("dmctoken", payload.dmctoken);
      formData.append("id", payload.id);
      formData.append(
        "cancel_start",
        newDate.cancel_start
          ? newDate.cancel_start
          : TimestampDateConverter(payload.cancel_start)
      );
      formData.append(
        "cancel_end",
        newDate.cancel_end
          ? newDate.cancel_end
          : TimestampDateConverter(payload.cancel_end)
      );
      formData.append("cancel_days", payload.cancel_days);
      formData.append("cancel_penalty", payload.cancel_penalty);
      formData.append("cancel_type", payload.cancel_type);
      Fetch(`${process.env.REACT_APP_END_POINT}/cancel-plan-update2`, formData)
        .then((res) => {
          setLoad(false);
          if (res.data.success === true) {
            setEdit(null);
            reloader();
            setShow(true);
            setValue(res.data.message)
            // window.location.reload()
          } else {
            setShow(true);
          setValue(res.data.message)
          }
        })
        .catch((err) => {
          setLoad(false);
          setShow(true);
          setValue(err.message)
        });
    } else {
      window.alert("Please fill in contract details first");
      setPayload({
        ...payload,
        cancel_days: "",
        cancel_penalty: "",
        cancel_type: "",
      });
      setNewDate({ ...newDate, cancel_start: "", cancel_end: "" });
    }
  };
  return (
    <>
      {edit ? (
        <tr>
          <td>
            <input
              type="text"
              className="form-control"
              name="timestamp"
              disabled
              onChange={(e) => handleCancellation(e)}
              value={TimestampConverter(penaltyList.timestamp)}
            />
          </td>
          <td>
            <p>{TimestampConverter(payload.cancel_start)}</p>
            <input
              type="date"
              className="form-control"
              name="cancel_start"
              onChange={(e) => handlePenDateChange(e)}
              value={newDate.cancel_start}
            />
          </td>
          <td>
            <p>{TimestampConverter(payload.cancel_end)}</p>
            <input
              type="date"
              className="form-control"
              name="cancel_end"
              onChange={(e) => handlePenDateChange(e)}
              value={newDate.cancel_end}
            />
          </td>
          <td>
            <select
              name="cancel_type"
              id="cancel_type"
              value={payload.cancel_type}
              style={{ width: "100%" }}
              onChange={(e) => handleCancellation(e)}
            >
              <option value="" defaultValue></option>
              <option value="First Night">First Night</option>
              <option value="Full Stay">Full Stay</option>
            </select>
          </td>
          <td>
            <input
              type="number"
              name="cancel_days"
              id=""
              className="form-control"
              onChange={(e) => handleCancellation(e)}
              value={payload.cancel_days}
            />
          </td>
          <td>
            <input
              type="number"
              name="cancel_penalty"
              id=""
              className="form-control"
              onChange={(e) => handleCancellation(e)}
              value={payload.cancel_penalty}
            />
          </td>
          <td className="oneFullColumn">
            <button className="btn h-100" onClick={(e) => updatePen(e)}>
              {load ? (
                <div
                  class={load && `spinner-border text-primary`}
                  role="status"
                >
                  Saving...
                </div>
              ) : (
                <div>Save</div>
              )}
            </button>
          </td>
        </tr>
      ) : (
        <StaticPenalty
          cancel_penalty={penaltyList.cancel_penalty}
          cancel_type={penaltyList.cancel_type}
          timeStamp={penaltyList.timestamp}
          cancel_days={penaltyList.cancel_days}
          cancel_from={penaltyList.cancel_start}
          timestamp={penaltyList.timestamp}
          cancel_to={penaltyList.cancel_end}
          handleEdit={(e) => handleEdit(e)}
          penId={``}
        />
      )}
      <ContractAlert show={showAlertt} setShow={setShow} value={value} />
    </>
  );
};

export default Penaltymodal;
