import React, { useState, useEffect }from 'react'
import { Fetch } from '../../../../controllers/controller';

const AddDay = ({reload}) => {
    const [loading, setLoading] = useState(false)
    const [dayCreated, setDayCreated] = useState(false);
    const [packageDetail, setPackage] = useState({});
    const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
    const [day,setDay] = useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    useEffect(() => {
        const data = localStorage.getItem("pkg");
        if (data) {
          const pkg = JSON.parse(data);
          setPackage(pkg);
        }
      }, []);
    const addDay = (e) => {
        e.preventDefault();
        setLoading(true);
       
        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        // formData.append("dmctoken", uDmc.usertoken);
        formData.append("package_id", packageDetail.package_id);
        formData.append("day", day);
    
        Fetch(`${process.env.REACT_APP_END_POINT}/create-package-day`, formData)
          .then((res) => {
            setLoading(false);
            if (res.data.success === true) {
              setDayCreated(true);
              reload(true)
              setTimeout(() => {
                  reload(false)
              })
            }
            setShow(true);
            setMessage(res.data.message);
          })
          .catch((err) => {
            setLoading(false);
            setShow(true);
            setMessage(err.message);
          });
      };
    return (
        <div className="row  mt-2">
            <div className="form-group mb-3 col-sm-2">
                <label htmlFor="name" className="form-label text-danger">
                Day
                </label>
                <div className="d-flex ">
                <input
                    type="number"
                    onChange={(e)=>{setDay(e.target.value)}}
                    value={day}
                    name="day"
                    id="day"
                    className="form-control px-2 py-2 me-3"
                    min="1"
                />
                {
                    loading ? (
                    <button className="btn d-block btn-danger">
                    <div className="spinner-border text-light" role="status"></div>
                    </button>
                    ):(

                <button className="btn d-block btn-danger" onClick={addDay}>
                    <i className="bi bi-plus"></i>
                </button>
                    )}
                </div>
              {/* <GAlertModal message={message} show={show} setShow={setShow} /> */}

            </div>
        </div>
    )
}

export default AddDay
