import React from "react";

const FeaturedCards = ({cardImage, CardTitle, rating, reviews }) => {
  return (
    <div className="shadow" style={{position:"relative"}}>
      <div>
      <img src={cardImage} className="" alt="..." style={{ width:"100%", padding:"0"}} />
      </div>
      <div className="card-body" style={{color:"rgba(9, 8, 72, 1)"}}>
        <h5 className="card-title" >{CardTitle}</h5>
        <div className="card-text d-flex justify-content-between">
          <div className="rating">{rating}</div>
          <div className="reviews">{reviews}</div>
        </div>
      </div>
      <div className="featured text-light">Featured</div>
    </div>
  );
};

export default FeaturedCards;
