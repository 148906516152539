import React, {useState, useEffect, useContext}from 'react'
import { Modal } from 'react-bootstrap';
import { Fetch } from '../../../../../../../controllers/controller';
import AlertComp from '../../../../../../Reusables/AlertComp/AlertComp';
import Loading from '../../../../../../Reusables/Loader/Loading';
import HotelContext from '../../../../../Junior/Manage/Context/hotelcontext';

const AddRooms = ({handleRemove, openModal}) => {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });

    const [hotelId] = useContext(HotelContext)
    useEffect(() => {
        const accowner = localStorage.getItem("hotelChain");
        if (accowner) {
            const useableAccOwner = JSON.parse(accowner);
            setPayload({...payload, usertoken: useableAccOwner.usertoken})
        }
    }, [])

    const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    hotelname: "",
    available: "",
    price:"",
    usertoken: "",
    description: ""

  })

  const handleImages = (e) =>{
      setPayload({...payload, file: e.target.files[0]})
  }
  const handleChange = (e) => { 
    setPayload({...payload, [e.target.name]: e.target.value})
  }
  const submitHotelRoom = (e) => {
    const formData = new FormData()
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN )
    formData.append("hoteltoken", hotelId )
    formData.append("name", payload.hotelname)
    formData.append("price", payload.price)
    formData.append("room_desc", payload.description)
    formData.append("avail_room_num", payload.available)

    setLoading(true)
    Fetch( `${process.env.REACT_APP_END_POINT}/add-new-room`, formData)
    .then( (res) => {
    setLoading(false);
    setShowAlert(true);
    setAlertType({ ...alert, type: "success", value: res.data.message });

  })
  .catch((err) => {
    setLoading(false);
    setShowAlert(true);
    setAlertType({ ...alert, type: "danger", value: err.message });
  });
  }
    const handleAlertClose = () =>{
      setShowAlert(false);
    }
  return  (
    <>
      <Modal show={openModal} onHide={handleRemove}>

        {loading ? (<Loading />):(

          <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold text-center">Add Hotel Room</h4>
          </div>
          <Modal.Body className="mb-5">
            <form className="row justify-content-center">
              <div className="form-left col-md-6">
              {showAlert === true && (
                    <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                  )}
                <div className="status mb-3">
                  <label htmlFor="hotelname" className="mb-2 text-primary form-label" style={{ fontSize: "12px" }} >
                    Room name
                  </label>
                  <input type="text" name="hotelname" id="hotelname" className="form-control" onChange={handleChange}  required value={payload.hotelname} />
                </div>
                <div className="price mb-3">
                  <label htmlFor="price" className="mb-2 text-primary form-label" style={{ fontSize: "12px" }} >
                    Price
                  </label>
                 <input type="number" name="price" className="form-control" required value={payload.price} onChange={handleChange} />
                </div>
                <div className="available mb-3">
                  <label htmlFor="available" className="mb-2 text-primary form-label" style={{ fontSize: "12px" }} >
                    Number of Available Rooms
                  </label>
                 <input type="number" name="available" id="available" className="form-control" value={payload.available} onChange={handleChange} required  />
                </div>
                <div className="description mb-3 w-100">
                  <label htmlFor="description" className="mb-2 text-primary form-label" style={{ fontSize: "12px" }} >
                    Desciption
                  </label>
                 <textarea name="description" id="description" cols="30" rows="5" className="form-control" value={payload.description} onChange={handleChange} required ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <div className="d-flex m-3">
            <button className="btn btn-lg btn-primary mb-2 w-50 m-auto" style={{  padding: "11px" }} onClick={submitHotelRoom} >
              Add Rooms
            </button>
            </div>
        </div>
      )}
      </Modal>
    </>
  );
}

export default AddRooms
