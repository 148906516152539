import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../controllers/controller";

const AmenitiesHolder = ({ id, description, amenities }) => {
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [usertoken, setUsertoken] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alert, setAlertType] = React.useState({
    type: "",
    value: "",
  });
  useEffect(() => {
    let mounted = true;
    if (id) {
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("roomtoken", id);
      Fetch(`${process.env.REACT_APP_END_POINT}/list-room-amenities`, formData)
        .then((res) => {
          setLoading(false);
          if (mounted) {
            if (res.data.success != false) {
              setDetail(res.data);
            } else {
              window.alert(res.data.message);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "success",
            value: err.message,
          });
        });
    }
    return () => (mounted = false);
  }, [id]);
  return (
    <section className="p-1">
      <ul
        className="d-flex amenities_list text-danger"
        style={{ gap: "2rem", fontSize: "12px", rowGap: "1rem" }}
      >
        <li>
          {" "}
          <p> {amenities} </p>{" "}
        </li>
        {detail.length > 0 &&
          detail.map((item) => (
            <li>
              <p dangerouslySetInnerHTML={{ __html: item.room_amenities }} />
            </li>
          ))}
      </ul>
    </section>
  );
};

export default AmenitiesHolder;
