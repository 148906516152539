import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ReactTooltip from "react-tooltip";
import {
  Fetch,
  TimestampConverter,
} from "../../../../../../controllers/controller";
import "../../../../../../App.css";

const Comp = ({ roomObj, date, all }) => {
  
  const [count, setCount] = useState(0);
  const [detail, setDetail] = useState({});
  const [load, setLoad] = useState(false);
  const token = localStorage.getItem("cont");


  const setColor = (room, price) => {
    console.log(room, parseInt(price))
    if (parseInt(room) > 0) {
      return "bg-success";
    } else if (parseInt(room) < 1 && parseInt(price) < 1) {
      return "bg-light";
    } else if (parseInt(room) <= 0) {
      return "bg-danger";
    }
  };

  function getContractDate(mounted) {
    setLoad(true);
    
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("token", token);
    formData.append("timestamp", date.toLocaleDateString());
    formData.append("id", all.id);
    formData.append("roomtoken", all.roomtoken);
    // formData.append("start_date", TimestampConverter(roomObj.start_date));
    // formData.append("end_date", TimestampConverter(roomObj.expiry_date));
    // formData.append("timestamp", new Date(date).getTime() / 1000);
    formData.append("start_date", roomObj.start_date);
    formData.append("end_date", roomObj.expiry_date);
    Fetch(
      `${process.env.REACT_APP_NEND_POINT}/get-contract-date-data`,
      formData
    )
      .then((res) => {
        // throw new Error({"error": "invalid expression"})
        setLoad(false);
        if (res.data.success === false) {
          return;
        } else {
          if (mounted) setDetail(...res.data);
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }
  useEffect(() => {
    let mounted = true;
    getContractDate(mounted);

    return () => (mounted = false);
  }, [roomObj]);

  if (load) {
    return (
      <div className="">
        <ReactTooltip place="top" className="bg-success p-1" />
        <small>... </small>
      </div>
    );
  } 
  console.log(roomObj)
  console.log("detail", detail)
  console.log("detail length", Object.keys(detail).length > 0);

  return (
    <>
        {Object.keys(detail).length > 0 ? (
        <div className="border border-dark">
          <>
            <ReactTooltip place="top" className="bg-success p-1" />
            <p
              className={` p-1 ${setColor(all.room, all.price_sgl)} emptyBox mb-0`}
              data-tip={`<p> sgl: ${
                detail.price_sgl === "" || detail.price_sgl === "undefined"
                  ? "-"
                  : detail.price_sgl
              } | dbl: ${
                detail.price_dbl === "" || detail.price_dbl === "undefined"
                  ? "-"
                  : detail.price_dbl
              } | twn: ${
                detail.twn === "" || detail.twn === "undefined"
                  ? "-"
                  : detail.twn
              } | trpl: ${
                detail.price_trl === "" || detail.price_trl === "undefined"
                  ? "-"
                  : detail.price_trl
              } | quad: ${
                detail.price_quad === "" || detail.price_quad === "undefined"
                  ? "-"
                  : detail.price_quad
              } | unit: ${
                detail.unit === "" || detail.unit === "undefined"
                  ? "-"
                  : detail.unit
              }</p>`}
              data-html={true}
            ></p>
          </>
        </div>
      ) : (
        <p></p>
      )}
    </>
  );
};
const ContractCal = (props) => {
  const { roomObject, room, all, handleDateClick, detail, index } = props;

  const date_range = [
    new Date(all.start_date * 1000),
    new Date(all.expiry_date * 1000),
  ];



  const tileContent = ({ date, view }) => {
    const date_to_unix = Math.floor(date.getTime() / 1000);
    
    console.log("unix_start",  date_to_unix >= all.start_date - (24 * 60 * 60*1000))
    console.log("unix_end", date_to_unix <= all.expiry_date + (24 * 60 * 60*1000))

    console.log(all.start_date, all.expiry_date)


    // subtract from start_date and add to end_date to cover for timezone difference
    if (
      date_to_unix >= all.start_date - (24 * 60 * 60*1000) &&
      date_to_unix <= all.expiry_date + (24 * 60 * 60*1000)
    ) {

      return (
        <Comp
          roomObj={roomObject}
          date={date}
          all={all}
          sgl={detail.price_sgl}
          dbl={detail.price_dbl}
          trpl={detail.price_trl}
          quad={detail.price_quad}
        />
      );
    }

    return null;
  };

  const setColor = (room, price) => {
    if (room > 0) {
      return "green";
    } else if (room <= 0) {
      return "red";
    } else if (room < 1 && price < 1) {
      return "white";
    }
  };
  console.log("calendar colour: ",setColor(all.room, all.price_sgl));
  // const marks = new Set(['10/10/2021', '10/11/2021', '10/12/2021', '10/13/2021']);
  return (
    <div className={`mb-2 me-lg-2 ${setColor(all.room, all.price_sgl)}`}>
      <p className="text-primary fw-bold">{room.toUpperCase()}</p>
      <Calendar
        onChange={(val, event) => handleDateClick(val, index)}
        className={["shadow", "rounded"]}
        value={date_range}
        // selectRange={true}
        tileContent={tileContent}
        // tileClassName={({ date, view }) => {
        //   if(marks.has(date.toLocaleDateString())){
        //    return  'highlight'
        //   }
        // }}
      />
    </div>
  );
};

export default ContractCal;
