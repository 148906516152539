import React, {useState, useEffect} from 'react'
import {Tabs, Tab} from "react-bootstrap"
import {useMutation} from "react-query"
import {useLocation} from "react-router-dom"

import AlertComp from '../../../../Reusables/AlertComp/AlertComp'

import {mainGetUsertoken} from "../../../Reusables/GetUsertoken/GetUsertoken"
import Sources from '../../../Reusables/Sources/Sources'
import {getdmc, createSource, getExternalSources, getAccountOwner} from "../../apis"
const Index = ()=> {
    return (
        <Tabs
        defaultActiveKey="create"
        id="uncontrolled-tab-example"
        className="mb-3 title_font"
      >
        <Tab eventKey="create" title="CREATE SOURCE">
            <CreateSource />
        </Tab>
        <Tab eventKey="external" title="MAP CATEGORY">
          <Sources />
        </Tab>
      </Tabs>
    )
}

export default Index

function CreateSource(){
    const location = useLocation()
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")
    const [loading, setLoading]= useState(false)
    const [sourceData, setSourceData]= useState({
        token:"",
        source:"",
        apptoken:process.env.REACT_APP_APP_TOKEN

    })
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });

    useEffect(()=>{
       mainGetUsertoken(location, setUsertoken, setUser)
        getDMCs.mutate({
            apptoken:process.env.REACT_APP_APP_TOKEN,
    
        }) 
       
    },[]) 

    useEffect(()=>{
      if(usertoken){
            getSources.mutate({
                apptoken:process.env.REACT_APP_APP_TOKEN,
                usertoken: usertoken,
                type:"Admin"
            })
        }
    },[usertoken]) 

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    function handleForm(e){
        setSourceData({...sourceData, [e.target.name]: e.target.value})
    }

    
    // fetch all dmc
    const getDMCs = useMutation(getdmc, {
        onError: (e)=>{
            console.error(e)
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        },
        onSuccess:(e)=>{
            console.log(e)
            if(e.data?.success === false) {
                setAlertType({ ...alert, type: "danger", value: e.data.message })
                setShowAlert(true)
            }
        }
    })
    
        // create source
    const addSource = useMutation(createSource, {
        onError: (e)=>{
            console.error(e)
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        },
        onSuccess: (e)=>{
            console.log(e)
            setAlertType({ ...alert, type: "success", value: e.data.message });
            setShowAlert(true)
        }
    })

    const getSources = useMutation(getExternalSources, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        },
        onSuccess: (e)=>{
            console.log(e)
            if(e.data?.success === false) {
                setAlertType({ ...alert, type: "danger", value: e.data.message })
                setShowAlert(true)
            }
        }
    })
    function handleSubmit(e){
        e.preventDefault();
        addSource.mutate({...sourceData, usertoken})
    }
    // console.log({getSources})
    // console.log({getDMCs})

    return (
        <div className="row justify-content-center">
            <form  className="form col-lg-9" onSubmit={handleSubmit}>
            {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
                <div className="row align-items-center">
                    <div className="p-1 col-sm-4 form-group">
                        <label htmlFor="dmc" className="form-label">DMC</label>
                        {  getDMCs.isLoading ?  
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>  
                        </div> :
                        <select name="token" id="dmc" className="form-select" value={sourceData.token} onChange={handleForm}>
                            <option value="">Select DMC</option>
                            {(getDMCs.data && getDMCs.data?.data.success !== false) && getDMCs.data.data.map(dmc=>(
                                <option key={dmc.id} value={dmc.token}>{dmc.first_name} - {dmc.last_name}</option>
                                ))
                            }
                        </select>
                        }
                    </div>
                   
                    <div className="p-1 col-sm-4 form-group">
                        <label htmlFor="source" className="form-label">Source</label>
                        {  getSources.isLoading ?  
                        <div>
                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>  
                        </div> :
                        <select name="source" id="source" className="form-select" value={sourceData.source} onChange={handleForm}>
                            <option value="" defaultValue>Select Source</option>
                                { (getSources.data && getSources.data?.data.success !== false) && getSources.data?.data.sources.map(source=>(
                                    <option key={source.id} value={source.name}>{source.name}</option>
                                    ))
                                }
                        </select>
                    }
                    </div>
                    <div className="p-1 col-sm-4 form-group sources-btn">
                        <button className="btn btn-primary" type="submit">{ addSource.isLoading ? <div className={`spinner-border text-primary` } role="status" ></div> : "Create" } </button>
                    </div>

                </div>
            </form>
        </div>
    )
}