import React, { useState, useEffect } from "react";
import { dateConverter, Fetch } from "../controllers/controller";
import Mealsmodal from "./Admins/Others/DMC/Contracts/Modal/Mealsmodal";
import AlertComp from "./Reusables/AlertComp/AlertComp";
import AlertModal from "./Reusables/AlertModal/AlertModal";


const Meals = ({ children, contract, saiA, saiC, uaiA, uaiC, allA, allC, breakA, breakC, fb_adult, fb_child, hb_adult, hb_child, mealStart, mealExp, }) => {
  const [meals, setMeals] = useState({ meals_start: '', meals_end: "", breakfast_adult:"", breakfast_child:"", hb_adult:"", hb_child: "", fb_adult:"", fb_child:"", sai_adult:"", sai_child:"", all_inclusive_child: "", all_inclusive_adult:"", uai_child:"", uai_adult:"" });
 
  const [reloadComponent, setRC] = useState(false)
  
  const [mealsList, setMealsList] = useState([])
  const [load, setLoad] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    
    const [show, setShow]=useState(false)
    const accowner = localStorage.getItem("DMC");
    const useableAccOwner = JSON.parse(accowner);
    
    const hotelId = localStorage.getItem("hotel");
    const ct = localStorage.getItem("CT");

  const handleMealsChange = (e) => {
    setMeals({ ...meals, [e.target.name]: e.target.value });
  };

  // const handleMealsModalOpen = (e, meal, index) => {
  //     setShow(true)
  //     setValue(meal)
  //     const parsedmeal = JSON.stringify(meal)
  //     localStorage.setItem("meal", parsedmeal)
      
  // }

const [reloadMeals, setReloadMeals] = useState(false)

  
  useEffect(() => {
    let mounted = true;
    const accowner = localStorage.getItem("DMC");
    const use = JSON.parse(accowner);

    const CT = localStorage.getItem("CT");



    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", use.usertoken);
    formData.append("hoteltoken", hotelId);
    formData.append("token", CT);

    Fetch(
      `${process.env.REACT_APP_END_POINT}/list-meals-plan`,
      formData
    )
      .then((res) => {
        if(res.data.length > 0) {

        const SortedMeals = res.data.sort((a, b)=>{
          let fm = new Date(a.meals_start * 1000).toLocaleDateString();
          let cfm = new Date(fm)
          let lm = new Date(b.meals_start * 1000).toLocaleDateString();
          let clm = new Date(lm);
          return clm - cfm
        })
        if (mounted) {
          setMealsList(res.data);
         
        }
      }
      })
      .catch((err) => {
        window.alert(`meals list: ${err.message}`)
      });

    return () => {
      mounted = false;
    };
  }, [alert, reloadMeals])

const getMeals = () => {
  const accowner = localStorage.getItem("DMC");
    const use = JSON.parse(accowner);
    const CT = localStorage.getItem("CT");

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", use.usertoken);
    formData.append("hoteltoken", hotelId);
    formData.append("token", CT);

    Fetch(
      `${process.env.REACT_APP_END_POINT}/list-meals-plan`,
      formData
    )
      .then((res) => {
          const sortedMeals = res.data.sort((a, b)=>{
          let fm = new Date(a.meals_start * 1000).toLocaleDateString();
          let cfm = new Date(fm)
          let lm = new Date(b.meals_start * 1000).toLocaleDateString();
          let clm = new Date(lm);
          return clm - cfm
        })
        setMealsList(sortedMeals);
      
        
      })
      .catch((err) => {
        window.alert(`meals list: ${err.message}`)
      });
}

  const handleSave = (e) => {
    e.preventDefault();
    if(contract.status !== "" && contract.contract_name !== ""  && contract.child_age_From !== ""  &&contract.child_age_to !== "" && contract.currency !== ""  ){

    setLoad(true)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("contract_name", contract.contract_name);
    formData.append("hoteltoken", hotelId);
    formData.append("token", ct);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("meals_end", meals.meals_end);
    formData.append("meals_start", meals.meals_start);
    formData.append("status", contract.status);
    formData.append("rooms_only_child", meals.rooms_only_child);
    formData.append("rooms_only_adult", meals.rooms_only_adult);
    formData.append("child_age_From", contract.child_age_From);
    formData.append("child_age_To", contract.child_age_To);
    formData.append("currency", contract.currency);
    formData.append("breakfast_child", meals.breakfast_child);
    formData.append("breakfast_adult", meals.breakfast_adult);
    formData.append("fb_child", meals.fb_child);
    formData.append("fb_adult", meals.fb_adult);
    formData.append("hb_child", meals.hb_child);
    formData.append("hb_adult", meals.hb_adult);
    formData.append("sai_child", meals.sai_child);
    formData.append("sai_adult", meals.sai_adult);
    formData.append("uai_child", meals.uai_child);
    formData.append("uai_adult", meals.uai_adult);
    formData.append("all_inclusive_child", meals.all_inclusive_child);
    formData.append("all_inclusive_adult", meals.all_inclusive_adult);
 
    Fetch(
      `${process.env.REACT_APP_END_POINT}/meals-plan-update`,
      formData
    )
      .then((res) => {
        setRC(true)
        setLoad(false);
        if(res.data.success === true){
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
        getMeals()
        setMeals({...meals, meals_start: "", meals_end:"", all_inclusive_adult:"", all_inclusive_child: "", uai_adult:"", uai_child:"", sai_child:"", sai_adult:"", hb_child:"", hb_adult:"", fb_adult:"", fb_child:"", breakfast_adult:"", breakfast_child:"", rooms_only_adult:"", rooms_only_child:""})
        // window.location.reload()
      } else{
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "danger",
          value: res.data.message,
        });
      }
      })
      .catch((err) => {
        setLoad(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
    } else {
      setShow(true)

    }
  };
  const handleAlertClose =() =>{
    setShowAlert(false);
  }
  return (
      <div className="mb-3">
        {showAlert === true && (
            <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
          )}
          <p className="lead text-primary">MEALS</p>
        <div className="table-responsive text-center mytable ">
          <table className="table table-bordered border-dark">
            <thead className="bg-primary text-light">
              <tr>
                <th></th>
                <th colSpan="2" scope="colgroup">
                  DATE
                </th>
                <th colSpan="2" scope="colgroup">ROOM ONLY</th>
                <th colSpan="2" scope="colgroup">
                  BREAKFAST
                </th>
                <th colSpan="2" scope="colgroup">
                  HB
                </th>
                <th colSpan="2" scope="colgroup">
                  FB
                </th>
                <th colSpan="2" scope="colgroup">
                  SOFT ALL INC
                </th>
                <th colSpan="2" scope="colgroup">
                  ALL INC
                </th>
                <th colSpan="2" scope="colgroup">
                  ULTRA ALL INC
                </th>
                <th >
                  ACTION
                </th>
              </tr>
              <tr>
                <th>TIMESTAMP</th>
                <th>FROM</th>
                <th>TO</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th>ADULT</th>
                <th>CHILD</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{minWidth: "100px"}}>
                  <input
                    type="text"
                    className="form-control"
                    name="meals_start"
                    onChange={handleMealsChange}
                    value={dateConverter()}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control date-input"
                    name="meals_start"
                    onChange={handleMealsChange}
                    value={meals.meals_start}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control date-input"
                    name="meals_end"
                    onChange={handleMealsChange}
                    value={meals.meals_end}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    name="rooms_only_adult"
                    onChange={handleMealsChange}
                    value={meals.rooms_only_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    name="rooms_only_child"
                    onChange={handleMealsChange}
                    value={meals.rooms_only_child}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="breakfast_adult"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.breakfast_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="breakfast_child"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.breakfast_child}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="hb_adult"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.hb_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="hb_child"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.hb_child}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="fb_adult"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.fb_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="fb_child"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.fb_child}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="sai_adult"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.sai_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="sai_child"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.sai_child}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="all_inclusive_adult"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.all_inclusive_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="all_inclusive_child"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.all_inclusive_child}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="uai_adult"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.uai_adult}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="uai_child"
                    id=""
                    className="form-control"
                    onChange={handleMealsChange}
                    value={meals.uai_child}
                  />
                </td>
                <td className="oneFullColumn">
                  <button className="btn h-100" onClick={handleSave}>
                  {load ? (
                    <div class={load && `spinner-border text-primary`} role="status">
                      Saving...
                    </div>
                  ) : (
                    <div className="fw-bold">SAVE</div>
                  )}
                </button>
                </td>
              </tr>
            </tbody>
            <tbody className="contracts">

          {mealsList.length > 0 && mealsList.map((meal, index)=>(
            <Mealsmodal mealsList={meal} contract={contract} reloader={getMeals} />
            ))}
            </tbody>
            </table>
        <div className="dater">
        </div>  

      </div>
      <AlertModal show={show} setShow={setShow} />

    </div>
      
  );

};

export default Meals;
