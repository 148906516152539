import React, { useState } from 'react';
import {GrStatusPlaceholderSmall} from "react-icons/gr"
import PkgEditModal from './PkgEditModal';
import Placeholder from "../../../../Images/placeholder_image.png"

const PkgCard = ({item, reloadPkgList, id, package_id, package_name, sharing_link, youtube_link, i}) => {
    const [show, setShow] = useState(false)
  const handleModalOpen = (e, id)=>{
      e.preventDefault()
      if(item.id === id){
          setShow(true)
      }
  }
  
    return (
    <>
        <tr>
            <td>{i + 1}</td>
            <td>{package_id}</td>
            <td>{package_name}</td>
            <td>{sharing_link}</td>
            <td>{youtube_link}</td>
            <td>
            <button className="btn btn-dark" onClick={(e)=>handleModalOpen(e, id)} >Edit</button>    
            </td>
        </tr>
    <PkgEditModal show={show} handleClose={()=>setShow(false)} packageItems={item} reloadPkgList={reloadPkgList} />
    </>
  )
};

export default PkgCard