import React from "react";
import SubscriptionImage from "../../../Images/Palm.png"
const Subscription = () => {
  return (
    <section className="subscription  py-5">
      <div className="container d-flex justify-content-around align-items-center flex-column flex-sm-row">
        <div className="left d-flex justify-content-center align-items-center mb-3 mb-sm-0">
          <img src={SubscriptionImage} alt="" className="img" />
          <div className="content ms-3">
            <h2>Get Updates &amp; More</h2>
            <small>Thoughtful thoughts to your inbox</small>
          </div>
        </div>
        <div className="right">
          <form className="form d-flex">
            <div className="form-group email-wrapper">
              <input type="email" name="email" className="form-control email" placeholder="Your email" aria-placeholder="your email" />
            </div>
            <button type="submit" className="btn subsButton text-light">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Subscription;
