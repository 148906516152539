/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom"
import {useMutation} from "react-query"

import {getExternalSources, getSourceHotels, getRoomCategories, mapHotelsToCategory, getRoomList, getHotelOwner, getExtContractRooms, getHotelCategories, checkMapped, getHotelData, getContractData, getExternalHotelData} from "../../../../Super/apis"
import {mainGetUsertoken} from "../../../../Reusables/GetUsertoken/GetUsertoken"
import AlertComp from '../../../../../Reusables/AlertComp/AlertComp'

const MapRooms = () => {
    return (
        <main className="pt-4 px-lg-4 px-3"  style={{ minHeight: "83vh" }}>
        {/* <header className=" mb-3 d-flex justify-content-between align-items-center dashHeader">
          <h4 className="text-primary text-center">Room Map</h4>
        </header> */}
        <div className="content">
            <Index />
        </div>
        </main>
      )
}
 
function Index(){
    const location = useLocation()
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")
    const [sourceData, setSourceData]= useState({})
    const [showAlert, setShowAlert] = useState(false);
    const [extData, setExtData] = useState({});
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const [hotelData, setHotelData] = useState({})
    const hotelId = localStorage.getItem("hotel");
    const contract_token = localStorage.getItem("CT")
    const token = localStorage.getItem("CT")

    const roomList = useMutation(getRoomList, {
        onError: (e)=>{
           
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        },
        onSuccess: (res)=>{
            if(res.data.success === false){
                setAlertType({ ...alert, type: "danger", value: res.data.message });
                setShowAlert(true)
            }
        }
    })

    const mutation = useMutation(getContractData, {
        onSuccess: (res)=>{
          setExtData(res.data.data)
        },
        onError: (err)=>{
        }
      })
    const fetchRoomCategories = useMutation(getRoomCategories, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }
    })
    const fetchHotelOwner = useMutation(getHotelOwner, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }
    })
    
    const gethoteldata = useMutation(getHotelData, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess:(e)=>{
            console.log(e)
            if(e.data.success === false) {
                setAlertType({ ...alert, type: "danger", value: e.data.message })
                setShowAlert(true)
            } else{
                setHotelData(e.data.data)

            }
        }
        
    })
    const getExthoteldata = useMutation(getExternalHotelData, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess:(e)=>{
            console.error(e)
            if(e.data.success === false) {
                setAlertType({ ...alert, type: "danger", value: e.data.message })
                setShowAlert(true)
            } else {
                setExtData({...extData, ...e.data.data[0]})
            }
        }
        
    })
    const mapHotel = useMutation(mapHotelsToCategory, {
        onError: (e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        },
        onSuccess: (e)=>{
            setAlertType({ ...alert, type: "success", value: e.data.message });
            setShowAlert(true)
        }
    })

    const getExtconRoom = useMutation(getExtContractRooms, {
        onError: (e)=>{
            console.error(e)
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess: (res)=>{
           
        }
    })
    const getHotelRoomCat = useMutation(getHotelCategories, {
        onError: (e)=>{
            console.error(e)
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess: (res)=>{
           
        }
    })

    const checkIfMapped = useMutation(checkMapped, {
        onError: (e)=>{
            console.error(e)
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }, 
        onSuccess: (res)=>{
           
        }
    })
    
   

   

    useEffect(() => {
      if(hotelId){
        getHotelRoomCat.mutate({
            apptoken: process.env.REACT_APP_APP_TOKEN,
            hoteltoken: hotelId
        })
        

      }
    }, [usertoken])

    useEffect(()=>{
        mainGetUsertoken(location, setUsertoken, setUser)
        fetchRoomCategories.mutate()
        fetchHotelOwner.mutate()
        gethoteldata.mutate(
            {
                apptoken: process.env.REACT_APP_APP_TOKEN,
                hoteltoken: hotelId,
            }
            
        )
        
        roomList.mutate(hotelId)
        if(token){
            getExtconRoom.mutate({
                apptoken: process.env.REACT_APP_APP_TOKEN,
                contract_token :token,
                hoteltoken: hotelId
            })
            mutation.mutate({
                apptoken: process.env.REACT_APP_APP_TOKEN,
                contract_token: token 
            })  
        }
    },[token])

   
    
    useEffect(() => {
      if(contract_token){
        checkIfMapped.mutate({
            apptoken: process.env.REACT_APP_APP_TOKEN,
            contract_token :contract_token
        })
      }
    }, [])

    useEffect(() => {
      if(extData.source){
        getExthoteldata.mutate({
            apptoken: process.env.REACT_APP_APP_TOKEN,
            hotelcode: extData.hotelcode,
            source: extData.source
        })
      }
    }, [extData.source])
    
    function handleAlertClose(){
        setShowAlert(false);
    };

    function handleForm(e, id){

        let hotel = localStorage.getItem('hotel');
        let data = JSON.parse(e.target.value)
        e.preventDefault()

        mapHotel.mutate({
            apptoken:process.env.REACT_APP_APP_TOKEN,
            roomid:data.roomid,
            category: id,
            contract_token:data.contract_token,
            hoteltoken:hotel
        })
        
    };
    console.log({gethoteldata})
    
    
    return (
        <div className="row">
            <form className="form col-12 row justify-content-center">
            {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
              {/* {
               checkIfMapped.data?.data?.success === false?  */}
                <>
                <div className="row">
                    <div className="col-sm-6 row">
                        <p>Hotelsoffline</p>
                        <div className="form-group col-md-8 p-1 ">
                            <input type="text" className="form-control" name="country" id="country" disabled value={gethoteldata?.data?.data?.data?.country} />
                        </div>
                        <div className="form-group col-md-8 p-1 ">
                            <input type="text" className="form-control" name="state" id="state" disabled  value={gethoteldata?.data?.data?.data?.city} />
                        </div>
                        <div className="form-group col-md-8 p-1 ">
                            <input type="text" className="form-control" name="hotel" id="hotel" disabled value={gethoteldata?.data?.data?.data?.hotelname} />
                        </div>
                        <div className="form-group col-md-8 p-1 ">
                            <label htmlFor="contract" className="form-label">Contract ID</label>
                            <input type="text" className="form-control" name="contract" id="contract" disabled value={contract_token} />
                        </div>
                        <div className="form-group col-md-8 p-1">
                            <label htmlFor="dmc" className="form-label d-block">Room</label>
                            
                            {  getHotelRoomCat.isLoading ?  <div className={`spinner-border text-primary` } role="status" ></div>  :
                                (getHotelRoomCat.data && getHotelRoomCat.data.data.success !== false) && getHotelRoomCat.data.data.data.map(category=>(
                                    <div className="form-group  p-1 ">
                                        <input type="text" className="form-control" name="hotel" id="hotel" disabled value={category.category} />
                                    </div>
                                
                                ))
                              
                            }
                        </div>
                    </div>

                    <div className="col-sm-6 row">
                        <p>External</p>
                        <div className="form-group col-md-8 p-1 ">
                            <input type="text" className="form-control" name="country" id="country" disabled value={extData?.Country} />
                        </div>
                        <div className="form-group col-md-8 p-1 ">
                            <input type="text" className="form-control" name="state" id="state" disabled value={extData?.City} />
                        </div>
                        <div className="form-group col-md-8 p-1 ">
                            <input type="text" className="form-control" name="hotel" id="hotel" disabled value={extData?.Hotel_Name} />
                        </div>
                        <div className="form-group col-md-8 p-1 ">
                        <label htmlFor="contract" className="form-label">Contract ID</label>
                            <input type="text" className="form-control" name="contract" id="contract" disabled value={extData?.token} />
                        </div>
                        <label htmlFor="dmc" className="form-label">Select a room to map</label>
                        {
                            getHotelRoomCat?.data?.data?.data?.map((item, i)=>(
                                <div className="form-group col-md-8 p-1 ">
                                    {  (getExtconRoom.isLoading  || mapHotel.isLoading)? 
                                        <div>
                                            <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>   
                                        </div> :
                                        <select name="room" id="room" className="form-select" value={sourceData.room} onChange={(e)=>handleForm(e, item.id)}>
                                            <option value="">Select room to map</option>
                                                { getExtconRoom?.data?.data?.data?.message !== "No results" && getExtconRoom?.data?.data?.data?.map((room, i)=>(
                                                    <option key={room.id} value={JSON.stringify(room)}>{room.room_name}</option>
                                                    ))
                                                }
                                        </select>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                </>
                 {/* : <p className="text-center text-muted">Rooms already mapped</p> 
               } */}

            </form>
        </div>
    )
}
export default MapRooms