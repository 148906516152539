import { set } from "lodash";
import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useMutation } from "react-query";
import { Link, useLocation } from "react-router-dom";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";
import {
	addStripeID,
	getAllUsers,
	getBio,
	signupToPayoneer,
	updateBio,
	updatePassword,
	userApprove,
} from "../../Super/apis";
import { mainGetUsertoken } from "../GetUsertoken/GetUsertoken";
import {Modal, Button} from "react-bootstrap"

const buffer = require("buffer");

const Settings = () => {
	return (
		<main className="pt-4 px-lg-4 px-2" style={{ minHeight: "90vh" }}>
			<header className=" mb-3 d-flex justify-content-between align-items-center flex-wrap dashHeader">
				<h4 className="text-primary">Profile</h4>
			</header>
			<section className="content">
				<Main />
			</section>
		</main>
	);
};

export default Settings;

function Main() {
	const location = useLocation();

	const [usertoken, setUsertoken] = useState("");
	const [user, setUser] = useState("");
	const tabs = [
		{
			id: 1,
			title: "PROFILE",
			key: "profile",
			comp: <Bio />,
		},
		{
			id: 2,
			title: "CHANGE PASSWORD",
			key: "changepassword",
			comp: <ChangePassword />,
		},

		// {
		//     id:5,
		//     title: "USERS",
		//     key:"users",
		//     comp: <Users />
		// },
		{
			id: 6,
			title: "DOCUMENTS",
			key: "documents",
			comp: <Docs />,
		},
	];

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	return (
		<Tabs
			defaultActiveKey={tabs[0].key}
			id="uncontrolled-tab-example"
			className="mb-3 title_font"
		>
			{location.pathname.includes("dmc") ||
			location.pathname.includes("hotel-owner")
				? tabs
						.filter((item) => item.title !== "USERS")
						.map(({ id, title, key, comp }) => (
							<Tab eventKey={key} key={id} title={title}>
								{comp}
							</Tab>
						))
				: location.pathname.includes("account-owner")
				? tabs.slice(0, 1).map(({ id, title, key, comp }) => (
						<Tab eventKey={key} key={id} title={title}>
							{comp}
						</Tab>
				  ))
				: tabs.slice(0, 2).map(({ id, title, key, comp }) => (
						<Tab eventKey={key} key={id} title={title}>
							{comp}
						</Tab>
				  ))}
		</Tabs>
	);
}

function Bio() {
	const location = useLocation();
	const [usertoken, setUsertoken] = useState("");
	const [user, setUser] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const [preview, setPreview] = useState({
		proof_of_id: null,
		company_reg_cert: null,
		proof_of_address: null,
	});

	const [file, setFile] = useState(null);
	const [formdata, setFormData] = useState({
		address: "",
		phone: "",
		fname: "",
		lname: "",
		country: "",
		city: "",
	});

	useEffect(() => {
		if (!user.fname || !user.lname) {
			mainGetUsertoken(location, setUsertoken, setUser);
		}

		if (user.fName || user.lname) {
			delete user.success;
			delete user.message;
			delete user.timeregistered;
			setFormData(user);
		}
	}, [user.fname]);

	function handleChange(e) {
		setFormData({ ...formdata, [e.target.name]: e.target.value });
	}
	const fetchBio = useMutation(getBio, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				// setFormData({...formdata, ...res.data})
			} else {
				// setAlertType({ ...alert, type: "danger", value: res.data.message });
			}
		},
	});

	useEffect(() => {
		fetchBio.mutate({
			usertoken,
			apptoken: process.env.REACT_APP_APP_TOKEN,
		});
	}, [usertoken]);

	const mutation = useMutation(updateBio, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setAlertType({ ...alert, type: "success", value: res.data.message });
				setShowAlert(true);
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		mutation.mutate({
			...formdata,
			apptoken: process.env.REACT_APP_APP_TOKEN,
			usertoken: usertoken,
		});
	};

	function createImgURL(e, file) {
		let url = URL.createObjectURL(file);
		setPreview({ ...preview, [e.target.name]: url });
	}

	function changeImageHandler(e) {
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.onloadend = () => {
			let item = reader.result;

			let blob = Buffer(reader.result);

			console.log({ blob });
			setFormData({ ...formdata, [e.target.name]: blob });
		};
		reader.readAsArrayBuffer(file);
		createImgURL(e, file);
	}

	return (
		<div>
			{showAlert === true && (
				<AlertComp
					variant={alert.type}
					alertText={alert.value}
					handleAlertClose={handleAlertClose}
				/>
			)}
			<form className="form" onSubmit={handleSubmit}>
				<div className="row justify-content-between">
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="fname" className="form-label">
								First Name
							</label>
							<input
								type="text"
								name="fname"
								id="fname"
								className="form-control"
								onChange={handleChange}
								value={formdata.fname}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="lname" className="form-label">
								Last Name
							</label>
							<input
								type="text"
								name="name"
								id="lname"
								className="form-control"
								onChange={handleChange}
								value={formdata.lname}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="email" className="form-label">
								Email
							</label>
							<input
								type="email"
								name="email"
								id="email"
								className="form-control"
								value={formdata.mail}
								disabled
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="phone" className="form-label">
								Phone
							</label>
							<input
								type="text"
								name="phone"
								id="phone"
								className="form-control"
								onChange={handleChange}
								value={formdata.phone}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="phone" className="form-label">
								Address
							</label>
							<input
								type="text"
								name="address"
								id="address"
								className="form-control"
								onChange={handleChange}
								value={formdata.address}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="country" className="form-label">
								Country
							</label>
							<input
								type="text"
								name="country"
								id="country"
								className="form-control"
								onChange={handleChange}
								value={formdata.country}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="state" className="form-label">
								State
							</label>
							<input
								type="text"
								name="state"
								id="state"
								className="form-control"
								onChange={handleChange}
								value={formdata.state}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="city" className="form-label">
								City
							</label>
							<input
								type="text"
								name="city"
								id="city"
								className="form-control"
								onChange={handleChange}
								value={formdata.city}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="zip_code" className="form-label">
								Zipcode
							</label>
							<input
								type="text"
								name="zip_code"
								id="zip_code"
								className="form-control"
								onChange={handleChange}
								value={formdata.zip_code}
							/>
						</div>
					</div>
					<div className="col-sm-5 my-2">
						<div className="form-group">
							<label htmlFor="zip_code" className="form-label">
								Payoneer Address
							</label>
							<input
								type="text"
								name="payoneer_address"
								id="payoneer_address"
								className="form-control"
								disabled={true}
								onChange={handleChange}
								value={formdata.payoneer_link}
							/>
						</div>
					</div>
					<div className="form-group col-sm-8 my-2">
						<label htmlFor="company_reg_cert" className="form-label">
							Company Registration Certificate
						</label>
						<input
							type="file"
							name="company_reg_cert"
							id="company_reg_cert"
							className="form-control"
							onChange={changeImageHandler}
						/>
						{preview.company_reg_cert && (
							<div
								className="preview"
								style={{
									maxHeight: "250px",
									width: "400px",
									margin: "1.5rem 0 ",
								}}
							>
								<img
									src={preview.company_reg_cert}
									alt="preview"
									className="img-fluid"
								/>
							</div>
						)}
					</div>
					<div className="form-group col-sm-7 my-2">
						<label htmlFor="proof_of_id" className="form-label">
							Proof of ID
						</label>
						<input
							type="file"
							name="proof_of_id"
							id="proof_of_id"
							className="form-control"
							onChange={changeImageHandler}
						/>
						{preview.proof_of_id && (
							<div
								className="preview"
								style={{
									maxHeight: "250px",
									width: "400px",
									margin: "1.5rem 0 ",
								}}
							>
								<img
									src={preview.proof_of_id}
									alt="preview"
									className="img-fluid"
								/>
							</div>
						)}
					</div>
					<div className="form-group col-sm-7 my-2">
						<label htmlFor="proof_of_address" className="form-label">
							Proof of Address
						</label>
						<input
							type="file"
							name="proof_of_address"
							id="proof_of_address"
							className="form-control"
							onChange={changeImageHandler}
						/>
						{preview.proof_of_address && (
							<div
								className="preview"
								style={{
									maxHeight: "250px",
									width: "400px",
									margin: "1.5rem 0 ",
								}}
							>
								<img
									src={preview.proof_of_address}
									alt="preview"
									className="img-fluid"
								/>
							</div>
						)}
					</div>
					<div className="col-10">
						{mutation.isLoading ? (
							<button className="btn btn-primary" type="submit">
								<div className="spinner-border text-light">
									<span className="visually-hidden">loading...</span>
								</div>
							</button>
						) : (
							<button className="btn btn-primary" type="submit">
								Update
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
function ChangePassword() {
	const location = useLocation();
	const [usertoken, setUsertoken] = useState("");
	const [user, setUser] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const [formdata, setFormData] = useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	function handleChange(e) {
		setFormData({ ...formdata, [e.target.name]: e.target.value });
	}
	const mutation = useMutation(updatePassword, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setAlertType({ ...alert, type: "success", value: res.data.message });
				setShowAlert(true);
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});
	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		mutation.mutate({
			...formdata,
			apptoken: process.env.REACT_APP_APP_TOKEN,
			usertoken: usertoken,
		});
	};
	function toggleShowPassword(e) {
		e.target.checked ? setShowPassword(true) : setShowPassword(false);
	}
	return (
		<div>
			{showAlert === true && (
				<AlertComp
					variant={alert.type}
					alertText={alert.value}
					handleAlertClose={handleAlertClose}
				/>
			)}
			<form className="form" onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-7 my-2">
						<div className="form-group">
							<label htmlFor="currentPassword" className="form-label">
								Current Password
							</label>
							<input
								type={showPassword ? "text" : "password"}
								name="currentPassword"
								id="currentPassword"
								className="form-control"
								onChange={handleChange}
								value={formdata.currentPassword}
							/>
						</div>
					</div>
					<div className="col-md-7 my-2">
						<div className="form-group">
							<label htmlFor="newPassword" className="form-label">
								New Password
							</label>
							<input
								type={showPassword ? "text" : "password"}
								name="newPassword"
								id="newPassword"
								className="form-control"
								onChange={handleChange}
								value={formdata.newPassword}
							/>
						</div>
					</div>
					<div className="col-md-7 my-2">
						<div className="form-group">
							<label htmlFor="confirmPassword" className="form-label">
								Confirm Password
							</label>
							<input
								type={showPassword ? "text" : "password"}
								name="confirmPassword"
								id="confirmPassword"
								className="form-control"
								onChange={handleChange}
								value={formdata.confirmPassword}
							/>
						</div>
					</div>
					<div className="col-8 d-flex my-3">
						<input
							type="checkbox"
							className="form-check-input me-4"
							name="showpassword"
							id="showPassword"
							onChange={toggleShowPassword}
						/>
						<label htmlFor="showPassword" className="form-check-label">
							Show Password
						</label>
					</div>
					<div className="col-6">
						{mutation.isLoading ? (
							<button className="btn btn-primary" type="submit">
								<div className="spinner-border text-light">
									<span className="visually-hidden">loading...</span>
								</div>
							</button>
						) : (
							<button className="btn btn-primary" type="submit">
								Update Password
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}

function Docs() {
	const [imageUrl, setImageUrl] = useState(null);
	const [file, setFile] = useState(null);

	function changeImageHandler(e) {
		let file = e.target.files[0];
		let url = URL.createObjectURL(file);
		setImageUrl((_) => url);
		setFile((_) => file);
	}
	async function uploadDoc(e) {
		try {
			let formdata = new FormData();
			formdata.append("image", file, file.name);

			setImageUrl((_) => null);
			setFile((_) => null);
		} catch (err) {
		} finally {
		}
	}

	return (
		<form className="form">
			<div className="row">
				<div className="col-md-8 my-4">
					<div className="form-group">
						<label htmlFor="certificate" className="form-label">
							Certificate of Ownership
						</label>
						<input
							type="file"
							name=""
							id="certificate"
							className="form-control"
							onChange={changeImageHandler}
						/>
						{imageUrl && (
							<div
								className="preview"
								style={{
									maxHeight: "250px",
									width: "400px",
									margin: "1.5rem 0 ",
								}}
							>
								<img src={imageUrl} alt="preview" className="img-fluid" />
							</div>
						)}
					</div>
				</div>
				<div className="col-md-8 my-4">
					<div className="form-group">
						<label htmlFor="coa" className="form-label">
							Certificate of Incorporation
						</label>
						<input
							type="file"
							name="coi"
							id="coi"
							className="form-control"
							onChange={changeImageHandler}
						/>
						{imageUrl && (
							<div
								className="preview"
								style={{
									maxHeight: "250px",
									width: "400px",
									margin: "1.5rem 0 ",
								}}
							>
								<img src={imageUrl} alt="preview" className="img-fluid" />
							</div>
						)}
					</div>
				</div>
				<div className="col-6">
					<button className="btn btn-success">Submit</button>
				</div>
			</div>
		</form>
	);
}
export function Stripe() {
	const [user, setUser] = useState({});
	const [usertoken, setUsertoken] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const [stripe, setStripe] = useState("");

	const location = useLocation();

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	// const fetchBio = useMutation(getBio, {
	//     onError: (e)=>{
	//         console.error(e)

	//     },
	//     onSuccess:(res)=>{

	//         if(res.data.success){
	//             setAvailable(res.data.payoneer_link)
	//         } else {
	//             console.error(res)
	//         }
	//     }
	// })
	// useEffect(()=>{
	//     fetchBio.mutate({
	//         usertoken,
	//         apptoken: process.env.REACT_APP_APP_TOKEN,
	//     })
	// },[usertoken])

	const mutation = useMutation(addStripeID, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setAlertType({ ...alert, type: "success", value: res.data.message });
				setShowAlert(true);

				// window.open(link, "_blank").focus()
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});

	function handleSubmit(e) {
		e.preventDefault();
		mutation.mutate({
			apptoken: process.env.REACT_APP_APP_TOKEN,
			stripe_id: stripe,
			usertoken,
		});
	}

	return (
		<form className="form" onSubmit={handleSubmit}>
			{showAlert === true && (
				<AlertComp
					variant={alert.type}
					alertText={alert.value}
					handleAlertClose={handleAlertClose}
				/>
			)}
			<div className="row">
				<div className="form-group my-4">
					<label htmlFor="" className="form-label">
						Stripe ID
					</label>
					<input
						type="text"
						name="stripe"
						id="stripe"
						className="form-control"
						onChange={(e) => setStripe(e.target.value)}
						value={stripe}
					/>
				</div>
				<div className="col-12">
					<button
						className="btn btn-primary col-4 text_font"
						disabled={mutation?.isLoading}
					>
						{mutation?.isLoading ? (
							<div className="spinner-border text-white">
								<div className="visually-hidden">Loading...</div>
							</div>
						) : (
							<span>Submit</span>
						)}
					</button>
				</div>
			</div>
		</form>
	);
}
export function Payoneer() {
	const [user, setUser] = useState({});
	const [usertoken, setUsertoken] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const [available, setAvailable] = useState(false);
	const [link, setLink] = useState(null);

	const location = useLocation();

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const fetchBio = useMutation(getBio, {
		onError: (e) => {
			console.error(e);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setAvailable(res.data.payoneer_link);
			} else {
				console.error(res);
			}
		},
	});
	useEffect(() => {
		fetchBio.mutate({
			usertoken,
			apptoken: process.env.REACT_APP_APP_TOKEN,
		});
	}, [usertoken]);

	const mutation = useMutation(signupToPayoneer, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setAlertType({
					...alert,
					type: "success",
					value: `${res.data.message}. Click on the button below to complete registration`,
				});
				setShowAlert(true);
				let link = res.data.payoneerData.result.registration_link;
				setLink(link);
				// window.open(link, "_blank").focus()
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});
	function handleLink(e) {
		e.preventDefault();
		if (link) {
			window.open(link, "_blank").focus();
		}
	}
	function subscribe(e) {
		e.preventDefault();
		mutation.mutate({
			apptoken: process.env.REACT_APP_APP_TOKEN,
			usertoken,
		});
	}

	return (
		<form className="form">
			{showAlert === true && (
				<AlertComp
					variant={alert.type}
					alertText={alert.value}
					handleAlertClose={handleAlertClose}
				/>
			)}
			<div className="row">
				{available ? (
					"Account already linked to payoneer"
				) : (
					<>
						{!link && (
							<div className="col-md-8 my-4">
								<div className="form-group">
									<label htmlFor="payoneer" className="form-label d-block">
										Connect to Payoneer
									</label>
									<button
										className="btn btn-primary text_font text-light"
										onClick={subscribe}
										disabled={mutation.isLoading}
									>
										{mutation.isLoading ? (
											<div className="spinner-border text-light">
												<span className="visually-hidden">loading...</span>
											</div>
										) : (
											"Add Payoneer"
										)}
									</button>
								</div>
							</div>
						)}
						<div className="col-12 my-4">
							{link && (
								<div className="form-group">
									<label
										htmlFor="payoneer"
										className="form-label d-block text-success"
									>
										Payoneer onboarding successful. Click on the button below to
										complete registration
									</label>
									<button
										className="btn btn-primary text_font text-light"
										onClick={handleLink}
										disabled={mutation.isLoading}
									>
										{mutation.isLoading ? (
											<div className="spinner-border text-light">
												<span className="visually-hidden">loading...</span>
											</div>
										) : (
											"Go to Payoneer"
										)}
									</button>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</form>
	);
}
export function Users() {
	const [user, setUser] = useState({});
	const [usertoken, setUsertoken] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});

	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState("");
	const head = [
		"No",
		"Name",
		"Email",
		"Account Type",
		"Country",
		"Date Added",
		"Action",
	];

	const location = useLocation();

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const mutation = useMutation(getAllUsers, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setUsers(res.data.users);
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});
	const approve = useMutation(userApprove, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				mutation.mutate({
					usertoken,
					apptoken: process.env.REACT_APP_APP_TOKEN,
				});
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});

	useEffect(() => {
		mutation.mutate({
			usertoken,
			apptoken: process.env.REACT_APP_APP_TOKEN,
		});
	}, [usertoken]);

	function approveUser(e, token) {
		e.preventDefault();
		approve.mutate({
			apptoken: process.env.REACT_APP_APP_TOKEN,
			admintoken: usertoken,
			usertoken: token,
		});
	}
	return (
		<section>
			{showAlert === true && (
				<AlertComp
					variant={alert.type}
					alertText={alert.value}
					handleAlertClose={handleAlertClose}
				/>
			)}

			{mutation.isLoading ? (
				<div className="spinner-border text-primary">
					<div className="visually-hidden">Loading...</div>
				</div>
			) : (
				<UserTypes
					data={users}
					head={head}
					approveUser={approveUser}
					search={search}
					approve={approve}
				/>
			)}
		</section>
	);
}

function UserTypes({ data, head, approveUser, search, approve }) {
	const location = useLocation();

	const [usertoken, setUsertoken] = useState("");
	const [user, setUser] = useState("");
	const tabs = [
		{
			id: 1,
			title: "DMCS",
			key: "dmc",
			comp: "DMC",
		},
		{
			id: 2,
			title: "ACCOUNT OWNERS",
			key: "changepassword",
			comp: "ACCOUNTOWNER",
		},

		{
			id: 3,
			title: "HOTEL OWNERS",
			key: "hotel_owners",
			comp: "HOTELCHAIN",
		},
		{
			id: 4,
			title: "TRAVELLERS",
			key: "documents",
			comp: "TRAVELLER",
		},
		// {
		//     id:4,
		//     title: "TRAVELLERS",
		//     key:"documents",
		//     comp: "INFLUENCER"
		// }
	];

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	return (
		<Tabs
			defaultActiveKey={tabs[0].key}
			id="uncontrolled-tab-example"
			className="mb-3 title_font"
		>
			{tabs.map(({ id, title, key, comp }) => (
				<Tab eventKey={key} key={id} title={title}>
					<UserType
						comp={comp}
						head={head}
						users={data?.filter((item) => item.role_alt === comp)}
						search={search}
						approve={approve}
						approveUser={approveUser}
					/>
				</Tab>
			))}
		</Tabs>
	);
}

export function UserType({ head, users, search = "a", approve, approveUser, comp, listOnly }) {
	const [user, setUser] = useState({});
	const [usertoken, setUsertoken] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const [available, setAvailable] = useState(false);
	const [link, setLink] = useState(null);
	const [open, setOpen] = useState(false);
	const [data, setData] = useState([]);
	const [type, setType] = useState("");
	const location = useLocation();

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const accountOwnerhead = listOnly ? 
	[
		"No",
		"Name",
		"Email",
		"Account Type",
		"Country",
		"Hotels",
		"DMC",
		"Contracts",
		"Agent",
		"Date Added"
	]
	: [
		"No",
		"Name",
		"Email",
		"Account Type",
		"Country",
		"Hotels",
		"DMC",
		"Contracts",
		"Agent",
		"Date Added",
		"Action",
	];

	function openDetailsModal(data, type) {
        setType(type);
        if(data){
            setData(data);
        }else {
            setData([])
        }
		setOpen(true);
	}

	return (
		<div className="table-responsive">
			<table className="table table-bordered ">
				<thead>
					<tr>
						{comp === "ACCOUNTOWNER"
							? accountOwnerhead.map((item) => <th>{item}</th>)
							: head.map((item) => <th>{item}</th>)}
					</tr>
				</thead>
				<tbody>
					{comp === "ACCOUNTOWNER"
						? users
								.filter(
									(item) =>
										item.roleName
											?.toLowerCase()
											.includes(search.toLowerCase()) ||
										item.country.toLowerCase().includes(search.toLowerCase())
								)
								.map((item, i) => (
									<tr key={i}>
										<td>{i + 1}</td>
										<td
											style={{ minWidth: "120px" }}
										>
											{
												listOnly ? 
												<Link to={`/admin/account-owners/details/${item.token}`}>
													{`${item.fname} ${item.lname}`}
												</Link>
												:
												`${item.fname} ${item.lname}`
											
											}
										</td>
										<td>{item.mail}</td>
										<td style={{ minWidth: "120px" }}>{item.roleName}</td>
										<td>{item.country}</td>
										<td
                                            style={{cursor:"pointer"}}
											onClick={() => openDetailsModal(item?.extraData?.hotels.list, "hotels") } 
                                        >
											{item?.extraData.hotels?.total ? item?.extraData.hotels?.total : "-"}
										</td>
										<td
                                            style={{cursor:"pointer"}}
											onClick={() => openDetailsModal(item?.extraData?.dmc.list, "dmcs") } 
                                        >
											{item?.extraData.dmc?.total ? item?.extraData.dmc?.total : "-"}
										</td>
										<td
                                            style={{cursor:"pointer"}}
											onClick={() => openDetailsModal(item?.extraData?.contracts.list, "contracts") } 
                                        >
											{item?.extraData.contracts?.total ? item?.extraData.contracts?.total : "-"}
										</td>
										<td
                                            style={{cursor:"pointer"}}
											onClick={() => openDetailsModal(item?.extraData?.agents.list, "agents") } 
                                        >
											{item?.extraData.agents?.total ? item?.extraData.agents?.total : "-"}
										</td>
										<td style={{ minWidth: "120px" }}>{item.real_date}</td>
										{/* <td>{item.isApproved === 1 ? "Approved": "Unapproved"}</td> */}
										{
											!listOnly &&
											<td>
												{approve?.isLoading ? (
													<div className="spinner-border text-primary">
														{/* <div className="visually-hidden">Loading...</div> */}
													</div>
												) : (
													<>
														{item.isApproved === 1 ? (
															<button className="btn btn-muted" disabled>
																Approved
															</button>
														) : (
															<button
																className="btn btn-success"
																onClick={(e) => approveUser(e, item.token)}
															>
																Approve
															</button>
														)}
													</>
												)}
											</td>
										}
									</tr>
								))
						: users
								.filter(
									(item) =>
										item.roleName
											?.toLowerCase()
											.includes(search.toLowerCase()) ||
										item.country.toLowerCase().includes(search.toLowerCase())
								)
								.map((item, i) => (
									<tr key={i}>
										<td>{i + 1}</td>
										<td
											style={{ minWidth: "120px" }}
										>
											{
												listOnly ? 
												<Link to={`/admin/dmcs/details/${item.token}`}>
													{`${item.fname} ${item.lname}`}
												</Link>
												:
												`${item.fname} ${item.lname}`
											}
										</td>
										<td>{item.mail}</td>
										<td style={{ minWidth: "120px" }}>{item.roleName}</td>
										<td>{item.country}</td>
										<td style={{ minWidth: "120px" }}>{item.real_date}</td>
										{/* <td>{item.isApproved === 1 ? "Approved": "Unapproved"}</td> */}

										{
											!listOnly &&
											<td>
												{approve?.isLoading ? (
													<div className="spinner-border text-primary">
														{/* <div className="visually-hidden">Loading...</div> */}
													</div>
												) : (
													<>
														{item.isApproved === 1 ? (
															<button className="btn btn-muted" disabled>
																Approved
															</button>
														) : (
															<button
																className="btn btn-success"
																onClick={(e) => approveUser(e, item.token)}
															>
																Approve
															</button>
														)}
													</>
												)}
											</td>
										}
									</tr>
								))}
				</tbody>
			</table>
            <ListModal data={data} type={type} show={open} setShow={setOpen} />
		</div>
	);
}

function ListModal({ show, setShow, data, type }) {
	const [showAlert, setShowAlert] = React.useState(false);
	const [alert, setAlertType] = React.useState({
		type: "",
		value: "",
	});

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal show={show} onHide={handleClose} centered className="modal-size">
			<Modal.Header className="d-flex justify-content-center">
				<h3 className="text-danger text-center">Details</h3>
			</Modal.Header>
			{showAlert === true && (
				<AlertComp
					variant={alert.type}
					alertText={alert.value}
					handleAlertClose={handleAlertClose}
				/>
			)}
			<Modal.Body>
				<div className="row justify-content-center align-items-center">

                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Name</th>
                                    <th>ID</th>
                                    {
                                     type === "Agent" &&  <th>Hotel</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((item, i) => (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{type === "dmcs" ? item?.contractData?.dmc: type === "hotels" ? item?.hotelData.hotelname : type === "agents" ? item?.agentData?.name : item?.contractData.hotelname}</td>
                                            <td>{type === "dmcs" ? item?.contractData?.dmctoken: type === "hotels" ? item?.hotelData.hoteltoken : type === "agents" ? item?.agentData?.token : item?.contractData.token}</td>
                                            {
                                            type === "Agent" &&  <th>{item?.agentData?.hotelname}</th>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
