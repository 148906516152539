import React, { useState, useEffect } from "react";
import { dateConverter, Fetch } from "../../../../../../controllers/controller";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import AlertModal from "../../../../../Reusables/AlertModal/AlertModal";
import SupplementsDetails from "./SupplementsDetails";

const Supplement = ({ contract }) => {
  const style = { 
    minWidth: "100px",
   }
  const [load, setLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [show, setShow] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [payload, setPayload] = useState({});
  const ct = localStorage.getItem("CT");
  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);
  const hotelId = localStorage.getItem("hotel");
  const [suppList, setSuppList] = useState([]);
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      reloadList();
    }

    return () => {
      mounted = false;
    };
  }, [load]);
  function getRoomCategories() {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelId);

    Fetch(`${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        console.log("cate", res)
        if(res.data.success !== false){
          setCategoryList(res.data);
        }
      })
      .catch((err) => {
        console.error(err)
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getRoomCategories()
    }
    return () => {
      mounted = false;
    };
  }, [load]);

  const reloadList = () => {
    const accowner = localStorage.getItem("DMC");
    const use = JSON.parse(accowner);

    const CT = localStorage.getItem("CT");

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", use.usertoken);
    formData.append("hoteltoken", hotelId);
    formData.append("token", CT);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-supplement-plan`, formData)
      .then((res) => {
        if (res.data.message !== false) {
          const sortedSup = res.data.sort((a, b) => {
            let fs = new Date(a.stay_from * 1000).toLocaleDateString();
            let sfs = new Date(fs);
            let ls = new Date(b.stay_from * 1000).toLocaleDateString();
            let sls = new Date(ls);

            return sls - sfs;
          });
          setSuppList(sortedSup);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      contract.status !== "" &&
      contract.contract_name !== "" &&
      contract.child_age_From !== "" &&
      contract.child_age_to !== "" &&
      contract.currency !== ""
    ) {
      setLoad(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

      formData.append("hoteltoken", hotelId);
      formData.append("token", ct);
      formData.append("dmctoken", useableAccOwner.usertoken);

      formData.append("stay_from", payload.stay_from);
      formData.append("stay_to", payload.stay_to);
      formData.append("price_adult", payload.price_adult);
      
      formData.append("price_child", payload.supp_child);
      
      formData.append("service", payload.service);
      formData.append("sup_type", payload.sup_type);
      formData.append("subscription", payload.subscription);
    
      formData.append("supp_room_type", payload.supp_room_type)

      formData.append("adult_amount", payload.adult_amount)
      formData.append("adult_perc", payload.adult_perc)
      formData.append("child_amount", payload.child_amount)
      formData.append("child_perc", payload.child_perc)
      formData.append("supp_child_age_from", payload.child_ageFrom)
      formData.append("supp_child_age_to", payload.child_ageTo)
      
      formData.append("contract_name", contract.contract_name);
      formData.append("status", contract.status);
      formData.append("child_age_From", contract.child_age_From);
      formData.append("child_age_To", contract.child_age_To);
      formData.append("currency", contract.currency);

      Fetch(
        `${process.env.REACT_APP_END_POINT}/supplement-plan-create`,
        formData
      )
        .then((res) => {
          setLoad(false);
          if (res.data.success === true) {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
            setPayload({
              ...payload,
              service: "",
              sup_type: "",
              stay_from: "",
              stay_to: "",
              subscription: "",
              price_adult: "",
              supp_child: "",
              child_from: "",
              child_to: "",
              supp_room_type: "",
              adult_amount: "",
              adult_perc: "",
              child_amount: "",
              child_perc: "",
              child_ageFrom: "",
              child_ageTo: "",
            });
            reloadList();
          } else {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "danger",
              value: res.data.message,
            });
          }
        })
        .catch((err) => {
          reloadList();
          setLoad(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      setShow(true);
    }
  };
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <div className="mb-3 ">
      <AlertModal show={show} setShow={setShow} />

      {showAlert === true && (
        <AlertComp
        variant={alert.type}
        alertText={alert.value}
        handleAlertClose={handleAlertClose}
        />
        )}
        <p className="lead text-primary">SUPPLEMENTS</p>
      <div className="table-responsive w-100  mytable">
        <table className="table table-bordered border-dark ">
          <thead className="bg-primary text-white ">
            <tr>
              <th style={style}></th>
              <th style={style}></th>
              <th  style={style}></th>
              <th style={style}></th>
              <th style={style}></th>
              <th colSpan="2" scope="colgroup"></th>
              <th colSpan="2" scope="colgroup"></th>
              <th colSpan="4" scope="colgroup">Supplement Based on Person in Half Double</th>
              <th colSpan="2" scope="colgroup"></th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th colSpan="2" scope="colgroup">
                STAY
              </th>
              <th colSpan="2" scope="colgroup">
                PRICE
              </th>
              <th colSpan="2" scope="colgroup">
                ADULT
              </th>
              <th colSpan="2" scope="colgroup">
                CHILD
              </th>
              <th colSpan="2" scope="colgroup">
                CHILD AGE
              </th>
             
              <th></th>
            </tr>
            <tr>
              <th>TIMESTAMP</th>
              <th>SERVICE</th>
              <th>SUPPLEMENT</th>
              <th>TYPE</th>
              <th>ROOM CATEGORY</th>
              <th style={style}>FROM</th>
              <th style={style}>TO</th>
              <th  style={style}>ADULT</th>
              <th style={style}>CHILD</th>
              {/* <th style={style}>CHILD TO</th> */}
              <th  style={style}>AMOUNT</th>
              <th  style={style}>%</th>
              <th  style={style}>AMOUNT</th>
              <th  style={style}>%</th>
              <th  style={{minWidth:"50px"}}>FROM</th>
              <th  style={{minWidth:"50px"}}>TO</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td >
                <p>{dateConverter()}</p>
              </td>
              <td>
                {/* <label htmlFor="service" className="visually-hidden">Service</label> */}
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="service"
                  value={payload.service}
                  id="service"
                />
              </td>
              <td>
                {/* <label htmlFor="subscription" className="visually-hidden">Subscription</label> */}
                <select
                  name="subscription"
                  id="subscription"
                  onChange={handleChange}
                  className="form-select"
                  value={payload.subscription}
                >
                  <option defaultValue></option>
                  <option value="per day">Per Day</option>
                  <option value="per stay">Per Stay</option>
                </select>
              </td>
              <td>
                {/* <label htmlFor="supplement" className="visually-hidden">Supplement Type</label> */}
                <select
                  name="sup_type"
                  id="supplement"
                  onChange={handleChange}
                  
                  className="form-select"
                  value={payload.sup_type}
                >
                  <option defaultValue></option>
                  <option value="Per Person">Per Person</option>
                  <option value="Per Booking">Per Booking</option>
                  <option value="per room">Per Room</option>

                </select>
              </td>
              <td>
                {/* <label htmlFor="supplement" className="visually-hidden">Supplement Room Type</label> */}
                <select
                  name="supp_room_type"
                  id="supplement"
                  onChange={handleChange}
                  className="form-select"
                  value={payload.supp_room_type}
                >
                  <option defaultValue></option>
                  {categoryList.length > 0 &&
                    categoryList.map((category) => (
                      <option value={category.roomid}>{category.category}</option>
                    ))}
                </select>
              </td>
              <td>
                {/* <label htmlFor="stay_from" className="visually-hidden">Stay From</label> */}
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  name="stay_from"
                  id="stay_from"
                  value={payload.stay_from}
                />
              </td>
              <td>
                {/* <label htmlFor="stay_to" className="visually-hidden">Stay To</label> */}
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  name="stay_to"
                  id="stay_to"
                  value={payload.stay_to}
                />
              </td>
              <td>
                {/* <label htmlFor="price_adult" className="visually-hidden">Price Adult</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="price_adult"
                  id="price_adult"
                  value={payload.price_adult}
                />
              </td>
              <td>
                {/* <label htmlFor="child_from" className="visually-hidden">Child From</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="supp_child"
                  id="supp_child"
                  value={payload.supp_child}
                />
              </td>
              {/* <td>
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="child_to"
                  id="child_to"
                  value={payload.child_to}
                />
              </td> */}
              <td>
              {/* <label htmlFor="adult_amount" className="visually-hidden">Adult Amount</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="adult_amount"
                  id="adult_amount"
                  value={payload.adult_amount}
                /></td>
              <td >
              {/* <label htmlFor="adult_perc" className="visually-hidden">Adult Percentage</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="adult_perc"
                  id="adult_perc"
                  value={payload.adult_perc}
                />
              </td>
              <td>
              {/* <label htmlFor="child_amount" className="visually-hidden">Child Amount</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="child_amount"
                  id="child_amount"
                  value={payload.child_amount}
                />
              </td>
              <td >
              {/* <label htmlFor="child_perc" className="visually-hidden">Child Percentage</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="child_perc"
                  id="child_perc"
                  value={payload.child_perc}
                />
              </td>
              <td >
                 {/* <label htmlFor="child_ageFrom" className="visually-hidden">Child Age From</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="child_ageFrom"
                  id="child_ageFrom"
                  value={payload.child_ageFrom}
                />
              </td>
              <td>
                 {/* <label htmlFor="child_ageTo" className="visually-hidden">Child Age To</label> */}
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  name="child_ageTo"
                  id="child_ageTo"
                  value={payload.child_ageTo}
                />
              </td>
              <td>
                <button className="btn h-100" onClick={handleSubmit}>
                  {load ? (
                    <div
                      class={load && `spinner-border text-primary`}
                      role="status"
                    >
                      Saving...
                    </div>
                  ) : (
                    <div className="fw-bold">SAVE</div>
                  )}
                </button>
              </td>
            </tr>
          </tbody>
          <tbody className="contracts">
            {suppList.length > 0 &&
              suppList.map((sup, index) => (
                <SupplementsDetails
                  supList={sup}
                  reloader={reloadList}
                  contractTop={contract}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Supplement;
