import React, {useEffect, useState} from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Fetch } from '../../../../../../controllers/controller';

const Markup = ({show, handleClose, token}) => {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    console.log({token})
    useEffect(() => {
        let mounted = true;
        if(token){
            setLoading(true)
            const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN)
            formData.append("token", token)

            Fetch(`${process.env.REACT_APP_END_POINT}/contract-markup-list`, formData)
            .then((res)=>{
                if (mounted) setList(res.data)
            })
            .catch((err)=> console.log(err)).finally(()=>{
                setLoading(false)
            }) 
        }
        return () => {
            mounted = false
        }
    }, [token])
    return (
        <Modal  show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className=" p-3">
                <div className="card-title fw-bold">Markups</div>
                <div className="all-contracts">
                    {
                        loading ? 
                        <div className="spinner-border text-primary">
                            <div className="visually-hidden">Loading</div>
                        </div>
                        :
                        list.length > 0 ? list.map(item=>(
                            <div className="bottom card border shadow ">
                                <div className="card-grid d-flex justify-content-between">
                                    <div className="box-wrapper">
                                        <div className="box">
                                            <p className="text-primary fw-bold">Markup: </p>
                                            <p>{item.markup}</p>
                                        </div>
                                        <div className="box">
                                            <p className="text-primary fw-bold">User: </p>
                                            <p>{item.user_type}</p>
                                        </div>
                                        <div className="box">
                                            <p className="text-primary fw-bold">Contract: </p>
                                            <p>{item.contract_token}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            )):(
                                <p className="lead">No Markup found</p>
                            )
                    }
                    
                    
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Markup
