import React from 'react'
import Card from '../Card/card'

const Dashboard = () => {
    return (
        <div className="pt-5 px-lg-4 px-3 bg-light" style={{minHeight:"90vh"}}>
      <header className="dashHeader">
        <h4 className="text-primary title_font">DASHBOARD</h4>
      </header>
      <section className="admin-dashboard-top mt-5 text-font">
        <Card />

      </section>
    </div>
    )
}

export default Dashboard
