import React, { useState} from 'react'
import { useHistory } from 'react-router-dom'

import HotelGroup from './Hotels/Hotels'
import AddHotel from './Modals/AddHotel'
import Rooms from "../../Reusables/Rooms/Rooms"
import {Route} from "react-router-dom"
import AddExternalHotel from './Hotels/AddExternalHotel'
import AddAllHotels from './Hotels/AddExternalHotel'

const Manage = () => {
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true)
    const handleRemove = () => setOpen(false);

    const history = useHistory();

    function handleExternal(){
        history.push("hotels/add-hotel")
    }
    
    return (
        <div className="pt-4 px-lg-4 px-3 ">
        <header className="d-flex justify-content-between align-items-center dashHeader">
            <h4 className="text-primary">MANAGE HOTELS</h4>
            <div className="add-hotel">
                {/* <button className="btn btn-outline-warning me-1" onClick={handleClick}>Add Hotel</button> */}
                <button className="btn btn-outline-primary me-1" onClick={handleExternal}>Add Hotel</button>
            </div>
        </header>
        <div className="admin-dashboard-top row mt-5">
            <Route exact path={["/account-owner/hotels", "/account-owner/hotels/lists" ]} component={HotelGroup} />
            <Route path="/account-owner/hotels/room" component={Rooms} />
            <Route path="/account-owner/hotels/add-hotel" component={AddAllHotels} />

        </div>
        <AddHotel open={open} handleRemove={handleRemove} />
    </div>
    )
}

export default Manage


