import React, { useContext, useState, useEffect } from 'react'
import { Fetch } from '../../../../../../controllers/controller';
import {useHistory} from "react-router-dom"
import Loading from '../../../../../Reusables/Loader/Loading';
import HotelContext from '../../../../Junior/Manage/Context/hotelcontext';
import Unavailable from '../../../../Junior/Manage/Hotels/Unavailable';
import Hotel from '../HotelsCard/Hotel';
// import AddOwner from '../../../../Junior/Manage/Modals/AddOwner';
// import Deletehotel from '../../../../Junior/Manage/Modals/Deletehotel';
import AddRooms from './Modals/AddRooms';
import Rooms from './Modals/Rooms';
import ListOfHotels from '../../../../Reusables/hotels/HotelList';
import { ListRow } from '../../../DMC/Manage/Hotels/hotels';

const HotelList = () => {
  const [hotelId, setHotelId] = useContext(HotelContext)
  const [loading, setLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [hotels, setHotels] = useState([]);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false)
  const [openRoomsModal, setOpenRoomsModal] = useState(false)

  const history = useHistory()

  useEffect(() => {
    let mounted = true;
    setLoading(true)
    const formData = new FormData();
    const accowner = localStorage.getItem("hotelChain");

    if (accowner) {
      const useableAccOwner = JSON.parse(accowner);
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("token", useableAccOwner.usertoken);

      Fetch(
        `${process.env.REACT_APP_END_POINT}/list-hotel-two`,
        formData
      )
        .then((res) => {
          if (mounted) {
            if(res.data.success !== false){

              setHotels(res.data)
            }
            setLoading(false);
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }

    return () => {
      mounted = false;
    };
  }, []);
  const addRooms = (e) =>{
    setHotelId(e)
    setOpenAddRoomsModal(true)
  }
  const openRooms = (e) =>{
    setHotelId(e)
    localStorage.setItem("hotelDetail", e)
    
  }
  function handleHotelEdit(e, all){
    e.preventDefault()
    localStorage.setItem("hotels-offline-hotel-info", JSON.stringify(all))
    history.push("hotels/edit")
  }
  
  return loading ? (
    <Loading />
  ) : (
    <div className="row justify-content-center">
      
      {hotels.length === 0 ? (<Unavailable />):(
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
            <tr>
                <th>No</th>
                <th>Name</th>
                <th>Description</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                hotels.map((hotel, i) => (
                  <ListRow { ...hotel} i={i} 
                    all={hotel}
                    type={"hotelOwner"}
                    handleAddRoom={addRooms}
                    handleListHotelRooms={openRooms}
                    handleHotelEdit={handleHotelEdit} />
                ))
                

              }
            </tbody>
          </table>
        </div>
        // <Hotel
        //   all={hotel}
        //   key={hotel.hotelId}
        //   title={hotel.hotel_name}
        //   description={hotel.hotel_desc}
        //   date={hotel.date}
        //   time={hotel.timestamp}
        //   image={hotel.image}
        //   hotelID={hotel.hotelid}
        //   handleAddRoom={addRooms}
        //   handleListHotelRooms={openRooms}
        //   handleHotelEdit={handleHotelEdit}
        //   noneAccountOwner="d-none"
        //   addRoom="d-none"
        // />
      )}
      <AddRooms openModal={openAddRoomsModal} handleRemove={()=> setOpenAddRoomsModal(false)} />
      <Rooms openRoomsModal={openRoomsModal} removeRoomsModal={()=> setOpenRoomsModal(false)} />
      
    </div>
  );
}

export default HotelList
