import React, { useState } from "react";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import ContractModal from "./Modal/ContractModal";
import Modals from "./Modal/Modal";
import "./contracts.css";
import Allcontracts from "./Status/AllContracts"
import ContractDetails from "../../../Reusables/contractDetails/ContractDetails";

const Contracts = ({match}) => {
  console.log(match)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  // rooms modal
  const [open, setOpen] = useState(false);
  const handleRemove = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true);
    setShow(false);
  };

  return (
    <main className="pt-3 px-lg-4 px-3" style={{ minHeight: "83vh" }}>
      <header className=" mb-3 d-flex justify-content-between align-items-center">
        <p className="lead fw-bolder text-primary">CONTRACT</p>
        {/* <button className="btn btn-lg-lg btn-primary contract-btn" onClick={handleShow}>
         <span className="button-text"> CREATE CONTRACT</span> 
          <span>
            <i className="bi bi-plus-circle"></i>
          </span>{" "}
        </button> */}
      </header>
      {/* <div className="links d-flex mb-3 ">
        <NavLink
          exact
          to="/hotel-owner/contracts"
          activeClassName="compActive"
          className="me-3 contract-link text-secondary text-decoration-none"
        >
          ALL CONTRACT
        </NavLink>

        <NavLink
          to="/hotel-owner/contracts/completed"
          activeClassName="compActive"
          className="me-3 text-secondary contract-link   text-decoration-none"
        >
          COMPLETED
        </NavLink>
        <NavLink
          to="/hotel-owner/contracts/cancelled"
          activeClassName="compActive"
          className="me-3 text-secondary contract-link   text-decoration-none"
        >
          CANCELLED
        </NavLink>
      </div> */}
      <div className="content">
          <Route exact path={["/hotel-owner/contracts", "/account-owner/contracts/all"]} component={Allcontracts} />
         
          <Route path="/hotel-owner/contracts/detail" component={ContractDetails} />

      </div>
      <Modals
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        handleOpen={handleOpen}
      />
      <ContractModal
        handleClose={handleClose}
        open={open}
        handleRemove={handleRemove}
      />
    </main>
  );
};

export default Contracts;
