import React, { useState, useEffect, useContext } from "react";
import {useLocation} from "react-router-dom"
import {useFormik} from "formik"


import Option from "./Option";
import Meals from "./Meals";
import {BookingContext} from "../../Context/BookingContext"
import PayChoiceModal from "../Payment/PayChoiceModal";
import { mainGetUsertoken } from "../../GetUsertoken/GetUsertoken";

const Options = ({token}) => {

const [book, setBook] = useContext(BookingContext)
const [roomTotal, setRoomTotal] = useState(0)
const [mealsTotal, setMealsTotal] = useState(0)
const [user, setUser] = useState("")
const [amount, setAmount]= useState([])


const location = useLocation()
const [suppTotal, setSuppTotal] = useState(0)
const [usertoken, setUsertoken] = useState("")

const [show, setShow]= useState(false)

const handleClose = () =>{
  setShow(false)
}

const [bookInfo, setBookInfo]= useState({
  apptoken:process.env.REACT_APP_APP_TOKEN,
  hoteltoken:"", 
  contractToken:"",
  usertoken:"",
  adult_number:"",
  child_number:"",
  dmctoken:"",
  start_date:"",
  end_date:"",
  totalAmount:0,
  bookingData:{
    rooms:[],
    meals:{},
    offers:[]
  }
})

  const filter = localStorage.getItem("filter");
  const booking = localStorage.getItem("booking");

  useEffect(()=>{
    mainGetUsertoken(location, setUsertoken, setUser)
  },[])

  useEffect(() => {
    if (filter && booking) {
      const item = JSON.parse(booking);
      const filterItems = JSON.parse(filter);

      setBookInfo({...bookInfo, start_date:filterItems.start_date, end_date:filterItems.end_date,  hoteltoken: item.hoteltoken, dmctoken:item.dmctoken, contractToken:item.contract_id, usertoken:usertoken});
    }
  }, [usertoken]);

  

console.log("bookInfo", bookInfo)
const formik = useFormik({
  initialValues: {
    price: [],
  },

  onSubmit: (values) => {
    console.log(values);
  },
});



useEffect(()=>{
  const filterOptions = JSON.parse(filter)
  const start = new Date(filterOptions.start_date)
  const end = new Date(filterOptions.end_date)

  let difference
  difference = Math.ceil(end - start)/(1000 * 3600 * 24)
  
  if(difference === 0){
    difference = 1
  }
  
  console.log("difference",difference)

  setBook({...book, 
    no_days:difference, total:(Number(roomTotal) + Number(mealsTotal) + Number(suppTotal)) * difference
    })
  setBookInfo({...bookInfo, 
    no_days:difference, totalAmount: (Number(roomTotal) + Number(mealsTotal) + Number(suppTotal))* difference
  })

},[roomTotal, mealsTotal, suppTotal])

console.log("mealstotal",mealsTotal)

function handleBooking(){
  setBook({...book, options: bookInfo})
  setShow(true)
}

  return (
    <section className="booking_options mt-5 mt-lg-0">
      <PayChoiceModal open={show} handleClose={handleClose} amount={book.total} />
      <Option setAmount={setAmount} amount={amount} formik={formik} setRoomTotal={setRoomTotal} bookInfo={bookInfo} setBookInfo={setBookInfo} />
      <Meals  setAmount={setAmount} amount={amount} formik={formik} setMealsTotal={setMealsTotal} bookInfo={bookInfo} setBookInfo={setBookInfo} />
      
      {/* <Meals  setAmount={setAmount} amount={amount} formik={formik} /> */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="booking_option_title" style={{flex: "20%"}}>
          <p className="fw-bold">Tax</p>
        </div>
        <div className="d-flex justify-content-evenly py-3" style={{flex: "80%", borderTop:"1px solid"}}>
          <p>VAT</p>
          <p>14%</p>
        </div>
      </div>
      <div className="d-flex justify-content-end ">
        <div className="form-group d-flex align-items-center me-2">
          <label htmlFor="total" className="form-label fw-bolder booking_options_total">TOTAL</label>
          {/* <p style={{fontSize:"10px"}}>(full stay)</p> */}
          <input type="text" name="total" id="total" className="form-control ms-4" disabled value={book.total}  />
        </div>
        <button className="btn btn-success" type="button" onClick={handleBooking} >Book Now</button>
      </div>
      <div className="d-flex justify-content-end ">
        <div className="form-group d-flex align-items-center">
        </div>
      </div>
    </section>
  );  
};

export default Options;
