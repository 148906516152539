import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useMutation } from 'react-query'
import {useHistory, useLocation} from "react-router-dom"
import AddRooms from '../../Junior/Manage/Modals/AddRooms'
import { deleteHotelRoom } from '../../Super/apis'
import { mainGetUsertoken } from '../GetUsertoken/GetUsertoken'

const RoomListsCard = ({name, price, available, time , image, details}) => {
    const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false)
    
    const navigate = useHistory()
    const location = useLocation();

    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser)
    
    },[])

    const deleteMutation = useMutation(deleteHotelRoom, {
        onSuccess: (res)=>{
            console.log(res)
            navigate.push(-1)
        },
        onError: (err)=>console.error(err)
    });

    function deleteRoom(){
        if (window.confirm("Delete Room?")) {
            deleteMutation.mutate({
                apptoken:process.env.REACT_APP_APP_TOKEN,
                roomId: details?.id
            })

        }
          
    }

    return (
        <div className="mb-5 col-sm-10">
            <div className="actions mb-1">
                {/* <Link to={``}></Link> */}
                {
                    (location.pathname.includes("account-owner") || location.pathname.includes("hotel-owner") || location.pathname.includes("admin")) &&
                    <>
                        <button className="text-light bg-secondary me-4 hotel-button" onClick={()=>setOpenAddRoomsModal(true)}><i className="bi bi-pen me-1"></i>Edit</button>
                        <button className="text-light bg-danger me-4 hotel-button" onClick={deleteRoom}><i className="bi bi-pen me-1"></i>Delete</button>
                    </>
                }
                
            </div>
            <div className="card">
                <div className="card-img-top card-top-wrap">
                    <div className="hotel-card-links d-flex justify-content-center align-items-center" style={{backgroundImage:`url(https://${image})`, backgroundColor:"rgba(0, 0, 0, 0.74)", backgroundBlendMode:"multiply", backgroundSize:"cover", paddingTop:"74px", paddingBottom:"74px"}}>
                        <div className="links-wrapper d-flex justify-content-center align-items-center">
                        </div>
                    </div>
                </div>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <div className="left">
                        <div className="card-title fw-bolder lead text-success">{name}</div>
                        <p>{available}</p>
                        <div>
                        <small className="text-muted">Last updated: <span className="me-3">{new Date(time * 1000).toDateString()}</span><span>{new Date(time * 1000).toLocaleTimeString()}</span></small> 
                        </div>
                    </div>
                    <div className="right">
                        <p># {price}</p>
                        <button className="btn text-white" style={{backgroundColor:"rgba(17, 172, 51, 1)"}}>Publish</button>
                    </div>
                </div>
            </div>
            <AddRooms openModal={openAddRoomsModal} handleRemove={()=> setOpenAddRoomsModal(false)} details={details} />

        </div>
    )
}

export default RoomListsCard
