import React from "react";
import { Modal, Button } from "react-bootstrap";

import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";
import * as Yup from "yup";
import PDF from "../Receipt/PDF";
import RequestError from "../../../404/RequestError";
import { Stripe } from "../Settings";
import { connectUserToStripe } from "../../Super/apis";
import {useMutation} from "react-query"

const StripeModal = ({ open, setOpen, usertoken }) => {

  const connectStripe = useMutation(connectUserToStripe, {
    onSuccess: res=>console.log(res),
    onError: err => console.error(err),
  })
  

  const handleClose = () => {
    setOpen(false);
  };


  const handleConnect = (e) => {
    e.preventDefault();
    connectStripe.mutate({
      usertoken,
      apptoken: process.env.REACT_APP_APP_TOKEN
    })
  }

  
  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header>
        <h3 className="text-primary title_font">Connect Stripe</h3>
      </Modal.Header>
      <Modal.Body>
            <div className="row justify-content-center">
                <div className="col-sm-6">
                    {/* <Stripe />           */}
                    <button className="btn btn-primary" onClick={handleConnect} disabled={connectStripe.isLoading}>
                      {
                        connectStripe.isLoading ? 
                        <div className="spinner-border text-white">
                          <div className="visually-hidden">loading...</div>
                        </div>
                        :
                        "Connect to Stripe"
                      }
                    </button>
                </div>
            </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StripeModal;
