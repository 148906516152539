import React, { useState } from "react";
import { dateConverter, TimestampConverter } from "../controllers/controller";
import ReadOnlyTest from "./ReadOnlyTest";

const ReadOnlyContract = ({ date, lists, list, handleContractEdit, index}) => {
  // const [editable, setEditable] = useState(true);
  return (
 
    <tbody className="contracts">
    <tr>
      <td className="oneFullColumn">
        <p>{dateConverter()}</p>
      </td>
      <td className=""><p>{TimestampConverter(date.start_date)}</p></td>
      <td className="">
        <p>{TimestampConverter(date.exp_date)}</p>
      </td>
      <ReadOnlyTest lists={date} />
      <td>
      <button className="btn" onClick={(e)=>handleContractEdit(e, index)}>EDIT</button>
      </td>
    </tr>
  </tbody>
    
  );
};

export default ReadOnlyContract;
