import React, { useEffect } from 'react'
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import AlertComp from '../../../Reusables/AlertComp/AlertComp'
import Loading from '../../../Reusables/Loader/Loading';
import { mainGetUsertoken } from '../../Reusables/GetUsertoken/GetUsertoken';
import { fetchAdminPackages, fetchAdminServices } from '../apis';
import {CreatePackageModal} from '../Packages/Packages';
import CreateService from './CreateService';

const Services = ({type}) => {

    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");
    const [services, setServices] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    })
    const [search, setSearch] = useState("")

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
      }, []);
  
    const handleAlertClose = () => {
        setShowAlert(false);
    };


    const fetchServices = useMutation(fetchAdminServices, {
        onSuccess: res => {
            console.log({res})
            if(res?.data?.services){
                setServices(res.data.services)
            }
        },
        onError: err => console.error(err)
    })
    const fetchPackages = useMutation(fetchAdminPackages, {
        onSuccess: res => {
            console.log({res})
            if(res?.data?.packages){
                setServices(res.data.packages)
            }
        },
        onError: err => console.error(err)
    })


    useEffect(()=>{
        if(usertoken){
            if(type !== "package"){
                fetchServices.mutate({usertoken})
            }else {
                fetchPackages.mutate({usertoken})
                
            }
        }
    },[usertoken])

    
    
    const tableHeaders 
    = type === "package" ? ["S/N", "Name","Creator", "Country", "Sharing", "Youtube"] : ["S/N", "Start Date", "End Date", "Name", "Location", "Price adult", "Price child", "Discount", "Discount from", "Discount To", "Min Pax Discount" ]



    // FOR PACKAGES && SERVICES
    const [openCreatePackage, setOpenCreatePackage] = useState(false)

    const [openCreateService, setOpenCreateService] = useState(false)

    function  openPackageModal(){
        setOpenCreatePackage(true)
    }


    function  openServiceModal(){
        setOpenCreateService(true)
    }



    const handleSearch = (e) => {
        setSearch(e.target.value);
      };
  return (
    <div className="pt-4 px-lg-4 px-3 ">
        <header className="d-flex justify-content-between align-items-center dashHeader">
            <h4 className="text-primary">{type === "package" ? "Packages": "Services"}</h4>
            {
                type === "package" ?
                <button
                    className={ 
                        location.pathname === "/admin/packages/create"
                        ? "d-none"
                        : `btn btn-lg-lg btn-primary contract-btn`
                    }
                    onClick={openPackageModal}
                >
                    <span className="button-text me-2 ">CREATE A PACKAGE</span>
                    <span>
                        <i className="bi bi-plus-circle"></i>
                    </span>{" "}
                </button>
                :
                <button className="btn" style={{background:"blue", color:"#fff"}} type="button" onClick={()=>setOpenCreateService(true)}>Add Service</button>
            }
        </header>
         <div className="admin-dashboard-top mt-2">
            {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
                )
            }
            <div className="search row justify-content-start align-items-center my-3" style={{gap:"1rem"}} >
                <div className="col-md-5">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="search..."
                    value={search}
                    onChange={handleSearch}
                    />
                </div>
            </div>
            <div className="admin_hotel">
                {
                    fetchPackages?.isLoading  ||fetchServices?.isLoading ? 
                    <Loading /> :
                    <div className="table-responsive">
                        <table className="table table-bordered admin_hotel_table position-relative">
                        <thead>
                            <tr>
                            {
                                tableHeaders.map(item => (
                                <th>{item}</th>
                                ))
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                type === "package" ?

                                services?.filter((item)=>item.package_name?.includes(search) || item.country?.includes(search))?.map((item, i)=> (
                                    <tr>
                                        <td>{i + 1} </td>
                                         <td>{item.package_name}</td>
                                         <td>{item.creator}</td>
                                         <td>{item.country}</td>
                                         <td>{item.sharing_link}</td>
                                         <td>{item.youtube_link}</td>
                                        {/*<td>{item.hoteltoken}</td>
                                        <td>
                                            <button className="btn btn-danger" onClick={()=>remove(item.hoteltoken)} disabled={deleteHotelFromDmc?.isLoading}>
                                                {
                                                    deleteHotelFromDmc.isLoading ? <div className="spinner-border text-white"></div> :
                                                    <span>Delete</span>
                                                }
                                            </button>
                                        </td> */}
                                    </tr>
                                ))
                                :
                                services?.filter((item)=>item.service_name?.includes(search) || item.location?.includes(search)).map((item, i)=> (
                                    <tr>
                                        <td>{i + 1} </td>
                                        <td>{item.real_date_from}</td>
                                        <td>{item.date_to}</td>
                                        <td>{item.service_name}</td>
                                        <td>{item.location}</td>
                                        <td>{item.price_adult}</td>
                                        <td>{item.price_child}</td>
                                        <td>{item.discounts}</td>
                                        <td>{item.real_discount_from}</td>
                                        <td>{item.real_discount_to}</td>
                                        <td>{item.min_pax_discount}</td>
                                        {/* <td>{item.hotelname}</td>
                                        <td>{item.hoteltoken}</td> */}
                                        {/* <td>
                                            <button className="btn btn-danger" onClick={()=>remove(item.hoteltoken)} disabled={deleteHotelFromDmc?.isLoading}>
                                                {
                                                    deleteHotelFromDmc.isLoading ? <div className="spinner-border text-white"></div> :
                                                    <span>Delete</span>
                                                }
                                            </button>
                                        </td> */}
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                    </div>
                }
                
            </div>
        </div>
        <CreatePackageModal show={openCreatePackage} setShow={setOpenCreatePackage} />
        <CreateService show={openCreateService} setShow={setOpenCreateService} />
    </div>
  )
}

export default Services