import React, { useState, useEffect } from "react"; 
import { useFormik } from "formik";
import { Fetch } from "../../../../controllers/controller";
import Loading from "../../../Reusables/Loader/Loading";
import useFetch from "../../../Reusables/Fetch/Fetch";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import SpecialTable from "./SpecialTable";
import {getAllUsers} from "../apis";
import {useMutation} from "react-query"
import { useLocation } from "react-router-dom";
import { mainGetUsertoken } from "../../Reusables/GetUsertoken/GetUsertoken";

const Special = () => {
  const [loading, setLoading] = useState(false);
  const [loadUser, setLoadUser] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [recipient, setRecipient] = useState([]);
  const [showForm, setShowForm] = useState(false)
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const location = useLocation();
  const [usertoken, setUsertoken] = useState("")
  const [user, setUser] = useState("")

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser)

},[])

  const formData = new FormData();
  formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
  const { data } = useFetch("/list-markup", formData);

  const nformData = new FormData();
  nformData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

  const { data: dmc } = useFetch("/list-dmc", nformData);
  const [search, setSearch] = useState("")

  const formik = useFormik({
    initialValues: {
      markup: "",
      markup_id: "",
      user_type: "",
    },

    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("usertoken", values.user);
      formData.append("markup", values.markup);
      formData.append("markup_id", values.markup_id);
      formData.append("user_type", values.user_type);
      Fetch(`${process.env.REACT_APP_END_POINT}/special-markup`, formData)
        .then((res) => {
          console.log({res})
          setLoading(false);
          setShowAlert(true);
          if(res.data.success){
            setAlertType({ ...alert, type: "success", value: res.data.message });
            setShowForm(false)
          
          } else {
            setAlertType({ ...alert, type: "danger", value: res.data.message });
          }
        })
        .catch((err) => {
          console.error(err)  
          setAlertType({ ...alert, type: "danger", value: "Something went wrong" });
        });
    },
  });


  const mutation = useMutation(getAllUsers, {
    onError: (e)=>{
        console.error(e)
        setAlertType({ ...alert, type: "danger", value: e.message });
        setShowAlert(true)
    },
    onSuccess:(res)=>{
        if(res.data.success){
          let agent = res.data.users.filter(item=>item.role_alt === "INFLUENCER")
          setRecipient(agent)
            
            
            
        } else {
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        }
    }
})
  useEffect(() => {
    let mounted = true;
    if (formik.values.user_type === "accountOwner") {
      setLoadUser(true)
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      Fetch(`${process.env.REACT_APP_END_POINT}/list-accountowner`, formData)
        .then((res) => {
          setLoadUser(false)
          if (mounted) {
            setRecipient(res.data);
          }

        })
        .catch((err) =>{
          setLoadUser(false)
          window.alert(err)
          });
    } else if (formik.values.user_type === "agent") {
      mutation.mutate({
        usertoken,
        apptoken: process.env.REACT_APP_APP_TOKEN,
      })
    }

    return () => {
      mounted = false;
    };
  }, [formik.values.user_type]);


  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="row justify-content-center">
      {loading === true ? (
        <div className="spinner-border text-primary">
          <div className="visually-hidden">Loading...</div>
        </div>
      ) : (
        <div className="wrap col-12 rounded p-4 border shadow">
          
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex w-100 justify-content-between mb-2 ">
              <div className="search d-flex align-items-center" style={{gap:"1rem"}} >
                <input
                  type="text"
                  className="form-control"
                  placeholder="search..."
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div style={{flex: "50%"}}>
              <button className="btn btn-primary" onClick={()=>setShowForm(!showForm)}>{showForm ? "Close Form" : "Create Commission"}</button>
            </div>
          </div>

          {
            showForm && 
            <form onSubmit={formik.handleSubmit} className="p-4 g-3 ">
              {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}

              <div className="row g-3">
                <div className="form-group col-6 col-sm-3 px-1">
                  <label htmlFor="markup" className="form-label visually-hidden">
                    User Type
                  </label>
                  <select
                    name="user_type"
                    id="user_type"
                    className="form-select border border-dark"
                    onChange={formik.handleChange}
                    value={formik.values.user_type}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" defaultValue>
                      User Type
                    </option>
                    <option value="accountOwner">Account Owner</option>
                    <option value="agent">Agent</option>
                    {/* {dmc.length > 0 &&
                      dmc.map((item) => (
                        <option value={item.token}>{item.mail}</option>
                      ))} */}
                  </select>
                </div>
                <div className="form-group col-6 col-sm-3 px-1">
                  <label htmlFor="markup" className="form-label visually-hidden">
                    User
                  </label>

                  {
                    mutation.isLoading || loadUser ? 
                    <div className="spinner-border text-primary">
                      <div className="visually-hidden">Loading</div> 
                      </div>
                    :
                    <select
                      name="user"
                      id="user"
                      className="form-select border border-dark"
                      onChange={formik.handleChange}
                      value={formik.values.user}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" defaultValue>
                        User
                      </option>
                      {recipient.length > 0 &&
                        recipient.map((item) => (
                          <option
                            value={item.hotelid || item.token}
                            dangerouslySetInnerHTML={{
                              __html: item.hotel_name || item.mail,
                            }}
                          />
                        ))}
                    </select>
                  }
                </div>
                <div className="form-group col-6 col-sm-3 px-1">
                  <label htmlFor="markup" className="form-label visually-hidden">
                    Commissions
                  </label>
                  <input
                    type="number"
                    name="markup"
                    id="markup"
                    className="form-control border border-dark"
                    Placeholder="Commissions"
                    onChange={formik.handleChange}
                    value={formik.values.markup}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* <div className="form-group col-lg-3 col-sm-3 col-6 px-1">
                  <label
                    htmlFor="recipient"
                    className="form-label visually-hidden"
                  >
                    Markups
                  </label>
                  <select
                    name="markup_id"
                    id="markup_id"
                    className="form-select border border-dark"
                    onChange={formik.handleChange}
                    value={formik.values.hotel}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" default>
                      Choose Markup
                    </option>
                    <option value="all_markups">All Markups</option>
                    {data.length > 0 &&
                      data.map((item) => (
                        <option value={item.id}>{item.contract_token}</option>
                      ))}
                  </select>
                </div> */}

                <div className="d-flex col-sm-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          }
          <SpecialTable search={search} />
        </div>
      )}
    </div>
  );
};

export default Special;
