import React, { useState, useEffect } from "react";
import Info from "./Info";
import Options from "./Options";
import { useParams } from "react-router-dom";
import { Fetch } from "../../../../../controllers/controller";
const BookingDetails = () => {
  //   const { token } = useParams();
  const [loading, setLoading] = useState(true);

   const [amenities,setAmenities] = useState([])

    useEffect(() => {
      let mounted = true;
     const all = localStorage.getItem("booking")

      if (all ) {
      const item = JSON.parse(all)

        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("roomtoken", item.roomtoken);
        Fetch(`${process.env.REACT_APP_END_POINT}/list-room-amenities`, formData)
          .then((res) => {
            setLoading(false);
            if (mounted) {
              if (res.data.success !== false) {
                setAmenities(res.data)
              } else {
                window.alert(res.data.message);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
      return () => (mounted = false);
    }, []);
  return (
    <section className="booking_details_wrapper d-md-flex  bg-light">
      <Info amenities={amenities} />
      <Options />
    </section>
  );
};

export default BookingDetails;
