import React from 'react'

const SectionHeader = ({title, lead}) => {
    return (
        <header className="section-header">
            <h1 className="listing__Header">{title}</h1>
            <p className="lead listing__subheader">{lead}</p>
        </header>
    )
}

export default SectionHeader
