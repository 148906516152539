import "./App.css";
import {useState} from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Landing from "./components/landing/Landing";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Super from "./components/Admins/Super/super";
import Activate from "./components/Activation/Activate";
import E404 from "./components/404/404";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import { PrivateRoute } from "./components/Auth/Auth";
import Junior from "./components/Admins/Junior/Junior";
import DMC from "./components/Admins/Others/DMC/dmc";
import Traveller from "./components/Admins/Others/Traveller/traveller";
import HotelState from "./components/Admins/Junior/Manage/Context/hotelState";
import ChangePassword from "./components/ChangePassword/changePassword";
import HotelOwner from "./components/Admins/Others/hotelOwner/hotelOwner";
import PrivateHotelOwner from "./components/Auth/HotelOwnerAuth";
import PrivateJunior from "./components/Auth/AccOwnerAuth";
import PrivateDMC from "./components/Auth/DmcAuth";
import PrivateUser from "./components/Auth/UserAuth";
import ContractDetails from "./components/Admins/Reusables/contractDetails/ContractDetails";
import NewContract from "./components/Admins/Others/DMC/Contracts/NewContract";
// import ContractDate from "./components/allContract";
import Pdf from "./components/Pdf";
import { WalletContext } from './components/Admins/Reusables/Wallet/WalletContext';
import {BookingContext} from "./components/Admins/Reusables/Context/BookingContext"
import {MyMap} from "./components/Reusables/Map/Index"
import GeneralContextProvider from "./components/Admins/Junior/Manage/Context/generalContext";
import DataTable from "./components/Admins/Others/DMC/Manage/Hotels/sortTable";

function App() {
  const [data, setData] = useState([])
  const [book, setBook]= useState({
    total:0,
    options:{}
  })
  return (
    <WalletContext.Provider value={[data, setData]}>
      <BookingContext.Provider value={[book, setBook]}> 
        <GeneralContextProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/activate/:token" component={Activate} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/pdf" component={Pdf} />
              <Route path="/test" component={DataTable} />

              <PrivateJunior path="/:pathname/contract/details" component={ContractDetails}/>
              <PrivateJunior path="/account-owner" component={Junior} />
              <PrivateRoute path="/admin" component={Super} />
              <PrivateDMC path="/dmc" component={DMC} />

              <PrivateHotelOwner path="/hotel-owner/:all" component={HotelOwner} />
              <PrivateHotelOwner path="/hotel-owner" component={HotelOwner} />
              <PrivateUser exact path="/user" component={Traveller} />
              {/* no influencer page yet */}
              <PrivateRoute exact path="/influencer" component={Traveller} />
              <PrivateRoute path="/user/:sub" component={Traveller} />
              <PrivateRoute path="/:id/change-password" component={ChangePassword} />

              <Route path="*" component={E404} />
            </Switch>
          </Router>
        </GeneralContextProvider>   
      </BookingContext.Provider>
    </WalletContext.Provider>

       
  )
}

export default App;
