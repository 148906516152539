import React, {useState, useEffect, useContext} from "react";
import { Modal } from "react-bootstrap";
import { Fetch } from "../../../../../controllers/controller";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import HotelContext from "../Context/hotelcontext";


const AddOwner = ({ handleRemoveOwner, openOwnerModal }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [hotelId] = useContext(HotelContext)

  useEffect(() => {
    const accowner = localStorage.getItem("accountOwner");
    if (accowner) {
        const useableAccOwner = JSON.parse(accowner);
        setPayload({...payload, usertoken: useableAccOwner.usertoken})
    }
  }, [])
  
  const [payload, setPayload] = useState({
      apptoken: process.env.REACT_APP_APP_TOKEN,
      hotelowner: "",
      hoteltoken: hotelId,
      usertoken: "", 
    
  })
  
  const handleChange = (e) => { 
      setPayload({...payload, hoteltoken: hotelId})
      setPayload({...payload, hotelowner: e.target.value})
    }

  const submitHotelOwner = (e) => {
    const formData = new FormData()
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN )
    formData.append("account_owner", payload.usertoken )
    formData.append("hotel_owner", payload.hotelowner)
    formData.append("hoteltoken", hotelId)
    setLoading(true)

    Fetch(`${process.env.REACT_APP_END_POINT}/add-hotel-owner-hotels`, formData)
    .then( (res) => {
      
    setLoading(false);
    setShowAlert(true);
    setAlertType({ ...alert, type: "success", value: res.data.message });

  })
  .catch((err) => {
    setLoading(false);
    setShowAlert(true);
    setAlertType({ ...alert, type: "danger", value: err.message });
  });
  }
    const handleAlertClose =() =>{
      setShowAlert(false);
    }
  return  (
    <>
      <Modal show={openOwnerModal} onHide={handleRemoveOwner}>
        {loading ? (<Loading />):(
          <div className="py-5 px-5 modals">
              <h4 className="text-center">Add Hotel Owner</h4>
          <Modal.Body className="mb-5">
            <form className="row justify-content-center">
              <div className="form-left col-md-6">
              {showAlert === true && (
                    <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                  )}
                <div className="status mb-3">
                  <label htmlFor="hotelname" className="mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >
                    Owner's email address
                  </label>
                  <input type="email" name="hotelOwner" id="hotelOwner" className="form-control" onChange={handleChange}  required value={payload.hotelowner} />
                </div>
                <div className="d-flex justify-content-between">
                <button className="btn btn-primary"  onClick={submitHotelOwner} >
                Add Owner
                </button>
                <button className="btn btn-secondary"  onClick={handleRemoveOwner} >
                Close
                </button>
                </div>
                </div>
            </form>
          </Modal.Body>
        </div>
      )}
      </Modal>
    </>
  );
};

export default AddOwner;
