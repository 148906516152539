import axios from "axios";
import {useState, useEffect} from "react";

const url = process.env.REACT_APP_END_POINT;


export default function useFetch(addendum, formData, loader, user) {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [alert, setAlertType] = useState({ 
        type: "",
        value: "",
    })
    const [showAlert, setShowAlert] = useState(false);
    
    useEffect(() => {
        async function init() {
            try {
                const response = await axios.post(url + addendum, formData, {
                    headers: {
                      "content-type": "multipart/form-data",
                    }
                })
                setData(response.data)
                setAlertType({...alert, type: "success", value: response.data.message})
                setShowAlert(true)
                
            }catch (e) {
                setError(e.message);
                setAlertType({...alert, type: "danger", value: e.message})
                setShowAlert(true)
            } finally {
                setLoading(false);
            }
        }
        init()
    }, [url, loading, loader, user])

    return {data, error, loading, setLoading, alert, showAlert, setShowAlert};
}