import React from "react";
import {FaBed, FaUsers, FaBath} from "react-icons/fa";
import Ruler  from "../../../Images/Ruler.png"

const RentalCards = ({ cardImage, cardTitle, rating, reviews }) => {
  return (
    <div className="card rental__card">
      <div className="image-wrapper w-100">
        <img src={cardImage} className="rental__image" style={{height:'244px', width: "100%"}} alt="..." />
        <p className="text-light rental__image__text">$0 /day</p>
      </div>
      <div className="card-body">
        <h5 className="card-title title">{cardTitle}</h5>
        <div className="d-flex justify-content-between align-items-center">
          <div className="rating text-primary">{rating}</div>
          <div className="reviews text-muted">{reviews}</div>
        </div>
        <hr />
        <div className="icons-section">
          <div className="row">
            <div className="col-3 col-md-6 col-lg-3 ">
              <i className="me-2" style={{color: "rgba(0, 0, 0, 1)"}}><FaUsers /></i>
              <span>8</span>
            </div>
            <div className="col-3 col-md-6 col-lg-3">
              <i className="me-2" style={{color: "rgba(0, 0, 0, 1)"}}><FaBed /></i>
              <span>3</span>
            </div>
            <div className="col-3 col-md-6 col-lg-3 ">
              <i className="me-2" style={{color: "rgba(0, 0, 0, 1)"}}><FaBath /></i>
              <span>8</span>
            </div>
            <div className="col-3 col-md-6 col-lg-3 d-lg-flex ">
              <i className="m" style={{color: "rgba(0, 0, 0, 1)"}}>
                    <img src={Ruler} alt="ruler icon" />
              </i>
              <span>123</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalCards;
