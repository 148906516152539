import React, {useState, useEffect} from 'react'
import { dateConverter, Fetch } from '../controllers/controller';
import AlertComp from './Reusables/AlertComp/AlertComp';
import Penaltymodal from './Admins/Others/DMC/Contracts/Modal/Penaltymodal';
import AlertModal from './Reusables/AlertModal/AlertModal';
import { addCancelPolicy } from './Admins/Super/apis';
import {useMutation} from "react-query"
const Penalty = ({contract, children}) => {
    const [penalty, setPenalty] = useState({
        cancel_start: '',
        cancel_end: "", 
        cancel_days:"",
        cancel_penalty:""
      });
    const [PenalList, setPenalList] = useState([])
      const [load, setLoad] = useState(false);
      const [showAlert, setShowAlert] = useState(false);
      const [alert, setAlertType] = useState({
          type: "",
          value: "",
      });
      const [show, setShow] = useState(false);

      const accowner = localStorage.getItem("DMC");
      const useableAccOwner = JSON.parse(accowner);
      const hotelId = localStorage.getItem("hotel");
      const ct = localStorage.getItem("CT");

    
      const handleCancellation = (e) => {
        setPenalty({ ...penalty, [e.target.name]: e.target.value });
      };

      
     
    const [reloadCancel, setReloadCancel] = useState(false)

     useEffect(() => {
      let mounted = true;
      const accowner = localStorage.getItem("DMC");
      const use = JSON.parse(accowner);
  
      const CT = localStorage.getItem("CT");
  
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("dmctoken", use.usertoken);
      formData.append("hoteltoken", hotelId);
      formData.append("token", CT);
  
      Fetch(
        `${process.env.REACT_APP_END_POINT}/list-cancel-plan`,
        formData
      )
        .then((res) => {
        if(res.data.length > 0) {

          const sortedPen = res.data.sort((a, b)=>{
            let fp = new Date(a.cancel_start * 1000).toLocaleDateString()
            let sfp = new Date(fp);
            let lp = new Date(b.cancel_start * 1000).toLocaleDateString();
            let slp = new Date(lp);
            return slp - sfp
          })
          if (mounted) {
            setPenalList(sortedPen);
          }
        }
        })
        .catch((err) => {
         window.alert(`Penality list:${err.message}`)
        })
      return () => {
        mounted = false;
      };
    }, [alert, reloadCancel])



    const getCancel = () => {
      const accowner = localStorage.getItem("DMC");
      const use = JSON.parse(accowner);
  
      const CT = localStorage.getItem("CT");
  
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("dmctoken", use.usertoken);
      formData.append("hoteltoken", hotelId);
      formData.append("token", CT);
  
      Fetch(
        `${process.env.REACT_APP_END_POINT}/list-cancel-plan`,
        formData
      )
        .then((res) => {
         
        if(res.data.length > 0) {
            const sortedPen = res.data.sort((a, b)=>{
            let fp = new Date(a.cancel_start * 1000).toLocaleDateString()
            let sfp = new Date(fp);
            let lp = new Date(b.cancel_start * 1000).toLocaleDateString();
            let slp = new Date(lp);
            return slp - sfp
          })
         
            setPenalList(sortedPen);
        }
        })
        .catch((err) => {
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "danger",
            value: err.message,
          });
        });
    }
      const handlePenaltySave = (e) => {
        e.preventDefault();
        if(contract.status !== "" && contract.contract_name !== ""  && contract.child_age_From !== ""  &&contract.child_age_to !== "" && contract.currency !== ""  ){
        
        setLoad(true)
        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

        formData.append("hoteltoken", hotelId);
        formData.append("token", ct);
        formData.append("dmctoken", useableAccOwner.usertoken);
        formData.append("contract_name", contract.contract_name);
        
        formData.append("id", penalty.id);
        formData.append("cancel_end", penalty.cancel_end);
        formData.append("cancel_start", penalty.cancel_start);
        formData.append("cancel_days", penalty.cancel_days);
        formData.append("cancel_type", penalty.cancel_type);
        formData.append("cancel_penalty", penalty.cancel_penalty);

        formData.append("status", contract.status);
        formData.append("child_age_From", contract.child_age_From);
        formData.append("child_age_To", contract.child_age_To);
        formData.append("currency", contract.currency);
       
     
        Fetch(
          `${process.env.REACT_APP_END_POINT}/cancel-plan-update`,
          formData
        )
          .then((res) => {
            setLoad(false)
            if(res.data.success === true){

            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
            setPenalty({...penalty, cancel_start: "", cancel_end:"", cancel_days:"", cancel_penalty:"", cancel_type:""})
            getCancel();
            // window.location.reload()
            } else {
              setShowAlert(true);
              setAlertType({
                ...alert,
                type: "danger",
                value: res.data.message,
              });
            }
          })
          .catch((err) => {
            setLoad(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "danger", value: err.message });
          });

        } else {
         setShow(true);
        }
      };
      const handleAlertClose =() =>{
        setShowAlert(false);
      }


    // POLICY

      const [data, setData]= useState("")

      const mutation = useMutation(addCancelPolicy, {
        onSuccess:  (res)=> {
          console.log(res)
          if(!res.data.success){
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "danger",
              value: res.data.message,
            });
            return
          }
          setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
        },
        onError: (err)=> console.error(err)
      })

      function handlePolicySubmit(e){
        e.preventDefault();
        mutation.mutate({policy_content:data, contractToken:ct, apptoken:process.env.REACT_APP_APP_TOKEN})
      }
    // POLICY ENDS

    return (
      <div className="mt-4">
        <p className="lead text-primary">CANCELLATION POLICY</p>

          <div className="table-responsive text-center mytable">
            {showAlert === true && (
            <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose}/>
            )} 

            <table className="table table-bordered border-dark w-75">
                <thead className="bg-primary text-white">
                    <tr>
                      <th></th>
                        <th colSpan="2" scope="colgroup">
                            DATE
                        </th>
                        <th></th>
                        <th colSpan="2" scope="colgroup">
                            CANCELLATION
                        </th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>TIMESTAMP</th>
                        <th>FROM</th>
                        <th>TO</th>
                        <th>TYPE</th>
                        <th>DAYS</th>
                        <th>PENALTY %</th>
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <input type="text" className="form-control" name="timestamp" disabled value={dateConverter()} />
                    </td>
                    <td>
                        <input type="date" className="form-control" name="cancel_start" onChange={handleCancellation} value={penalty.cancel_start} />
                    </td>
                    <td>
                        <input type="date" className="form-control" name="cancel_end" onChange={handleCancellation} value={penalty.cancel_end} />
                    </td>
                    <td style={{minWidth:"100px"}}>
                      <select name="cancel_type" id="cancel_type" value={penalty.cancel_type} onChange={handleCancellation} style={{minWidth:"100%"}}>
                        <option value="" defaultValue></option>
                        <option value="First Night">First Night</option>
                        <option value="Full Stay">Full Stay</option>
                      </select>
                    </td>
                    <td className="penTable">
                        <input type="number" name="cancel_days" id="" className="form-control" onChange={handleCancellation} value={penalty.cancel_days}/>
                    </td>
                    <td className="penTable">
                        <input type="number" name="cancel_penalty" id="" className="form-control" onChange={handleCancellation} value={penalty.cancel_penalty }/>
                    </td> 
                    <td className="oneFullColumn">
                    
                    <button className="btn h-100" onClick={handlePenaltySave} >
                        {load ? (
                          <div class={load && `spinner-border text-primary`} role="status">
                            Saving...
                          </div>
                        ) : (
                          <div className="fw-bold">SAVE</div>
                        )}
                      </button>
                    </td>
                  </tr>
                </tbody>
                
                <tbody className="contracts">

                {PenalList.length > 0 && PenalList.map((pen, index)=>(
                  <Penaltymodal penaltyList={pen} contract={contract} reloader={getCancel} />
                  ))}
                </tbody>
            </table>
        </div>

        <form action="" className="form mb-4" onSubmit={handlePolicySubmit}>
          <div className="form-group">
            <label htmlFor="booking_policy" className="form-label">Booking Policy</label>
            <textarea name="booking_policy" className='form-control' id="booking_policy" cols="20" rows="10" onChange={(e)=>setData(e.target.value)}></textarea>
          </div>
          <button className="btn btn-primary my-3">
            {
              mutation.isLoading ? 
              <div className="spinner-border text-white">
                  <div className="visually-hidden">Loading...</div>
                </div>
              :
              <span>Add Policy</span>
            
            }
          </button>
        </form>
        <AlertModal show={show} setShow={setShow} />

      </div>

    )
}

export default Penalty
