import React from 'react'

const RC = ({title, value, handleRadioChange, name, typee}) => {
    return (
        <div className="form-group me-1 mb-1">
            <input type={typee} name={name} onChange={handleRadioChange} id={title} value={value} className="me-1" />
            {/* <label htmlFor="" className="text-capitalize">{title}</label> */}
            <label htmlFor={title} className="text-capitalize" dangerouslySetInnerHTML={ {__html:  title} } />
        </div>
    )
}

export default RC
