import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TimestampConverter } from "../../../../controllers/controller";
const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "bold",
    marginBottom:"15"
  },
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    fontStyle: "bold",
  },
  tag: {
      color:'#000000',
    //   width:'200'
  },

  amount: {
    color:"#007bff"
  }

});

// const items = [1, 2, 3, 4]
const ReceiptTableRow = ({ data }) => {
  const rows = (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.tag}>Date: </Text>
        <Text style={styles.amount}>{TimestampConverter(data.timestamp)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.tag}>Transaction ID: </Text>
        <Text style={styles.amount}>{data.transaction_id}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.tag}>Amount: </Text>
        <Text style={styles.amount}>{data.amount}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.tag}>Sender: </Text>
        <Text style={styles.amount}>{data.senderFullname} </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.tag}>Recipient: </Text>
        <Text style={styles.amount}>{data.recipientMail}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.tag}>Status: </Text>
        <Text style={styles.amount}>{data.message}</Text>
      </View>
    </Fragment>
  );
  return <Fragment>{rows}</Fragment>;
};

export default ReceiptTableRow;
