import React from 'react'

const Unavailable = ({title}) => {
    return (
        <div className=" d-flex align-items-center justify-content-center" style={{minHeight:"70vh"}}>
            <p className="text-center text-muted display-3">{title ? title: "No hotel found"}</p>
        </div>
    )
}

export default Unavailable
