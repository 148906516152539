import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {useMutation} from "react-query"
import { Route, useLocation } from 'react-router-dom'
import AlertComp from '../../../Reusables/AlertComp/AlertComp'
import Loading from '../../../Reusables/Loader/Loading'
import Countries from '../../../Signup/Countries/Countries'
import { mainGetUsertoken } from '../../Reusables/GetUsertoken/GetUsertoken'
import { UserType } from '../../Reusables/Settings'
import { addAccountOwnerToHotel, addAgentToAccountOwner, addDMCToAccountOwner, fetchAccountOwnerHotel, fetchAccountOwners, fetchAdminHotels, fetchDMCs, getAllUsers, removeAccountOwnerFromHotel } from '../apis'



const AccountOwners = () => {
    
    const tableHeaders = ["S/N", "Country", "Hotel", "DMC", "Token"]
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");
    const [accountOwners, setaccountOwners] = useState([]);
    const [accountOwner, setaccountOwner] = useState("");
    const [accountOwnerHotels, setaccountOwnerHotels] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    })
    const [open, setOpen] = useState(false)
    const [openDMC, setOpenDMC] = useState(false)
    const [openAgent, setOpenAgent] = useState(false)

    
    
    useEffect(() => {
      mainGetUsertoken(location, setUsertoken, setUser);
    }, []);


    const fetchAdminAccOwners = useMutation(fetchAccountOwners, {
        onSuccess: res => {
            if(res?.data?.data){
                setaccountOwners(res.data.data)
                return
            }
            setaccountOwners([])
        },
        onError: err => console.error(err)
    })


    useEffect(()=>{
        if(usertoken){
            fetchAdminAccOwners.mutate({usertoken})
        }
    },[usertoken])



    const fetchAccountOwnerHotels = useMutation(fetchAccountOwnerHotel, {
        onSuccess: res => {
            console.log({res})
            if(res?.data?.hotels){
                setaccountOwnerHotels(res.data.hotels)
                return
            }
            setaccountOwnerHotels([])
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            // console.error(err)
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }  
    })



    useEffect(()=>{
        if(accountOwner){
            fetchAccountOwnerHotels.mutate({aotoken: accountOwner, usertoken})
        }
    },[accountOwner])



    const handleAlertClose = () => {
        setShowAlert(false);
    };



    function closeModal() {
        setOpen(false)
    }


    function closeDMCModal() {
        setOpenDMC(false)
    }


    function closeAgentModal() {
        setOpenAgent(false)
    }


    


  return (
    <div className="pt-4 px-lg-4 px-3 ">
      <header className="d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">Account Owner</h4>
      </header>
      <div className="admin-dashboard-top mt-2">
        {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
            )
        }
        <Route exact path="/admin/account-owners" component={MainAccountOwner}  />
        <Route path="/admin/account-owners/details/:id" component={AccountOwnerDetail}  />
      </div>
    </div>
  )
}



function MainAccountOwner(){
    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
	const [usertoken, setUsertoken] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const head = [
		"No",
		"Name",
		"Email",
		"Account Type",
		"Country",
		"Date Added",
		"Action",
	];


    const mutation = useMutation(getAllUsers, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setUsers(res.data.users);
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});


    useEffect(() => {
		mutation.mutate({
			usertoken,
			apptoken: process.env.REACT_APP_APP_TOKEN,
		});
	}, [usertoken]);


    return (
        <>
            {
                mutation?.isLoading ? 
                <Loading />
                :
                <UserType
                    comp="ACCOUNTOWNER"
                    head={head}
                    users={users?.filter((item) => item.role_alt === "ACCOUNTOWNER")}
                    search={search}
                    listOnly={true}
                    // approve={approve}
                    // approveUser={approveUser}
                />

            }
        </>
    )
}

const AccountOwnerDetail = () => {
    
    const tableHeaders = ["S/N", "Country", "Hotel", "DMC", "Token","Action"]
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");
    const [accountOwners, setaccountOwners] = useState([]);
    const [accountOwner, setaccountOwner] = useState("");
    const [accountOwnerHotels, setaccountOwnerHotels] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    })
    const [open, setOpen] = useState(false)
    const [openDMC, setOpenDMC] = useState(false)
    const [openAgent, setOpenAgent] = useState(false)

    
    
    useEffect(() => {
      mainGetUsertoken(location, setUsertoken, setUser);
    }, []);


    const fetchAdminAccOwners = useMutation(fetchAccountOwners, {
        onSuccess: res => {
            if(res?.data?.data){
                setaccountOwners(res.data.data)
                return
            }
            setaccountOwners([])
        },
        onError: err => console.error(err)
    })


    useEffect(()=>{
        if(usertoken){
            fetchAdminAccOwners.mutate({usertoken})
        }
    },[usertoken])



    const fetchAccountOwnerHotels = useMutation(fetchAccountOwnerHotel, {
        onSuccess: res => {
            console.log({res})
            if(res?.data?.hotels){
                setaccountOwnerHotels(res.data.hotels)
                return
            }
            setaccountOwnerHotels([])
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            // console.error(err)
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }  
    })



    useEffect(()=>{
        if(accountOwner){
            fetchAccountOwnerHotels.mutate({aotoken: accountOwner, usertoken})
        }
    },[accountOwner])



    const handleAlertClose = () => {
        setShowAlert(false);
    };



    function closeModal() {
        setOpen(false)
    }


    function closeDMCModal() {
        setOpenDMC(false)
    }


    function closeAgentModal() {
        setOpenAgent(false)
    }


    


  return (
    <div className="pt-4 px-lg-4 px-3 ">
      {/* <header className="d-flex justify-content-between align-items-center dashHeader">
        <h4 className="text-primary">Account Owner</h4>
      </header> */}
      
      <div className="admin-dashboard-top mt-2">
        {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
            )
        }
        {
            fetchAdminAccOwners?.isLoading ?
            <div className="spinner-border border-primary">
                <div className="visually-hidden">Loading...</div>
            </div>
            :
            <div className="form-group">
                <select name="accountOwner" id="accountOwner" className="p-2 rounded" onChange={(e)=>setaccountOwner(e.target.value)} value={accountOwner}>
                    <option value="">Select Account owner</option>
                    {
                        accountOwners?.map((item, i) => (
                            <option value={item.token}>{`${item.first_name} ${item.last_name} - ${item.mail}`}</option>
                        ))
                    }
                    {/* list of accountOwner */}
                </select>
            </div>
        }
        <div className="d-flex justify-content-end gap-2 my-4">
            <button className="btn btn-primary" onClick={()=> setOpen(true)}>Add Hotel</button>
            <button className="btn btn-primary" onClick={()=> setOpenDMC(true)}>Add DMC</button>
            <button className="btn btn-primary" onClick={()=> setOpenAgent(true)}>Add Agent</button>
        </div>
        <div className="admin_hotel">
            {
                fetchAccountOwnerHotels?.isLoading ?
                <Loading /> :
                <div className="table-responsive">
                    <table className="table table-bordered admin_hotel_table position-relative">
                        <thead>
                            <tr>
                            {
                                tableHeaders.map((item,i) => (
                                <th key={i}>{item}</th>
                                ))
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                accountOwnerHotels?.map((item, i)=> (
                                    <TableRow item={item} i={i} 
                                        setAlertType={setAlertType} 
                                        setShowAlert={setShowAlert} 
                                        fetchAccountOwnerHotels={fetchAccountOwnerHotels}
                                        accountOwner={accountOwner}
                                        usertoken={usertoken}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>

        <AddAccountOwnerToHotel open={open} handleClose={closeModal} accountOwner={accountOwner} fetchAccountOwnerHotels={fetchAccountOwnerHotels} />
        <AddDMCToAccountOwner open={openDMC} handleClose={closeDMCModal} accountOwner={accountOwner} fetchAccountOwnerHotels={fetchAccountOwnerHotels} />
        <AddAgentToAccountOwner open={openAgent} handleClose={closeAgentModal} accountOwner={accountOwner} fetchAccountOwnerHotels={fetchAccountOwnerHotels} />
      </div>
    </div>
  )
}




function TableRow({item, i, setAlertType, setShowAlert, fetchAccountOwnerHotels, accountOwner, usertoken }){

    // DELETE
    const deleteHotelFromAccountOwner = useMutation(removeAccountOwnerFromHotel, {

        onSuccess: res => {
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true)
                fetchAccountOwnerHotels.mutate({aotoken: accountOwner, usertoken})
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })


    function remove(hoteltoken){
        deleteHotelFromAccountOwner.mutate({usertoken, accountOwnerToken : accountOwner, hoteltoken:hoteltoken})
    }


    return (
        <tr key={i}>
            <td>{i + 1} </td>
            <td>{item.hotelData?.country}</td>
            <td>{item.hotelData?.hotelname}</td>
            <td>{`${item?.userData?.fname} ${item?.userData?.lname}`}</td>
            <td>{item.hoteltoken}</td>
            <td>
                <button className="btn btn-danger" onClick={()=>remove(item.hoteltoken)} disabled={deleteHotelFromAccountOwner?.isLoading}>
                    {
                        deleteHotelFromAccountOwner.isLoading ? <div className="spinner-border text-white"></div> :
                        <span>Delete</span>
                    }
                </button>
            </td>
        </tr>
    )
}



function AddAgentToAccountOwner({open, handleClose, accountOwner, fetchAccountOwnerHotels}){
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [users, setUsers]= useState([])

    const [formstate, setFormstate] = useState({})
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
      }, []);
  
    const handleAlertClose = () => {
        setShowAlert(false);
    }

    const addDmcToAccountOwner = useMutation(addAgentToAccountOwner, {
        onSuccess: res => {
            console.log({res})
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true);
                fetchAccountOwnerHotels.mutate({aotoken: accountOwner, usertoken})
                // handleClose()
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    // FETCH Agent
    const fetchUsers = useMutation(getAllUsers, {
        onSuccess: res => {
            if(res.data.success) {
                setUsers(res.data.users);

                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    
    
    useEffect(()=>{
        if(usertoken){
            fetchUsers.mutate({usertoken, apptoken: process.env.REACT_APP_APP_TOKEN})
        }
    },[usertoken])

    
    
    console.log({accountOwner})
    function add(){
        if(accountOwner === ""){
            setAlertType({ ...alert, type: "danger", value: "Please select an account owner first" });
            setShowAlert(true)
        }
        addDmcToAccountOwner.mutate({...formstate, aotoken: accountOwner, usertoken})
    }



    const changeHandler = (value) => {
       setFormstate({ ...formstate, country: value.label });
    };

    function onChange(e){
        setFormstate({...formstate, [e.target.name]: e.target.value})
    }

   

    return (
        <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="deletePrompt"
        centered
      >
        {addDmcToAccountOwner.isLoading ? (
          <Loading heightvalue="45vh" />
        ) : (
            <Modal.Body>
              {showAlert === true && (
                  <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleAlertClose}
                  />
                  )
              }
              <header>
                <h6 className='fw-bolder mb-3'>Add Agent to Account Owner</h6>
              </header>
              <form>
                <div className="form-group">
                    <label htmlFor="agent_token" className="form-label">Agents</label>
                    <select name="agent_token" id="agent_token" className="form-select" onChange={onChange} value={formstate.agent_token}>
                        <option value="">Select Agent</option>
                        {/* list of hotels */}
                        {
                            users?.filter(item => item.role_alt === "INFLUENCER").map((item, i) => (
                                <option key={i} value={item.token}>{`${item.fname} ${item.lname} - ${item.mail}`}</option>
                            ))
                        }
                    </select>
                </div>
              </form>
            <div className="d-flex justify-content-between align-items-center w-100 mt-3">
              <button className="btn btn-secondary" onClick={handleClose}>
                Close
              </button>
              <button className="btn btn-dark" onClick={add}>
                Add
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    )
}
function AddDMCToAccountOwner({open, handleClose, accountOwner, fetchAccountOwnerHotels}){
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [dmcs, setDmcs]= useState([])

    const [formstate, setFormstate] = useState({})
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
      }, []);
  
    const handleAlertClose = () => {
        setShowAlert(false);
    }

    const addDmcToAccountOwner = useMutation(addDMCToAccountOwner, {
        onSuccess: res => {
            console.log({res})
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true);
                fetchAccountOwnerHotels.mutate({aotoken: accountOwner, usertoken})
                // handleClose()
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    // FETCH DMC
    const fetchDmcs = useMutation(fetchDMCs, {
        onSuccess: res => {
            if(res.data.success) {
                setDmcs(res.data.data)
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    
    
    useEffect(()=>{
        if(usertoken){
            fetchDmcs.mutate({usertoken})

        }
    },[usertoken])

    
    
    function add(){
        if(!accountOwner){
            setAlertType({ ...alert, type: "danger", value: "Please select a DMC" });
            setShowAlert(true)
        }
        addDmcToAccountOwner.mutate({...formstate, accountOwnerToken: accountOwner, usertoken})
    }



    const changeHandler = (value) => {
       setFormstate({ ...formstate, country: value.label });
    };

    function onChange(e){
        setFormstate({...formstate, [e.target.name]: e.target.value})
    }

   

    return (
        <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="deletePrompt"
        centered
      >
        {addDmcToAccountOwner.isLoading ? (
          <Loading heightvalue="45vh" />
        ) : (
            <Modal.Body>
              {showAlert === true && (
                  <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleAlertClose}
                  />
                  )
              }
              <header>
                <h6 className='fw-bolder mb-3'>Add DMC to Account Owner</h6>
              </header>
              <form>
                <div className="form-group">
                    <label htmlFor="dmctoken" className="form-label">DMCS</label>
                    <select name="dmctoken" id="dmctoken" className="form-select" onChange={onChange} value={formstate.dmctoken}>
                        <option value="">Select DMC</option>
                        {/* list of hotels */}
                        {
                            dmcs?.map((item, i) => (
                                <option key={i} value={item.token}>{`${item.first_name} ${item.last_name} - ${item.mail}`}</option>
                            ))
                        }
                    </select>
                </div>
                {/* <div className="form-group mt-3">
                    <label htmlFor="country" className="form-label">Country</label>
                    <Countries
                        changeHandler={changeHandler}
                    /> 
                </div> */}
              </form>
            <div className="d-flex justify-content-between align-items-center w-100 mt-3">
              <button className="btn btn-secondary" onClick={handleClose}>
                Close
              </button>
              <button className="btn btn-dark" onClick={add}>
                Add
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    )
}




function AddAccountOwnerToHotel({open, handleClose, accountOwner, fetchAccountOwnerHotels}){
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [hotels, setHotels]= useState([])
    const [formstate, setFormstate] = useState({})
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
      }, []);
  
    const handleAlertClose = () => {
        setShowAlert(false);
    }

    const addHotel = useMutation(addAccountOwnerToHotel, {
        onSuccess: res => {
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true);
                fetchAccountOwnerHotels.mutate({aotoken: accountOwner, usertoken})
                // handleClose()
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    const fetchHotels = useMutation(fetchAdminHotels, {
        onSuccess: res => {
            if(res.data.success) {
                setHotels(res.data.hotels)
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    useEffect(()=>{
        fetchHotels.mutate({token: usertoken})
    },[usertoken, open])
    

    function add(){
        if(!accountOwner){
            setAlertType({ ...alert, type: "danger", value: "Please select an account Owner" });
            setShowAlert(true)
        }
        addHotel.mutate({...formstate, accountOwnerToken : accountOwner, usertoken})
    }

    function onChange(e){
        setFormstate({...formstate, [e.target.name]: e.target.value})
    }

   

    return (
        <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="deletePrompt"
        centered
      >
        {addHotel.isLoading ? (
          <Loading heightvalue="45vh" />
        ) : (
            <Modal.Body>
              {showAlert === true && (
                  <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleAlertClose}
                  />
                  )
              }
              <header>
                <h6 className='fw-bolder mb-3'>Add Account Owner to Hotel</h6>
              </header>
              <form>
                <div className="form-group">
                    <label htmlFor="hoteltoken" className="form-label">Hotel</label>
                    <select name="hoteltoken" id="hoteltoken" className="form-select" onChange={onChange} value={formstate.hoteltoken}>
                        <option value="">Select Hotel</option>
                        {/* list of hotels */}
                        {
                            hotels?.map((item, i) => (
                                <option key={i} value={item.token}>{`${item.hotelname} - ${item.token}`}</option>
                            ))
                        }
                    </select>
                </div>
                {/* <div className="form-group mt-3">
                    <label htmlFor="country" className="form-label">Country</label>
                    <Countries
                        changeHandler={changeHandler}
                    /> 
                </div> */}
              </form>
            <div className="d-flex justify-content-between align-items-center w-100 mt-3">
              <button className="btn btn-secondary" onClick={handleClose}>
                Close
              </button>
              <button className="btn btn-dark" onClick={add}>
                Add
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    )
}


export default AccountOwners