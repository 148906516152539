import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { TimestampDateConverter } from "../controllers/controller";
const Comp = ({e}) =>{
  return(
    <p>{e}</p>
  )
}
const ContractDate = ({  timeStamp, from, to, roomName, roomNum, release, breakfastA, breakfastC, CT,halfbA, halfbC, fullbA, contract, fullbC, saiA, refT,saiC, index, AllA, AllC, price, uaiC, uaiA, cancelD, cancelP, handleChange, roomId, token, occupyMax, occupyMin, occupyMaxChild, occupyMinChild,  handleUpdate}) => {
  const [editable, setEditable] = useState(false)
  const [value, onChange] = useState(new Date());
  // const tileContent = (date, view) =>{
  //   return
  // }
  
  const trial = [
    new Date('10/10/2021'),
    new Date('10/30/2021')
  ]
  
  const marks = new Set(['10/10/2021', '10/11/2021', '10/12/2021', '10/13/2021'])
  return (
    <>
    <div>
      <Calendar
        onChange={(val, event) => {
          window.alert(`New date is: , ${val.toLocaleDateString()}`)
      }}
        value={trial}
        // selectRange={true}
        // tileContent={({date, view}) => (<Comp e={"$10"} />)}
        // tileClassName={({ date, view }) => {
        //   if(marks.has(date.toLocaleDateString())){
        //    return  'highlight'
        //   }
        // }}
      />
    </div>
    </>
      // <tr className="bodyRow">
      //   <td className="oneFullColumn">
      //     <input
      //       type="text"
      //       name="time"
      //       id="time"
      //       className="initialColumns form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={new Date(timeStamp * 1000).toLocaleDateString()}
      //     />
      //   </td>
      //   <td className="oneFullColumn">
      //     {" "}
      //     <input
      //       type="date"
      //       name="start_date"
      //       id="from"
      //       className="initialColumns form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={from}
      //     />
      //   </td>
      //   <td className="oneFullColumn">
      //     {" "}
      //     <input
      //       type="date"
      //       name="expiry_date"
      //       id="to"
      //       className="initialColumns form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={to}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="room_name"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={roomName}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="occupy_min"
      //       id="occupy_min"
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={occupyMin}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="occupy_max"
      //       id="occupy_max"
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={occupyMax}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="occupy_min_child"
      //       id="occupy_min_child"
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={occupyMinChild}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="occupy_max_child"
      //       id="occupy_max_child"
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={occupyMaxChild}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="unknown"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="endofUnknown"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //     />
      //   </td>
      //   {/* <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="price"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={price}
      //     />
      //   </td> */}
      //   {/* <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="endofUnknown"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //     />
      //   </td> */}
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="availnum"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={roomNum}
      //     />
      //   </td>
      //   <td style={{ padding: "0" }}>
      //     <input
      //       type="text"
      //       name="release_date"
      //       id=""
      //       className="form-control"
      //       disabled={editable}
      //       onChange={handleChange}
      //       value={release}
      //     />
      //   </td>

       
      //   <td className="oneFullColumn">
      //     <button className="btn h-100" onClick={(e)=>handleUpdate(e, index)} >
      //       Update
      //     </button>
         
      //   </td>
      // </tr>
    
  );
};

export default ContractDate;
