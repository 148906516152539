import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Fetch } from "../../../../../controllers/controller";
import { useFormik } from "formik";
import OptionRow from "./OptionRow";
import { mainGetUsertoken } from "../../GetUsertoken/GetUsertoken";

const Option = ({
  setAmount,
  amount,
  formik,
  setRoomTotal,
  bookInfo,
  setBookInfo,
}) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [discount, setDiscount] = useState({});
  const [supplementList, setSupplementList] = useState([]);
  const [usertoken, setUsertoken] = React.useState("");
  const [user, setUser] = React.useState("");

  const location = useLocation();

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);

  useEffect(() => {
    if (usertoken) {
      const filter = localStorage.getItem("filter");
      const all = localStorage.getItem("booking");

      if (all && filter) {
        const item = JSON.parse(all);
        const dates = JSON.parse(filter);

        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("contract_token", item.contract_id);
        formData.append("from", dates.start_date);
        formData.append("to", dates.end_date);
        formData.append("usertoken", usertoken);

        Fetch(`${process.env.REACT_APP_NEND_POINT}/search-get-rooms`, formData)
          .then((res) => {
            setLoading(false);
            if (res.data.success === false) {
              setList([]);
            } else if (res.data.length > 0) {
              setList(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    }
  }, [usertoken]);

  const roomFormik = useFormik({
    initialValues: {
      price: [],
    },
  });

  const filter = localStorage.getItem("filter");
  const all = localStorage.getItem("booking");

  useEffect(() => {
    if (all && filter) {
      const item = JSON.parse(all);
      const dates = JSON.parse(filter);

      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_token", item.contract_id);
      formData.append("from", dates.start_date);
      formData.append("to", dates.end_date);

      Fetch(
        `${process.env.REACT_APP_NEND_POINT}/search-get-offers-rooms`,
        formData
      )
        .then((res) => {
          // console.log("offers", res.data);
          if (res.data.success === false) {
          } else if (res.data.length > 0) {
            setDiscount(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);
  useEffect(() => {
    if (all && filter) {
      const item = JSON.parse(all);
      const dates = JSON.parse(filter);

      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_token", item.contract_id);
      formData.append("from", dates.start_date);
      formData.append("to", dates.end_date);
      formData.append("roomtoken", item.roomtoken);

      Fetch(
        `${process.env.REACT_APP_NEND_POINT}/search-get-supplements`,
        formData
      )
        .then((res) => {
          // console.log("suppl", res.data);
          if (res.data.succes !== false) {
            setSupplementList(res.data);
          }
        })
        .catch((err) => {
          window.alert(err.message);
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(roomFormik.values.price).length > 0) {
      let total = roomFormik.values.price.reduce(
        (a, b) => Number(a) + Number(b),
        0
      );
      if (discount.length > 0) {
        if (
          discount[0].discount_rate !== "undefined" &&
          discount[0].discount_rate !== "0"
        ) {
          let perc = (total * discount[0].discount_rate) / 100;
          setRoomTotal(total - perc);
        } else {
          setRoomTotal(total - discount[0].discount_amount);
        }
      } else {
        setRoomTotal(total);
      }
    } else {
      setRoomTotal(0)
    }
  }, [roomFormik.values.price]);

  // console.log(roomFormik.values)

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="booking_option_title ">
          <p className="fw-bold">Room</p>
        </div>
        {loading ? (
          <div className="w-100  d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={formik?.handleSubmit} className="form booking_table">
            <div className="table-responsive ">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Adult</th>
                    <th>Child</th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 &&
                    list.map((item, index) => (
                      <OptionRow
                        key={index}
                        index={index}
                        formik={roomFormik}
                        item={item}
                        supplement={supplementList}
                        bookInfo={bookInfo}
                        setBookInfo={setBookInfo}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </form>
        )}
      </div>

      {discount.length > 0 && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="booking_option_title " style={{ flex: "20%" }}>
            <p className="fw-bold">Offers</p>
          </div>
          <div
            className="d-flex justify-content-evenly py-3"
            style={{ flex: "80%", borderTop: "1px solid" }}
          >
            <p>Advance Booking</p>
            <p>{`- ${
              discount[0].discount_rate !== "undefined" && discount[0].discount_rate !== "0"
                ? discount[0].discount_rate + "%"
                : "$" + discount[0].discount_amount
            }`}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Option;
