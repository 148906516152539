import React, { useEffect, useState } from "react";
import Countries from "../../../Signup/Countries/Countries";
import { Fetch, TimestampDateConverter } from "../../../../controllers/controller";
import { useFormik } from "formik";
import { getServiceUsertoken } from "../GetUsertoken/GetUsertoken";
import { useLocation } from "react-router-dom";
import {Modal, Tab, Tabs} from "react-bootstrap";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";

// const EditService = ({ item, index, edit }) => {
//   const location = useLocation();
//   const [loading, setLoading] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [reload, setReload] = useState(false);
//   const [alert, setAlertType] = useState({
//     type: "",
//     value: "",
//   });

//   const [usertoken, setUsertoken] = useState("");

//   useEffect(() => {
//     getServiceUsertoken(location, setUsertoken);
//   }, []);

//   const changeHandler = (value) => {
//     formik.setValues({ ...formik.values, country: value.label });
//   };

//   const formik = useFormik({
//     initialValues: {
//       service_name: "",
//     },

//     onSubmit: (values) => {
//       setLoading(true);
//       const formData = new FormData();
//       formData.append("usertoken", usertoken);
//       formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
//       formData.append("service_name", values.service_name);
//       formData.append("date_from", values.date_from);
//       formData.append("date_to", values.date_to);
//       formData.append("location", values.location);
//       formData.append("country", values.country);
//       formData.append("city", values.city);
//       formData.append("longitude", values.longitude);
//       formData.append("latitude", values.latitude);
//       formData.append("description", values.description);
//       formData.append("social_media_link", values.social_media_link);
//       formData.append("youtube_link", values.youtube_link);
//       formData.append("price_adult", values.price_adult);
//       formData.append("price_child", values.price_child);
//       formData.append("child_age_from", values.child_age_from);
//       formData.append("child_age_to", values.child_age_to);
//       formData.append("discounts", values.discounts);
//       formData.append("discount_from", values.discount_from);
//       formData.append("discount_to", values.discount_to);
//       formData.append("min_pax_discount", values.min_pax_discount);
//       formData.append("avatar", values.image_url);

//       Fetch(`${process.env.REACT_APP_END_POINT}/edit-service`, formData)
//         .then((res) => {
//           setLoading(false);
//           window.alert(res.data.message);
//           if (res.data.success === false) {
//             setShowAlert(true);
//             setAlertType({ ...alert, type: "danger", value: res.data.message });
//             setReload(true);
//             setTimeout(() => {
//               setReload(false);
//             }, 1000);
//           } else {
//             edit(false);
//             setShowAlert(true);
//             setAlertType({
//               ...alert,
//               type: "success",
//               value: res.data.message,
//             });
//             setReload(true);
//             setTimeout(() => {
//               setReload(false);
//               window.location.reload();
//             }, 1000);
//           }
//         })
//         .catch((err) => {
//           window.alert(err.message);
//           setLoading(false);
//           window.location.reload();

//         });
//     },
//   });

//   // copy original input state
//   useEffect(() => {
//     formik.setValues({ ...formik.values, ...item });
//   }, []);

//   return (
//     <tr>
//       <td>{index + 1}</td>
//       <td>{TimestampConverter(item.timestamp)}</td>
//       <td>
//         {TimestampConverter(item.date_from)}
//         <input
//           className="form-control"
//           type="date"
//           name="date_from"
//           id="date_from"
//           onChange={formik.handleChange}
//           // value={formik.values.date_from}
//         />
//       </td>
//       <td>
//         <p>{TimestampConverter(item.date_to)}</p>
//         <input
//           className="form-control"
//           type="date"
//           name="date_to"
//           id="date_to"
//           onChange={formik.handleChange}
//           // value={formik.values.date_to}
//         />
//       </td>
//       <td>
//         {item.service_name}
//         <input
//           className="form-control"
//           type="text"
//           name="service_name"
//           id="service"
//           value={formik.values.service_name}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {item.location}
//         <input
//           className="form-control"
//           type="text"
//           name="location"
//           id="location"
//           value={formik.values.location}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {item.country}
//         <Countries changeHandler={changeHandler} />
//       </td>
//       <td>
//         {item.city}
//         <input
//           className="form-control"
//           type="text"
//           name="city"
//           id="city"
//           value={formik.values.city}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {item.price_adult}
//         <input
//           className="form-control"
//           type="number"
//           name="price_adult"
//           id="price_adult"
//           value={formik.values.price_adult}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {item.price_child}
//         <input
//           className="form-control"
//           type="number"
//           name="price_child"
//           id="price_child"
//           value={formik.values.price_child}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {item.discounts}
//         <input
//           className="form-control"
//           type="number"
//           name="discounts"
//           id="discount"
//           value={formik.values.discounts}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {TimestampConverter(item.discount_from)}
//         <input
//           className="form-control"
//           type="date"
//           name="date_from"
//           id="date_from"
//           onChange={formik.handleChange}
//           // value={formik.values.discount_from}
//         />
//       </td>
//       <td>
//         {TimestampConverter(item.discount_to)}
//         <input
//           className="form-control"
//           type="date"
//           name="date_from"
//           id="date_from"
//           onChange={formik.handleChange}
//           // value={formik.values.discount_to}
//         />
//       </td>
//       <td>
//         {item.min_pax_discount}
//         <input
//           className="form-control"
//           type="number"
//           name="min_pax_discount"
//           id="min_pax_discount"
//           value={formik.values.min_pax_discount}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         {item.description}
//         <input
//           className="form-control"
//           type="text"
//           name="description"
//           id="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//         />
//       </td>
//       <td>
//         <div className="d-flex">
//           {loading ? (
//             <div className="spinner-border text-success" role="status"></div>
//           ) : (
//             <>
//               <button className="btn btn-success me-2" onClick={formik.handleSubmit}>
//                 Save
//               </button>
//               <button className="btn btn-danger" onClick={() => edit(false)}>
//                 cancel
//               </button>
//             </>
//           )}
//         </div>
//       </td>
//     </tr>
//   );
// };

const EditService = ({item, index, id, show, handleClose})=>{
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [reload, setReload] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
const [dates, setDates]=useState({})

  const [usertoken, setUsertoken] = useState("");

  const [image, setImage] = useState(null);
  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    getServiceUsertoken(location, setUsertoken);
  }, []);

  const handleDates = (e)=>{
      setDates({...dates, [e.target.name]: e.target.value})
  }

  const changeHandler = (value) => {
    formik.setValues({ ...formik.values, country: value.label });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const formik = useFormik({
    initialValues: {
      service_name: "",
    },

    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("usertoken", usertoken);
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("service_name", values.service_name);
      formData.append("date_from", dates.date_from ? dates.date_from : TimestampDateConverter(values.date_from));
      formData.append("date_to", dates.date_to  ? dates.date_to : TimestampDateConverter(values.date_to));
      formData.append("location", values.location);
      formData.append("country", values.country);
      formData.append("city", values.city);
      formData.append("longitude", values.longitude);
      formData.append("latitude", values.latitude);
      formData.append("description", values.description);
      formData.append("social_media_link", values.social_media_link);
      formData.append("youtube_link", values.youtube_link);
      formData.append("price_adult", values.price_adult);
      formData.append("price_child", values.price_child);
      formData.append("child_age_from", values.child_age_from);
      formData.append("child_age_to", values.child_age_to);
      formData.append("discounts", values.discounts);
      formData.append("discount_from", dates.discount_from ? dates.discount_from : TimestampDateConverter(values.discount_from));
      formData.append("discount_to", dates.discount_to ? dates.discount_to : TimestampDateConverter(values.discount_to));
      formData.append("min_pax_discount", values.min_pax_discount);
      formData.append("avatar", image ? image : values.image_url.split("/").slice(-1)[0]);

      Fetch(`${process.env.REACT_APP_END_POINT}/edit-service`, formData)
        .then((res) => {
          setLoading(false);
          if (res.data.success === false) {
            setShowAlert(true);
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 1000);
          } else {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
            setReload(true);
            setTimeout(() => {
              setReload(false);
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          window.alert(err.message);
          setLoading(false);
          window.location.reload();

        });
    },
  });

  // copy original input state
  useEffect(() => {
    formik.setValues({ ...formik.values, ...item });
  }, [item]);
  
 
  return(
    <Modal show={show} onHide={handleClose} centered className="deleteService">
      {loading ?<Loading />:(
      <>
      <Modal.Header>
        <div className="w-100">
          <h5 className="text-dark text-uppercase text-center">
            Edit Service
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body>

      <>
      <form
        className="row justify-content-around services"
        onSubmit={formik.handleSubmit}
      >
        <div>
          {showAlert === true && (
            <AlertComp
              variant={alert.type}
              alertText={alert.value}
              handleAlertClose={handleAlertClose}
            />
          )}
        </div>
        <Tabs defaultActiveKey="content" className="mb-3 title_font">
          <Tab eventKey="content" title="CONTENT">
            <div className="row justify-content-around">
              <div className="left col-12 ">
                <div className="row px-sm-4">
                  <div className="form-group mb-2 col-12">
                    <label htmlFor="service" className="form-label">
                      Service Name
                    </label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.service_name}
                      onBlur={formik.handleBlur}
                      name="service_name"
                      id="service"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="date_from" className="form-label">
                      Date from
                    </label>
                    <input
                      type="date"
                      onChange={handleDates}
                      onBlur={formik.handleBlur}
                      name="date_from"
                      id="date_from"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="date_to" className="form-label">
                      Date to
                    </label>
                    <input
                      type="date"
                      onChange={handleDates}
                      onBlur={formik.handleBlur}
                      name="date_to"
                      id="date_to"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-12">
                    <label htmlFor="location" className="form-label">
                      Location
                    </label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.location}
                      onBlur={formik.handleBlur}
                      name="location"
                      id="location"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <Countries changeHandler={changeHandler}  />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      onBlur={formik.handleBlur}
                      name="city"
                      id="city"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="longitude" className="form-label">
                      Longitude
                    </label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.longitude}
                      onBlur={formik.handleBlur}
                      name="longitude"
                      id="longitude"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="latitude" className="form-label">
                      Latitude
                    </label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.latitude}
                      onBlur={formik.handleBlur}
                      name="latitude"
                      id="latitude"
                      className="form-control px-2 py-2"
                    />
                  </div>
                </div>
              </div>
              <div className="right col-12 row">
              <div className="row px-sm-4">   
                <div className="form-group mb-2 col-12">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    name="description"
                    className="d-block w-100 px-2 py-2 form-control"
                    id="desc"
                    cols="40"
                    rows="8"
                  ></textarea>
                </div>
                <div className="form-group mb-2 col-sm-6 pe-1">
                  <label htmlFor="social" className="form-label">
                    Social media link
                  </label>
                  <input
                    type="url"
                    onChange={formik.handleChange}
                    value={formik.values.social_media_link}
                    onBlur={formik.handleBlur}
                    name="social_media_link"
                    id="social"
                    className="form-control px-2 py-2"
                  />
                </div>
                <div className="form-group mb-2 col-sm-6 ps-1">
                  <label htmlFor="youtube" className="form-label">
                    Youtube link
                  </label>
                  <input
                    type="url"
                    onChange={formik.handleChange}
                    value={formik.values.youtube_link}
                    onBlur={formik.handleBlur}
                    name="youtube_link"
                    id="youtube"
                    className="form-control px-2 py-2"
                  />
                </div>
                <div className="form-group mb-2 col-12">
                  <label htmlFor="images" className="form-label">
                    Images
                  </label>
                  <input
                    // onChange={formik.handleChange}
                    name="images"
                    id="images"
                    type="file"
                    onChange={handleImage}
                    className="d-block w-100 form-control  px-2 py-2"
                  />
                </div>
              </div>
              </div>

            </div>{" "}
            {/* row ends */}
          </Tab>
          <Tab eventKey="price" title="PRICE">
            <div className="row justify-content-center">
              <div className="right  ">
                <div className="row px-sm-4">
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="price_adult" className="form-label">
                      Price Adult
                    </label>
                    <input
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.price_adult}
                      onBlur={formik.handleBlur}
                      name="price_adult"
                      id="price_adult"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="price_child" className="form-label">
                      Price Child
                    </label>
                    <input
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.price_child}
                      onBlur={formik.handleBlur}
                      name="price_child"
                      id="price_child"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="child_age_from" className="form-label">
                      Child Age From
                    </label>
                    <input
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.child_age_from}
                      onBlur={formik.handleBlur}
                      name="child_age_from"
                      id="child_age_from"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="child_age_to" className="form-label">
                      Child Age To
                    </label>
                    <input
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.child_age_to}
                      onBlur={formik.handleBlur}
                      name="child_age_to"
                      id="child_age_to"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="discounts" className="form-label">
                      Discounts
                    </label>
                    <input
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.discounts}
                      onBlur={formik.handleBlur}
                      name="discounts"
                      id="discounts"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="discount_from" className="form-label">
                      Discount From
                    </label>
                    <input
                      type="date"
                      onChange={handleDates}
                      onBlur={formik.handleBlur}
                      name="discount_from"
                      id="discount_from"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 pe-1">
                    <label htmlFor="discount_to" className="form-label">
                      Discount To
                    </label>
                    <input
                      type="date"
                      onChange={handleDates}
                      onBlur={formik.handleBlur}
                      name="discount_to"
                      id="discount_to"
                      className="form-control px-2 py-2"
                    />
                  </div>
                  <div className="form-group mb-2 col-sm-6 ps-1">
                    <label htmlFor="min_pax_discount" className="form-label">
                      Min Pax Discount
                    </label>
                    <input
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.min_pax_discount}
                      onBlur={formik.handleBlur}
                      name="min_pax_discount"
                      id="min_pax_discount"
                      className="form-control px-2 py-2"
                    />
                  </div>
                </div>
                <div className="servicebtn my-3 col-12">
                  <button type="submit" className="btn btn-primary w-75">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </form>
    </>
      </Modal.Body>
      </>)}
    </Modal>
  )
}

export default EditService;
