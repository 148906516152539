import React from "react"
import {Modal, Button} from "react-bootstrap"

function AlertModal({value, show, setShow}) {
  
    const handleClose = () => {
        setShow(false)
        window.location.reload()
    };
  
    return (
        <Modal show={show} onHide={handleClose}>
          
          <Modal.Body>Please fill in contract's header details first</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      
    );
  }

export default AlertModal