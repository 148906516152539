import React, { useState } from 'react'
import {Switch, Route, useHistory} from "react-router-dom"
import Header from '../../Junior/Header/Header';
import Dashboard from './Dashboard/Dashboard';
import Sidebar from './sidebar/sidebar';
import History from "../../Reusables/Wallet/History"
import Bookings from '../../Reusables/Bookings/Bookings';
import E404 from '../../../404/404';
const Traveller = () => {
  const [sidebar, setSidebar] = useState("hide");
  const [mainWrapper, setMainWrapper] = useState("")

  const [main, setMain] = useState("col-12")
  const handleClick = () => {
    sidebar === "hide" ? setSidebar("showsidebar"): setSidebar("hide")
    sidebar === "hide" ? setMainWrapper("d-flex justify-content-end wrapper-width") : setMainWrapper("mainWrapper")

  };
  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear()
    history.push("/login")
  }
    return (
        <div>
        <div className="dash d-flex justify-content-end" style={{position:"relative"}}>
        <Sidebar sidebar={sidebar} close={handleClick} handleLogout={handleLogout}/>
        
      <div className={`${mainWrapper} px-0 mainWrapper`}>
        <div className={` ${main}`} >
         <Header handleClick={handleClick} navButton="Log in as an influencer" />
          <Switch>
            <Route exact path={["/user", "/user/dashboard"]} component={Dashboard} />
            <Route path="/user/wallet-history" component={History} />
            <Route path="/user/bookings" component={Bookings} />
            <Route path="*" component={E404} />


          </Switch>
        </div>
      </div>
        </div>
        </div>
    )
}

export default Traveller
