import React from 'react'

const Cookie = ({handleClick}) => {
    return (
        <section className="cookie d-flex justify-content-around align-items-center py-3 flex-column flex-sm-row"> 
            <div className="left text-light mb-3 mb-sm-0">
                <p className="mb-0">This website requires cookies to provide all of its features. By using our website, you agree to our use of cookies</p>
            </div>
            <div className="right">
            <button className="btn btn-warning cookie__button text-light fw-bolder" onClick={handleClick}>Got it</button>
            </div>
        </section>
    )
}

export default Cookie
