import React, {useState, useEffect} from 'react'
import { LOOP } from '../controllers/controller';

const ReadOnlyTest = ({room,twn, price_sgl, index, price_dbl, price_trl, unit, unit2, price_qud, price_chd1, price_chd2,inventory_room, inventory_rel}) => {
   
    return (
       
            <tr className="innerTr special_table" key={index}>
            <td style={{ padding: "0" }}>
              <p>{room}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{price_sgl === "" || price_sgl === "undefined" || price_sgl === "0" ? "-" : price_sgl}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{price_dbl === "" || price_dbl === "undefined" ||price_dbl === "0"   ? "-" : price_dbl}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{twn === "" || twn === "undefined" || twn === "0" ?  "-" :twn}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{price_trl === "" || price_trl === "undefined" | price_trl === "0" ? "-" : price_trl}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{price_qud === "" || price_qud === "undefined" || price_qud === "0" ? "-" : price_qud}</p>
            </td>
            {/* <td style={{ padding: "0" }}>
              <p>{price_chd1 === "" || price_chd1 === "undefined"   ? "-" : price_chd1}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{price_chd2 === ""  || price_chd2 === "undefined"   ? "-" : price_chd2}</p>
            </td> */}
            <td style={{ padding: "0" }}>
              <p>{unit === "" || unit === "undefined" || unit === "0"  ?  "-" :unit}</p>
            </td>
            
            <td style={{ padding: "0" }}>
             <p>{inventory_room === "" || inventory_room === "undefined" || inventory_room === "0" ? "-" : inventory_room}</p>
            </td>
            <td style={{ padding: "0" }}>
              <p>{inventory_rel === "" || inventory_rel === "undefined" || inventory_rel === "0" ?  "-" :inventory_rel}</p>
            </td>
           
          </tr>
            
    )
}

export default ReadOnlyTest
