import React from "react";
import { Modal, Button } from "react-bootstrap";
import PayPal from "./PayPal";
import Stripe from "./Stripe";
import {useLocation} from "react-router-dom"
import Loading from "../../../Reusables/Loader/Loading"
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import { Fetch } from "../../../../controllers/controller";
import { mainGetUsertoken } from "../GetUsertoken/GetUsertoken";

const PaymentModal = ({ show, setShow }) => {

  const [loading, setLoading] = React.useState(false)
  
  const [paypal, setPaypal] = React.useState(false);
  const [stripe, setStripe] = React.useState(false);
  const [showPaypal, setShowPaypal] = React.useState(false);
  const [showStripe, setShowStripe] = React.useState(false);
  const [user, setUser] = React.useState({})
  const [usertoken, setUsertoken] = React.useState("")

  const [amount, setAmount] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alert, setAlertType] = React.useState({
    type: "",
    value: "",
  });

  const location = useLocation();
    

    React.useEffect(()=>{
      mainGetUsertoken(location,  setUsertoken, setUser)
    }, [])

    function addMoneyToDB(source){
      setLoading(true)
      console.log(source)
      const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("usertoken", user.role === "6" ? 1 : usertoken);
        formData.append("amount", amount);
        formData.append("source", source);
  
        Fetch(`${process.env.REACT_APP_END_POINT}/credit-wallet`, formData)
        .then((res) => {
          setLoading(false);
          setShowAlert(true)
          setAlertType({
            ...alert,
            type: "success",
            value: res.data.message,
          });
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true)
          setAlertType({
            ...alert,
            type: "success",
            value: err.message,
          });
          
        });
    }
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const handleChange = (e) => {
    setAmount(e.target.value);
  };
  const handleClose = () => {
    setShow(false);
    window.location.reload()
  };
  function handlePaypal() {
    setShowPaypal(true);
  }
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>Select Payment Gateway</Modal.Header>
      <Modal.Body>
        { loading ?   <Loading /> : (
        <div className="row">
           {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
          {paypal ? (
            <div className="col-12 d-flex justify-content-center">
              {showPaypal ? (
                <PayPal amount={amount} addMoneyToDB={addMoneyToDB} setLoading={setLoading} setAlertType={setAlertType} setShowAlert={setShowAlert} />
              ) : (
                <div className=" d-flex flex-column justify-content-center">
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    className="form-control mb-3"
                    value={amount}
                    onChange={handleChange}
                    placeholder="Enter amount"
                  />
                  <button className="btn btn-primary" onClick={handlePaypal}>
                    Pay with PayPal
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-primary w-50 mb-3"
                onClick={(e) => {
                  e.preventDefault();
                  setStripe(false);
                  setPaypal(true);
                  setShowStripe(false);
                }}
              >
                Pay with PayPal
              </button>
            </div>
          )}
          <hr />
          {stripe ? (
            <div className="col-12 d-flex justify-content-center ">
              <Stripe setLoading={setLoading} setShowAlert={setShowAlert} setAlertType={setAlertType} email={user.mail} />
            </div>
          ) : (
            <div className="col-12  d-flex justify-content-center">
              <button
                className="btn btn-outline-warning w-50 mx-auto"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPaypal(false);
                  setPaypal(false);
                  setStripe(true);
                }}
              >
                Pay with Stripe
              </button>
            </div>
          )}
        </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;
