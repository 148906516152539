import React from "react";
import { useState } from "react";
import Chart from  "react-apexcharts"
import Date from "../../../../Super/Dashboard/DashboardChart/DatePicker";

const DChart = () => {
    const [options, setOptions] = useState({
        options: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
              categories: ["12/7/2021", "12/7/2021", "12/7/2021", "12/7/2021", "12/7/2021", "12/7/2021", "12/7/2021", "12/7/2021"],
              title: {
                text: 'Timeline',
              },
            },
            yaxis: {
                categories: [20, 40, 60, 80, 100],
                title: {
                    text: 'Currency USD',
                  },
            },
            legend: {
                position: "top",
                horizontalAlign: "center"
            },
            colors: ["#E83E8C", "#9D67FD"],
            plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: false,
                    position: 'top', 
                  },
                  
                  columnWidth: ["25%"],
                },
                
            }
          },
          series: [
            {
              name: "Total Revenue",
              data: [30, 40, 45, 50, 49, 60, 70, 91]
            },
            { name: "Total Earnings", data: [50, 60, 70, 80, 60, 50, 70, 60] },
          ]
    })
  return (
        <div className="card col-lg-7 shadow">
        <div className="top border-bottom py-2 px-4 mb-3 d-flex justify-content-between flex-column flex-sm-row">
          <header>
            <h5 className="text_font">Last year vs current year revenue</h5>
          </header>
          <div className="calender d-flex justify-content-start">
            <div className="icon me-sm-3 me-1 text-primary">
              <i className="bi bi-calendar"></i>
            </div>
            <div className="date">
              <Date />
            </div>
          </div>
      </div>
      <div className="mixed-chart card-body" style={{ minHeight: "300px" }}>
        <Chart
          options={options.options}
          series={options.series}
          type="bar"
          width="100%"
          height="250"
        />
      </div>
    </div>
    
  );
};

export default DChart;
