import React, { useState, useEffect } from "react";
import {
  Fetch,
  TimestampConverter
} from "../../../../../../../controllers/controller";
import Countries from "../../../../../../Signup/Countries/Countries";
import ContractAlert from "../../../../../../Reusables/ContractAlert/ContractAlert";
import StaticOffer from "./StaticOffer";

const OfferDetail = ({ list, contractTop, reloader, setReload, setShowAlert, setAlertType }) => {
  const [payload, setPayload] = useState({});
  const [showAlertt, setShow] = useState(false);
  const [value, setValue] = useState('');

  const [editable, setEditable] = useState(null);
  const [OfferList, setOfferList] = useState({});

  const [load, setLoad] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  // const [alert, setAlertType] = useState({
  //   type: "",
  //   value: "",
  // });

  const [offerDate, setOfferDate] = useState({})

  
  // countries
  const changeHandler = (value) => {
    setPayload({ ...payload, source_market: value.label });
  };
  const hotelId = localStorage.getItem("hotel");

  const CT = localStorage.getItem("CT");
  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);

  const handleChange = (e) => {
    e.preventDefault();
    setPayload({ ...payload, [e.target.name]: e.target.value });
    
  };

  const handleCheck = (e) => {
      e.target.value === "0" ? setPayload({...payload, [e.target.name]: "1"}) : setPayload({...payload, [e.target.name]: "0"}) 
    
  }
  const [bookingDate, setBookingDate] = useState({})
  const [categoryList, setCategoryList] = useState([]);
 

  function getRoomCategories() {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelId);

    Fetch(`${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        if(res.data.success !== false){
          setCategoryList(res.data);
        }
      })
      .catch((err) => {
        console.error(err)
        // setAlertType({ ...alert, type: "danger", value: err.message });
      });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getRoomCategories()
    }
    return () => {
      mounted = false;
    };
  }, [load]);


  const handleBookingDate = (e) => {
    setBookingDate({...bookingDate, [e.target.name]: e.target.value})
  }

  
  const handleDateChange = (e) =>{
    e.preventDefault();
    setOfferDate({...offerDate, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(contractTop.status !== "" && contractTop.contract_name !== ""  && contractTop.child_age_From !== ""  && contractTop.child_age_to !== "" && contractTop.currency !== ""  ){

    setLoad(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("contract_name", contractTop.contract_name);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("hoteltoken", hotelId);
    formData.append("token", CT);

    formData.append("status", contractTop.status);

    formData.append("child_age_From", contractTop.child_age_From);
    formData.append("child_age_To", contractTop.child_age_To);
    formData.append("currency", contractTop.currency);

    formData.append("stay_from", offerDate.stay_from   ?  offerDate.stay_from : TimestampConverter(payload.stay_from));
    formData.append("stay_to", offerDate.stay_to  ?  offerDate.stay_to : TimestampConverter(payload.stay_from));
    formData.append("booking_from", bookingDate.booking_from ? bookingDate.booking_from : TimestampConverter(payload.booking_from));
    formData.append("booking_to", bookingDate.booking_to ? bookingDate.booking_to : TimestampConverter(payload.booking_to));

    formData.append("offer", payload.offer);
    formData.append("order", payload.order);
    formData.append("id", payload.id);
    formData.append("link1", payload.link1);
    formData.append("offers_room_type", payload.offers_room_type);
    formData.append("offer_room", payload.offer_room);
    formData.append("offer_meals", payload.offer_meals);
    formData.append("offer_supplement", payload.offer_supplement);
    

    formData.append("discount_amt", payload.discount_amountt);
    formData.append("discount_rate", payload.discount_rate);
    formData.append("source_market", payload.source_market);
    Fetch(
       `${process.env.REACT_APP_END_POINT}/offer-plan-update`,
      formData
    )
      .then((res) => {
        
        setLoad(false);
        if(res.data.success === true){

          setEditable(null);
          setShow(true);
          setValue(res.data.message)
          reloader()
          // window.location.reload()
        } else {
          setShowAlert(true);
          setValue(res.data.message)
        }


        
      })
      .catch((err) => {
        setLoad(false);
        setShow(true);
        setValue(err.message)
        reloader()

      });
    } else {
      window.alert("Please fill in contract's header details first")
    }
  };
  return  (
    <>
    {editable ? (

    <tr>

      <td className="oneFullColumn">
        <p>{TimestampConverter(list.timestamp)}</p>
      </td>
      <td>
      <select  id="offer" name="offer" value={payload.offer} onChange={(e) => handleChange(e)}>
        <option ></option>
        <option value="geo offer" >Geo offer</option>
        <option value="last minute" >Last minute</option>
        <option value="non refundable" >Non refundable</option>
        <option value="early bird" >Early bird</option>
      </select>
      </td>
      <td>
          {/* <label htmlFor="supplement" className="visually-hidden">Supplement Room Type</label> */}

          <select
            name="offers_room_type"
            id="offers"
            onChange={handleChange}
            className="form-select"
            value={payload.offers_room_type}
          >
            <option defaultValue></option>
            {categoryList.length > 0 &&
                    categoryList.map((category) => (
                      <option value={category.id}>{category.category}</option>
                    ))}
          </select>
        </td>
      <td>
        <p>{TimestampConverter(payload.stay_from)}</p>
        <input
          type="date"
          className="form-control"
          onChange={(e) => handleDateChange(e)}
          // value={offerDate.stay_from}
          name="stay_from"
          id=""
        />
      </td>
      <td>
      <p>{TimestampConverter(payload.stay_to)}</p>
        <input
          type="date"
          className="form-control"
          onChange={(e) => handleDateChange(e)}
          // value={offerDate.stay_to}
          name="stay_to"
          id=""
        />
      </td>
      <td>
        <p>{TimestampConverter(payload.booking_from)}</p>
        <input
          type="date"
          className="form-control"
          onChange={(e) => handleBookingDate(e)}
          value={bookingDate.booking_from}
          name="booking_from"
          id=""
        />
      </td>
      <td>
      <p>{TimestampConverter(payload.booking_to)}</p>
        <input
          type="date"
          className="form-control"
          onChange={(e) => handleBookingDate(e)}
          value={bookingDate.booking_to}
          name="booking_to"
          id=""
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          onChange={(e) => handleChange(e)}
          value={payload.discount_amountt}
          name="discount_amountt"
          id="dis_amount"
        />
      </td>
      <td>
        <input
          type="number"
          className="form-control"
          onChange={(e) => handleChange(e)}
          value={payload.discount_rate}
          name="discount_rate"
          id="percentage"
        />
      </td>
      <td>
        <Countries changeHandler={changeHandler} />
      </td>
      <td>
      <input type="checkbox" name="link1" id="link1" onChange={handleCheck} value={payload.link1} checked={payload.link1 === "1" ? true : false }   />
      </td> 
      <td>
         <input
                  type="checkbox"
                  name="offer_room"
                  id="offer_room"
                  onChange={handleCheck}
                  value={payload.offer_room}
                  checked={payload.offer_room === "0" ? false : true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="offer_meals"
                  id="offer_meals"
                  onChange={handleCheck}
                  value={payload.offer_meals}
                  checked={payload.offer_meals === "0" ? false : true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="offer_supplement"
                  id="offer_supplement"
                  onChange={handleCheck}
                  value={payload.offer_supplement}
                  checked={payload.offer_supplement === "0" ? false : true}
                />
              </td>
      <td>
      <select  id="order" name="order" onChange={(e) => handleChange(e)}  value={payload.order}>
        <option value="1" >1</option>
        <option value="2" >2</option>
        <option value="3" >3</option>
        <option value="4" >4</option>
      </select>
      </td>
      <td>
        <button className="btn" onClick={(e) => handleSubmit(e)}>
          {load ? (
            <div class={load && `spinner-border text-primary`} role="status">
              Saving...
            </div>
          ) : (
            <div>Save</div>
          )}
        </button>
      </td>
    </tr>
  ) : (
    <StaticOffer
      handleEdit={() => {
        setEditable(true);
        setPayload({ ...payload, ...list });
      }}
      timestamp={list.timestamp}
      offer={list.offer}
      order={list.order}
      stay_from={list.stay_from}
      stay_to={list.stay_to}
      booking_to={list.booking_to}
      link1={list.link1}  
      offers_room_type={list.offers_room_type}
      meals={list.offer_meals}  
      room={list.offer_room}  
      supplement={list.offer_supplement}  
      booking_from={list.booking_from}
      discount_rate={list.discount_rate}
      discount_amt={list.discount_amountt}
      countries={list.source_market}
    />
  )}
    <ContractAlert show={showAlertt} setShow={setShow} value={value} />
    </>

  )
};

export default OfferDetail;
