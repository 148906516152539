import React, {useState} from 'react'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import Header from '../../Junior/Header/Header'
import HotelState from '../../Junior/Manage/Context/hotelState'
import Services from '../../Reusables/Services/Services'
import Packages from './Packages/Packages'
import Contracts from './Contracts/Contracts'
import Dashboard from './Dashboard/Dashboard'
import Manage from './Manage/manage'
import Overrides from './Overrides/Overrides'
import Sidebar from './Sidebar/sidebar'
import History from '../../Reusables/Wallet/History'
import Bookings from '../../Reusables/Bookings/Bookings'
import E404 from '../../../404/404'
import AllSources from './Sources/Sources'
import Settings from '../../Reusables/Settings'
const HotelOwner = () => {
  const {pathname} = useParams()
  const [sidebar, setSidebar] = useState("hide");
  const [mainWrapper, setMainWrapper] = useState("")

  const [main, setMain] = useState("col-12")
  const handleClick = () => {
    sidebar === "hide" ? setSidebar("showsidebar"): setSidebar("hide")
    // main === "col-12" ? setMain("col-lg-10") : setMain("col-12")
    sidebar === "hide" ? setMainWrapper("d-flex justify-content-end wrapper-width") : setMainWrapper("mainWrapper")

  };
    
  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear()
    history.push("/login")
  }
    return (  
      <HotelState>

        <div>
        <div className="dash h-100 d-flex justify-content-end" style={{position:"relative"}}>
        <Sidebar sidebar={sidebar} close={handleClick} handleLogout={handleLogout} />
        
      <div className={`${mainWrapper} px-0 mainWrapper`}>
        <div className={` ${main}`} >
         <Header handleClick={handleClick} navButton="Change Password" />
          <Switch>
            <Route exact path={["/hotel-owner", "/hotel-owner/dashboard"]} component={Dashboard} />
            <Route path="/hotel-owner/contracts" component={Contracts} /> 
            <Route path="/hotel-owner/hotels" component={Manage} />
            <Route path="/hotel-owner/overrides" component={Overrides} />
            <Route path="/hotel-owner/services" component={Services} />
            <Route path="/hotel-owner/packages" component={Packages} />
            <Route path="/hotel-owner/wallet-history" component={History} />
            <Route path="/hotel-owner/bookings" component={Bookings} />
            <Route path="/hotel-owner/sources" component={AllSources} />
            <Route path="/hotel-owner/profile" component={Settings} />
            <Route path="*" component={E404} />
          </Switch>
        </div>
      </div>
        </div>
        </div>
      </HotelState>
    )
}

export default HotelOwner

