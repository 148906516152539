import React from 'react'
import useFetch from '../../../../Reusables/Fetch/Fetch';
import OverrideCard from "./OverrideCard";
import Loading from "../../../../Reusables/Loader/Loading";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";


const Overrides = () => {
    const dmc = localStorage.getItem("DMC");
    const uDmc = JSON.parse(dmc)
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("token", uDmc.usertoken);
    
    const {data,  error, loading, alert, setLoading, showAlert, setShowAlert} = useFetch("/dmc-override-list", formData)

    const handleAlertClose = () => {
        setShowAlert(false);
      }
    console.log("data", data)
    return loading ? (<Loading />): (
        <div className="pt-4 px-lg-4 px-3">
            <header className="d-flex justify-content-between align-items-center dashHeader">
                <h4 className="text-primary">OVERRIDES</h4>
            </header>
            <div className="admin-dashboard-top row mt-5">
            {showAlert === true && data.message !== undefined && 
            <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
          }
                <div className="override">
                    {  data.length > 0 && data.map(item=>(
                        <OverrideCard item={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Overrides

