import React, { useState } from 'react'
import HotelContext from "./hotelcontext"
const HotelState = ({children}) => {
    const [hotelId, setHotelId] = useState("")
    return (
        <HotelContext.Provider value={[hotelId, setHotelId]}>
                {children}
        </HotelContext.Provider>
        
    )
}

export default HotelState
