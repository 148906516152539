import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import { mainGetUsertoken } from '../../../Reusables/GetUsertoken/GetUsertoken'
import SidebarLinks from '../../../Reusables/sidenavlinks/SidebarLinks'
import { DMC } from '../../../Super/superData/data'

const Sidebar = ({sidebar, close, handleLogout}) => {
  const style ={
    width:"10px",
    height:"3rem",
    position:"absolute",
    top:"6%", 
    right:"-17px",
    color:"grey",
    zIndex:"22000",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",

  }
  const location = useLocation()
  const  [user, setUser] = useState({})
  const  [usertoken, setUsertoken]=useState({})

  useEffect(()=>{
    mainGetUsertoken(location, setUsertoken, setUser)
},[])
  // console.log(location)
    return (
      // <div className={`${sidebar} sidebar-container`} style={{position:"relative"}} >
        <div  className={`sidebar-container bg-light ${sidebar} `} style={{transition:"all .1s ease-in-out"}}>
          <header className="my-5 ps-2">
          <div className="right d-flex">
                <div className="right me-3">
                  <div className="bg-warning h2 text-center text-light p-2" style={{height: "50px", width:"50px", borderRadius: "50%"}}>H</div>
                </div>
                <div className="left">
                  <h2 className="text-center sidenav-header lead" style={{color:"rgba(38, 58, 83, 1)"}}>Hotelsoffline</h2>
                  {/* <div className="bottom text-secondary" style={{fontSize: "14px"}}><span className="me-2">Edit Profile</span><span><i className="bi bi-pen-fill"></i></span></div> */}
                  <div className="d-flex justify-content-center align-items-center closeSidebar" style={style}>
                    <i className=" text-center close fw-bolder" onClick={close}>
                      <AiOutlineClose style={{fontSize: "30px" }} />
                    </i>
                  </div>
                </div>
            </div>
            <div className="text-center"><small className="text-lead text-secondary text-center mt-2 title_font">{user?.fname} {user?.lname}</small></div>
            <div><p className="text-lead text-secondary text-center mt-2">DMC</p></div>

          </header>
        <div className="sidebar_bottom">
          <div className="sidenav-links">
          {DMC.map(link=>(
            <SidebarLinks title={link.title} link={link.link}  key={link.id} icon={link.class}/>
            
          ))}
          </div>
          <footer className="sidenav-footer d-flex flex-column  justify-content-center my-5 align-items-center">
            {/* <button className="btn btn-primary btn px-5 py-1">Admin</button> */}
            {/* <button className="btn btn-secondary  btn px-2 py-1 mt-2">Change password</button> */}
            <button className="btn btn-danger btn px-5 py-1 mt-2" onClick={handleLogout} >Logout</button>
          </footer>
        </div>
      </div>
      // <i className="bi bi-list text-warning ham close" onClick={close}></i>
      // </div>
    )
}

export default Sidebar
