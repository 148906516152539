import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../controllers/controller";
// import ClassicalImage from "../../../../../Images/Classical.png"
import Loading from "../../../Reusables/Loader/Loading";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import PkgCard from "./PkgCard";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [search, setSearch] = useState("");
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      reloadPkgList();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const reloadPkgList = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    Fetch(`${process.env.REACT_APP_END_POINT}/list-all-package`, formData)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "danger",
          value: err.message,
        });
      });
  };
  console.log(data);
  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <input type="search" name="search" id="search" className="p-2 border border-dark rounded" placeholder="Search..." onChange={(e)=>setSearch(e.target.value)} />
        
      </div>
      {showAlert === true && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      {loading ? (
        <Loading />
      ) : data.length > 0 ? (
        <div className=" w-100">
          {" "}
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead> 
                <tr>
                  <th>No</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Sharing</th>
                  <th>Youtube</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  data.filter(item => (
                    item.package_name.includes(search) ||
                     item.sharing_link.includes(search) ||
                     item.youtube_link.includes(search)
                     
                    )).map((item, i) => (
                    <PkgCard item={item} {...item} i={i} reloadPkgList={reloadPkgList} />
                  ))

                }
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p className="text-center lead fw-bolder">No Packages Yet</p>
      )}
    </div>
  );
};

export default Home;
