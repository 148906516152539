import React, { useState, useEffect } from "react";
import { dateConverter, Fetch } from "../../../../../../controllers/controller";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import Countries from "../../../../../Signup/Countries/Countries";
import "./offer.css";
import AlertModal from "../../../../../Reusables/AlertModal/AlertModal";
import OfferDetail from "./OfferDate/OfferDetail";
const Offer = ({ contractTop }) => {
  const [payload, setPayload] = useState({
    countries: { value: "", label: "" },
    link1: "0",
    offer_meals: "0",
    offer_room: "0",
    offer_supplement: "0",
  });

  const [categoryList, setCategoryList] = useState([]);
  const [load, setLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [show, setShow] = useState(false);

  const handleCheck = (e) => {
    e.target.value === "0"
      ? setPayload({ ...payload, [e.target.name]: "1" })
      : setPayload({ ...payload, [e.target.name]: "0" });
  };

  const changeHandler = (value) => {
    setPayload({ ...payload, countries: value });
  };

  const handleSelectChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const hotelId = localStorage.getItem("hotel");

  const CT = localStorage.getItem("CT");
  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      contractTop.status !== "" &&
      contractTop.contract_name !== "" &&
      contractTop.child_age_From !== "" &&
      contractTop.child_age_to !== "" &&
      contractTop.currency !== ""
    ) {
      setLoad(true);

      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("contract_name", contractTop.contract_name);
      formData.append("dmctoken", useableAccOwner.usertoken);
      formData.append("hoteltoken", hotelId);
      formData.append("token", CT);

      formData.append("status", contractTop.status);

      formData.append("child_age_From", contractTop.child_age_From);
      formData.append("child_age_To", contractTop.child_age_To);
      formData.append("currency", contractTop.currency);

      formData.append("stay_from", payload.stay_from);
      formData.append("stay_to", payload.stay_to);
      formData.append("booking_from", payload.booking_from);
      formData.append("booking_to", payload.booking_to);

      formData.append("offer", payload.offer);
      formData.append("order", payload.order);
      formData.append("link1", payload.link1);
      formData.append("offers_room_type", payload.offers_room_type);

      formData.append("offer_room", payload.offer_room);
      formData.append("offer_meals", payload.offer_meals);
      formData.append("offer_supplement", payload.offer_supplement);

      formData.append("discount_amt", payload.discount_amt);
      formData.append("discount_rate", payload.discount_rate);
      formData.append("source_market", payload.countries.label);
      Fetch(`${process.env.REACT_APP_END_POINT}/offer-plan-update`, formData)
        .then((res) => {
          setLoad(false);
          if (res.data.success === true) {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
            setPayload({
              ...payload,
              discount_amt: "",
              discount_rate: "",
              offer: "",
              order: "",
              link1: "0",
              countries: { value: "", label: "" },
              stay_from: "",
              stay_to: "",
              booking_from: "",
              booking_to: "",
            });
            getOffer();
          } else {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "danger",
              value: res.data.message,
            });
          }
        })
        .catch((err) => {
          setLoad(false);
          setShowAlert(true);
          getOffer();
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      setShow(true);
    }
  };

  const [lists, setList] = useState([]);

  const getOffer = () => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("token", CT);
    formData.append("hoteltoken", hotelId);
    formData.append("dmctoken", useableAccOwner.usertoken);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-offer-plan`, formData)
      .then((res) => {
        if (res.data.message !== false) {
          const sortedOffer = res.data.sort((a, b) => {
            let fo = new Date(a.stay_from * 1000).toLocaleDateString();
            let sfo = new Date(fo);
            let lo = new Date(b.stay_from * 1000).toLocaleDateString();
            let slo = new Date(lo);

            return slo - sfo;
          });
          setList(sortedOffer);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  function getRoomCategories() {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelId);

    Fetch(`${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  }
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getOffer();
      getRoomCategories()
    }
    return () => {
      mounted = false;
    };
  }, [load]);
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <div className="supplement">
      <p className="lead text-primary">OFFERS</p>
      {showAlert === true && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      <div className="table-responsive mytable  supplement">
        <table className="table table-bordered border-dark w-75">
          <thead className="bg-primary text-light">
            <tr>
              <th scope="col" className=" primary"></th>
              <th className="text-center  success"></th>
              <th className="text-center  success"></th>
              <th colSpan="2" scope="colgroup" className=" primary">
                STAY
              </th>
              <th colSpan="2" scope="colgroup" className=" primary">
                BOOKING WINDOW
              </th>
              <th colSpan="2" scope="colgroup" className=" primary">
                DISCOUNT
              </th>
              <th className=" primary" style={{ minWidth: "150px" }}>
                SOURCE
              </th>
              <th className="text-center  success"></th>
              <th className="text-center  success"></th>
              <th className="text-center  success"></th>
              <th className="text-center  success"></th>
              <th className="text-center  success"></th>
              <th className=" primary"></th>
            </tr>

            <tr>
              <th scope="col" className=" primary">
                TIMESTAMP
              </th>
              <th className="text-center  success">OFFER</th>
              <th className="text-center  success">ROOM CATEGORY</th>

              <th scope="colgroup" className=" primary">
                FROM
              </th>
              <th scope="colgroup" className=" primary">
                TO
              </th>
              <th className=" primary">FROM</th>
              <th className=" primary">TO</th>
              <th className=" primary">AMOUNT</th>
              <th className=" primary" style={{ minWidth: "50px" }}>
                %
              </th>
              <th className=" primary">COUNTRIES</th>
              <th className="text-center success">LINKED</th>
              <th className="text-center success">ROOM</th>
              <th className="text-center success">MEALS</th>
              <th className="text-center success">SUPP</th>
              <th className="text-center success">ORDER</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody className="top_contract_body">
            <tr>
              <td style={{ minWidth: "100px" }}>
                <p className="form-control">{dateConverter()}</p>
              </td>
              <td style={{ minWidth: "120px" }}>
                <select
                  id="offer"
                  name="offer"
                  onChange={handleSelectChange}
                  value={payload.offer}
                >
                  <option defaultValue></option>
                  <option value="Geo Offer">Geo offer</option>
                  <option value="Last Minute">Last minute</option>
                  <option value="Non Refundable">Non refundable</option>
                  <option value="Early Bird">Early bird</option>
                </select>
              </td>
              <td style={{ minWidth: "120px" }}>
                {/* <label htmlFor="supplement" className="visually-hidden">Supplement Room Type</label> */}

                <select
                  name="offers_room_type"
                  id="offers"
                  onChange={handleChange}
                  className="form-select"
                  value={payload.offers_room_type}
                >
                  <option defaultValue></option>
                  {categoryList.length > 0 &&
                    categoryList.map((category) => (
                      <option value={category.id}>{category.category}</option>
                    ))}
                </select>
              </td>

              <td>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  value={payload.stay_from}
                  name="stay_from"
                  id=""
                />
              </td>
              <td>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  value={payload.stay_to}
                  name="stay_to"
                  id=""
                />
              </td>
              <td>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  value={payload.booking_from}
                  name="booking_from"
                  id=""
                />
              </td>
              <td>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  value={payload.booking_to}
                  name="booking_to"
                  id=""
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  value={payload.discount_amt}
                  name="discount_amt"
                  id="discount_amt"
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  value={payload.discount_rate}
                  name="discount_rate"
                  id="percentage"
                />
              </td>
              <td>
                <Countries
                  changeHandler={changeHandler}
                  value={payload.countries}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="link1"
                  id="link1"
                  onChange={handleCheck}
                  value={payload.link1}
                  checked={payload.link1 === "0" ? false : true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="offer_room"
                  id="offer_room"
                  onChange={handleCheck}
                  value={payload.offer_room}
                  checked={payload.offer_room === "0" ? false : true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="offer_meals"
                  id="offer_meals"
                  onChange={handleCheck}
                  value={payload.offer_meals}
                  checked={payload.offer_meals === "0" ? false : true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="offer_supplement"
                  id="offer_supplement"
                  onChange={handleCheck}
                  value={payload.offer_supplement}
                  checked={payload.offer_supplement === "0" ? false : true}
                />
              </td>
              <td>
                <select
                  id="order"
                  name="order"
                  onChange={handleSelectChange}
                  value={payload.order}
                >
                  <option defaultValue value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </td>
              <td>
                <button className="btn" onClick={handleSubmit}>
                  {load ? (
                    <div
                      class={load && `spinner-border text-primary`}
                      role="status"
                    >
                      Saving...
                    </div>
                  ) : (
                    <div className="fw-bold">SAVE</div>
                  )}
                </button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {lists.length > 0 &&
              lists.map((list) => (
                <OfferDetail
                  list={list}
                  contractTop={contractTop}
                  reloader={getOffer}
                  setShowAlert={setShowAlert}
                  setAlertType={setAlertType}
                />
              ))}
          </tbody>
          {/* <OfferDate lists={lists} setShowAlert={setShowAlert} setAlertType={setAlertType} getOffer={getOffer} contractTop={contractTop} load={showAlert}  /> */}
        </table>
      </div>
      <AlertModal show={show} setShow={setShow} />
    </div>
  );
};

export default Offer;
