import React, { useEffect, useState } from "react";

const Info = ({ amenities }) => {
  const [info, setInfo] = useState({});
  const [bookingInfo, setBookingInfo] = useState({});
  const filter = localStorage.getItem("filter");
  const booking = localStorage.getItem("booking");
  useEffect(() => {
    if (filter && booking) {
      const data = JSON.parse(filter);
      const item = JSON.parse(booking);
      setInfo(data);
      setBookingInfo(item);
    }
  }, []);
  return (
    <div className="booking_info me-2">
      <div className="form-group mb-2">
        <div className="d-flex me-1">
          <p className="fw-bold me-1">Date From: </p>
          <p>{info?.start_date}</p>
        </div>
        <div className="d-flex">
          <p className="fw-bold me-1">Date To: </p>
          <p>{info?.end_date}</p>
        </div>
      </div>
      <div className="form-group d-flex">
        <label htmlFor="date" className="form-label fw-bolder me-2">
          City:
        </label>
        <p
          className="ms-3 text-capitalize"
          dangerouslySetInnerHTML={{ __html: bookingInfo?.city }}
        />
      </div>
      <div className="form-group d-flex">
        <label htmlFor="date" className="form-label fw-bolder">
          Hotel:
        </label>
        <p
          className="ms-3 text-capitalize"
          dangerouslySetInnerHTML={{ __html: bookingInfo?.hotelname }}
        />
      </div>
      <div className="form-group d-flex">
        <label htmlFor="date" className="form-label fw-bolder">
          Adult:
        </label>
        <p className="ms-3 text-capitalize">{info?.adult}</p>
      </div>
      <div className="form-group d-flex">
        <label htmlFor="date" className="form-label fw-bolder">
          Child:
        </label>
        <p className="ms-3 text-capitalize">{info?.child}</p>
      </div>
      <div className="booking_info_amenities">
        <p className="fw-bold">Amenities</p>
        <div>
          <ul>
            {amenities.length > 0 &&
              amenities.map((item) => <li dangerouslySetInnerHTML={{ __html: item.room_amenities }} />)}
          </ul>
        </div>
      </div>
      <div className="booking_info_tc">
        <p className="fw-bold">Terms &amp; Conditions</p>
      </div>
    </div>
  );
};

export default Info;
