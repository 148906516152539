import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Sidebar from "./navbar/Sidebar";

import Manage from "./Manage/manage";
import Header from "./Header/Header";
import HotelState from "./Manage/Context/hotelState";
import ChangePassword from "../../ChangePassword/changePassword";
import Contract from "./Contract/Contract";
import Services from "../Reusables/Services/Services";
import Packages from "./Packages/Packages";
import History from "../Reusables/Wallet/History"
import Bookings from '../Reusables/Bookings/Bookings'
import E404 from "../../404/404";
import AllSources from "./Sources/Sources";
import AddExternalHotel from "./Manage/Hotels/AddExternalHotel";
import Settings from "../Reusables/Settings";
import Pricing from "./Pricing";

const Junior = () => {
  const [sidebar, setSidebar] = useState("hide");
  const [mainWrapper, setMainWrapper] = useState("")

  const [main, setMain] = useState("col-12")
  const handleClick = () => {
    sidebar === "hide" ? setSidebar("showsidebar"): setSidebar("hide")
    sidebar === "hide" ? setMainWrapper("d-flex justify-content-end wrapper-width") : setMainWrapper("mainWrapper")

  };

  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear()
    history.push("/login")
  }
  return (
    <HotelState>
    <div className="dash h-100 d-flex justify-content-end" style={{position:"relative", minHeight:"100vh"}}>
        <Sidebar sidebar={sidebar} close={handleClick} handleLogout={handleLogout} />
        
      <div className={`${mainWrapper} px-0 mainWrapper`}>
        <div className={` ${main} main`} >
         <Header handleClick={handleClick} navButton="Change Password"/>
          <Switch>
            <Route exact path={["/account-owner", "/account-owner/dashboard"]} component={Dashboard} />
            <Route path="/account-owner/hotels" component={Manage} />
            <Route path="/account-owner/contracts" component={Contract} />
            <Route path="/account-owner/services" component={Services} />
            <Route path="/account-owner/packages" component={Packages} />
            <Route path="/account-owner/wallet-history" component={History} />
            <Route path="/:id/change-password" component={ChangePassword} />
            <Route path="/account-owner/bookings" component={Bookings} />
            <Route path="/account-owner/profile" component={Settings} />
            <Route path="/account-owner/pricing" component={Pricing} />
            {/* <Route path="/account-owner/sources" component={AllSources} /> */}
            <Route path="*" component={E404} />

          </Switch>
        </div>
      </div>
    </div>
    </HotelState>
  );
};

export default Junior;
