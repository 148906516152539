import React from 'react'
import Special from '../../Super/Price/Special'

const Pricing = () => {
  return (
        <div className="pt-4 px-lg-4 px-3 ">
            <header className="d-flex justify-content-between align-items-center dashHeader">
                <h4 className="text-primary">PRICING</h4>
            </header>
            <div className="admin-dashboard-top mt-2 text-center">
                    <Special />
            </div>
        </div>
     
    )
}

export default Pricing