import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {useMutation} from "react-query"
import { Route, useLocation } from 'react-router-dom'
import AlertComp from '../../../Reusables/AlertComp/AlertComp'
import Loading from '../../../Reusables/Loader/Loading'
import Countries from '../../../Signup/Countries/Countries'
import { mainGetUsertoken } from '../../Reusables/GetUsertoken/GetUsertoken'
import { UserType } from '../../Reusables/Settings'
import { addDMCToHotel, fetchAdminHotels, fetchDMCs, getAllUsers, listHotelbyDmc, removeDMCFromHotel } from '../apis'



const DMCs = () => {
    
    const tableHeaders = ["S/N", "Country", "Hotel", "Token","Action"]

    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");
    const [dmcs, setdmcs] = useState([]);
    const [dmc, setdmc] = useState("");
    const [dmcHotels, setDmcHotels] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    })
    const [open, setOpen] = useState(false)



    useEffect(() => {
      mainGetUsertoken(location, setUsertoken, setUser);
    }, []);


    const fetchAdminDMCs = useMutation(fetchDMCs, {
        onSuccess: res => {
            if(res?.data?.data){
                setdmcs(res.data.data)
                return
            }
            setdmcs([])
        },
        onError: err => console.error(err)
    })


    useEffect(()=>{
        if(usertoken){
            fetchAdminDMCs.mutate({usertoken})
        }
    },[usertoken])


    const fetchDmcHotels = useMutation(listHotelbyDmc, {
        onSuccess: res => {
            console.log({res})
            if(res?.data?.hotels){
                setDmcHotels(res.data.hotels)
                return
            }
            setDmcHotels([])
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            // console.error(err)
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }  
    })

    useEffect(()=>{
        if(dmc){
            fetchDmcHotels.mutate({token: dmc})
        }
    },[dmc])

    
    const handleAlertClose = () => {
        setShowAlert(false);
    };


    function closeModal() {
        setOpen(false)
    }

     // DELETE
     const deleteHotelFromDmc = useMutation(removeDMCFromHotel, {

        onSuccess: res => {
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true)
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })


    function remove(hoteltoken){
        deleteHotelFromDmc.mutate({usertoken, dmctoken: dmc, hoteltoken:hoteltoken})
    }


  return (
    <div className="pt-4 px-lg-4 px-3 ">
        <header className="d-flex justify-content-between align-items-center dashHeader">
            <h4 className="text-primary">DMCs</h4>
        </header>
         <div className="admin-dashboard-top mt-2">
            {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
                )
            }
            <Route exact path="/admin/dmcs" component={MainDMCs}  />
            <Route path="/admin/dmcs/details/:id" component={MainDMC}  />
        </div>
    </div>
  )
}


function MainDMCs(){
    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
	const [usertoken, setUsertoken] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});
	const head = [
		"No",
		"Name",
		"Email",
		"Account Type",
		"Country",
		"Date Added",
	];


    const mutation = useMutation(getAllUsers, {
		onError: (e) => {
			console.error(e);
			setAlertType({ ...alert, type: "danger", value: e.message });
			setShowAlert(true);
		},
		onSuccess: (res) => {
			if (res.data.success) {
				setUsers(res.data.users);
			} else {
				setAlertType({ ...alert, type: "danger", value: res.data.message });
				setShowAlert(true);
			}
		},
	});


    useEffect(() => {
		mutation.mutate({
			usertoken,
			apptoken: process.env.REACT_APP_APP_TOKEN,
		});
	}, [usertoken]);


    return (
        <>
            {
                mutation?.isLoading ? 
                <Loading />
                :
                <UserType
                    comp="DMC"
                    head={head}
                    users={users?.filter((item) => item.role_alt === "DMC")}
                    search={search}
                    listOnly={true}
                    // approve={approve}
                    // approveUser={approveUser}
                />

            }
        </>
    )
}


function MainDMC(){
    const tableHeaders = ["S/N", "Country", "Hotel", "Token","Action"]

    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");
    const [dmcs, setdmcs] = useState([]);
    const [dmc, setdmc] = useState("");
    const [dmcHotels, setDmcHotels] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    })
    const [open, setOpen] = useState(false)



    useEffect(() => {
      mainGetUsertoken(location, setUsertoken, setUser);
    }, []);


    const fetchAdminDMCs = useMutation(fetchDMCs, {
        onSuccess: res => {
            if(res?.data?.data){
                setdmcs(res.data.data)
                return
            }
            setdmcs([])
        },
        onError: err => console.error(err)
    })


    useEffect(()=>{
        if(usertoken){
            fetchAdminDMCs.mutate({usertoken})
        }
    },[usertoken])


    const fetchDmcHotels = useMutation(listHotelbyDmc, {
        onSuccess: res => {
            console.log({res})
            if(res?.data?.hotels){
                setDmcHotels(res.data.hotels)
                return
            }
            setDmcHotels([])
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            // console.error(err)
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }  
    })

    useEffect(()=>{
        if(dmc){
            fetchDmcHotels.mutate({token: dmc})
        }
    },[dmc])

    
    const handleAlertClose = () => {
        setShowAlert(false);
    };


    function closeModal() {
        setOpen(false)
    }

     // DELETE
     const deleteHotelFromDmc = useMutation(removeDMCFromHotel, {

        onSuccess: res => {
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true)
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })


    function remove(hoteltoken){
        deleteHotelFromDmc.mutate({usertoken, dmctoken: dmc, hoteltoken:hoteltoken})
    }


  return (
    <div className="pt-4 px-lg-4 px-3 ">
        {/* <header className="d-flex justify-content-between align-items-center dashHeader">
            <h4 className="text-primary">DMCs</h4>
        </header> */}
         <div className="admin-dashboard-top mt-2">
            {showAlert === true && (
                <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                />
                )
            }
                {
                    fetchAdminDMCs?.isLoading ? 

                    <div className="spinner-border border-primary">
                        <div className="visually-hidden">Loading...</div>
                    </div>
                    :
                    <div className="form-group">
                        <select name="dmc" id="dmc" className="p-2 rounded" onChange={(e)=>setdmc(e.target.value)} value={dmc}>
                            <option value="">Select DMC</option>
                            {
                                dmcs.map(item => (
                                    <option value={item.token}>{`${item.first_name} ${item.last_name} - ${item.mail}`}</option>
                                ))
                            }
                        </select>
                    </div>
                }
            <div className="d-flex justify-content-end gap-2 my-4">
                <button className="btn btn-primary" onClick={()=>setOpen(true)}>Add Hotel</button>
            </div>
            <div className="admin_hotel">
                {
                    fetchDmcHotels?.isLoading ? 
                    <Loading /> :
                    <div className="table-responsive">
                        <table className="table table-bordered admin_hotel_table position-relative">
                        <thead>
                            <tr>
                            {
                                tableHeaders.map(item => (
                                <th>{item}</th>
                                ))
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dmcHotels?.map((item, i)=> (
                                    <tr>
                                        <td>{i + 1} </td>
                                        <td>{item.country}</td>
                                        <td>{item.hotelname}</td>
                                        <td>{item.hoteltoken}</td>
                                        <td>
                                            <button className="btn btn-danger" onClick={()=>remove(item.hoteltoken)} disabled={deleteHotelFromDmc?.isLoading}>
                                                {
                                                    deleteHotelFromDmc.isLoading ? <div className="spinner-border text-white"></div> :
                                                    <span>Delete</span>
                                                }
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                    </div>
                }
                <AddDMCToHotel open={open} handleClose={closeModal} dmc={dmc} fetchDmcHotels={fetchDmcHotels} />
            </div>
        </div>
    </div>
  )
}


function AddDMCToHotel({open, handleClose, dmc, fetchDmcHotels}){
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [hotels, setHotels]= useState([])
    const [formstate, setFormstate] = useState({})
    const location = useLocation();
    const [usertoken, setUsertoken] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        mainGetUsertoken(location, setUsertoken, setUser);
      }, []);
  
    const handleAlertClose = () => {
        setShowAlert(false);
    }

    const addHotel = useMutation(addDMCToHotel, {
        onSuccess: res => {
            if(res.data.success) {
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setShowAlert(true);
                fetchDmcHotels.mutate({token: dmc})
                handleClose()
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    const fetchHotels = useMutation(fetchAdminHotels, {
        onSuccess: res => {
            if(res.data.success) {
                setHotels(res.data.hotels)
                return
            }
            setAlertType({ ...alert, type: "danger", value: res.data.message });
            setShowAlert(true)
        },
        onError: err => {
            setAlertType({ ...alert, type: "danger", value: err.message });
            setShowAlert(true)
        }
    })

    useEffect(()=>{
        fetchHotels.mutate({token: usertoken})
    },[usertoken])

    function add(){
        if(!dmc){
            setAlertType({ ...alert, type: "danger", value: "Please select a DMC" });
            setShowAlert(true)
        }
        addHotel.mutate({...formstate, dmctoken: dmc, usertoken})
    }

    const changeHandler = (value) => {
       setFormstate({ ...formstate, country: value.label });
    };

    function onChange(e){
        setFormstate({...formstate, [e.target.name]: e.target.value})
    }

   

    return (
        <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="deletePrompt"
        centered
      >
        {addHotel.isLoading ? (
          <Loading heightvalue="45vh" />
        ) : (
            <Modal.Body>
              {showAlert === true && (
                  <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleAlertClose}
                  />
                  )
              }
              <header>
                <h6 className='fw-bolder mb-3'>Add DMC to Hotel</h6>
              </header>
              <form>
                <div className="form-group">
                    <label htmlFor="hoteltoken" className="form-label">Hotel</label>
                    <select name="hoteltoken" id="hoteltoken" className="form-select" onChange={onChange} value={formstate.hoteltoken}>
                        <option value="">Select Hotel</option>
                        {/* list of hotels */}
                        {
                            hotels?.map((item, i) => (
                                <option key={i} value={item.token}>{`${item.hotelname} - ${item.token}`}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="country" className="form-label">Country</label>
                    <Countries
                        changeHandler={changeHandler}
                    /> 
                </div>
              </form>
            <div className="d-flex justify-content-between align-items-center w-100 mt-3">
              <button className="btn btn-secondary" onClick={handleClose}>
                Close
              </button>
              <button className="btn btn-dark" onClick={add}>
                Add
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    )
}
export default DMCs