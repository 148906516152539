import React, {useState, useEffect} from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { FiFilter } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Fetch } from '../../../../../controllers/controller';
import AlertComp from '../../../../Reusables/AlertComp/AlertComp';
import Loading from '../../../../Reusables/Loader/Loading';
import ContractList from '../../../Others/DMC/Contracts/ContractList';
import ContractCalModal from '../../../Others/DMC/Contracts/Modal/ContractCalModal';
import Markup from '../../../Others/DMC/Contracts/Modal/Markup';
import Completed from '../../../Others/DMC/Contracts/Status/Completed';
import HotelContract from '../../../Reusables/contractCard/hotelContract';

const All = ({match}) => {

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
      type: "",
      value: "",
    });
    const [show, setShow] = useState(false);
    const [buttonLoad, setButtonLoad] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [calValues, setCalValues] = useState([]);
    const [showMarkup, setShowMarkup] = useState(false);
    const [id, setId] = useState("");
    const [indexx, setIndex] = useState(null);
    const [search, setSearch] = useState("");
    const [cccFilter, setCCCFilter] = useState({
      company: "",
      city: "",
      country: "",
    });
    const history = useHistory();

    const handleSummary = (e) =>{
      localStorage.setItem("con", e)
    }
    useEffect(() => {
      let mounted = true;
      const formData = new FormData();
      const accowner = localStorage.getItem("accountOwner");
  
      if (accowner) {
          setLoading(true)
        const useableAccOwner = JSON.parse(accowner);
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("token", useableAccOwner.usertoken);
        Fetch(
          `${process.env.REACT_APP_END_POINT}/list-contract-three`,
          formData
        )
          .then((res) => {
            if (mounted) {
              if(res.data.success !== false){
                setContracts(res.data)
              }
              if(res.data.success === false){
                setContracts(res.data)
               
              }
              setLoading(false);
              setAlertType({
                ...alert,
                type: "success",
                value: res.data.message,
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "danger", value: err.message });
          });
      }
  
      return () => {
        mounted = false;
      };
    }, []);

    const handleSearch = (e) => {
      setSearch(e.target.value);
    };
    const handleAlertClose = () => {
      setShowAlert(false);
    };
    const handleMarkupClose = () => {
      setShowMarkup(false);
    };
    const openMarkup = (e) => {
      setId(e);
      setShowMarkup(true);
    };
    

    const openAvailModal = (e, event, index) => {
      // if(event)
      setButtonLoad(e);
      localStorage.setItem("cont", e);
      setIndex(index);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("token", e);
      Fetch(`${process.env.REACT_APP_NEND_POINT}/get-contract-rooms`, formData)
        .then((res) => {
          setButtonLoad(null);
          setCalValues([...res.data]);
          setShow(true);
        })
        .catch((err) => {
          setButtonLoad(null);
          window.alert(err);
        });
    };

    return loading ? (<Loading />):(
        <>
             {showAlert === true && (
                <AlertComp variant={alert.type} alertText={alert.value} />
              )}
            <div className="d-flex flex-column justify-content-center align-items-center">

              { 
                contracts.length > 0 ? 
                  
                  <Tabs
                    defaultActiveKey="all"
                    id="uncontrolled-tab-example"
                    className="mb-3 title_font"
                  >
                      <Tab eventKey="all" title="ALL">
                      {contracts.length > 0 ? (
                      <>
                        <div className="d-flex w-100 justify-content-between mb-2">
                          <div className="search d-flex align-items-center" style={{gap:"1rem"}} >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="search..."
                              value={search}
                              onChange={handleSearch}
                            />
                          <div className="form-group d-flex align-items-center my-3">
                            <label htmlFor="filter" className="form-label d-flex me-3">Filter <i><FiFilter /></i></label>
                            <select name="name" id="name" className="form-select form-control"  onChange={handleSearch}>
                              <option value="">Select contract type</option>
                              <option value="Static">Static</option>
                              <option value="Dynamic">Dynamic</option>
                            </select>
                          </div>
                          </div>
                        </div>
                        <div className="table-responsive" style={{ fontSize: "13px" }}>
                          <table className="table table-bordered table-striped table-hover">
                            <thead>
                              <tr>
                                <th>S/N</th>
                                <th>Contract</th>
                                <th>ID</th>
                                <th>Type</th>
                                <th style={{ minWidth: "180px" }}>Duration</th>
                                <th style={{ minWidth: "110px" }}>Hotel</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>Company</th>
                                <th>Status</th>
                                <th>Age(yrs)</th>
                                <th>Currency</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {contracts.length > 0 &&
                                contracts
                                  .filter(
                                    (item) =>
                                      (item.company &&
                                        item.company
                                          .toLowerCase()
                                          .includes(search.toLowerCase())) ||
                                      item.city.toLowerCase().includes(search.toLowerCase()) ||
                                      item.country.toLowerCase().includes(search.toLowerCase()) ||
                                      item.status.toLowerCase().includes(search.toLowerCase()) ||
                                      item.type.toLowerCase().includes(search.toLowerCase()) ||
                                      item.contract_name.toLowerCase().includes(search.toLowerCase())
                                  )
                                  .map((contract, index) => (
                                    <ContractList
                                      {...contract}
                                      index={index}
                                      openAvailabilityModal={(event, id) =>
                                        openAvailModal(event, id, index)
                                      }
                                      openMarkup={openMarkup}
                                      buttonLoad={buttonLoad}
                                      
                                    />
                                  ))}
                            </tbody>
                          </table>
                        </div>
                          <ContractCalModal
                            show={show}
                            value={calValues}
                            setShow={setShow}
                            handleClose={() => {
                              setShow(false);
                            }}
                          />
                          <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
                        </>
                        ):(<Completed />)}
                      </Tab>
                      <Tab eventKey="live" title="LIVE">
                              {contracts.length > 0 && contracts?.filter(item=>item.status === "live" || item.status === "LIVE") ? (
                              <>
                                <div className="d-flex justify-content-between mb-2">
                                  <div className="search">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="search..."
                                      value={search} 
                                      onChange={handleSearch}
                                    />
                                    <div className="form-group my-3">
                                      <label htmlFor="filter" className="form-label">Filter <i><FiFilter /></i></label>
                                      <select name="name" id="name" className="form-select"  onChange={handleSearch}>
                                        <option value="">Select contract type</option>
                                        <option value="Static">Static</option>
                                        <option value="Dynamic">Dynamic</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-responsive" style={{ fontSize: "13px" }}>
                                  <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th>S/N</th>
                                        <th>Contract</th>
                                        <th>ID</th>
                                        <th>Type</th>
                                        <th style={{ minWidth: "180px" }}>Duration</th>
                                        <th style={{ minWidth: "110px" }}>Hotel</th>
                                        <th>Country</th>
                                        <th>City</th>
                                        <th>Company</th>
                                        <th>Status</th>
                                        <th>Age(yrs)</th>
                                        <th>Currency</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {contracts.length > 0 &&
                                        contracts?.filter(item=>item.status === "live" || item.status === "LIVE")
                                          .filter(
                                            (item) =>
                                              (item.company &&
                                                item.company
                                                  .toLowerCase()
                                                  .includes(search.toLowerCase())) ||
                                              item.city.toLowerCase().includes(search.toLowerCase()) ||
                                              item.country.toLowerCase().includes(search.toLowerCase()) ||
                                              item.status.toLowerCase().includes(search.toLowerCase()) ||
                                              item.type.toLowerCase().includes(search.toLowerCase()) ||
                                              item.contract_name.toLowerCase().includes(search.toLowerCase())
                                          )
                                          .map((contract, index) => (
                                            <ContractList
                                              {...contract}
                                              index={index}
                                              openAvailabilityModal={(event, id) =>
                                                openAvailModal(event, id, index)
                                              }
                                              openMarkup={openMarkup}
                                              buttonLoad={buttonLoad}
                                              
                                            />
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                                  <ContractCalModal
                                    show={show}
                                    value={calValues}
                                    setShow={setShow}
                                    handleClose={() => {
                                      setShow(false);
                                    }}
                                  />
                                  <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
                              </>
                          ):(<Completed />)}
                      </Tab>
                      <Tab eventKey="renewal" title="RENEWAL">
                          {contracts.length > 0 && contracts?.filter(item=>item.status === "renew" ||item.status === "RENEW") ? (
                                  <>
                                    <div className="d-flex justify-content-between mb-2">
                                      <div className="search">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="search..."
                                          value={search}
                                          onChange={handleSearch}
                                        />
                                      <div className="form-group my-3">
                                      <label htmlFor="filter" className="form-label">Filter <i><FiFilter /></i></label>
                                      <select name="name" id="name" className="form-select"  onChange={handleSearch}>
                                        <option value="">Select contract type</option>
                                        <option value="Static">Static</option>
                                        <option value="Dynamic">Dynamic</option>
                                      </select>
                                    </div>
                                      </div>
                                    </div>
                                    <div className="table-responsive" style={{ fontSize: "13px" }}>
                                      <table className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>S/N</th>
                                            <th>Contract</th>
                                            <th>ID</th>
                                            <th>Type</th>
                                            <th style={{ minWidth: "180px" }}>Duration</th>
                                            <th style={{ minWidth: "110px" }}>Hotel</th>
                                            <th>Country</th>
                                            <th>City</th>
                                            <th>Company</th>
                                            <th>Status</th>
                                            <th>Age(yrs)</th>
                                            <th>Currency</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {contracts.length > 0 &&
                                            contracts?.filter(item=>item.status === "renew" || item.status === "RENEW")
                                              .filter(
                                                (item) =>
                                                  (item.company &&
                                                    item.company
                                                      .toLowerCase()
                                                      .includes(search.toLowerCase())) ||
                                                  item.city.toLowerCase().includes(search.toLowerCase()) ||
                                                  item.country.toLowerCase().includes(search.toLowerCase()) ||
                                                  item.status.toLowerCase().includes(search.toLowerCase()) ||
                                                  item.type.toLowerCase().includes(search.toLowerCase()) ||
                                                  item.contract_name.toLowerCase().includes(search.toLowerCase())
                                              )
                                              .map((contract, index) => (
                                                <ContractList
                                                  {...contract}
                                                  index={index}
                                                  openAvailabilityModal={(event, id) =>
                                                    openAvailModal(event, id, index)
                                                  }
                                                  openMarkup={openMarkup}
                                                  buttonLoad={buttonLoad}
                                                  
                                                />
                                              ))}
                                        </tbody>
                                      </table>
                                    </div>
                                      <ContractCalModal
                                        show={show}
                                        value={calValues}
                                        setShow={setShow}
                                        handleClose={() => {
                                          setShow(false);
                                        }}
                                      />
                                      <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
                                  </>
                              ):(<Completed />)}
                      </Tab>
                      <Tab eventKey="closed" title="CLOSED">
                      {contracts.length > 0 && contracts?.filter(item=>item.status === "closed" || item.status === "CLOSED") ? (
                              <>
                                <div className="d-flex justify-content-between mb-2">
                                  <div className="search">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="search..."
                                      value={search}
                                      onChange={handleSearch}
                                    />
                                  <div className="form-group my-3">
                                      <label htmlFor="filter" className="form-label">Filter <i><FiFilter /></i></label>
                                      <select name="name" id="name" className="form-select"  onChange={handleSearch}>
                                        <option value="">Select contract type</option>
                                        <option value="Static">Static</option>
                                        <option value="Dynamic">Dynamic</option>
                                      </select>
                                  </div>
                                  </div>
                                </div>
                                <div className="table-responsive" style={{ fontSize: "13px" }}>
                                  <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th>S/N</th>
                                        <th>Contract</th>
                                        <th>ID</th>
                                        <th>Type</th>
                                        <th style={{ minWidth: "180px" }}>Duration</th>
                                        <th style={{ minWidth: "110px" }}>Hotel</th>
                                        <th>Country</th>
                                        <th>City</th>
                                        <th>Company</th>
                                        <th>Status</th>
                                        <th>Age(yrs)</th>
                                        <th>Currency</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {contracts.length > 0 &&
                                        contracts?.filter(item=>item.status === "closed" || item.status === "CLOSED")
                                          .filter(
                                            (item) =>
                                              (item.company &&
                                                item.company
                                                  .toLowerCase()
                                                  .includes(search.toLowerCase())) ||
                                              item.city.toLowerCase().includes(search.toLowerCase()) ||
                                              item.country.toLowerCase().includes(search.toLowerCase()) ||
                                              item.status.toLowerCase().includes(search.toLowerCase()) ||
                                              item.type.toLowerCase().includes(search.toLowerCase()) ||
                                              item.contract_name.toLowerCase().includes(search.toLowerCase())
                                          )
                                          .map((contract, index) => (
                                            <ContractList
                                              {...contract}
                                              index={index}
                                              openAvailabilityModal={(event, id) =>
                                                openAvailModal(event, id, index)
                                              }
                                              openMarkup={openMarkup}
                                              buttonLoad={buttonLoad}
                                            />
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                                  <ContractCalModal
                                    show={show}
                                    value={calValues}
                                    setShow={setShow}
                                    handleClose={() => {
                                      setShow(false);
                                    }}
                                  />
                                  <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
                              </>
                          ):(<Completed />)}
                      </Tab>
                  </Tabs>

                  // <HotelContract title={contract.hotelname} ID={contract.contract_id} matched={match.url} time={contract.timestamp} handleSummary={handleSummary}  name={contract.room_name} duration={contract.expiry_date} price={contract.price} />
                :
                (<Completed />)
              }
            </div>
            <ContractCalModal
              show={show}
              value={calValues}
              setShow={setShow}
              handleClose={() => {
                setShow(false);
              }}
          />
          <Markup show={showMarkup} handleClose={handleMarkupClose} token={id} />
        </>
    )
}

export default All
