import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";
import * as Yup from "yup";
import PDF from "../Receipt/PDF";
import RequestError from "../../../404/RequestError";
import { Payoneer } from "../Settings";
import { verifyUserStatus } from "../../Super/apis";
import { useMutation } from "react-query";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { mainGetUsertoken } from "../GetUsertoken/GetUsertoken";

const PayoneerModal = ({ open, setOpen }) => {
	const location = useLocation();
	const [usertoken, setUsertoken] = useState("");
	const [user, setUser] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlertType] = useState({
		type: "",
		value: "",
	});

	useEffect(() => {
		mainGetUsertoken(location, setUsertoken, setUser);
	}, []);

	const handleClose = () => {
		setOpen(false);
	};

	console.log({ usertoken });

	const getPayoneerStatus = useMutation(verifyUserStatus, {
		onSuccess: (res) => {
			console.log({ res });
			if (res?.data.success) {
			} else {
				setShowAlert(true);
				setAlertType({ type: "danger", value: res.data.message });
			}
		},
		onError: (err) => {
			setShowAlert(true);
			setAlertType({ type: "danger", value: err.message });
		},
	});

	useEffect(() => {
		if (usertoken) {
			getPayoneerStatus.mutate({ usertoken });
		}
	}, [usertoken]);

  
  
  const handleAlertClose = () => {
    setShowAlert(false);
  };

	return (
		<Modal show={open} onHide={handleClose} centered>
			<Modal.Header>
				<h3 className="text-primary title_font">Connect Payoneer</h3>
			</Modal.Header>
			<Modal.Body>
				{showAlert === true && (
					<AlertComp
						variant={alert.type}
						alertText={alert.value}
						handleAlertClose={handleAlertClose}
					/>
				)}
				<Payoneer />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PayoneerModal;
