import React from 'react'
import DashboardChart from '../../Super/Dashboard/DashboardChart/DashboardChart'
import Card from '../Card/Card'

const Dashboard = () => {
    return (
        
        <div className="pt-4 px-lg-4 px-3 ">
            <header className="dashHeader">
                <h4 className="text-primary title_font">DASHBOARD</h4>
            </header>
            <div className="admin-dashboard-top mt-5">
                <Card />
                <DashboardChart />
                
            </div>
        </div>
    )
}

export default Dashboard
