import React, { useState, useEffect, useRef } from "react";
import PaginatedItems from './Paginate'
import { useFormik } from "formik";
import NoBookings from "./NoBookings";
import { Fetch } from "../../../../controllers/controller";
import { BsFilter } from "react-icons/bs";
import Loading from "../../../Reusables/Loader/Loading";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";


const Booking = () => {
    const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  
  const [showAlert, setShowAlert] = React.useState(false);
  const [alert, setAlertType] = React.useState({
    type: "",
    value: "",
  });
  const [date, setDate] = useState(false);
  const [date2, setDate2] = useState(false);
  const [roll, setRoll] = useState(false);
  
  const dropRef = useRef(null);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const formik = useFormik({
    initialValues: {
      location: "",
      adult: 0,
      room: 0,
      child: 0,
      start_date: "",
      end_date: "",
    },

    onSubmit: (values) => {
      localStorage.setItem("filter", JSON.stringify(values))
      setLoading(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("keyword", values.location);
      formData.append("from", values.start_date);
      formData.append("to", values.end_date);
      formData.append("childAge", values.childAge);

      Fetch(`${process.env.REACT_APP_END_POINT}/search-engine`, formData)
        .then((res) => {
        
          setLoading(false);
          if (res.data.success === false) {
            setShowAlert(true);
            setList([]);
            setAlertType({
              ...alert,
              type: "danger",
              value: res.data.message,
            });
          } else if (res.data.length > 0) {
            setList(res.data.filter(item=>item.roomtoken !== "0"));
          }
        })
        .catch((err) => {
          
          setLoading(false);
          setAlertType({
            ...alert,
            type: "danger",
            value: err.message,
          });
        });
    },
  });
  const [childCount, setChildCount] = useState([])
  const [childArr, setChildArr] = useState({})
  const addAdult = (e) => {
    e.preventDefault();
    formik.setValues({ ...formik.values, adult: formik.values.adult + 1 });
  };
  const addChild = (e) => {
    e.preventDefault();
    formik.setValues({ ...formik.values, child: formik.values.child + 1 });
    setChildCount([...childCount, formik.values.child + 1])
  };
  


  const addRoom = (e) => {
    e.preventDefault();
    formik.setValues({ ...formik.values, room: formik.values.room + 1 });
  };

  const subtractAdult = (e) => {
    e.preventDefault();
    if (formik.values.adult === 0) {
      return;
    }
    formik.setValues({ ...formik.values, adult: formik.values.adult - 1 });
  };
  const subtractChild = (e) => {
    e.preventDefault();
    if (formik.values.child === 0) {
      return;
    }
    formik.setValues({ ...formik.values, child: formik.values.child - 1 });
    
    const newChildCount = childCount.filter(item=> item !== childCount.slice(-1)[0])
    setChildCount(newChildCount)

  };
  const subtractRoom = (e) => {
    e.preventDefault();
    if (formik.values.room === 0) {
      return;
    }
    formik.setValues({ ...formik.values, room: formik.values.room - 1 });
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const toggleRoll = () => {
    setRoll(!roll);
  };
  
useEffect(() => {
  localStorage.removeItem("filter")
  localStorage.removeItem("booking")
}, [])

useEffect(() => {
  if(Object.keys(childArr).length > 0){
    const childVal = Object.values(childArr)
    const total = childVal.reduce((a,b)=> Number(a) + Number(b), 0)
    formik.setValues({ ...formik.values, childAge: total / childVal.length });

  }
}, [childArr])

function handleChildAge(e){
  setChildArr({...childArr, [e.target.name]: e.target.value})
}
const status = OutsideClick(dropRef);

useEffect(()=>{
  
  status === true && setShow(false)
},[status])

  return (
    <section className="content">
        <section>
          <div className="Booking_search ">
            <form className="form " onSubmit={formik.handleSubmit}>
              <div
                className="row justify-content-between align-items-center booking_search px-1"
                style={{ gap: ".5rem" }}
              >
                <div className="col-sm-2">
                  <input
                    type="text"
                    name="location"
                    id="location"
                    placeholder="Search term..."
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.location}
                    style={{ backgroundColor: "unset !important" }}
                  />
                </div>
                <div
                  className="form-group d-flex col-sm-4"
                  style={{ gap: "0.5rem" }}
                >
                  <input
                    name="start_date"
                    id="start_date"
                    type="date"
                    // type={date ? "date" : "text"}
                    // onBlur={() => setDate(false)}
                    // onFocus={() => setDate(true)}
                    className="form-control"
                    placeholder="Stay From"
                    onChange={formik.handleChange}
                    value={formik.values.start_date}
                  />
                  <input
                    type="date"
                    // type={date2 ? "date" : "text"}
                    // onBlur={() => setDate2(false)}
                    // onFocus={() => setDate2(true)}
                    name="end_date"
                    id="end_date"
                    className="form-control"
                    placeholder="Stay End"
                    onChange={formik.handleChange}
                    value={formik.values.end_date}
                  />
                </div>
                <div className="Bookings_droptrigger col-12 col-md-4 bg-light rounded ">
                  <div className=" Bookings_droptrigger_content d-flex justify-content-between rounded">
                    <p>
                      {formik.values.adult}
                      <span className="ms-1 text-muted">Adults</span>
                    </p>
                    <p>
                      {formik.values.child}
                      <span className="ms-1 text-muted">Children</span>
                    </p>
                    <p>
                      {formik.values.room}
                      <span className="ms-1 text-muted">Room</span>
                    </p>
                    <p>
                      <i
                        className="bi bi-file-plus fw-bolder"
                        style={{ fontSize: "17px" }}
                        onClick={() => setShow(!show)}
                      ></i>
                    </p>
                  </div>
                  {show ? (
                    <div className="Booking_dropdown p-2" ref={dropRef}>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <p>Adult</p>
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-outline-primary me-2"
                            onClick={subtractAdult}
                          >
                            -
                          </button>
                          <p>{formik.values.adult}</p>
                          <button
                            className="btn btn-outline-primary ms-2 "
                            onClick={addAdult}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <p>Children</p>
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-outline-primary me-2"
                            onClick={subtractChild}
                          >
                            -
                          </button>
                          <p>{formik.values.child}</p>
                          <button
                            className="btn btn-outline-primary ms-2 "
                            onClick={addChild}
                          >
                            +
                          </button>
                        </div>
                      </div>

                        <div className="d-flex align-items-center row">
                          {childCount.length > 0 && childCount.map((item)=>(
                            <div className="p-1 col-md-6">
                              <input type="number" className=" form-control" placeholder={`Child ${item} age`} name={"child" + item} onChange={handleChildAge} />
                            </div>
                          ))}
                        </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <p>Rooms</p>
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-outline-primary me-2"
                            onClick={subtractRoom}
                          >
                            -
                          </button>
                          <p>{formik.values.room}</p>
                          <button
                            className="btn btn-outline-primary ms-2 "
                            onClick={addRoom}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-1 bg-white p-1">
                  <button type="submit" className="btn " style={{background: "blue", color:"#fff"}}>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section className="row Bookings_main justify-content-center ">
          <div
            className={`${
              roll ? "unroll" : "filter"
            } d-none d-sm-block col-md-2  `}
          >
            <div className="p-2">
              <p className="fw-bolder">
                Filter{" "}
                <span>
                  <i onClick={toggleRoll}>
                    <BsFilter />
                  </i>
                </span>
              </p>
              <form>
                <hr />
                <div className="form-check d-flex  align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name="max_1000"
                    id="max_1000"
                  />
                  <label htmlFor="max_1000" className="form-check-label">
                    USD 0 - USD 1,000
                  </label>
                </div>
                <div className="form-check d-flex  align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name="max_10000"
                    id="max_10000"
                  />
                  <label htmlFor="max_10000" className="form-check-label">
                    USD 1,000 - USD 10,000
                  </label>
                </div>
                <div className="form-check d-flex  align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name="max_20000"
                    id="max_20000"
                  />
                  <label htmlFor="max_20000" className="form-check-label">
                    USD 10,000 - USD 20,000
                  </label>
                </div>
                <div className="form-check d-flex  align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name="min_20000"
                    id="min_20000"
                  />
                  <label htmlFor="min_20000" className="form-check-label">
                    USD 20,000 +{" "}
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div className="sm_filter mb-1 d-sm-none col-12">
            <div className="p-2 ps-0 small_filter">
              <p
                className="fw-bolder btn btn-outline-primary"
                onClick={handleShowFilter}
              >
                Filter
              </p>
              {showFilter ? (
                <form className="bg-primary text-white">
                  <hr />

                  <div className="form-check d-flex  align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name="max_1000"
                      id="max_1000"
                    />
                    <label htmlFor="max_1000" className="form-check-label">
                      USD 0 - USD 1,000
                    </label>
                  </div>
                  <div className="form-check d-flex  align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name="max_10000"
                      id="max_10000"
                    />
                    <label htmlFor="max_10000" className="form-check-label">
                      USD 1,000 - USD 10,000
                    </label>
                  </div>
                  <div className="form-check d-flex  align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name="max_20000"
                      id="max_20000"
                    />
                    <label htmlFor="max_20000" className="form-check-label">
                      USD 10,000 - USD 20,000
                    </label>
                  </div>
                  <div className="form-check d-flex  align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name="min_20000"
                      id="min_20000"
                    />
                    <label htmlFor="min_20000" className="form-check-label">
                      USD 20,000 +{" "}
                    </label>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
          <div className="main_cards col-md-10 justify-content-center ps-2">
    {showAlert &&  <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} /> }

            {loading && <Loading />}
            {list.length > 0 ? 
            <PaginatedItems
              loading={loading}
              showAlert={showAlert}
              alert={alert}
              handleAlertClose={handleAlertClose}
              list={list}
              itemsPerPage={5}
            /> :
            (
              <NoBookings />
              )
            }
          </div>
        </section>
      </section>
  )
}

function OutsideClick(ref) {
  const [isClicked, setIsClicked] = useState();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        
        setIsClicked(true);
      } else {
        setIsClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return isClicked;
}

export default Booking