import React from 'react'

const ClassicalCard = ({cardImage, cardTitle, rating, reviews, startTime, countdown, discountOff, newPrice}) => {
    return (
        <div className="card classical__card">
            <div className="image-wrapper border" >
                <img src={cardImage} className="card-img-top rental__image img-fluid" style={{height:'244px', maxWidth: "100%"}} alt="..." />
                <p className="classical__featured text-light bg-danger">Featured</p>
                <p className="classical__offer text-light bg-danger">51%</p>
            </div>
            <div className="card-body classical__body">
                <h5 className="card-title title">{cardTitle}</h5>
                <div className="d-flex justify-content-between align-items-center feedback">
                    <div className="rating text-primary">{rating}</div>
                    <div className="reviews text-muted">{reviews}</div>
                </div>
                <div className="time">
                    <p>Start Time: {startTime}</p>
                </div>
                <hr />
                <div className="icons-section d-flex justify-content-between">
                    <div className="countdown text-muted">
                        {countdown}
                    </div>
                    <span>From <s className="text-danger">{discountOff}</s><span className="text-muted">{newPrice}</span></span>
                    
                </div>
            </div>
        </div>
    )
}

export default ClassicalCard
