import React, { useEffect, useState } from "react";
import Button from "../buttons/Button";
import Eye from "../../Images/Icons/Eye.svg";
import { Link } from "react-router-dom";
import LoginImage from "../../Images/Login.png";
import { Fetch } from "../../controllers/controller";
import "./Signup.css";
import axios from "axios";
import AlertComp from "../Reusables/AlertComp/AlertComp";
import Loading from "../Reusables/Loader/Loading";
import Countries from "./Countries/Countries";
const Signup = () => {
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: ""
  })
  const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    fname: "",
    lname: "",
    phone: "",
    mail: "",
    password: "",
    cpassword: "",
    role: "",
    country:"",
    state:"",
    zip_code:"",
  });
  const handleChange = (e) => {
    if(e.target.value === "default"){
      return
    }
    const name = e.target.name;
    setPayload({ ...payload, [name]: e.target.value });
  };

  //Country select function
  const changeHandler = value => {
    setPayload({...payload, country: value.label})
  }
  useEffect(() => {
    let mounted = true;

    const getRoles = () => {
      const formData = new FormData ()
      formData.append("apptoken", "HB2JHK342")
      axios
        .post(`${process.env.REACT_APP_NEND_POINT}/get-roles`, formData)
        .then((res) => {
            
          if(mounted){
            setLoading(false)
            setRole(res.data)
           
          }
        }).catch((err) => {
          if(mounted) {
            setLoading(false)
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: err.message})

          }
        });
    };

    getRoles();
    return () => {
      mounted = false;
    };
  }, []);
  const handleAlertClose = () => {
    setShowAlert(false);
  }
  const {fname, lname, mail, password, cpassword} = payload
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fname", payload.fname);
    formData.append("lname", payload.lname);
    formData.append("mail", payload.mail);
    formData.append("password", payload.password);
    formData.append("cpassword", payload.cpassword);
    formData.append("role", payload.role);
    formData.append("city", payload.city);
    formData.append("country", payload.country);
    formData.append("apptoken", payload.apptoken);
    
    if(fname !== "" && lname !=="" && mail !== "" && password !== "" && cpassword !== "" && payload.role !== ""){
      if(payload.password === payload.cpassword) { 
        setLoading(true)
        Fetch(`${process.env.REACT_APP_NEND_POINT}/register`, formData).then(
          (res) => {
          
            setLoading(false)
            setShowAlert(true)
            setAlertType({...alert, type: "success", value: `${res.data.message}`})
          }
          ).catch(err => {
            setLoading(false)
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: err.message})
          });
      } else {
        setLoading(false)
        setShowAlert(true)
        setAlertType({...alert, type: "danger", value: "Passwords do not match"})
      }
    } else {
      
      setShowAlert(true)
      setAlertType({...alert, type: "danger", value: "All fields are required"})
    }
  };
  return loading ? (<Loading />):(
    <div className="signup-container row">
        <div className="col-sm-6 d-none d-sm-block">
          <img
            src={LoginImage}
            className="img-fluid login-image"
            alt="beach chair and umbrella overlooking the sea"
          />
        </div>
        <div className="col-sm-6 px-0 px-sm-0 d-flex justify-content-center align-items-center signup-right">
            <Link to="/">
              <i className="bi bi-house-fill text-primary" style={{position:"absolute", top:"10px", right:"10px", fontSize:"20px"}}></i>
             </Link>
          <div className=" row w-100 justify-content-center h-100">
            <div className="form-wrapper px-5 px-sm-0 col-lg-6 col-md-7 mt-5">
              <header className="mb-4">
                <p className="mb-0 fw-bol">Welcome</p>
                <h3 className="text-decoration-none font-weight-bolder">
                  {" "}
                  Create an account{" "}
                </h3>
              </header>
              <div className="row justify-content-center">
                <form className="form row">
                  {showAlert === true && (
                    <AlertComp
                    variant={alert.type}
                    alertText={alert.value}
                    handleAlertClose={handleAlertClose}
                  />
                )}
                
                  <div className="status form-group mb-3">
                    <label htmlFor="role">Account Type</label>
                    <select name="role" id="status" className="form-select" onChange={handleChange}>
                      <option value="default" >Account Type</option>
                      {role.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="countries form-group my-2 col-6 mb-3">
                    <label htmlFor="countries">Country</label>
                    <Countries changeHandler={changeHandler}  />
                  </div>
                  <div className="city my-2 col-6">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.state}
                    />
                  </div>
                  <div className="city my-2 col-6">
                    <label htmlFor="City">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.city}
                    />
                  </div>
                  <div className="city my-2 col-6">
                    <label htmlFor="zip_code">Zipcode</label>
                    <input
                      type="text"
                      name="zip_code"
                      id="zip_code"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.zip_code}
                    />
                  </div>
                  <div className="firstname mb-3 mt-2">
                    <label htmlFor="fname">Firstname</label>
                    <input
                      type="text"
                      name="fname"
                      id="firstname"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.fname}
                      
                    />
                  </div>
                  <div className="firstname mb-3">
                    <label htmlFor="lname">Lastname</label>
                    <input
                      type="text"
                      name="lname"
                      id="lastname"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.lname}
                    />
                  </div>
                 

                  <div className="email">
                    <label htmlFor="mail">Email</label>
                    <input
                      type="text"
                      name="mail"
                      id="mail"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.mail}
                    />
                  </div>
                  <div className="password mt-2">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.password}
                    />
                  </div>
                  <div className="password mt-2">
                    <label htmlFor="cpassword">Confirm Password</label>
                    <input
                      type="password"
                      name="cpassword"
                      id="cpassword"
                      className="form-control py-2"
                      onChange={handleChange}
                      value={payload.cpassword}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary btn-block w-100 mt-3 mb-3"
                      onClick={handleSubmit}
                    >
                      Sign up now
                    </button>
                  </div>

                  <div className="form-footer">
                    <p className="text-center mt-4">
                      {" "}
                      Already have an account?{" "}
                      <span className="ml-2">
                        {" "}
                        <Link to="/login">Login</Link>{" "}
                      </span>{" "}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Signup;
