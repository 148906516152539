import React, { useContext } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import StripeCheckout from "react-stripe-checkout";
import { useLocation } from "react-router-dom";
import { Fetch } from "../../../../../controllers/controller";
import { BookingContext } from "../../Context/BookingContext";


import axios from "axios";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_519Ss7DEOJOIsAVXOWTpWooFyL5dzhpurpKTLYJ4Vx0QcvWHMvSNUBEcIJOAyDQ3sEErCsgf7j7Emi06Tpi8EX1j500IfiCsQDu"
);

const CheckoutForm = () => {
  return (
    <form>
      <PaymentElement />
      <button className="btn btn-warning">Submit</button>
    </form>
  );
};

export default function StripeChoice({
  setLoading,
  setAlertType,
  setShowAlert,
  email,
  amount
}) {
  const [book, setBook] = useContext(BookingContext)
  const location = useLocation();
  const [usertoken, setUsertoken] = React.useState("");
  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.REACT_APP_STRIPE_SECRET,
  };
  React.useEffect(() => {
    if (location.pathname.includes("dmc")) {
      const data = localStorage.getItem("DMC");
      const token = JSON.parse(data);
      setUsertoken(token.usertoken);
    } else if (location.pathname.includes("account-owner")) {
      const data = localStorage.getItem("accountOwner");
      const token = JSON.parse(data);
      setUsertoken(token.usertoken);
    } else if (location.pathname.includes("hotel-owner")) {
      const data = localStorage.getItem("hotelChain");
      const token = JSON.parse(data);
      setUsertoken(token.usertoken);
    } else if (location.pathname.includes("user")) {
      const data = localStorage.getItem("traveller");
      const token = JSON.parse(data);
      setUsertoken(token.usertoken);
    }
  }, []);

  async function addMoneyToDB(){
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_NEND_POINT}/book-card`,  JSON.stringify(book.options), {
        "content-type":"application/json"
      })
      setShowAlert(true);
      setAlertType({ ...alert, type: "success", value: response.data.message });

      console.log(response)
    } catch (error) {
      console.error(error)
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: error.message });
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = (token) => {
    console.log(token);
    addMoneyToDB("stripe");
  };
  // const onClosed = (token) => {
  //   window.location.reload();
  // };
  return (
    // <Elements stripe={stripePromise} options={options}>
    //   <CheckoutForm />
    // </Elements>

    <div className="d-flex flex-column">
      <StripeCheckout
        description="Fund Wallet"
        token={onSubmit}
        stripeKey="pk_test_519Ss7DEOJOIsAVXOWTpWooFyL5dzhpurpKTLYJ4Vx0QcvWHMvSNUBEcIJOAyDQ3sEErCsgf7j7Emi06Tpi8EX1j500IfiCsQDu"
        amount={Number(amount) * 100}
        currency="USD"
        // closed={onClosed}
        email={email}
      />
    </div>
  );
}
