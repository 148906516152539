import React from 'react'
import { Link } from 'react-router-dom'

const E404 = () => {
    return (
        <div className="container" style={{height: "100vh"}}>
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <h1 className="display-1 error-code">404</h1>
                <h2 className="lead text-center">Oops. The page you're looking for does not exist</h2>
                <Link to="/" className="text-decoration-none px-3 py-2 bg-success text-white">Go to Homepage</Link>
            </div>
        </div>
    )
}

export default E404
