import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    }
    
  });


  const StatementNo = ({data}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text>{data[0].fullName} </Text>
            </View >
            {/* <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Balance: </Text>
                <Text >{new Date().toLocaleDateString()}</Text>
            </View > */}
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date: </Text>
                <Text >{new Date().toLocaleDateString()}</Text>
            </View >

        </Fragment>
  );
  
  export default StatementNo