import React from "react";
import OfferMail from "../../../Images/offer-mail.png";
import OfferPalm from "../../../Images/offer-palm.png";

const Offer = () => {
  return (
    <div
      className="container"
      style={{ marginTop: "5rem", marginBottom: "5rem", scrollSnapAlign:"center" }}
    >
      <div className="row" style={{gap:".5rem"}}>
        <div className="col-12 col-md-5 " style={{ height: "396px" }}>
          <div className="card h-100 w-100 specialOffer d-flex justify-content-center">
            <div className=" d-flex flex-row justify-content-start align-item-center px-3">
              <div
                className="d-flex flex-column offerHeader"
                style={{ width: "60%" }}
              >
                <h1 className="title text-light">Special Offer</h1>
                <p className="card-text lead text-light">
                  Find Your Perfect Hotels Get the best prices on 20,000+
                  properties the best prices on
                </p>
                <a
                  href="/"
                  className="btn btn-outline-primary text-light my-3 offerButton"
                  style={{
                    width: "50%",
                    backgroundColor: " rgba(20, 151, 208, 1)",
                    borderColor: "white",
                  }}
                >
                  See Deals
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-3 mt-md-0" style={{ height: "396px" }}>
          <div className="card h-100 card2 pt-4">
            <div className=" d-flex flex-row justify-content-start align-item-center px-3">
              <div className="d-flex flex-column offerHeader">
                <div className="image-wrapper mb-4">
                  <img src={OfferMail} alt="mail" />
                </div>
                <div className="content">
                  <h1 className="title text-light mb-3">Newsletter</h1>
                  <p className="card-text lead text-light mb-4">
                    Join for free and get our tailored newsletters full of hot
                    travel deals.
                  </p>
                  <a href="#" className="btn btn-outline-primary text-light mb-3 offerButton" style={{ width: "50%", backgroundColor: " rgba(20, 151, 208, 1)", borderColor: "white", }} >
                    Sign up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-3 mt-md-0" style={{ height: "396px" }}>
          <div className="card h-100 card3 pt-2">
            <div className=" d-flex flex-row justify-content-start align-item-center px-4">
              <div className="d-flex flex-column offerHeader">
                <div className="image-wrapper mb-4">
                  <img src={OfferPalm} alt="mail" />
                </div>
                <div className="content">
                  <h1 className="title text-light mb-2">Newsletter</h1>
                  <p className="card-text lead text-light mb-4">
                    Join for free and get our tailored newsletters full of hot
                    travel deals.
                  </p>
                  <a href="#" className="btn btn-outline-warning text-light  offerButton" style={{ width: "50%", borderColor: "white", }} >
                    Sign up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
