import { queryByDisplayValue } from '@testing-library/react';
import React, {useState, useEffect, useContext} from 'react'
import { Modal } from 'react-bootstrap';
import { Fetch } from '../../../../../../../controllers/controller';
import AlertComp from '../../../../../../Reusables/AlertComp/AlertComp';
import Loading from '../../../../../../Reusables/Loader/Loading';
import HotelContext from '../../../../../Junior/Manage/Context/hotelcontext';

const Rooms = ({openRoomsModal, removeRoomsModal}) => {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const [hotelId] = useContext(HotelContext)
    useEffect(() => {
        const formData = new FormData()
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN )
        formData.append("hotelid", hotelId )
        setLoading(true)
        Fetch( `${process.env.REACT_APP_END_POINT}/room-list`, formData)
        
        .then( (res) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "success", value: res.data.message });
    
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
    
    }, [])

    const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    hotelname: "",
    available: "",
    price:"",
    usertoken: "",
    description: ""

  })
  const handleAlertClose =() =>{
    setShowAlert(false);
  }
  return  (
    <>
      <Modal show={openRoomsModal} onHide={removeRoomsModal}>

        {loading ? (<Loading />):(

          <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-success fw-bold text-center">Rooms</h4>
          </div>
          <Modal.Body className="mb-5">
            <div className="row justify-content-center">
              <div className="form-left col-md-6">
              {showAlert === true && (
                    <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                )}


                </div>
            </div>
          </Modal.Body>
          
        </div>
      )}
      </Modal>
    </>
  );
}

export default Rooms
