import React, { useState, useEffect } from "react";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import AlertModal from "../../../../Reusables/AlertModal/AlertModal";
import {
  dateConverter,
  Fetch,
  TimestampConverter,
} from "../../../../../controllers/controller";
import NewTest from "../../../../NewTest";
import Test from "../../../../test";
const PriceAvailability = ({ contractTop, setContractTop }) => {
  const [edit, setEdit] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [Dates, setDates] = useState([]);
  const [editedDates, setEditedDates] = useState([]);
  const [load, setLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [initialDates, setInitialDates] = useState({});

  const hotelId = localStorage.getItem("hotel");
  const CT = localStorage.getItem("CT");

  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);

  const [roomsLoad, setRoomsLoad] = useState(false);
  const [updating, setUpdating] = useState(null);

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const handleEditDate = (e, index) => {
    e.preventDefault();
    setEdit(index);
  };
  const getDateLists = () => {
    const dmc = localStorage.getItem("DMC");
    const use = JSON.parse(dmc);
    const CT = localStorage.getItem("CT");
    const hotelId = localStorage.getItem("hotel");

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("dmctoken", use.usertoken);
    formData.append("hoteltoken", hotelId);
    formData.append("token", CT);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-date-plan`, formData)
      .then((res) => {
        if (res.data.success !== false) {
            res.data.sort((a, b) => {
              let da = new Date(a.start_date * 1000).toLocaleDateString();
              let daa = new Date(da);
  
              let db = new Date(b.start_date * 1000).toLocaleDateString();
              let dbb = new Date(db);
  
              return dbb - daa;
            });
  
              setDates(res.data);
              setEditedDates(res.data);
          }
    })
      .catch((err) => {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
    });
  };

  useEffect(() => {
    let mounted = true;
    if(mounted){
        getDateLists();
    }

    return () => {
      mounted = false;
    };
  }, [reload]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getRoomsList();
    }
    return () => {
      mounted = false;
    };
  }, [reload, load]);

  const getRoomsList = () => {
    const hotelId = localStorage.getItem("hotel");
    setRoomsLoad(true);
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelId);

    Fetch(`${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        if (res.data.success === false) {
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: res.data.message });
          setRoomsLoad(false);
        }
        setRooms(res.data);
        setRoomsLoad(false);
      })
      .catch((err) => {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
        setRoomsLoad(false);
      });
  };
 

  const handleChange = (e, index) => {
    const day = new Date(e.target.value).getDate();
    const month = new Date(e.target.value).getMonth() +1;
    const year = new Date(e.target.value).getFullYear();


    setContractTop({ ...contractTop, [e.target.name]: `${month}/${day}/${year}`});
    setInitialDates({ ...initialDates, [e.target.name]: e.target.value});
    const header = JSON.stringify(contractTop);
    localStorage.setItem("header", header);
  };

  console.log(editedDates)
  // For Date FROM
  const handleDateListChange = (e, index, date) => {
    const clonedDates = [...editedDates];
    clonedDates[index][e.target.name] = e.target.value;
    setEditedDates(clonedDates);
  };

  // For Date TO. Send both dates to the server
  const handleDateListChange2 = (e, index, date) => {
    const clonedDates = [...editedDates];
    clonedDates[index][e.target.name] = e.target.value;
    setEditedDates(clonedDates);

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("old_end_date", clonedDates[index].exp_date);
    formData.append("old_start_date", clonedDates[index].start_date);
    formData.append("new_end_date", clonedDates[index].dp_exp);
    formData.append("new_start_date", editedDates[index].dp_start);
    formData.append("id", editedDates[index].id);
    Fetch(`${process.env.REACT_APP_END_POINT}/change-date-duration`, formData)
      .then((res) => {
        if (res.data.success === true) {
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "success",
            value: res.data.message,
          });
        } else {
          setShowAlert(true);
          setAlertType({
            ...alert,
            type: "danger",
            value: res.data.message,
          });
        }
      })
      .catch((err) => {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      })
      .finally(window.location.reload());
  };
  const handleSubmit = (e, index) => {
    e.preventDefault();
    if (
      contractTop.status !== "" &&
      contractTop.contract_name !== "" &&
      contractTop.child_age_From !== "" &&
      contractTop.child_age_to !== "" &&
      contractTop.currency !== ""
    ) {
      setLoad(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("hoteltoken", hotelId);
      formData.append("token", CT);
      formData.append("dmctoken", useableAccOwner.usertoken);
      formData.append("exp_date", contractTop.exp_date);
      formData.append("start_date", contractTop.start_date);
      Fetch(`${process.env.REACT_APP_END_POINT}/confirm-date-plan`, formData)
        .then((res) => {
          setLoad(false);
          if (res.data.success === true) {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
            setContractTop({ ...contractTop, start_date: "", exp_date: "" });
            setInitialDates({ ...initialDates, start_date: "", exp_date: "" });
            getRoomsList();
           
            getDateLists();
          } else {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "danger",
              value: res.data.message,
            });
           
          }
        })
        .catch((err) => {
          setLoad(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
      // .finally( window.location.reload());
    } else {
      setShow(true);
    }
  };
  const handleUpdates = (e, index) => {
    e.preventDefault();

    if (
      contractTop.status !== "" &&
      contractTop.contract_name !== "" &&
      contractTop.child_age_From !== "" &&
      contractTop.child_age_to !== "" &&
      contractTop.currency !== ""
    ) {
      setLoader(true);

      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("hoteltoken", hotelId);
      formData.append("token", CT);
      formData.append("dmctoken", useableAccOwner.usertoken);
      formData.append(
        "exp_date",
        editedDates[index].dp_exp ? editedDates[index].dp_exp : TimestampConverter(editedDates[index].exp_date)
      );
      formData.append(
        "start_date",
        editedDates[index].dp_start ? editedDates[index].dp_start : TimestampConverter(editedDates[index].start_date)
      );
      Fetch(`${process.env.REACT_APP_END_POINT}/confirm-date-plan`, formData)
        .then((res) => {
          setLoader(false);
          if (res.data.success === true) {
            setShowAlert(true);
            setReload(true);
            setEdit(false);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
            // window.location.reload();
            getDateLists();
          } else {
            setShowAlert(true);
            setAlertType({
              ...alert,
              type: "danger",
              value: res.data.message,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      setShow(true);
    }
  };
  return (
    <div>
      <div>
        <p className="lead text-primary">PRICE AND AVAILABILITY</p>{" "}
      </div>
      <form className="form">
        <div>
          {showAlert === true && (
            <AlertComp
              variant={alert.type}
              alertText={alert.value}
              handleAlertClose={handleAlertClose}
            />
          )}
        </div>
        <div className="table-responsive mytable mb-5">
          <table className="table table-bordered border-dark w-75">
            <thead className="bg-primary text-white">
              <tr>
                <th scope="col" rowSpan="2" className="primary" style={{minWidth:"120px"}}>
                  TIMESTAMP
                </th>
                <th
                  colSpan="2"
                  scope="colgroup"
                  className="text-center  success"
                >
                  DATE
                </th>
                <th className="text-center  success"></th>
                <th className="text-center  success"></th>
              </tr>
              <tr>
                <th scope="col" className=" success">
                  FROM
                </th>
                <th scope="col" className=" success">
                  TO
                </th>
                <th
                  colSpan=""
                  scope="colgroup"
                  className="border border-dark inner_table"
                >
                  {/* <table className="table table-bordered border-dark mb-0"> */}
                  <tr className=" special__table border border-dark">
                    <th>CATEGORY</th>
                    <th>SGL</th>
                    <th>DBL</th>
                    <th>TWN</th>
                    <th>TRL</th>
                    <th>QUD</th>
                    <th>UNIT</th>
                    <th>ALLOTMENT</th>
                    <th>REL</th>
                  </tr>

                  {/* </table> */}
                </th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="">
              <tr>
                <td className="oneFullColumn">
                  <p className="initialColumns">{dateConverter()}</p>
                </td>
                <td className="">
                  <input
                    type="date"
                    name="start_date"
                    id="from"
                    className="initialColumns form-control date-input"
                    onChange={handleChange}
                    value={initialDates.start_date}
                  />
                </td>
                <td className="">
                  <input
                    type="date"
                    name="exp_date"
                    id="to"
                    className="initialColumns form-control date-input"
                    onChange={handleChange}
                    value={initialDates.exp_date}
                  />
                </td>
                {!roomsLoad ? (
                  <NewTest
                    lists={rooms}
                    setRooms={setRooms}
                    update={setUpdating}
                    contractTop={contractTop}
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                  />
                ) : (
                  <td>
                    <div>
                      <div
                        className={`spinner-border text-primary`}
                        role="status"
                      ></div>
                    </div>
                  </td>
                )}
                <td>
                  {updating === false ? (
                    <button className="btn text-muted visually-hidden">SAVE</button>
                  ) : (
                    <button className="btn" onClick={handleSubmit}>
                      {load ? (
                        <div
                          className={load && `spinner-border text-primary`}
                          role="status"
                        >
                          {" "}
                        </div>
                      ) : (
                        <div className="fw-bold">SAVE</div>
                      )}
                    </button>
                  )}
                </td>
              </tr>
            </tbody>

            {Dates.length > 0 &&
              Dates.map((date, index) => (
                <tbody className="contracts" key={index}>
                  <tr>
                    <td className="oneFullColumn">
                      <p>{TimestampConverter(date.timestamp)}</p>
                    </td>

                    <td className="">
                      {edit === index ? (
                        <>
                          <p>{TimestampConverter(date.start_date)}</p>
                          <input
                            type="date"
                            name="dp_start"
                            id="from"
                            className="initialColumns form-control date-input"
                            onChange={(e) =>
                              handleDateListChange(e, index, date)
                            }
                            value={editedDates[index].dp_start}
                            // value={TimestampDateConverter(date.start_date)}
                          />
                        </>
                      ) : (
                        <p>{TimestampConverter(date.start_date)}</p>
                      )}
                    </td>
                    <td className="">
                      {edit === index ? (
                        <>
                          <p>{TimestampConverter(date.exp_date)}</p>
                          <input
                            type="date"
                            name="dp_exp"
                            id="to"
                            className="initialColumns form-control date-input"
                            onChange={(e) =>
                              handleDateListChange2(e, index, date)
                            }
                            value={editedDates[index].dp_exp}
                            // value={TimestampDateConverter(date.exp_date)}
                          />
                        </>
                      ) : (
                        <p>{TimestampConverter(date.exp_date)}</p>
                      )}
                    </td>
                    <Test
                      lists={date}
                      setEdit={setEdit}
                      edit={edit}
                      initIndex={index}
                      contract={editedDates[index]}
                      contractTop={contractTop}
                      setAlertType={setAlertType}
                      alert={alert}
                      setShowAlert={setShowAlert}
                    />

                    {edit === index ? (
                      <td>
                        <button
                          className="btn fw-bold"
                          onClick={(e) => handleUpdates(e, index)}
                        >
                          {loader ? (
                            <div
                              className={
                                loader && `spinner-border text-primary`
                              }
                              role="status"
                            ></div>
                          ) : (
                            <div>SAVE</div>
                          )}
                        </button>
                      </td>
                    ) : (
                      <td>
                        <button
                          className="btn fw-bold"
                          onClick={(e) => handleEditDate(e, index)}
                        >
                          EDIT
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </form>
      <AlertModal show={show} setShow={setShow} />
    </div>
  );
};

export default PriceAvailability;
