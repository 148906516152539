import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import MiniContractDetails from "./miniContractDetail";
const ContractDetails = ({ number, match }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [nonDmc, setNonDmc] = useState("")
  const [contract, setContractDetail] = useState({});

  console.log(match)
  useEffect(() => {
    let mounted = true;
    if(match.path !== "/dmc/contracts/detail"){
      if(mounted){
        setNonDmc("d-none")
      }
  }
    const token = localStorage.getItem("con");
    if (token) {
      if (token) {
        setLoading(true);
        const formData = new FormData();
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("token", token);
        Fetch(
          `${process.env.REACT_APP_END_POINT}/contract-detail`,
          formData
        )
          .then((res) => {
        
            if (mounted) {
              if (res.data.success !== false) {
                setContractDetail(res.data[0]);
              }
              if (res.data.success === false) {
                setContractDetail(res.data);
                setShowAlert(true);
              }
              setLoading(false);
              setAlertType({
                ...alert,
                type: "success",
                value: res.data.message,
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "danger", value: err.message });
          });
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleDatePlan = (e) => {
    localStorage.setItem("contract", e)
  }

  console.log(contract);
  return (
    <div className="px-3 bg-light ">
      {showAlert === true && (
        <AlertComp variant={alert.type} alertText={alert.value} />
      )}
      <div className="contract-row mb-4">
        <div className="">
          <div className="w-100">
            <div className=" mb-4">
              <p style={{ fontSize: "12px", color: "rgba(51, 212, 161, 1)" }}>
                Hotel name
              </p>
            </div>
          </div>
          <div
            className="card d-flex align-items-center justify-content-center"
            style={{ height: "114px" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center">
              <p
                className="text-center  fw-bolder lead text-uppercase mb-0"
                style={{ color: "rgba(51, 212, 161, 1)", fontSize: "28px" }}
              >
                {contract.hotelname}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-100">
            <div className=" mb-4">
              <p style={{ fontSize: "12px", color: "rgba(157, 103, 253, 1)" }}>
                Room name
              </p>
            </div>
          </div>
          <div className="card " style={{ height: "114px" }}>
            <div className="card-body d-flex justify-content-center align-items-center">
              <p
                className="text-center fw-bolder  lead text-uppercase mb-0"
                style={{ color: "rgba(157, 103, 253, 1)", fontSize: "28px" }}
              >
                {contract.room_name}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-100">
            <div className=" mb-4">
              <p style={{ fontSize: "12px", color: "rgba(131, 123, 249, 1)" }}>
                Available rooms
              </p>
            </div>
          </div>
          <div className="card" style={{ height: "114px" }}>
            <div className="card-body d-flex justify-content-around">
              <div
                className={` d-flex align-items-center justify-content-center me-3`}
              >
                <div
                  className={` p-3 px- 0 px-lg-3 `}
                  style={{
                    borderRadius: "50%",
                    background: "rgba(9, 97, 206, 0.22)",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  {number}
                </div>
              </div>
              <div className="card-p-wrapper d-flex flex-column align-items-center justify-content-center">
                <p
                  className="text-center fw-bolder  lead h4"
                  style={{ color: "rgba(131, 123, 249, 1)", fontSize: "28px" }}
                >
                  {contract.availnum}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contract-row mb-4">
        <MiniContractDetails
          cardheader="Price of rooms"
          price={contract.price}
          subtext={""}
          end="d-none"
          color="rgba(51, 212, 161, 1)"
          date="d-none"
          exp="d-none"
        />
        <MiniContractDetails
          cardheader="Contract expires"
          expiry={contract.expiry_date}
          subtext={""}
          end="d-none"
          dollar="d-none"
          date="d-none"
         
          color="rgba(157, 103, 253, 1)"
        />
        <MiniContractDetails
          cardheader="Release date"
          details={contract.release_date}
          subtext={""}
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(131, 123, 249, 1)"
        />
        <MiniContractDetails
          cardheader="Contract Duration"
          start={contract.start_date}
          endDate={contract.expiry_date}
          dollar="d-none"
          exp="d-none"
          color="rgba(254, 167, 68, 1)"
        />
      </div>
      <div className="contract-row mb-4">
        <MiniContractDetails
          cardheader="Child Age"
          details={contract.child_age}
          subtext="Maximum"
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(51, 212, 161, 1)"
        />
        <MiniContractDetails
          cardheader="Days to cancel"
          details={contract.cancel_days}
          subtext="Maximum"
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(157, 103, 253, 1)"
        />
        <MiniContractDetails
          cardheader="Penalty for cancelling"
          details={contract.cancel_penalty}
          subtext={""}
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(131, 123, 249, 1)"
        />
      </div>
      <div className="contract-row mb-4">
        <MiniContractDetails
          cardheader="Number of Adult"
          details={contract.occupy_max}
          subtext="Maximum"
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(51, 212, 161, 1)"
        />
        <MiniContractDetails
          cardheader="Number of Children"
          details={contract.occupy_max_child}
          subtext="Maximum"
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(157, 103, 253, 1)"
        />
        <MiniContractDetails
          cardheader="Number of Adult"
          details={contract.occupy_min}
          subtext="Minimum"
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(131, 123, 249, 1)"
        />
        <MiniContractDetails
          cardheader="Number of Children"
          details={contract.occupy_min_child}
          subtext="Minimum"
          end="d-none"
          dollar="d-none"
          date="d-none"
          exp="d-none"
          color="rgba(254, 167, 68, 1)"
        />
      </div>
      <div className="supplements">
        <p className="lead fw-bold">Supplements</p>
        <div className="contract-row mb-4">
          <MiniContractDetails
            cardheader="Full bar (Child)"
            details={contract.fb_child}
            subtext="Maximum"
            end="d-none"
            dollar="d-none"
            date="d-none"
            exp="d-none"
            color="rgba(51, 212, 161, 1)"
          />
          <MiniContractDetails
            cardheader="Full bar (Adult)"
            details={contract.fb_adult}
            subtext="Maximum"
            end="d-none"
            dollar="d-none"
            date="d-none"
            exp="d-none"
            color="rgba(157, 103, 253, 1)"
          />
          <MiniContractDetails
            cardheader="Half bar (Child)"
            details={contract.hb_child}
            subtext="Minimum"
            end="d-none"
            dollar="d-none"
            date="d-none"
            exp="d-none"
            color="rgba(131, 123, 249, 1)"
          />
          <MiniContractDetails
            cardheader="Half bar (Adult)"
            details={contract.hb_adult}
            subtext="Minimum"
            end="d-none"
            dollar="d-none"
            date="d-none"
            exp="d-none"
            color="rgba(254, 167, 68, 1)"
          />
        </div>
        <div className="contract-row mb-4">
          <MiniContractDetails
            cardheader="Soft all inclusive (Child)"
            details={contract.sai_child}
            subtext="Maximum"
            end="d-none"
            exp="d-none"
            dollar="d-none"

            color="rgba(51, 212, 161, 1)"
          />
          <MiniContractDetails
            cardheader="Soft all inclusive (Adult)"
            details={contract.sai_adult}
            subtext="Maximum"
            end="d-none"
            exp="d-none"
            dollar="d-none"

            color="rgba(157, 103, 253, 1)"
          />
          <MiniContractDetails
            cardheader="Ultra all inclusive (Child)"
            details={contract.uai_child}
            subtext="Minimum"
            end="d-none"
            exp="d-none"
            dollar="d-none"

            color="rgba(131, 123, 249, 1)"
          />
          <MiniContractDetails
            cardheader="Ultra all inclusive (Adult)"
            details={contract.uai_adult}
            subtext="Minimum"
            end="d-none"
            exp="d-none"
            dollar="d-none"

            color="rgba(254, 167, 68, 1)"
          />
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
