import React from "react";
import ClassicalCard from "../../Cards/classicalCard/classicalCard";
import SectionHeader from "../SectionHeader/SectionHeader";
import ClassicalImage from "../../../Images/Classical.png"

const Classical = () => {
  return (
    <section className="container classical">
      <SectionHeader title="Classical Music Event" lead="Homes highly rated for thoughtful design" />
      <div className="row justify-content-center justify-content-lg-start">
        <div className="col-12 col-sm-6 col-lg-3">
          <ClassicalCard cardImage={ClassicalImage} cardTitle="Event of Washingtion D.C Highlights" reviews="2 Reviews" rating="4.0/5 Very Good" startTime="17:00" discountOff="$2,100" newPrice="$ 1, 110" countdown="5 hours" />
        </div>
      </div>
    </section>
  );
};

export default Classical;
