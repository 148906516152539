import React, {useState} from 'react'
import { GetData, Fetch, TimestampConverter } from '../../../../controllers/controller'
import useFetch from "../../../Reusables/Fetch/Fetch"
import DeleteOverride from './DeleteOverride'


const SpecialTable = ({search}) => {
    const [showMarkDelete, setShowMarkDelete] = useState(false);
    const [id, setId] = useState("");
    // const [overList, setOverList] = useState([])

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN); 
    const {data,  error, loading, alert, setLoading, showAlert, setShowAlert} = useFetch("/list-special-markup", formData)

    const handleClick = (listId)=> {
        setShowMarkDelete(true)
        setId(listId)
    }
    return (
        <div className="table-responsive mt-4">
            {
                loading ? <div className="spinner-border text-primary">
                    <div className="visually-hidden">setLoading...</div>
                </div> 
                :
                <table className="table table-striped table-bordered pricing_table">
                    <thead className="table-primary">
                        <th>S/N</th>
                        <th>Timestamp</th>
                        <td>Markup ID</td>
                        <td>Country</td>
                        <td>Hotel</td>
                        <th>Markup</th>
                        <td>Type</td>
                        <td>User</td>
                        {/* <th>Action</th> */}
                    </thead>
                    <tbody>
                        {data.length > 0 && data.filter(item=>(
                          item.country.toLowerCase().includes(search.toLowerCase()) ||
                          item.hotelname.toLowerCase().includes(search.toLowerCase()) ||
                          item.markup.toLowerCase().includes(search.toLowerCase())
                        
                        )).map((list, index)=>(
                            <tr>
                                <td>{index}</td>
                                <td>{TimestampConverter(list.timestamp)}</td>
                                {/* <td>{list.contract_token}</td> */}
                                <td>{list.markup_id}</td>
                                <td>{list.country}</td>
                                <td>{list.hotelname}</td>
                                <td>{list.markup}</td>
                                <td>{list.user_type}</td>
                                <td>{list.usertoken}</td>
                                {/* <td className="d-flex justify-content-center">
                                    <i className="bi bi-pen-fill text-warning me-2" onClick={()=>handleClick(list.id)}></i>
                                    <i className="bi bi-trash text-danger" onClick={()=>handleClick(list.id)}></i>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>      
            }
            {/* <DeleteOverride show={showMarkDelete} id={id}  handleRemove={()=>setShowMarkDelete(false)} load={setLoading} endpoint={""}  /> */}

        </div>
    )
}

export default SpecialTable
