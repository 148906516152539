import React, { useEffect, useState } from "react";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { Fetch } from "../../../../../controllers/controller";
import RequestError from "../../../../404/RequestError";
import AlertComp from "../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../Reusables/Loader/Loading";
import DeletePrompt from "./DeletePrompt";

const AddOptions = ({
  openOptions,
  amenList,
  optionsLoad,
  setOptionsLoad,
  setAmenList,
  cateList,
  setCateList,
  handleRemove,
  showModal,
  setItem,
  showCategoryModal,
  setCateItem,
}) => {
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [amenAlert, setAmenAlert] = useState(false);
  const [cateAlert, setCateAlert] = useState(false);
  //   const [deleteItem, setDeleteItem] = useState("");
  //   const [showPrompt, setShowPrompt] = useState(false);
  const [payload, setPayload] = useState({});

  // const [amen, setAmen] = useState(false)
  const [amen, setAmen] = useState(false);
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };
  const confirmOptions = () => {};
  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-amenities`, formData)
      .then((res) => {
        if (mounted) setAmenList(res.data);
      })
      .catch((err) => {
        setAlertType({ ...alert, type: "danger", value: err.message });
      });

    return () => {
      mounted = false;
    };
  }, [optionsLoad]);

  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-room-category`, formData)
      .then((res) => {
        if (mounted) setCateList(res.data);
      })
      .catch((err) => {
        setAlertType({ ...alert, type: "danger", value: err.message });
      });

    return () => {
      mounted = false;
    };
  }, [optionsLoad]);

  const deleteAmenities = (name) => {
    showModal(true);
    setItem(name);
  };

  const deleteCategory = (name) => {
    showCategoryModal(true);
    setCateItem(name);
  };
  const addAmenities = () => {
    setOptionsLoad(true);

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("room_amenities", payload.amenities);
    Fetch(`${process.env.REACT_APP_END_POINT}/create-amenities`, formData)
      .then((res) => {
        setOptionsLoad(false);
        setPayload({ ...payload, amenities: "" });
        setAmenAlert(true);
        setAlertType({ ...alert, type: "success", value: res.data.message });
      })
      .catch((err) => {
        setOptionsLoad(false);
        setAmenAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  };
  const handleAlertClose = () => {
    setAmenAlert(false);
  };

  const handleCateClose = () => {
    setCateAlert(false);
  };
  const addCategory = () => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("category", payload.category);

    setOptionsLoad(true);
    Fetch(`${process.env.REACT_APP_END_POINT}/create-room-category`, formData)
      .then((res) => {
        setOptionsLoad(false);
        setPayload({ ...payload, category: "" });
        setAmen(true);
        setCateAlert(true);
        setAlertType({ ...alert, type: "success", value: res.data.message });
      })
      .catch((err) => {
        setOptionsLoad(false);
        setCateAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  };
  return (
    <RequestError>
      <Modal show={openOptions} onHide={handleRemove} centered>
        {optionsLoad ? (
          <Loading />
        ) : (
          <div className="py-5 px-5 modals addrooms">
            <div className="d-flex flex-column justify-content-start  mb-5">
              <h4 className="text-success fw-bold text-center">Add Options</h4>
            </div>
            <Modal.Body className="mb-5 mb-sm-2">
              <Tabs defaultActiveKey="amenities" className="mb-3 title_font">
                <Tab eventKey="amenities" title="AMENITIES">
                  {amenAlert === true && (
                    <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleAlertClose}
                    />
                  )}
                  <div>
                    <form className="form row justify-content-center">
                      <div className="col-sm-8 d-flex">
                        <input
                          type="text"
                          name="amenities"
                          id="amenities"
                          className="form-control me-2"
                          onChange={handleChange}
                          required
                          value={payload.room_amenitites}
                          placeholder="Name"
                        />
                        <button
                          className="btn btn-outline-success"
                          onClick={addAmenities}
                        >
                          ADD
                        </button>
                      </div>
                    </form>
                    <div className="list mt-2 d-flex justify-content-center flex-wrap">
                      {amenList.length > 0 &&
                        amenList.map((amenity) => (
                          <div className="me-1 my-1 py-1 d-flex bg-light justify-content-center align-items-center px-1 rounded">
                            <p
                              className="me-2 mb-0 text-capitalize"
                              dangerouslySetInnerHTML={{
                                __html: amenity.room_amenities,
                              }}
                            />
                            <div className="delete-opt-amenities">
                              <i
                                className="bi bi-x text-danger"
                                onClick={() =>
                                  deleteAmenities(amenity.room_amenities)
                                }
                              ></i>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="category" title="CATEGORY">
                  {cateAlert === true && (
                    <AlertComp
                      variant={alert.type}
                      alertText={alert.value}
                      handleAlertClose={handleCateClose}
                    />
                  )}
                  <div>
                    <form className="form row justify-content-center">
                      <div className="col-sm-8 d-flex">
                        <input
                          type="text"
                          name="category"
                          id="category"
                          className="form-control me-2"
                          onChange={handleChange}
                          required
                          value={payload.category}
                          placeholder="Type"
                        />
                        <button
                          className="btn btn-outline-success"
                          onClick={addCategory}
                        >
                          ADD
                        </button>
                      </div>
                    </form>
                    <div className="list mt-2 d-flex justify-content-center flex-wrap">
                      {cateList.length > 0 &&
                        cateList.map((cate) => (
                          <div className="me-1 my-1 py-1 d-flex bg-light justify-content-center align-items-center px-1 rounded">
                            <p className="me-2 mb-0 text-capitalize">
                              {cate.category}
                            </p>
                            <div className="delete-opt-category">
                              <i
                                className="bi bi-x text-danger"
                                onClick={() => deleteCategory(cate.category)}
                              ></i>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex mb-3">
                <button className="btn btn-lg btn-secondary mb-2 m-auto" style={{  padding: "11px" }} onClick={handleRemove} >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    </RequestError>
  );
};

export default AddOptions;
