import React from "react";
import "./miniContract.css"
const MiniContractDetails = ({ cardheader, details, subtext, end, endDate, color,dollar, exp, date,start, expiry, price}) => {
  return (
    <div className=" me-2">
      <div className="wrapper">
        <div className="w-100">
          <div className="actions mb-3">
            <p className=" form-label" style={{ color:`${color}`,fontSize: "12px" }}>{cardheader}</p>
          </div>
        </div>
        <div className="card card-rounded" style={{height:"114px"}}>
          <div className="card-body  d-flex justify-content-around ">
            <div className={` d-flex align-items-center justify-content-center me-3`}>
              <div className={` p-3 px- 0 px-lg-3 `} style={{ borderRadius: "50%", background: "rgba(9, 97, 206, 0.22)", width: "50px", height: "50px", }} > </div>
            </div>
            <div className="card-p-wrapper d-flex flex-column align-items-center justify-content-center">
              <p className={` lead`} style={{color:`${color}`}}>{details}</p>
              <p className={`lead`} style={{color:`${color}`}}><span className={`${dollar}`}>$</span>{price}</p>
              <p className={`lead exp  ${exp}`} style={{color:`${color}`}}>{new Date(expiry * 1000).toDateString()}</p>
              <p className={`lead end ${end}`} style={{color:`${color}`}}><small class={`${date} duration`}>Start</small>{new Date(start * 1000).toDateString()}</p>
              <p className={`lead end ${end}`} style={{color:`${color}`}}><small class={`${date} duration`}>End</small>{new Date(endDate * 1000).toDateString()}</p>
              <p className={``} style={{color:`${color}`}}>{subtext}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniContractDetails;
