import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../controllers/controller";
import AddOptions from "../../Junior/Manage/Modals/AddOptions";
import DeletePrompt from "../../Junior/Manage/Modals/DeletePrompt";
import DeleteCate from "../../Junior/Manage/Modals/DeleteCate";
import RequestError from "../../../404/RequestError";
import { useHistory } from "react-router-dom";
import {Route} from "react-router-dom"
import Rooms from "../../Reusables/Rooms/Rooms"
import AllHotels from "./Hotels/AllHotels";
import AddAllHotels from "../../Junior/Manage/Hotels/AddExternalHotel";
import AddHotelOptions from "../../Junior/Manage/Modals/AddFacilities";
const Hotel = () => {
  const [openOpts, setOpenOpts] = useState(false);
  const handleOptions = () => setOpenOpts(true);
  const handleOptionsRemove = () => setOpenOpts(false);


  const [deleteItem, setDeleteItem] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [open, setOpen] = useState(false)
  // const [deleteCate, setDeleteCate] = useState("");
  const [showCategory, setShowCategory] = useState(false);

  const [cateItem, setCateItem] = useState("");

  const [amenList, setAmenList] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [optionsLoad, setOptionsLoad] = useState(false);
  const history = useHistory()
  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-amenities`, formData)
      .then((res) => {
        if (res.data.length) {
          if (mounted) setAmenList([res.data]);
        } else {
          window.alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      mounted = false;
    };
  }, [optionsLoad]);

  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-room-category`, formData)
      .then((res) => {
        if (mounted) setCateList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      mounted = false;
    };
  }, [optionsLoad]);

  const listAmenities = () => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-amenities`, formData)
      .then((res) => {
        setAmenList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listCategory = () => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-room-category`, formData)
      .then((res) => {
        setCateList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteModal = () => {
    setShowPrompt(false);
  };
  const handleCateModal = () => {
    setShowCategory(false);
  };
  function handleExternal(){
    history.push("hotel/add-hotel")
  }

  function handleFacilitiesOptions(){
    setOpen(true)
  }


  return (
    <RequestError>
      <div className="pt-4 px-lg-4 px-3 ">
        <header className="d-flex justify-content-between align-items-center dashHeader">
          <h4>HOTELS</h4>
          <div className="add-options d-flex flex-wrap">
            <button className="btn btn-primary me-2" onClick={handleExternal}>
              Add Hotel
            </button>
            <button className="btn btn-primary me-2" onClick={handleFacilitiesOptions}>
              Hotel Facilities
            </button>
            <button className="btn btn-primary me-2" onClick={handleOptions}>
              Room Amenities
            </button>
            {/* <button className="ms-3 btn btn-outline-primary" onClick={handleExternal}>
              Add External Hotel
            </button> */}
          </div>
        </header>
        <div className="admin-dashboard-top mt-5 text-center">
            <Route exact path={["/admin/hotel", "/admin/hotel/lists" ]} component={AllHotels} />
            <Route path="/admin/hotel/rooms" component={Rooms} />
            <Route path="/admin/hotel/add-hotel" component={AddAllHotels} />
        </div>
        <DeletePrompt
          open={showPrompt}
          listAmenities={listAmenities}
          setShow={setShowPrompt}
          name={deleteItem}
          handleRemove={handleDeleteModal}
        />
        <AddOptions
          openOptions={openOpts}
          optionsLoad={optionsLoad}
          setOptionsLoad={setOptionsLoad}
          amenList={amenList}
          cateList={cateList}
          setAmenList={setAmenList}
          setCateList={setCateList}
          showModal={setShowPrompt}
          setItem={setDeleteItem}
          showCategoryModal={setShowCategory}
          setCateItem={setCateItem}
          handleRemove={handleOptionsRemove}
        />
        <DeleteCate
          open={showCategory}
          listCategory={listCategory}
          handleCateRemove={handleCateModal}
          name={cateItem}
        />

        <AddHotelOptions
          open={open}
          setOpen={setOpen}
        />
      </div>
    </RequestError>
  );
};

export default Hotel;
