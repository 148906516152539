import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { mainGetUsertoken } from '../../../Reusables/GetUsertoken/GetUsertoken'
import {useMutation} from "react-query"
import {fetchAdminHotels} from "../../apis"
import { tr } from 'date-fns/locale'
import { Modal } from 'react-bootstrap'
import Loading from '../../../../Reusables/Loader/Loading'

const AllHotels = () => {
  return (
    <Tabs
      defaultActiveKey="internal"
      id="uncontrolled-tab-example"
      className="mb-3 title_font"
    >
      <Tab eventKey="internal" title="HOTELS">
      <HotelTable />
      </Tab>
      {/* <Tab eventKey="external" title="EXTERNAL HOTELS">
        <ExternalHotels />
      </Tab> */}
    </Tabs>
  )
}

function HotelTable(){
  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");
  const [hotelList, setHotelList] = useState([])
  
  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);


  const fetchHotels = useMutation(fetchAdminHotels, {
    onSuccess: (res)=> {
      if(res.data?.hotels.length >  0){
        setHotelList(res.data.hotels)
        return
      }
      setHotelList([])
    }
  })

  useEffect(()=>{
    if(usertoken){
      fetchHotels.mutate({usertoken: usertoken})
    }
  },[usertoken])

  console.log({hotelList})

  return (
    <>
     {
      fetchHotels?.isLoading ?
        <div className="spinner-border text-primary">
          <div className="visually-hidden">Loading...</div>
        </div>
        :

    <div className="admin_hotel">
      
       
        <div className="table-responsive">
          <table className="table table-bordered admin_hotel_table position-relative">
            <thead>
              <tr>
                {
                  tableHeaders.map(item => (
                    <th>{item}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                hotelList?.map((item, i)=>(
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.country === "0" ? "-" : item.country}</td>
                    <td>{item.city === "0" ? "-" : item.city}</td>
                    <td>{item.hotelname}</td>
                    <td>
                      <ValueWithPopUP 
                        item={item.totalContracts}
                        type="Contracts"
                        token={item.token}
                      />
                    </td>
                    <td>{item.totalRooms}</td>
                    <td>{item.totalDMCs}</td>
                    <td>{item.direct}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
    </div>
    }
    </>
    

  )
}

const tableHeaders = ["S/N", "COUNTRY", "CITY", "HOTEL","CONTRACTS", "ROOMS", "DMCs", "DIRECT"]


function ValueWithPopUP({item, token, type}){
  const [show, setShow] = useState(false)

  function handleClose(){
    setShow(false)
  }

  // fetchDmc
  // fetchhotel
  // fetchrooms



  // AddContract
  // addroom
  // addhoetl

  return (
    <>
    <span style={{cursor: "pointer"}} onClick={()=>setShow(true)}>{item}</span>

    <Modal
     show={show}
     onHide={handleClose}
     dialogClassName="select-hotel"
     centered
    
    >
      <Modal.Body>
        <header>
          <h2>{type}</h2>
        </header>

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
              </tr>
            </thead>
          </table>
        </div>
      </Modal.Body>
    </Modal>
    </>

  )
}
export default AllHotels