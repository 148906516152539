import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../../../controllers/controller";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../../../Reusables/Loader/Loading";
import HotelContract from "../../../../Reusables/contractCard/hotelContract";
import Completed from "./Completed";

const Allcontracts = ({ match }) => {
  console.log(match.url);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const [contracts, setContracts] = useState([]);

  const [show, setShow] = useState(false);
  useEffect(() => {
    let mounted = true;
    const formData = new FormData();
    const accowner = localStorage.getItem("hotelChain");

    if (accowner) {
      setLoading(true);
      const useableAccOwner = JSON.parse(accowner);
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("token", useableAccOwner.usertoken);
      Fetch(
        "http://api.test.hotelsoffline.com/v1/yayo/list-contract-two",
        formData
      )
        .then((res) => {
          if (mounted) {
            if (res.data.success !== false) {
              setContracts(res.data);
            }
            if (res.data.success === false) {
              setContracts(res.data);
              setShowAlert(true);
            }
            setLoading(false);
            setAlertType({
              ...alert,
              type: "success",
              value: res.data.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    }

    return () => {
      mounted = false;
    };
  }, []);
  const handleSummary = (e) => {
    localStorage.setItem("con", e);
  };
  return loading ? (
    <Loading />
  ) : (
    <>
      {showAlert === true && (
        <AlertComp variant={alert.type} alertText={alert.value} />
      )}
      <div className="d-flex flex-column justify-content-center align-items-center px-lg-5">
        {contracts.length > 0 ? (
          <div className="all-contracts">
            {contracts.map((contract) => (
              <HotelContract
                title={contract.hotelname}
                // index2={indexx}
                // buttonLoad={buttonLoad}
                dmc_name={contract.contract_name}
                country={contract.country}
                matched={match.url}
                ID={contract.contract_id}
                id={contract.contract_id}
                contract={contract.contract_name}
                status={contract.status}
                childFrom={contract.child_age_From}
                currency={contract.currency}
                childTo={contract.child_age_To}
                time={contract.timestamp}
                handleSummary={handleSummary}
                name={contract.room_name}
                From={contract.start_date}
                To={contract.expiry_date}
                price={contract.price}
                // openAvailabilityModal={(event, id) =>
                //   openAvailModal(event, id, index)
                // }
              />
            ))}
          </div>
        ) : (
          <Completed />
        )}
      </div>
    </>
  );
};

export default Allcontracts;
