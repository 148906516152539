import React, { useState, useEffect } from "react";
import {
  Fetch,
  TimestampConverter,
} from "../../../../../../controllers/controller";
import StaticSupp from "./StaticSupp";
import ContractAlert from "../../../../../Reusables/ContractAlert/ContractAlert";

const SupplementsDetails = ({ supList, contractTop, reloader }) => {
  const hotelId = localStorage.getItem("hotel");
  
  const [load, setLoad] = useState(false);
  const [showAlertt, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [edit, setEdit] = useState(null);
  const [allSupplement, setAllSupplement] = useState({});
  const [newDate, setNewDate] = useState({});
  
  const handleEdit = (e) => {
    setEdit(true);
    setAllSupplement({ ...allSupplement, ...supList });
  };

  const handleChange = (e) => {
    setAllSupplement({ ...allSupplement, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e) => {
    e.preventDefault();
    setNewDate({ ...newDate, [e.target.name]: e.target.value });
  };
  function getRoomCategories() {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelId);

    Fetch(`${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        console.error(err)
        // setAlertType({ ...alert, type: "danger", value: err.message });
      });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getRoomCategories()
    }
    return () => {
      mounted = false;
    };
  }, [load]);
  
  const handleSave = (e) => {
    e.preventDefault();
    if (
      contractTop.status !== "" &&
      contractTop.contract_name !== "" &&
      contractTop.child_age_From !== "" &&
      contractTop.child_age_to !== "" &&
      contractTop.currency !== ""
    ) {
      setLoad(true);
      const formData = new FormData();
      formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
      formData.append("hoteltoken", allSupplement.hoteltoken);
      formData.append("token", allSupplement.contractToken);
      formData.append("dmctoken", allSupplement.dmctoken);
      formData.append("id", allSupplement.id);

      formData.append(
        "stay_from",
        newDate.stay_from
          ? newDate.stay_from
          : TimestampConverter(allSupplement.stay_from)
      );
      formData.append(
        "stay_to",
        newDate.stay_to
          ? newDate.stay_to
          : TimestampConverter(allSupplement.stay_to)
      );
      formData.append("price_adult", allSupplement.price_adult);
      formData.append("price_child", allSupplement.price_child);
      // formData.append("price_childfrom", allSupplement.price_childfrom);
      // formData.append("price_childto", allSupplement.price_childto);

      formData.append("service", allSupplement.service);
      formData.append("sup_type", allSupplement.sup_type);
      formData.append("subscription", allSupplement.subscription);
      // formData.append("unit-1", allSupplement.unit1);

      formData.append("supp_room_type", allSupplement.supp_room_type);
      formData.append("adult_amount", allSupplement.adult_amount);
      formData.append("adult_perc", allSupplement.adult_perc);
      formData.append("child_amount", allSupplement.child_amount);
      formData.append("child_perc", allSupplement.child_perc);
      formData.append("supp_child_age_from", allSupplement.supp_child_age_from);
      formData.append("supp_child_age_to", allSupplement.supp_child_age_to);

      formData.append("contract_name", allSupplement.contract_name);
      formData.append("status", allSupplement.status);
      formData.append("child_age_From", allSupplement.child_age_From);
      formData.append("child_age_To", allSupplement.child_age_To);
      formData.append("currency", allSupplement.currency);
      Fetch(
        `${process.env.REACT_APP_END_POINT}/supplement-plan-update`,
        formData
      )
        .then((res) => {
          setLoad(false);
          setEdit(false);
          if (res.data.success === true) {
            setShow(true);
            setValue(res.data.message);
            reloader();

            // window.location.reload()
          } else {
            setShow(true);
            setValue(res.data.message);
          }
        })
        .catch((err) => {
          setLoad(false);
          reloader();
          setShow(true);
          setValue(err.message);
        });
    } else {
      window.alert("Please fill in contract's header details first");
      setAllSupplement({
        ...allSupplement,
        service: "",
        sup_type: "",
        stay_from: "",
        stay_to: "",
        subscription: "",
        price_adult: "",
        price_child: "",
      });
    }
  };

  return (
    <>
      {edit ? (
        <tr>
          <td>
            <p>{TimestampConverter(allSupplement.timestamp)}</p>
          </td>
          <td>
            {/* <label htmlFor="service" className="visually-hidden"> Service </label> */}
            <input
              type="text"
              name="service"
              id="service"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={allSupplement.service}
            />
          </td>
          <td>
            {/* <label htmlFor="subscription" className="visually-hidden"> Subscription </label> */}
            <select
              name="subscription"
              id="subscription"
              onChange={(e) => handleChange(e)}
              className="form-select"
              value={allSupplement.subscription}
            >
              <option value="per day">Per day</option>
              <option value="per stay">Per stay</option>
            </select>
          </td>

          <td>
            {/* <label htmlFor="supplement" className="visually-hidden"> Supplement Type </label> */}
            <select
              name="sup_type"
              id="supplement"
              onChange={(e) => handleChange(e)}
              className="form-select"
              value={allSupplement.sup_type}
            >
              <option defaultValue></option>
              <option value="Per Person">Per Person</option>
              <option value="Per Booking">Per Booking</option>
            </select>
          </td>
          <td>
            {/* <label htmlFor="supplement" className="visually-hidden"> Supplement Room Type </label> */}
            <select
              name="supp_room_type"
              id="supp_room_type"
              onChange={(e) => handleChange(e)}
              className="form-select"
              value={allSupplement.supp_room_type}
            >
              <option defaultValue></option>
              {categoryList.length > 0 &&
              categoryList.map((category) => (
                      <option value={category.roomid}>{category.category}</option>
                    ))}
            </select>
          </td>
          <td>
            <p>{TimestampConverter(allSupplement.stay_from)}</p>
            {/* <label htmlFor="stay_from" className="visually-hidden"> Stay From </label> */}
            <input
              type="date"
              name="stay_from"
              className="form-control"
              onChange={(e) => handleDateChange(e)}
              id="stay_from"
              value={newDate.stay_from}
            />
          </td>
          <td>
            <p>{TimestampConverter(allSupplement.stay_to)}</p>
            {/* <label htmlFor="stay_to" className="visually-hidden"> Stay To </label> */}
            <input
              type="date"
              name="stay_to"
              className="form-control"
              onChange={(e) => handleDateChange(e)}
              id="stay_to"
              value={newDate.stay_to}
            />
          </td>
          <td>
            {/* <label htmlFor="price_adult" className="visually-hidden"> Price Adult </label> */}
            <input
              type="number"
              name="price_adult"
              className="form-control"
              onChange={(e) => handleChange(e)}
              id="price_adult"
              value={allSupplement.price_adult}
            />
          </td>
          <td>
            {/* <label htmlFor="child_from" className="visually-hidden"> Child From </label> */}
            <input
              type="number"
              name="price_child"
              className="form-control"
              onChange={(e) => handleChange(e)}
              id="price_child"
              value={allSupplement.price_child}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {/* <label htmlFor="adult_amount" className="visually-hidden">Adult Amount</label> */}
            <input
              type="number"
              className="form-control"
              onChange={handleChange}
              name="adult_amount"
              id="adult_amount"
              value={allSupplement.adult_amount}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {/* <label htmlFor="adult_perc" className="visually-hidden">Adult Percentage</label> */}
            <input
              type="number"
              className="form-control"
              onChange={handleChange}
              name="adult_perc"
              id="adult_perc"
              value={allSupplement.adult_perc}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {/* <label htmlFor="child_amount" className="visually-hidden">Child Amount</label> */}
            <input
              type="number"
              className="form-control"
              onChange={handleChange}
              name="child_amount"
              id="child_amount"
              value={allSupplement.child_amount}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {/* <label htmlFor="child_perc" className="visually-hidden">Child Percentage</label> */}
            <input
              type="number"
              className="form-control"
              onChange={handleChange}
              name="child_perc"
              id="child_perc"
              value={allSupplement.child_perc}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {/* <label htmlFor="child_ageFrom" className="visually-hidden">Child Age From</label> */}
            <input
              type="number"
              className="form-control"
              onChange={handleChange}
              name="supp_child_age_from"
              id="supp_child_age_from"
              value={allSupplement.supp_child_age_from}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {/* <label htmlFor="child_ageTo" className="visually-hidden">Child Age To</label> */}
            <input
              type="number"
              className="form-control"
              onChange={handleChange}
              name="supp_child_age_to"
              id="supp_child_age_to"
              value={allSupplement.supp_child_age_to}
            />
          </td>
          <td>
            <button className="btn h-100" onClick={(e) => handleSave(e)}>
              {load ? (
                <div
                  class={load && `spinner-border text-primary`}
                  role="status"
                ></div>
              ) : (
                <div className="fw-bold">SAVE</div>
              )}
            </button>
          </td>
          {/* <AlertModal show={show} setShow={setShow} /> */}
        </tr>
      ) : (
        <StaticSupp
          timestamp={supList.timestamp}
          service={supList.service}
          type={supList.sup_type}
          roomType={supList.supp_room_type}
          subscription={supList.subscription}
          from={supList.stay_from}
          to={supList.stay_to}
          adult={supList.price_adult}
          child={supList.price_child}
          child_from={supList.price_child}
          child_to={supList.price_childto}
          handleEdit={handleEdit}
          adult_amount={supList.adult_amount}
          adult_perc={supList.adult_perc}
          child_amount={supList.child_amount}
          child_perc={supList.child_perc}
          child_age_from={supList.supp_child_age_from}
          child_age_to={supList.supp_child_age_to}
        />
      )}

      <ContractAlert show={showAlertt} setShow={setShow} value={value} />
    </>
  );
};

export default SupplementsDetails;
