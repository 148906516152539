import React, { useState, useEffect } from "react";
import RequestError from "../../../404/RequestError";
import { getAdminContracts } from "../apis";
import { useMutation } from "react-query";
import { Route, useLocation } from "react-router-dom";
import { mainGetUsertoken } from "../../Reusables/GetUsertoken/GetUsertoken";
import { CreateContractButton } from "../../Others/DMC/Contracts/Status/AllContracts";
import NewContract from "../../Others/DMC/Contracts/NewContract";
import ContractDetails from "../../Reusables/contractDetails/ContractDetails";
import Loading from "../../../Reusables/Loader/Loading";





function Contracts(){
  return (
    <div className="pt-4 px-lg-4 px-3 ">
      <header className="d-flex justify-content-between align-items-center dashHeader">
        <h4>Contracts</h4>
        <CreateContractButton />
      </header>

      <>
        <Route exact path="/admin/contracts" component={ContractLists} />
        <Route exact path="/admin/contracts/create" component={NewContract} />
        <Route path="/admin/contracts/detail" component={ContractDetails} />
      </>
    </div>
  )
}

const ContractLists = () => {
  const [contracts, setContracts] = useState([])
  const [usertoken, setUsertoken] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);



  const fetchContracts = useMutation((token)=>getAdminContracts(token), {
    onSuccess: res => {
      if(res?.data?.contracts){
        setContracts(res.data.contracts)
      }else {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: res.data.message });
      }
      
    },
    onError: err => {
      console.error(err)
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
    }
  })


  useEffect(()=>{
    if(usertoken){
      fetchContracts.mutate({usertoken})
    }
  },[usertoken])



  return (
    <RequestError>
      <div className="pt-4 px-lg-4 px-3 ">
        {/* <header className="d-flex justify-content-between align-items-center dashHeader">
          <h4>Contracts</h4>
          <CreateContractButton />
        </header> */}
        <div className="admin-dashboard-top table_wrapper mt-5 text-center">
            <div className="table-responsive">

              {
                fetchContracts?.isLoading ?
                <Loading />
                :
                <table className="admin_contract_table table table-bordered position-relative">
                    <thead>
                        <tr>
                        {
                            tableHeaders.map(item=>(
                                <th style={{background: "blue", color:"#FFF"}}>{item}</th>
                            ))
                        }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        contracts?.map((item, i)=>(
                            <tr>
                                <td>{i + 1}</td>
                                <td>{item.country}</td>
                                <td>{item.city}</td>
                                <td dangerouslySetInnerHTML={{__html: item.hotelname}} />
                                <td>{item.contract_name}</td>
                                <td>{item.status}</td>
                                <td>{item.company}</td>
                                <td>{item.type}</td>
                                <td>{item.currency}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
              }
            </div>

        </div>      
      </div>
    </RequestError>
  );
};


const tableHeaders = ["S/N", "Country", "City", "Hotel Name", "Contract", "Status", "Company", "Type", "Currency"]

export default Contracts;
