import React, { useEffect, useState } from "react";
import Eye from "../../Images/Icons/Eye.svg";
import { Link, useHistory } from "react-router-dom";
import LoginImage from "../../Images/Login.png";
import { Fetch } from "../../controllers/controller";
import Loading from "../Reusables/Loader/Loading";
import AlertComp from "../Reusables/AlertComp/AlertComp";
import {motion} from "framer-motion"


const Login = () => {
  const history = useHistory();

  // state for toggle password and toggle remember me
  const [multiState, setMultiState] = useState({
    showPassword: "password",
  });
  //deconstructing the multistate object above
  const { showPassword } = multiState;

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const [payload, setPayload] = useState({
    apptoken: process.env.REACT_APP_APP_TOKEN,
    mail: "",
    password: "",
    latitude: "",
    longitude: "",
    action: "LOG1"
  });

  useEffect(() => {
    let mounted = true;
    navigator.geolocation.getCurrentPosition((position) => {
     
      if (mounted) {
        setPayload({
          ...payload,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayload({ ...payload, [name]: value });
  };
 
  const handleAlertClose =()=>{
    setShowAlert(false);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("apptoken", payload.apptoken);
    formData.append("mail", payload.mail);
    formData.append("password", payload.password);
    formData.append("latitude", payload.latitude);
    formData.append("longitude", payload.longitude);
    if (payload.mail !== "" && payload.password !== "") {
      setLoading(true);
      Fetch(`${process.env.REACT_APP_NEND_POINT}/login`, formData)
        .then((res) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "success", value: res.data.message });
          if(res.data.role === "0") {
            localStorage.clear();
            const admin = JSON.stringify(res.data)
            localStorage.setItem("admin", admin)
            history.push("/admin")

          } else if (res.data.role === "1") {
            localStorage.clear();
            const traveller = JSON.stringify(res.data)
            localStorage.setItem("traveller", traveller)
            history.push("/user")

          } else if (res.data.role === "2") {
            localStorage.clear();
            const hotelChain = JSON.stringify(res.data)
            localStorage.setItem("hotelChain", hotelChain)
            history.push("/hotel-owner")
          } else if(res.data.role === "3"){
            localStorage.clear();
            const DMC = JSON.stringify(res.data)
            localStorage.setItem("DMC", DMC)
            history.push("/dmc")
          }
           else if(res.data.role === "4"){
             localStorage.clear();
            const influencer = JSON.stringify(res.data)
            localStorage.setItem("influencer",influencer)
            history.push("/influencer")
          }
           else if(res.data.role === "5"){
             localStorage.clear();
            const accountOwner = JSON.stringify(res.data)
            localStorage.setItem("accountOwner", accountOwner);
            history.push("/account-owner")
          }
           else if(res.data.role === "6"){
             localStorage.clear();
            const Admin = JSON.stringify(res.data)
            localStorage.setItem("admin", Admin);
            history.push("/admin")
          }

        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setAlertType({ ...alert, type: "danger", value: err.message });
        });
    } else {
      setShowAlert(true);
      setAlertType({
        ...alert,
        type: "danger",
        value: "All fields are required",
      });
    }
  };
  return loading ? (
    <Loading />
  ) : (
    <div className="w-100" style={{ height: "100vh" }}>
      <div className="row w-100">
        <div className="col-sm-6 d-none d-sm-block">
          <img
            src={LoginImage}
            className="img-fluid"
            style={{ height: "100vh", width: "50vw" }}
            alt="beach chair and umbrella overlooking the sea"
          />
        </div>
        <div className="col-sm-6 col-12 px-lg-4 px-sm-0 container flex-column d-flex justify-content-center align-items-center" style={{ height: "100vh", position:"relative" }} >
             <Link to="/">
             <i className="bi bi-house-fill text-primary" style={{position:"absolute", top:"10px", right:"10px", fontSize:"20px"}}></i>
             </Link> 

          <div className="purchase row w-100 justify-content-center px-2 px-md-0">
            <motion.div className="form-wrapper col-md-8"
            // initial={{x: 200, opacity: 0}}
            // animate={{x: 0, opacity: 1}}
            // transition={{duration: .3}}
            >
              <header>
                <p className="mb-2 fw-bold">Welcome back</p>
                <h3 className="text-decoration-none font-weight-bolder mb-2" style={{ color: "rgba(14, 73, 152, 1)" }} > Login to your account </h3>
              </header>
              <div className="row justify-content-center">
                <form className="form">
                  {showAlert === true && (
                    <AlertComp variant={alert.type} alertText={alert.value} handleAlertClose={handleAlertClose} />
                  )}
                  <motion.div className="email"
                  initial={{x: 600}}
                  animate={{x: 0}}
                  transition={{duration: .3}}
                  >
                    <label htmlFor="mail">Email</label>
                    <input
                      type="text"
                      name="mail"
                      id="email"
                      className="form-control"
                      onChange={handleChange}
                      value={payload.mail}
                    />
                    
                  </motion.div>
                  <motion.div 
                  initial={{x: 600}}
                  animate={{x: 0}}
                  transition={{duration: .5}}
                  className="password mt-3" style={{position: "relative"}}> 
                    <label htmlFor="password">Password</label>
                    <input
                      type={showPassword}
                      name="password"
                      id="password"
                      className="form-control"
                      onChange={handleChange}
                      value={payload.password}
                    />
                    <i style={{position:"absolute", right: "0", top:"30px"}}>
                      <img
                        src={Eye}
                        className="unmask"
                        alt="show password"
                        onClick={() => {
                          if (showPassword === "password") {
                            setMultiState({
                              ...multiState,
                              showPassword: "text",
                            });
                          } else {
                            setMultiState({
                              ...multiState,
                              showPassword: "password",
                            });
                          }
                        }}
                      />
                    </i>
                  </motion.div>
                  <div>
                    <div className="bottom d-flex justify-content-between align-items-center mt-3">
                      <div className="">
                        <input
                          type="checkbox"
                          name="rememberMe"
                          id="rememberMe"
                          className="me-2"
                        />
                        <label htmlFor="rememberMe">Remember me</label>
                      </div>

                      <div className="forgot-password text-right">
                        <Link
                          to="/forgot-password"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Forgot password?
                        </Link>
                      </div>
                     
                    </div>
                    <motion.button
                     initial={{x: 600}}
                     animate={{x: 0}}
                     transition={{duration: .6}}
                      className="btn btn-primary btn-block w-100 mt-3 mb-5"
                      onClick={handleSubmit}
                    >
                      Login
                    </motion.button>
                  </div>

                  <div className="form-footer">
                    <p
                      className="text-center mt-4"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      Dont have an account?{" "}
                      <span className="ml-2">
                        {" "}
                        <Link to="/signup">Join free today</Link>{" "}
                      </span>{" "}
                    </p>
                  </div>
                </form>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
