import React, { useEffect, useState } from 'react'
import {useQueryClient, useMutation} from "react-query"
import { useLocation } from 'react-router-dom'
import AlertComp from '../../../../Reusables/AlertComp/AlertComp'
import Loading from '../../../../Reusables/Loader/Loading'
import { mainGetUsertoken } from '../../../Reusables/GetUsertoken/GetUsertoken'
import { addExternalHotel, getAccountOwner, getdmc, getExternalSources } from '../../apis'

const AddXHotel = () => {
    const location = useLocation()
    const [usertoken, setUsertoken] = useState("")
    const [user, setUser] = useState("")
    const queryClient = useQueryClient()

    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });
    const[hotelstate, setHotelState]= useState({
        apptoken: process.env.REACT_APP_APP_TOKEN,
        usertoken: usertoken
    })

    const handleChange = (e)=> {
        e.preventDefault()
        setHotelState({...hotelstate, [e.target.name]:e.target.value})
    }
    useEffect(()=>{
        mainGetUsertoken(location, setUsertoken, setUser)
      },[])
    
    const getSourceMutation = useMutation(getExternalSources, { onSuccess:()=>{
        console.log("done")
    }})

    const getDMC = useMutation(getdmc, {onSuccess:()=>{
        console.log("done")
    }})
    // fetch all account owners
    const getAccountOwners = useMutation(getAccountOwner, {
      onError: (e)=>{
          console.error(e)
          setAlertType({ ...alert, type: "danger", value: e.message });
          setShowAlert(true)
      },
      onSuccess:(e)=>{
          console.log(e)
          if(e.data?.success === false) {
              setAlertType({ ...alert, type: "danger", value: e.data.message })
              setShowAlert(true)
          }
      }
  })

    const submitForm = useMutation(addExternalHotel, { 
        onSuccess:(e)=>{
            if(e.data?.success !== false) {

            setAlertType({ ...alert, type: "success", value: e.data.message });
            setShowAlert(true)
            setHotelState({hotelname:"",hotelcode:"", dmctoken:"", source:""})
            console.log(e)
            } else {
                setAlertType({ ...alert, type: "danger", value: e.data.message });
            setShowAlert(true)
            }
        }, 
        onError:(e)=>{
            setAlertType({ ...alert, type: "danger", value: e.message });
            setShowAlert(true)
        }
    })

    useEffect(() => {
        if(usertoken){
            setHotelState({...hotelstate, usertoken:usertoken})

            getSourceMutation.mutate({
                apptoken:process.env.REACT_APP_APP_TOKEN,
                usertoken:usertoken
            })

            getDMC.mutate({
                apptoken:process.env.REACT_APP_APP_TOKEN,

            })
            getAccountOwners.mutate({
              apptoken:process.env.REACT_APP_APP_TOKEN,
      
          }) 
        }
    }, [usertoken])
          
    function handleSubmit(e){
        e.preventDefault()
        submitForm.mutate({...hotelstate,   apptoken:process.env.REACT_APP_APP_TOKEN, usertoken})
    }
    const handleAlertClose = () => {
        setShowAlert(false);
    };
  return (
    <div className="pt-4 px-lg-4 px-3 ">
        <header className="d-flex justify-content-between align-items-center dashHeader">
          <h4>ADD EXTERNAL HOTELS</h4>
        </header>
        <div className="admin-dashboard-top mt-5 text-center row justify-content-center">
         <form className="form row" onSubmit={handleSubmit}>
         {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}
            {submitForm.isLoading ? <Loading /> : <>
          <div className="form-group col-md-5 col-6 p-3">
            <label htmlFor="hotelname" className="form-label">Hotel Name</label>
            <input type="text" name="hotelname" id="hotelname" className="form-control"  onChange={handleChange} value={hotelstate.hotelname}/>
          </div>
          <div className="form-group col-md-5 col-6 p-3">
            <label htmlFor="hotelcode" className="form-label">Hotel Code</label>
            <input type="text" name="hotelcode" id="hotelcode" className="form-control"  onChange={handleChange} value={hotelstate.hotelcode}/>
          </div>
          <div className="form-group col-md-5 col-6 p-3">
            <label htmlFor="source" className="form-label d-block">Source</label>
            {getSourceMutation.isLoading ?  <div className={`spinner-border text-primary` } role="status" ></div> :   
            <select name="sourceid" id="source" className="form-select" onChange={handleChange} value={hotelstate.sourceid}>
                <option value="">Select a source</option>
                {getSourceMutation.data && getSourceMutation.data.data.sources.map(source=>(
                    <option key={source.id} value={source.id}>{source.name}</option>
                ))}
            </select>
            }
          </div>
          <div className="form-group col-md-5 col-6 p-3">
            <label htmlFor="dmctoken" className="form-label d-block">DMC</label>
            {getDMC.isLoading ?  <div className={`spinner-border text-primary` } role="status" ></div> :   
                <select name="dmctoken" id="dmctoken" className="form-select" onChange={handleChange} value={hotelstate.dmctoken}>
                    <option value="">Select a DMC</option>
                    {(getDMC.data && getDMC.data?.data.success !== false) && getDMC.data.data.map(dmc=>(
                        <option key={dmc.id} value={dmc.token}>{dmc.first_name} - {dmc.last_name}</option>
                        ))
                    }
                </select>
            }
          </div>
          <div className="form-group col-md-5 col-6 p-3">
              <label htmlFor="aotoken" className="form-label">Account Owner</label>
              {  getAccountOwners.isLoading ?  
              <div>
                  <div className={`spinner-border text-primary d-block mx-auto` } role="status" ></div>  
              </div> :
              <select name="aotoken" id="aotoken" className="form-select" value={hotelstate.aotoken} onChange={handleChange}>
                  <option value="">Select Account Owner</option>
                  {(getAccountOwners.data && getAccountOwners.data?.data.success !== false) && getAccountOwners.data.data.map(accOwn=>(
                      <option key={accOwn.id} value={accOwn.token}>{accOwn.first_name} - {accOwn.last_name}</option>
                      )) 
                  }
              </select>
              }
            </div>
            <div className="btn-wrapper">
                <button className="btn btn-primary" type="submit">Submit</button>
            </div>
            </>}
         </form>
        </div>
      </div>
  )
}

export default AddXHotel

