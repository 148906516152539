import React from "react";
import { Link } from "react-router-dom";
import { SideNavLinks } from "../Super/superData/data";
import SidebarLinks from "./sidenavlinks/SidebarLinks";
import "../Junior/Junior.css"
import {AiOutlineClose} from "react-icons/ai";



const Sidebar = ({sidebar, close, handleLogout}) => {
  const handleScroll = () =>{

  }
  const style ={
    width:"10px",
    height:"3rem",
    position:"absolute",
    top:"6%", 
    right:"-17px",
    color:"grey",
    zIndex:"22000",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",

  }
  return (
    <div  className={`sidebar-container bg-light ${sidebar}`} style={{transition:"all .1s ease"}}>
      <header className=" my-5">
        <div className="d-flex justify-content-center align-items-center closeSidebar" style={style}>
          <i className=" text-center close" onClick={close}>
            <AiOutlineClose style={{fontSize: "30px" }} />
          </i>
        </div>
        <h2 className="text-center sidenav-header lead" style={{color:"rgba(38, 58, 83, 1)"}}>Hotelsoffline</h2>
        <div><p className="text-lead text-secondary text-center mt-2">ADMIN</p></div>
      </header>

      <div className="sidebar_bottom">
        <div className="sidenav-links">
        {SideNavLinks.map(link=>(
          <SidebarLinks title={link.title} link={link.link}  key={link.id} icon={link.class}/>
          
        ))}
        </div>
        <footer className="sidenav-footer d-flex flex-column  justify-content-center my-5 align-items-center">
          {/* <button className="btn btn-primary px-5 py-1">Admin</button> */}
          <button className="btn btn-danger px-5 py-1 mt-2" onClick={handleLogout} >Logout</button>
        </footer>
      </div>

    </div>
  );
};

export default Sidebar;
