import React from 'react'
import { TimestampConverter } from '../../../../../../controllers/controller'

const Staticmeals = ({ handleEdit,key, timeStamp, saiA, roomOnlyA,  roomOnlyC, saiC, uaiA, uaiC, allA, allC, breakA, breakC, fb_adult, index, fb_child, hb_adult, hb_child, mealStart, mealExp}) => {
    return (
        
            <tr>
                <td>
                   <p>{TimestampConverter(timeStamp)}</p>
                </td>
                <td>
                   <p>{TimestampConverter(mealStart)}</p>
                </td>
                <td>
                   <p>{TimestampConverter(mealExp)}</p>
                </td>
                <td>
                   <p>{roomOnlyA}</p>
                </td>
                <td>
                   <p>{roomOnlyC}</p>
                </td>

                <td>
                    <p>{breakA}</p>
                </td>
                <td>
                    <p>{breakC}</p>
                </td>
                <td>
                    <p>{hb_adult}</p>
                </td>
                <td>
                    <p>{hb_child}</p>
                </td>
                <td>
                    <p>{fb_adult}</p>
                </td>
                <td>
                    <p>{fb_child}</p>
                </td>
                <td>
                    <p>{saiA}</p>
                </td>
                <td>
                    <p>{saiC}</p>
                </td>
                <td>
                    <p>{allA}</p>
                </td>
                <td>
                    <p>{allC}</p>
                </td>
                <td>
                    <p>{uaiA}</p>
                </td>
                <td>
                    <p>{uaiC}</p>
                </td>
                <td className="oneFullColumn">
                <button className="btn h-100" onClick={(e)=>handleEdit(e, index)}>
                    Edit
                </button>
                </td>
            </tr>
        
    )
}

export default Staticmeals
