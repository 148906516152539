import React from 'react'

const BottomBanner = () => {
    return (
        <section className="container bottomBanner bg-warning d-flex justify-content-around align-items-center py-5 flex-column flex-sm-row"> 
            <div className="left text-light mb-3 mb-sm-0">
                <h1 className="display-1">Know your city?</h1>
                <small>Join 2000+ locals &amp; 1200+ contributors from 3000 cities</small>

            </div>
            <div className="right">
                <button className="btn btn-light bottomBanner__button">Become Local Expert</button>
            </div>
        </section>
    )
}

export default BottomBanner
