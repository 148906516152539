import React, { useState } from 'react'
import { Fetch } from '../../controllers/controller'
import AlertComp from '../Reusables/AlertComp/AlertComp'
import Loading from '../Reusables/Loader/Loading'


const ForgotPassword = () => {
    const [token, setToken] = useState('')
    const [payload, setPayload] = useState({
        apptoken: process.env.REACT_APP_APP_TOKEN,
        code: ""
    })


    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: ""
    })

    const handleChange = (e) => {
        setPayload({...payload, code: e.target.value})
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData ();
        formData.append("apptoken", payload.apptoken)
        formData.append("mail", payload.code)
        if(payload.code !== "") {
            setLoading(true)
            Fetch(`${process.env.REACT_APP_NEND_POINT}/forgot-password`, formData)
            .then(res=>{
                setLoading(false)
                setShowAlert(true)
                setAlertType({...alert, type: "success", value: res.data.message})
            })
            .catch(err=>{
            setLoading(false)
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: err.message})
          
            })
        } else {
            setShowAlert(true)
            setAlertType({...alert, type: "danger", value: "Email is required"})

        }
    }
    return loading ? (<Loading />):(
        <div className="container" style={{height: "100vh"}}>
            <div>
                <p className="display-4 text-center fw-bolder py-3">Reset Password</p>
            </div>
            <div className="row  justify-content-center align-items-center">
            <form action="" className="form col-sm-6 col-md-4">
            {showAlert === true && (
                  <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                />
              )}
                <div className="activate mb-3">
                    <label htmlFor="token" className="py-3">Enter your email address</label>
                    <input type="text" name="token" id="token" className="form-control" onChange={handleChange} value={payload.code}/>
                </div>
                <button className="btn btn-primary btn-block btn-large" onClick={handleSubmit}>Reset Password</button>
            </form>
            </div>
        </div>
    )
}

export default ForgotPassword
