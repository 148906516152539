import React, { useState } from "react";
import hamburger from "../../../../../Images/Icons/ham.png"
const Header = ({handleClick}) => {
  const [sidebar, setSidebar] = useState("");
  const [main, setMain] = useState("col-lg-10");


  return (
    <div className="top-nav navbar px-lg-4">
      <div className="left col-6 col-md-9 d-flex">
        <div className="hamburger" onClick={handleClick}>
          <img src={hamburger} alt="" />
        </div>
        <p className="ms-2 text-primary" style={{ fontSize: "18px" }}>
          Home
        </p>
      </div>
      <div className="right col-6 col-md-3 d-sm-flex justify-content-center d-none">
        <div className="right me-3 ">
          <div className="bg-primary h2 text-center text-light p-2" style={{ height: "50px", width: "50px", borderRadius: "50%" }} > H </div>
        </div>
        <div className="left ">
          <div className="top">Admin</div>
          <div className="bottom text-secondary">
            <span className="me-2">Edit Profile</span>
            <span>
              <i className="bi bi-pen-fill"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
