import React, { useMemo } from 'react'
import Select from "react-select";
import countryList from "react-select-country-list"

const Countries = ({value, changeHandler}) => {
  
    const options = useMemo(() => countryList().getData(), []);
      return <Select options={options}  value={value} onChange={changeHandler} />
    
}

export default Countries
