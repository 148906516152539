import React from 'react'
import Sources from '../../../Reusables/Sources/Sources'

const AllSources = () => {
  return (
    <main className="pt-4 px-lg-4 px-3"  style={{ minHeight: "83vh" }}>
    <header className=" mb-3 d-flex justify-content-between align-items-center dashHeader">
      <h4 className="text-primary">Sources</h4>
    </header>

    <div className="content">
        <Sources />
    </div>
    </main>
  )
}

export default AllSources