import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Fetch } from "../../../../controllers/controller";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
import Loading from "../../../Reusables/Loader/Loading";
import { getUsertoken, mainGetUsertoken } from "../GetUsertoken/GetUsertoken";
import * as Yup from "yup";
import { useFormik } from "formik";
import PDF from "../Receipt/PDF";
import RequestError from "../../../404/RequestError";
import { adminTransfer } from "../../Super/apis";
import {useMutation} from "react-query"



const TransferModal = ({ open, setOpen, loadHistory, loadWalBal, type }) => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [usertoken, setUsertoken] = React.useState("");
  const [user, setUser] = React.useState("");
  const [alert, setAlertType] = React.useState({
    type: "",
    value: "",
  });
  const [ShowReceipt, setShowReceipt] = React.useState(false);
  const [receiptDetails, setReceiptDetails] = React.useState({});
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  React.useEffect(() => {
    mainGetUsertoken(location, setUsertoken, setUser);
  }, []);




  const mutation = useMutation(adminTransfer, {
    onSuccess: res => {
      console.log(res)
      if(res?.data?.success){
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      }else {
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "danger",
          value: res.data.message,
        });
      }
      loadHistory();


    },
    onError: err => {
      console.error(err)
    }
  })



  const formik = useFormik({
    initialValues: {
      amount: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      amount: Yup.number().required("Required"),
    }),


    onSubmit: (values) => {
      if(type !== "admin"){
          setLoading(true);
          const formData = new FormData();
          formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
          formData.append("usertoken", usertoken);
          formData.append("amount", values.amount);
          formData.append("email", values.email);
          formData.append("note", values.note);
    
          Fetch(`${process.env.REACT_APP_END_POINT}/transfer`, formData)
            .then((res) => {
              setLoading(false);
              loadHistory();
              loadWalBal();
              values.amount = "";
              values.email = "";
              if (res.data.success === false) {
                setShowAlert(true);
                setAlertType({
                  ...alert,
                  type: "danger",
                  value: res.data.message,
                });
              }
              setShowReceipt(true);
              setReceiptDetails(res.data);
              setShowAlert(true);
              setAlertType({
                ...alert,
                type: "success",
                value: res.data.message,
              });
            })
            .catch((err) => {
              setLoading(false);
              setShowAlert(true);
              setAlertType({
                ...alert,
                type: "success",
                value: err.message,
              });
            });
      }else {
        mutation.mutate({usertoken, recipient: values.email, amount: values.amount })
      }
    },
  });
  // const handleSubmit = ()=>{
  //     setLoading(true)
  //     const formData = new FormData();
  //       formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
  //       formData.append("usertoken", usertoken);
  //       formData.append("amount", amount);

  //       Fetch(`${process.env.REACT_APP_END_POINT}/withdraw`, formData)
  //       .then((res) => {
  //
  //         setLoading(false);
  //         loadHistory()
  //         setShowAlert(true)
  //         setAlertType({
  //           ...alert,
  //           type: "success",
  //           value: res.data.message,
  //         });
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         setShowAlert(true)
  //         setAlertType({
  //           ...alert,
  //           type: "success",
  //           value: err.message,
  //         });

  //       });
  // }
  const handleClose = () => {
    setOpen(false);
    window.location.reload()
  };

  
  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header>
        <h3 className="text-success">Transfer</h3>
      </Modal.Header>
      {showAlert === true && (
        <AlertComp
          variant={alert.type}
          alertText={alert.value}
          handleAlertClose={handleAlertClose}
        />
      )}
      <Modal.Body>
        <div className="row justify-content-center align-items-center">
          {loading|| mutation?.isLoading ? (
            <Loading />
          ) : ShowReceipt ? (
            <div className="d-flex justify-content-center  align-items-center">
              <RequestError>
                <PDF
                  data={
                    Object.keys(receiptDetails).length > 0 && receiptDetails
                  }
                />
              </RequestError>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} className="form col-sm-5">
              <div className="input-wrapper">
                <label className="form-label text-success fw-bold" for="amount">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  className="form-control"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <small className="text-danger">{formik.errors.amount}</small>
                ) : null}
              </div>
              <div className="input-wrapper">
                <label className="form-label text-success fw-bold" for="email">
                  Recipient Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-danger">{formik.errors.email}</p>
                ) : null}
              </div>
              <div className="input-wrapper">
                <label className="form-label text-success fw-bold" for="note">
                  Note
                </label>
                <textarea name="note" className="form-control" id="note"  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}></textarea>

                {formik.touched.note && formik.errors.note ? (
                  <p className="text-danger">{formik.errors.note}</p>
                ) : null}
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <button className="mt-2 btn btn-success" onClick>
                  Send
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferModal;
