import React, {useState} from "react";
import axios from "axios";
function controller() {
  return <div></div>;
}

export const LOOP = (start, end, contract) => {
  const formData = new FormData();
  formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
  formData.append("start_date", end);
  formData.append("exp_date", start);
  formData.append("token", contract);

  return axios.post(
    `${process.env.REACT_APP_END_POINT}/list-date-plan-details`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
};

export const dateConverter = () => {
  const d = new Date();
  const mth = d.toLocaleString("default", { month: "short" });
  const dd = d.toLocaleString("default", { day: "numeric" });
  const yy = d.toLocaleString("default", { year: "numeric" });
  return `${dd}-${mth}-${yy}`;
};
export const TimestampConverter = (ts) => {
  const d = new Date(ts * 1000);
  const mth = d.toLocaleString("default", { month: "short" });
  const dd = d.toLocaleString("default", { day: "numeric" });
  const yy = d.toLocaleString("default", { year: "numeric" });
  return `${dd}-${mth}-${yy}`;
};
export const TimestampDateConverter = (ts) => {
  const d = new Date(ts * 1000);
  const mth = d.toLocaleString("default", { month: "numeric" });
  const dd = d.toLocaleString("default", { day: "numeric" });
  const yy = d.toLocaleString("default", { year: "numeric" });
  if(mth < 10) {
    return `${yy}-0${mth}-${dd}`;
  }  
  return `${yy}-${mth}-${dd}`;
};
export const TimestampDateChecker = () => {
  const d = new Date();
  const mth = d.toLocaleString("default", { month: "numeric" });
  const dd = d.toLocaleString("default", { day: "numeric" });
  const yy = d.toLocaleString("default", { year: "numeric" });
  if(mth < 10) {
    return `${yy}-0${mth}-${dd}`;
  }  
  return `${yy}-${mth}-${dd}`;
};
export const CalendarDate = () => {
  const d = new Date();
  const mth = d.toLocaleString("default", { month: "numeric" });
  const dd = d.toLocaleString("default", { day: "numeric" });
  const yy = d.toLocaleString("default", { year: "numeric" });
  if(mth < 10) {
    return `0${mth}/${dd}/${yy}`;
  }  
  return `${mth}/${dd}/${yy}`;
};

export const datefix = (dates) => {
  let doneDate = TimestampConverter(dates);
  
}

export const Fetch = (url, formData) => {
  return axios.post(url, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
export default controller;

const url = process.env.REACT_APP_END_POINT;

export const GetData =  async (addendum, formData) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlertType] = useState({ 
      type: "",
      value: "",
  })
  const [showAlert, setShowAlert] = useState(false);
  try {
    const response = await axios.post(url + addendum, formData, {
        headers: {
          "content-type": "multipart/form-data",
        }
    })
    setData(response.data);
    console.log(response)
    setAlertType({...alert, type: "success", value: response.data.message})
    // setShowAlert(true)
    
  }catch (e) {
      setError(e.message);
      setAlertType({...alert, type: "danger", value: e.message})
      setShowAlert(true)
  } finally {
      setLoading(false);
  }

  return {data, error, loading, alert, showAlert, setShowAlert};
}


export const getContractbyCountry = (loading, country, setContractList)=>{
  let contracts = [];

  const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    // formData.append("country", country);
    formData.append("country", country);

    Fetch(`${process.env.REACT_APP_END_POINT}/list-contract-country`, formData)
    .then((res)=> {
    loading(false)

      if (res.data.message === "No results") {
        contracts = []
        window.alert(res.data.message)
      } else {
        contracts = res.data
        setContractList(res.data)
        // loadHotel(true)
      }
    })
    .catch((err) => {
      loading(false)  
      console.log(err)
    })

    return contracts

}