import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
const Modals = ({ handleShow, show, handleClose, handleOpen }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">Create Contract</h4>

            <p className="text-secondary ">
              For ease of trackings and documentation
            </p>
          </div>
          <Modal.Body className="mb-5">
            <form>
              <label htmlFor="hotel" className=" mb-2 text-secondary form-label" style={{ fontSize: "12px" }} >
                Select Hotel
              </label>
              <select
                name="hotels"
                id="hotels"
                className="form-select"
                
              >
                {/* <option value="default">Select Hotel</option> */}
              </select>
            </form>
          </Modal.Body>
          <div className="d-flex justify-content-center mb-3">
            <button className="btn btn-lg btn-primary btn-block" style={{ borderRadius: "0", padding: "11px 90px" }} onClick={handleOpen} >
              Next
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Modals;
