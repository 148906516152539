import React, {useState, useEffect} from 'react'
import {Fetch } from '../../../../controllers/controller'
import useFetch from "../../../Reusables/Fetch/Fetch"
import DeletePrompt from  "./Delete"
import {useFormik} from "formik"
import AlertComp from '../../../Reusables/AlertComp/AlertComp'

const Table = ({reload, setter, search}) => {
    const [showMarkDelete, setShowMarkDelete] = useState(false);
    const [id, setId] = useState("");
    const [edit, setEdit] = useState("")
    const [payload, setPayload] = useState({})
    const [loading, setLoading] = useState(false);
    const [seller, setSeller] = useState([]);
  const [buyer, setBuyer] = useState([]);

    

    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });

    const handleAlertClose = () => {
        setShowAlert(false);
    };
    

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN); 
    const {data, loading: loadList} = useFetch("/list-markup", formData, reload)
     
    const handleChange =(e) =>{
        setPayload({...payload, [e.target.name]: e.target.value})
    }
    const formik = useFormik({
        initialValues: {
            category:"",
            user_type:"",
            markup:""
        },
        onSubmit: (values) => {
            setLoading(true);
            const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            formData.append("contract_token", payload.contract_token);
            formData.append("id", payload.id);
            formData.append("markup", payload.markup);
            formData.append("user_type", values.user_type);
            formData.append("buyer_type", values.buyer_type !== undefined ? values.buyer_type : payload.buyer_type);
            formData.append("seller_type", values.seller_type !== undefined ? values.seller_type : payload.seller_type);
            formData.append("seller", values.seller !== undefined ? values.seller : payload.seller);
            formData.append("buyer", values.buyer !== undefined ? values.buyer : payload.buyer);
            // formData.append("category", values.category);
            formData.append("category", "seaside");
            
            Fetch(`${process.env.REACT_APP_END_POINT}/update-markup`, formData)
              .then((res) => {
                setLoading(false);
                setShowAlert(true);
                setAlertType({ ...alert, type: "success", value: res.data.message });
                setter(!reload)
                setEdit(false)
                // window.location.reload()
              })
              .catch((err) => {
                setLoading(false);
                setShowAlert(true);
                setAlertType({ ...alert, type: "danger", value: err.message });  
              });
          },
    })
    const handleClick = (listId)=> {
        setShowMarkDelete(true)
        setId(listId)
    }
    const handleEdit = (id, index) => {
        setEdit(index)
        setPayload({...data[index]})
    }
    useEffect(()=>{
    
        let mounted = true;
    
        if(formik.values.seller_type === "dmc"){
          const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            Fetch(`${process.env.REACT_APP_END_POINT}/list-dmc`, formData)
              .then((res) => {
                if(mounted){
                  setSeller(res.data)
                }
                
              })
              .catch((err) => window.alert(err));
        } else if(formik.values.seller_type === "hotel owner") {
    
          const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            Fetch(`${process.env.REACT_APP_END_POINT}/hotel-owner-list`, formData)
              .then((res) => {
                if(mounted){
                  setSeller(res.data)
                }
              })
              .catch((err) => {
                window.alert(err)
              });
            } else if(formik.values.seller_type === "account owner") {
        
              const formData = new FormData();
                formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
                Fetch(`${process.env.REACT_APP_NEND_POINT}/list-accountowner`, formData)
                  .then((res) => {
                    if(mounted){
                      setSeller(res.data)
                    }
                  })
                  .catch((err) => {
                    window.alert(err)
                  });
                } 
    
        return ()=>{
          mounted = false
        }
      },[formik.values.seller_type])

      useEffect(()=>{
    
        let mounted = true;
    
        if(formik.values.buyer_type === "dmc"){
          const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            Fetch(`${process.env.REACT_APP_END_POINT}/list-dmc`, formData)
              .then((res) => {
                if(mounted){
                  setBuyer(res.data)
                }
                
              })
              .catch((err) => window.alert(err));
        } else if(formik.values.buyer_type === "traveller") {
    
          const formData = new FormData();
            formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
            Fetch(`${process.env.REACT_APP_END_POINT}/traveller-list`, formData)
              .then((res) => {
                if(mounted){
                  setBuyer(res.data)
                }
              })
              .catch((err) => {
                window.alert(err)
              });
            }
    
        return ()=>{
          mounted = false
        }
      },[formik.values.buyer_type])

    return (
        <div className="table-responsive">
            {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}

              {
                (loading || loadList) ? <div className="spinner-border text-primary">
                  <div className="visually-hidden">Loading...</div>
                </div> :

              <table className="table table-striped table-bordered text-center p-2">
                  <thead className="table-primary">
                      <th>S/N</th>
                      <th>Country</th>
                      <th>Hotel</th>
                      <th>Contract</th>
                      <th>Category</th>
                      <th>Markup</th>
                      <th>Seller Type</th>
                      <th>Seller</th>
                      <th>Buyer Type</th>
                      <th>Buyer</th>
                      <th>Action</th>
                  </thead>
                  <tbody>
                      {data.length > 0 && data.filter(item=>(
                          item.country.toLowerCase().includes(search.toLowerCase()) ||
                          item.category.toLowerCase().includes(search.toLowerCase()) 
                        
                        )).map((list, index)=>(
                          edit === index ? (
                              <tr>
                                  <td>{index}</td>
                                  
                                  <td> 
                                      <input type="text" name="country" id="" disabled  className="form-control"   value={payload.country} onChange={handleChange} /> 
                                  </td>
                                  <td> 
                                      <input type="text" name="hotel" id=""  disabled className="form-control"   value={payload.hotelname} onChange={handleChange} /> 
                                  </td>
                                  <td>{list.contract_token}</td>
                                  <td>{list.category}</td>
                                  <td> 
                                      <input type="number" name="markup" id="" className="form-control" value={payload.markup} onChange={handleChange} /> 
                                  </td>
                                  <td>  
                                      <select name="seller_type" id="seller_type" onChange={formik.handleChange} value={formik.values.seller_type} style={{maxWidth:"120px"}} >
                                          <option value="" defaultValue>
                                              Seller Type
                                          </option>
                                          <option value="dmc">DMC</option>
                                          <option value="hotel owner">Hotel</option>
                                          <option value="account owner">Account Owner</option>
                                      </select> 
                                  </td>
                                  <td>  
                                      <select name="seller" id="seller" onChange={formik.handleChange} value={formik.values.seller} style={{maxWidth:"120px"}} >
                                          <option value="" defaultValue>
                                            Seller
                                          </option>
                                          {seller.length > 0 && seller.map(item=>(
                                      <option value={item.token} dangerouslySetInnerHTML={{__html: item.last_name + " " +  item.first_name + " - " + item.mail}} />
                                    ))}
                                      </select> 
                                  </td>
                                  <td>  
                                      <select name="buyer_type" id="buyer_type" onChange={formik.handleChange} value={formik.values.buyer_type} style={{maxWidth:"120px"}} >
                                          <option value="" defaultValue>
                                              Buyer Type
                                          </option>
                                          <option value="dmc">DMC</option>
                                          <option value="traveller">Traveller</option>
                                          <option value="hotel owner">Hotel</option>
                                      </select> 
                                  </td>
                                  <td>  
                                      <select name="buyer" id="buyer" onChange={formik.handleChange} value={formik.values.buyer} style={{maxWidth:"120px"}} >
                                          <option value="" defaultValue>
                                              Buyer
                                          </option>
                                          {buyer.length > 0 && buyer.map(item=>(
                                        <option value={item.token} dangerouslySetInnerHTML={{__html: item.last_name + " " +  item.first_name + " - " + item.mail}} />

                                      ))}
                                      </select> 
                                  </td>
                                  <td>
                                      <i className="bi bi-check-lg text-success me-2" onClick={formik.handleSubmit}></i>
                                      <i className="bi bi-x-lg text-danger me-2" onClick={()=>setEdit("")}></i>
                                  </td>
                              </tr>
                          ):(
                              <tr>
                                  <td>{index}</td>
                                  <td dangerouslySetInnerHTML={{__html: list.country === "UNDEFINED" ? "-": list.country}} />
                                  <td dangerouslySetInnerHTML={{__html: list.hotelname === "UNDEFINED" ? "-": list.hotelname}} />
                                  <td>{list.contract_name ===  "UNDEFINED" ? "-" : list.contract_name}</td>
                                  <td>{list.category ===  "UNDEFINED" ? "-" : list.category}</td>
                                  <td>{list.markup ===  "UNDEFINED" ? "-" : list.markup}</td>
                                  {/* <td>{list.category}</td> */}
                                  <td>{list.seller_type === "UNDEFINED" ? "-" : list.seller_type}</td>
                                  <td>{list.seller === "UNDEFINED" ? "-" : list.seller}</td>
                                  <td>{list.buyer_type ===  "UNDEFINED" ? "-" : list.buyer_type}</td>
                                  <td>{list.buyer ===  "UNDEFINED" ? "-" : list.buyer}</td>
                                  <td>
                                      <i className="bi bi-pen-fill text-warning me-2" onClick={()=>handleEdit(list.id, index)}></i>
                                      <i className="bi bi-trash-fill text-danger" onClick={()=>handleClick(list.id)}></i>
                                  </td>
                              </tr>
                          )
                      ))}
                  </tbody>
              </table>      
              }
            <DeletePrompt show={showMarkDelete} id={id}  handleRemove={()=>setShowMarkDelete(false)} load={setLoading}  />

        </div>
    )
}

export default Table
