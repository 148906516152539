import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import StatementTableHeader from './StatementTableHeader'
import StatementTableRow from './StatementTableRow'
import StatementTableBlankSpace from './StatementTableBlankSpace'

const tableRowsCount = 5;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const StatementItemsTable = ({data}) => (
    <View style={styles.tableContainer}>
        <StatementTableHeader />
        {/* <StatementTableRow /> */}
        <StatementTableRow data={data} />
        <StatementTableBlankSpace rowsCount={ tableRowsCount} />
    </View>
  );
  
  export default StatementItemsTable