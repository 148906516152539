import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Fetch } from "../../../../../../controllers/controller";
import AlertComp from "../../../../../Reusables/AlertComp/AlertComp";
import DateSelect from "../../../../../Reusables/Date/Date";
import Loading from "../../../../../Reusables/Loader/Loading";
import "./modal.css";

const ContractModal = ({ handleRemove, open, match }) => {
  console.log(match);
  const [rooms, setRooms] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    let mounted = true;

    const hotelId = localStorage.getItem("hotel");

    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("hotelid", hotelId);

    Fetch(`${process.env.REACT_APP_END_POINT}/room-list`, formData)
      .then((res) => {
        if (mounted) {
          setRooms(res.data);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      mounted = false;
    };
  }, []);

  const accowner = localStorage.getItem("DMC");
  const useableAccOwner = JSON.parse(accowner);
  const [payload, setPayload] = useState({});

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const handleInput = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleDate = (e) => {
    setStartDate(e);
    let y = e.getFullYear();
    let d = e.getDate();
    let m = e.getMonth() + 1;
    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }

    setPayload({ ...payload, start_date: `${m}/${d}/${y}` });
  };
  console.log(payload.start_date);
  const handleEnd = (e) => {
    setEndDate(e);
    let y = e.getFullYear();
    let d = e.getDate();
    let m = e.getMonth() + 1;
    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }

    setPayload({ ...payload, exp_date: `${m}/${d}/${y}` });
  };
  console.log(startDate, endDate);
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("roomtoken", payload.roomtoken);
    formData.append("dmctoken", useableAccOwner.usertoken);
    formData.append("room_price", payload.price);
    formData.append("avail_room_num", payload.avail_room_num);
    formData.append("exp_date", payload.exp_date);
    formData.append("start_date", payload.start_date);
    formData.append("status", payload.status);
    formData.append("child_age", payload.child_age);
    formData.append("currency", payload.currency);
    formData.append("occupy_min", payload.occupy_min);
    formData.append("occupy_max", payload.occupy_max);
    formData.append("occupy_min_child", payload.occupy_min_child);
    formData.append("occupy_max_child", payload.occupy_max_child);
    formData.append("release_date", payload.release_date);
    formData.append("breakfast_child", payload.breakfast_child);
    formData.append("breakfast_adult", payload.breakfast_adult);
    formData.append("fb_child", payload.fb_child);
    formData.append("fb_adult", payload.fb_adult);
    formData.append("hb_child", payload.hb_child);
    formData.append("hb_adult", payload.hb_adult);
    formData.append("sai_child", payload.sai_child);
    formData.append("sai_adult", payload.sai_adult);
    formData.append("uai_child", payload.uai_child);
    formData.append("uai_adult", payload.uai_adult);
    formData.append("all_inclusive_child", payload.all_inclusive_child);
    formData.append("all_inclusive_adult", payload.all_inclusive_adult);
    formData.append("cancel_days", payload.cancel_days);
    formData.append("cancel_penalty", payload.cancel_penalty);
    setLoading(true);
    Fetch(`${process.env.REACT_APP_END_POINT}/create-contract`, formData)
      .then((res) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  };
  return (
    <>
      <div show={open} onHide={handleRemove}>
        {loading ? (
          <Loading />
        ) : (
          <div className="py-5 px-5 modals">
            <div className="d-flex flex-column justify-content-start  mb-5">
              <h4 className="text-primary fw-bold">Create Contract</h4>

              <p className="text-secondary ">
                For ease of trackings and documentation
              </p>
            </div>
            <div className="mb-5">
              <form className="row">
                {showAlert === true && (
                  <AlertComp variant={alert.type} alertText={alert.value} />
                )}
                <div className="col-12">
                  <div className="row">
                    <div className="availableRooms col-md-6">
                      <label
                        htmlFor="availableRooms"
                        className="mb-2 text-secondary form-label"
                        style={{ fontSize: "12px" }}
                      >
                        Available rooms
                      </label>
                      <select
                        name="roomtoken"
                        id="availableRooms"
                        className="form-select"
                        onChange={handleInput}
                      >
                        <option value="" selected={true}>
                          Rooms
                        </option>

                        {rooms.length > 0 &&
                          rooms.map((room) => (
                            <option key={room.count} value={room.roomid}>
                              {room.room_name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="expiry col-md-3">
                      <fieldset>
                        <div className="row">
                          <div className="col-sm-6">
                            <label
                              label
                              htmlFor="start_date"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              From
                            </label>

                            <DateSelect
                              handleDate={handleDate}
                              startDate={startDate}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="expiry"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              To
                            </label>

                            <DateSelect
                              handleDate={handleEnd}
                              startDate={endDate}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className=" col-md-3 price ">
                      <label
                        htmlFor="price"
                        className="mb-2 text-secondary form-label"
                        style={{ fontSize: "12px" }}
                      >
                        price
                      </label>
                      <input
                        type="number"
                        name="price"
                        id="price"
                        className="form-control"
                        onChange={handleInput}
                        value={payload.price}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className=" col-md-3 currency">
                      <label
                        htmlFor="currency"
                        className="mb-2 text-secondary form-label"
                        style={{ fontSize: "12px" }}
                      >
                        Currency
                      </label>
                      <input
                        type="text"
                        name="currency"
                        id="currency"
                        className="form-control"
                        onChange={handleInput}
                        value={payload.currency}
                      />
                    </div>

                    <div className="col-md-3 status mb-3">
                      <label
                        htmlFor="status"
                        className="mb-2 text-secondary form-label"
                        style={{ fontSize: "12px" }}
                      >
                        Status
                      </label>

                      <select
                        name="status"
                        id="status"
                        onChange={handleInput}
                        className="form-select"
                      >
                        <option disabled selected>
                          status
                        </option>
                        <option value="live">Live</option>
                        <option value="closed">Closed</option>
                      </select>
                    </div>
                    <div className=" col-md-3 select-rooms mb-3">
                      <label
                        htmlFor="avail_room_num"
                        className="mb-2 text-secondary form-label"
                        style={{ fontSize: "12px" }}
                      >
                        No of available rooms
                      </label>
                      <input
                        type="text"
                        name="avail_room_num"
                        id="avail_room_num"
                        className="form-control"
                        onChange={handleInput}
                        value={payload.avail_room_num}
                      />
                    </div>
                    <div className="release_date col-md-3">
                      <label
                        htmlFor="release_date"
                        className="mb-2 text-secondary form-label"
                        style={{ fontSize: "12px" }}
                      >
                        Min days to book room
                      </label>
                      <input
                        type="text"
                        name="release_date"
                        className="form-control"
                        id="release_date"
                        placeholder="Minimum days to book room"
                        aria-placeholder="Minimum days to book room"
                        onChange={handleInput}
                        value={payload.release_date}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <fieldset>
                    <legend>Occupants</legend>
                    <div className="row">
                      <div className="col-sm-3 form-group">
                        <label
                          htmlFor="occupy_min"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Min occupants (adult){" "}
                        </label>
                        <input
                          type="number"
                          name="occupy_min"
                          id="occupy_min"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.occupy_min}
                        />
                      </div>
                      <div className="col-sm-3 form-group">
                        <label
                          htmlFor="occupy_max"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Max occupants (adult){" "}
                        </label>
                        <input
                          type="number"
                          name="occupy_max"
                          id="occupy_max"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.occupy_max}
                        />
                      </div>
                      <div className="col-sm-3 form-group">
                        <label
                          htmlFor="occupant_min_child"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Min occupants (children){" "}
                        </label>
                        <input
                          type="number"
                          name="occupy_min_child"
                          id="occupy_min_child"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.occupy_min_child}
                        />
                      </div>
                      <div className="col-sm-3 form-group">
                        <label
                          htmlFor="occupant_max_child"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Max occupants (children){" "}
                        </label>
                        <input
                          type="number"
                          name="occupy_max_child"
                          id="occupy_max_child"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.occupy_max_child}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 form-group">
                        <label
                          htmlFor="child_age"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Child's Age{" "}
                        </label>
                        <input
                          type="number"
                          name="child_age"
                          id="child_age"
                          placeholder="Age"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.child_age}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 my-3">
                  <fieldset>
                    <legend>Meals</legend>
                    <div className="row">
                      <div className="col-sm-3">
                        <label
                          htmlFor="fbAdult"
                          style={{ fontSize: "12px" }}
                          className="mb-2 text-secondary form-label"
                        >
                          Full bar (adult)
                        </label>
                        <input
                          type="number"
                          name="fb_adult"
                          id="fbAdult"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.fb_adult}
                        />
                      </div>
                      <div className="col-sm-3">
                        <label
                          htmlFor="hbAdult"
                          style={{ fontSize: "12px" }}
                          className="mb-2 text-secondary form-label"
                        >
                          Half bar (adult)
                        </label>
                        <input
                          type="number"
                          name="hb_adult"
                          id="hbAdult"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.hb_adult}
                        />
                      </div>
                      <div className="col-sm-3">
                        <label
                          htmlFor="fbChild"
                          style={{ fontSize: "12px" }}
                          className="mb-2 text-secondary form-label"
                        >
                          Full bar (child)
                        </label>
                        <input
                          type="number"
                          name="fb_child"
                          id="fbChild"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.fb_child}
                        />
                      </div>
                      <div className="col-sm-3 form-group">
                        <label
                          htmlFor="hbChild"
                          style={{ fontSize: "12px" }}
                          className="mb-2 text-secondary form-label"
                        >
                          Half bar (child)
                        </label>
                        <input
                          type="number"
                          name="hb_child"
                          id="hbChild"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.hb_child}
                        />
                      </div>
                    </div>
                    {/* end of first row */}
                    <div className="row">
                      <div className="col-sm-6">
                        <label
                          htmlFor="breakfast_adult"
                          style={{ fontSize: "12px" }}
                          className="mb-2 text-secondary form-label"
                        >
                          Adult's breakfast
                        </label>
                        <input
                          type="number"
                          name="breakfast_adult"
                          id="breakfast_adult"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.breakfast_adult}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="breakfast_child"
                          style={{ fontSize: "12px" }}
                          className="mb-2 text-secondary form-label"
                        >
                          Child's breakfast
                        </label>
                        <input
                          type="number"
                          name="breakfast_child"
                          id="breakfast_child"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.breakfast_child}
                        />
                      </div>
                    </div>
                    {/* end of breakfast */}
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <label
                              htmlFor="sai_adult"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              Soft all inclusive adult{" "}
                            </label>
                            <input
                              type="number"
                              name="sai_adult"
                              id="sai_adult"
                              className="form-control"
                              onChange={handleInput}
                              value={payload.sai_adult}
                            />
                          </div>
                          <div className="col-6">
                            <label
                              htmlFor="sai_child"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              Soft all inclusive child{" "}
                            </label>
                            <input
                              type="number"
                              name="sai_child"
                              id="sai_child"
                              className="form-control"
                              onChange={handleInput}
                              value={payload.sai_child}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <label
                              htmlFor="uai_adult"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              Ultra all inclusive adult{" "}
                            </label>
                            <input
                              type="number"
                              name="uai_adult"
                              id="uai_adult"
                              className="form-control"
                              onChange={handleInput}
                              value={payload.uai_adult}
                            />
                          </div>
                          <div className="col-6">
                            <label
                              htmlFor="uai_child"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              Ultra all inclusive child{" "}
                            </label>
                            <input
                              type="number"
                              name="uai_child"
                              id="uai_child"
                              className="form-control"
                              onChange={handleInput}
                              value={payload.uai_child}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end of sai */}
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6">
                            <label
                              htmlFor="all_inclusive_adult"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              All inclusive adult
                            </label>
                            <input
                              type="number"
                              name="all_inclusive_adult"
                              id="all_inclusive_adult"
                              className="form-control"
                              onChange={handleInput}
                              value={payload.all_inclusive_adult}
                            />
                          </div>
                          <div className="col-6">
                            <label
                              htmlFor="all_inclusive_child"
                              className="mb-2 text-secondary form-label"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              All inclusive child
                            </label>

                            <input
                              type="number"
                              name="all_inclusive_child"
                              id="all_inclusive_child"
                              className="form-control"
                              onChange={handleInput}
                              value={payload.all_inclusive_child}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12">
                  <fieldset>
                    <legend>Fines</legend>
                    <div className="row">
                      <div className="form-group col-sm-6">
                        <label
                          htmlFor="cancel_days"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Days before cancelling bookings{" "}
                        </label>
                        <input
                          type="number"
                          name="cancel_days"
                          id="cancel_days"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.cancel_days}
                        />
                      </div>

                      <div className="col-sm-6">
                        <label
                          htmlFor="cancel_penalty"
                          className="mb-2 text-secondary form-label"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Cancellation Fine{" "}
                        </label>
                        <input
                          type="number"
                          name="cancel_penalty"
                          id="cancel_penalty"
                          className="form-control"
                          onChange={handleInput}
                          value={payload.cancel_penalty}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </form>
            </div>
            <div className={`d-flex mb-3 `}>
              <button
                className="btn btn-lg btn-primary mb-2 w-50 m-auto"
                style={{ padding: "11px" }}
                onClick={handleSubmit}
              >
                Create Contract
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ContractModal;
