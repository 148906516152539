import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { TimestampConverter } from '../../../../../../controllers/controller'
import { mainGetUsertoken } from '../../../../Reusables/GetUsertoken/GetUsertoken'
import { listContractHotel } from '../../../../Super/apis'

const ContractView = ({open, handleClose, hoteltoken, name}) => {
    const location = useLocation()
    const history = useHistory()
    const  [user, setUser] = useState({})
    const  [usertoken, setUsertoken]=useState({})
    const [conList, setConList]= useState([])
    useEffect(()=>{
        mainGetUsertoken(location, setUsertoken, setUser)
    },[])

    const contractDetails = useMutation(listContractHotel, {
        onSuccess: (res)=>{
            console.log(res)
            if(res.data.length > 0){
                setConList(res.data)
            }
        },
        onError: (err)=>{console.error(err)}
    })


    useEffect(()=>{

        if(hoteltoken){
            contractDetails.mutate({
                apptoken: process.env.REACT_APP_APP_TOKEN,
                hoteltoken,
            })
        }
    },[hoteltoken])
    console.log({contractDetails})

    const handleEdit = (...args) => {
        const [ id, token, hotelname, country, city, contract_name, child_age_From, child_age_To, status, currency, ] = args;
        
        localStorage.setItem("CT", id);
        localStorage.setItem("hotel", token);
        localStorage.setItem("Count", country);
        localStorage.setItem("City", city);
        localStorage.setItem("hotelname", hotelname);
        localStorage.setItem(
          "contract_info",
          JSON.stringify({
            contract_name: contract_name,
            status: status,
            child_age_From: child_age_From,
            child_age_To: child_age_To,
            currency: currency,
          })
        );
        history.push("/dmc/contracts/create");
      };
  return (
    <Modal
        show={open}
        onHide={handleClose}
        dialogClassName=""
        centered
      >
        <div className="py-5 px-5 modals">
          <div className="d-flex flex-column justify-content-start  mb-5">
            <h4 className="text-primary fw-bold">{name} Contract Details</h4>
          </div>
          <Modal.Body className="mb-5">
            {
                contractDetails.isLoading ? <div className="spinner-border text-primary">
                    <div className="visually-hidden">Loading...</div>
                </div>
                : 

                <div className="table-responsive">
                    <table className="table table-borderr">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Contract</th>
                                <th>Date</th>
                                <th>City</th>
                                <th>Country</th>
                                <th>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                conList?.map((item, i)=>(
                                    <tr key={i} style={{cursor: "pointer"}}>
                                        <td onClick={()=>handleEdit(item.contract_id, item.hoteltoken, item.hotelname, item.country, item.city, item.contract_name, item.child_age_From, item.child_age_To, item.status,item.currency )}>{ i + 1}</td>
                                        <td onClick={()=>handleEdit(item.contract_id, item.hoteltoken, item.hotelname, item.country, item.city, item.contract_name, item.child_age_From, item.child_age_To, item.status,item.currency )}>{item.contract_name}</td>
                                        <td onClick={()=>handleEdit(item.contract_id, item.hoteltoken, item.hotelname, item.country, item.city, item.contract_name, item.child_age_From, item.child_age_To, item.status,item.currency )}>{`${item.start_date && TimestampConverter(item.start_date)} - ${item.expiry_date && TimestampConverter(item.expiry_date)}`}</td>
                                        <td onClick={()=>handleEdit(item.contract_id, item.hoteltoken, item.hotelname, item.country, item.city, item.contract_name, item.child_age_From, item.child_age_To, item.status,item.currency )}>{item.city}</td>
                                        <td onClick={()=>handleEdit(item.contract_id, item.hoteltoken, item.hotelname, item.country, item.city, item.contract_name, item.child_age_From, item.child_age_To, item.status,item.currency )}>{item.country}</td>
                                        <td onClick={()=>handleEdit(item.contract_id, item.hoteltoken, item.hotelname, item.country, item.city, item.contract_name, item.child_age_From, item.child_age_To, item.status,item.currency )}>{item.status}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
        </div>    
     </Modal>
        
  )
}

export default ContractView