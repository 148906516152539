import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Fetch } from "../../../../../controllers/controller";
import Loading from "../../../../Reusables/Loader/Loading";

const DeleteCate = ({ name, open, handleCateRemove, listCategory }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  const confirmDelete = () => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("category", name);

    setLoading(true);
    Fetch(`${process.env.REACT_APP_END_POINT}/delete-room-category`, formData)
      .then((res) => {
        listCategory();
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "success", value: res.data.message });
        handleCateRemove();
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: err.message });
      });
  };
  return (
    <div className="deletePrompt">
      <Modal
        show={open}
        onHide={handleCateRemove}
        dialogClassName="deletePrompt"
        centered
      >
        {loading ? (
          <Loading heightvalue="55vh" />
        ) : (
          <Modal.Body>
            <p className="lead text-center">
              Delete{" "}
              <span
                className="text-capitalize"
                dangerouslySetInnerHTML={{ __html: name }}
              />
              ?
            </p>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
              <button className="btn btn-secondary" onClick={handleCateRemove}>
                Cancel
              </button>
              <button className="btn btn-danger" onClick={confirmDelete}>
                Delete
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default DeleteCate;
