import React from "react";
import Discount from "../../../Images/Discount.png"

const DetailedCards = ({cardImage, cardTitle, time, price, discount}) => {
  return (
    <div className="card promotion-card" style={{height:"280px"}}>
      <img src={cardImage} className="card-img-top" style={{height:"60%"}} alt="..." />
      <div className="card-body">
            <h5 className="card-title title">{cardTitle}</h5>
        <div className="d-flex justify-content-between align-items-center">
          <div className="left align-self-end">
            <div className="time text-muted ">{time}</div>
          </div>
          <div className="right">
            <div className="price text-danger">{price}</div>
            <div className="discount fw-bolder">{discount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedCards;
