import React, {useState} from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import Header from '../../Junior/Header/Header'
import HotelState from '../../Junior/Manage/Context/hotelState'
import EditContract from '../../Reusables/EditContract/EditContract'

import Contracts from './Contracts/Contracts'
import Dashboard from './Dashboard/Dashboard'
import Manage from './Manage/manage'
import Overrides from './Overrides/Overrides'
import Packages from './Packages/Packages'
import Services from '../../Reusables/Services/Services'
import Sidebar from './Sidebar/sidebar'
import History from '../../Reusables/Wallet/History'
import Bookings from "../../Reusables/Bookings/Bookings"
import E404 from '../../../404/404'
import MapRooms from './Manage/Hotels/MapRooms'
import Settings from '../../Reusables/Settings'

const DMC = () => {
  const [sidebar, setSidebar] = useState("hide");
  const [mainWrapper, setMainWrapper] = useState("")

  const [main, setMain] = useState("col-12")
  const handleClick = () => {
    sidebar === "hide" ? setSidebar("showsidebar"): setSidebar("hide")
    sidebar === "hide" ? setMainWrapper("d-flex justify-content-end wrapper-width") : setMainWrapper("mainWrapper")

  };
  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear()
    history.push("/login")
  }
    const location = useLocation();
    return (  
      <HotelState>
        <div>
          <div className="dash h-100 d-flex justify-content-end" style={{position:"relative",  minHeight:"100vh"}}>
          <Sidebar sidebar={sidebar} close={handleClick} handleLogout={handleLogout} />
          <div className={`${mainWrapper} px-0 mainWrapper`}>
            <div className={` ${main}`} >
            <Header handleClick={handleClick} navButton="Change Password" />
              <Switch>
                <Route exact path={["/dmc", "/dmc/dashboard"]} component={Dashboard} />
                <Route path="/dmc/contracts" component={Contracts} /> 
                <Route path="/dmc/hotels" component={Manage} />
                {/* <Route path="/dmc/sources" component={MapRooms} /> */}
                <Route path="/dmc/update-contract" component={EditContract} />
                <Route path="/dmc/overrides" component={Overrides} />
                <Route path="/dmc/services" component={Services} />
                <Route path="/dmc/packages" component={Packages} />
                <Route path="/dmc/wallet-history" component={History} />
                <Route path="/dmc/bookings" component={Bookings} />
                <Route path="/dmc/profile" component={Settings} />
                <Route path="*" component={E404} />
              </Switch>
            </div>
          </div>
        </div>
        </div>
      </HotelState>
    )
}

export default DMC

