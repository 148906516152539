import React, {useState} from 'react'
import { Fetch, TimestampConverter } from '../../../../controllers/controller'
import useFetch from "../../../Reusables/Fetch/Fetch"
import DeleteOverride from './DeleteOverride';
import AlertComp from '../../../Reusables/AlertComp/AlertComp'
import Loading from '../../../Reusables/Loader/Loading';


const OverrideTable = ({load, setter, search}) => {
    const [showMarkDelete, setShowMarkDelete] = useState(false);
    const [id, setId] = useState("");
    // const [overrideList, setOverrideList] = useState([]);
    const [edit, setEdit] = useState(false)
    const [blur, setBlur] = useState(false)
    const [blur2, setBlur2] = useState(false)
    const [ payload, setPayload] = useState({})
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlertType] = useState({
        type: "",
        value: "",
    });


    const handleBlur = (e) => {
        setBlur(!blur)
    }
    const handleFocus = (e) => {
        setBlur(!blur)
    }
    const handleBlur2 = (e) => {
        setBlur2(!blur2)
    }
    const handleFocus2 = (e) => {
        setBlur2(!blur2)
    }
    const handleAlertClose = () => {
        setShowAlert(false);
    };
    const handleChange = (e) => {
        setPayload({...payload, [e.target.name]: e.target.value})
    }
    const handleEdit = (id, index) => {
        setEdit(index)
        setPayload({...data[index]})
    }
    const handleSave = (index) => {
        formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
        formData.append("type", payload.type);
        formData.append("target_amount1",  payload.target_amount1);
        formData.append("target_amount2",  payload.target_amount2);
        formData.append("target_amount3",  payload.target_amount3);
        formData.append("recipient",  payload.recipient);
        formData.append("nature",  payload.nature);
        formData.append("id",  payload.id);
        formData.append("country",  payload.country);
        formData.append("hotelname",  payload.hotelname);
        formData.append("override1",  payload.override1);
        formData.append("override2",  payload.override2);
        formData.append("override3",  payload.override3);
        formData.append("start_duration",  payload.start_duration);
        formData.append("end_duration",  payload.end_duration);
        Fetch(`${process.env.REACT_APP_END_POINT}/update-override`, formData)
          .then((res) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "success", value: res.data.message });
            setter(!load)
            setEdit(false)
          })
          .catch((err) => {
            setLoading(false);
            setShowAlert(true);
            setAlertType({ ...alert, type: "danger", value: err.message });  
        });
      
    }
    // const [overList, setOverList] = useState([])


    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN); 
    const {data, setLoading, loading} = useFetch("/list-override", formData, load)

    // useEffect(() => {
    //     const formData = new FormData();
    //     formData.append("apptoken", process.env.REACT_APP_APP_TOKEN); 
    //     Fetch(`${process.env.REACT_APP_END_POINT}/list-override`, formData)
    //     .then((res)=>{
    //         if(res.data.message === "No results"){
    //         window.alert(res.data.message)
    //         } else {
    //         setOverrideList([...res.data])
    //         }
    //     })
    //     .catch((err) => console.log(err))
    // }, [load])

    const handleClick = (listId)=> {
        setShowMarkDelete(true)
        setId(listId)
    }
    return (
        <div className="table-responsive">
            {showAlert === true && (
                <AlertComp
                  variant={alert.type}
                  alertText={alert.value}
                  handleAlertClose={handleAlertClose}
                />
              )}

              {
                loading ? <div className="spinner-border text-primary">
                    <div className="visually-hidden">Loading...</div>
                </div> :
                <table className="table table-striped table-bordered text-center pricing_table">
                    <thead className="table-primary">
                        <th>S/N</th>
                        <th>Recipient</th>
                        <th>Type</th>
                        <td>Start</td>
                        <td>End</td>
                        <td>Country</td>
                        <td>Hotel</td>
                        <th>Nature</th>
                        <th>Target 1</th>
                        <th>Override 1</th>
                        <th>Target 2</th>
                        <th>Override 2</th>
                        <th>Target 3</th>
                        <th>Override 3</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.filter(item=>(
                          item.recipient.toLowerCase().includes(search.toLowerCase()) ||
                          item.type.toLowerCase().includes(search.toLowerCase()) ||
                          item.country.toLowerCase().includes(search.toLowerCase()) ||
                          item.hotelname.toLowerCase().includes(search.toLowerCase())
                        
                        )).map((list, index)=>(
                            edit === index ? (
                                <tr>
                                <td>{index}</td>
                                <td>{list.recipient}</td>
                                <td>{list.type}</td>
                                <td>
                                    {list.start_date && TimestampConverter(list.end_date)}
                                    <input
                                    id="start_duration"
                                    name="start_duration"
                                    className="form-control border border-dark col border border-dark"
                                    type="date"
                                    onChange={handleChange}
                                    // value={formik.values.start_duration}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    placeholder="Date From"
                                    />
                                </td>
                                <td>
                                    {list.end_date && TimestampConverter(list.end_date)}
                                    <input
                                    id="end_duration"
                                    name="end_duration"
                                    className="form-control border border-dark col"
                                    type="date"
                                    onChange={handleChange}
                                    // value={formik.values.end_duration}
                                    onFocus={handleFocus2}
                                    onBlur={handleBlur2}
                                    placeholder="Date To"
                                    />
                                </td>
                                <td><input type="text" name="country" id="country" className="form-control" onChange={handleChange} disabled value={payload.country} /></td>
                                <td><input type="text" name="hotel" id="hotel" className="form-control" onChange={handleChange} disabled value={payload.hotelname} /></td>
                                <td> 
                                <select
                                    name="nature"
                                    id="nature"
                                    className="form-select border border-dark"
                                    onChange={handleChange}
                                    value={payload.nature}
                                >
                                    <option value="" default>
                                    Nature
                                    </option>
                                    <option value="In">In</option>
                                    <option value="out">Out</option>
                                </select>
                                </td>
                                <td><input type="number" name="target_amount1" id="target_amount1" className="form-control" onChange={handleChange} value={payload.target_amount1} /></td>
                                <td><input type="number" name="override1" id="override1" className="form-control" onChange={handleChange} value={payload.override1} /></td>
                                <td><input type="number" name="target_amount2" id="target_amount2" className="form-control" onChange={handleChange} value={payload.target_amount2} /></td>
                                <td><input type="number" name="override2" id="override2" className="form-control" onChange={handleChange} value={payload.override2} /></td>
                                <td><input type="number" name="target_amount3" id="target_amount3" className="form-control" onChange={handleChange} value={payload.target_amount3} /></td>
                                <td><input type="number" name="override3" id="override3" className="form-control" onChange={handleChange} value={payload.override3} /></td> 
                                <td className="d-flex">
                                    <i className="bi bi-check-lg text-success me-2" onClick={()=>handleSave(index)}></i>
                                    <i className="bi bi-x-lg text-danger" onClick={()=> setEdit("")}></i>
                                </td>
                                </tr>
                            ) :(

                            <tr>
                                <td>{index + 1}</td>
                                <td>{list.recipient}</td>
                                <td>{list.type}</td>
                                {/* <td>{list.start_duration}</td>
                                <td>{list.end_duration}</td> */}
                                <td>{list.start_duration && TimestampConverter(list.start_duration)}</td>
                                <td>{list.end_duration && TimestampConverter(list.end_duration)}</td>
                                <td dangerouslySetInnerHTML={{__html: list.country === "UNDEFINED" ? "-": list.country}} />
                                <td dangerouslySetInnerHTML={{__html: list.hotelname === "UNDEFINED" ? "-": list.hotelname}} />
                                <td>{list.nature}</td>
                                <td>{list.target_amount1}</td>
                                <td>{list.override1}</td>
                                <td>{list.target_amount2}</td>
                                <td>{list.override2}</td>
                                <td>{list.target_amount3}</td>
                                <td>{list.override3}</td>
                                <td className="d-flex">
                                    <i className="bi bi-pen-fill text-warning me-2" onClick={()=>handleEdit(list.id, index)}></i>
                                    <i className="bi bi-trash text-danger" onClick={()=>handleClick(list.id)}></i>
                                </td>
                            </tr>
                            )

                        ))}
                    </tbody>
                </table>      

              }
            <DeleteOverride show={showMarkDelete} id={id}  handleRemove={()=>setShowMarkDelete(false)} load={setLoading}  />

        </div>
    )
}

export default OverrideTable
