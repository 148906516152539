import React, { useState, useEffect } from "react";
import { Fetch } from "../../../../../../controllers/controller";
import { Cards } from "../../../superData/data";
import DashboardCard from "../DashboardCard";
import Services from "../../../../../../Images/Icons/services-card.png";

const MainCard = () => {
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });
  useEffect(() => {
    const formData = new FormData();
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);

    Fetch(`${process.env.REACT_APP_END_POINT}/system-wallet-balance`, formData)
      .then((res) => {
        setBalance(res.data.systemWalletBalance);
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: err.message,
        });
      });
  }, []);
  return (
    <div className="card-row">
      {Cards.map((card) => (
        <DashboardCard
          key={card.id}
          text={card.color}
          color={card.background}
          title={card.title}
          footer={card.footer}
          logo={card.icon}
          lowerImage="d-none"
          amount={card.amount}
        />
      ))}
      <DashboardCard
        text={"#EB5B9D"}
        title={"Wallet"}
        footer={"Total Balance"}
        logo={Services}
        lowerImage="d-none"
        amount={balance}
        color={"#EB0B50"}
        juniorBackground={"#EB5B9D"}
      />
    </div>
  );
};

export default MainCard;
