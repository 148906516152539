import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Fetch, TimestampConverter } from "../../../../controllers/controller";
import { getUsertoken } from "../GetUsertoken/GetUsertoken";
import EditService from "./EditService";
import Loading from "../../../Reusables/Loader/Loading";
import AlertComp from "../../../Reusables/AlertComp/AlertComp";
const TableContent = ({ index, item, handleClick }) => {
  const [edit, setEdit] = useState(true);
  const [ID, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleEdit = (id) => {
    setShowEdit(true)
    setId(id);
  };

  // const handleClose = () => {
  //   setShowEdit(false)
  // };
  const handleDelete = (id) => {
    // set id
    setDeleteId(id);
    // open confirm modal
    setShow(true);
    //
  };
  return (
    <>
      {/* {edit && item.id === ID ? (
        <EditService index={index} item={item} edit={setEdit} />
      ) : ( */}
        <tr>
          <td>{index + 1}</td>
          <td>{TimestampConverter(item.timestamp)}</td>
          <td>{TimestampConverter(item.date_from)}</td>
          <td>{TimestampConverter(item.date_to)}</td>
          <td>{item.service_name}</td>
          <td>{item.location}</td>
          <td>{item.country}</td>
          <td>{item.city}</td>
          <td>{item.price_adult}</td>
          <td>{item.price_child}</td>
          <td>{item.discounts}</td>
          <td>{TimestampConverter(item.discount_from)}</td>
          <td>{TimestampConverter(item.discount_to)}</td>
          <td>{item.min_pax_discount}</td>
          <td>{item.description}</td>
          <td>
            <div className="d-flex justify-content-around">
              <i
                className="bi bi-pen-fill text-warning"
                onClick={() => handleEdit(item.id, index)}
              ></i>
              <i
                className="bi bi-trash-fill text-danger"
                onClick={() => handleDelete(item.id)}
              ></i>
            </div>
          </td>
        </tr>
      {/* )} */}
      
      {
        // check id to display modals
      }
      <ConfirmDelete
        show={show}
        handleClose={() => setShow(false)}
        id={deleteId}
        centered
      />
      <EditService
       show={showEdit}
       item={item}
        handleClose={() => setShowEdit(false)}
        id={ID} />
    </>
  );
};

const ConfirmDelete = ({ handleClose, show, id }) => {
  const location = useLocation();
  const [usertoken, setUsertoken] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [reload, setReload] = useState(false);
  const [alert, setAlertType] = useState({
    type: "",
    value: "",
  });

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    getUsertoken(location, setUsertoken);
  }, []);

  function deleteService(e) {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    // formData.append("usertoken", usertoken);
    formData.append("apptoken", process.env.REACT_APP_APP_TOKEN);
    formData.append("id", id);

    Fetch(`${process.env.REACT_APP_END_POINT}/delete-service`, formData)
    .then((res) => {
      setLoading(false);
      if (res.data.success === false) {
        setShowAlert(true);
        setAlertType({ ...alert, type: "danger", value: res.data.message });
        setReload(true);
        setTimeout(() => {
          setReload(false);
        }, 1000);
      } else {
        setShowAlert(true);
        setAlertType({
          ...alert,
          type: "success",
          value: res.data.message,
        });
        setReload(true);
        setTimeout(() => {
          setReload(false);
          window.location.reload();
        }, 1000);
      }
    })
    .catch((err) => {
      setLoading(false);
      setShowAlert(true);
      setAlertType({ ...alert, type: "danger", value: err.message });
      window.location.reload();
    });
  }

  return (
    <Modal show={show} onHide={handleClose} centered className="editService">
      {loading ?<Loading />:(
      <>
      <Modal.Header>
        <div className="w-100">
          <h5 className="text-dark text-uppercase text-center">
            Delete Service
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
        {showAlert === true && (
            <AlertComp
              variant={alert.type}
              alertText={alert.value}
              handleAlertClose={handleAlertClose}
            />
          )}
        <div className="text-center">
          <p className="text-center text-danger fw-bolder text-uppercase">
            Delete Service?
          </p>
        </div>
        <div className="d-flex justify-content-between ">
          <button className="btn btn-danger" onClick={deleteService}>
            Delete
          </button>
          <button className="btn btn-dark" onClick={handleClose}>
            Cancel
          </button>
        </div>
        </>
      </Modal.Body>
      </>)}
    </Modal>
  );
};

export default TableContent;
